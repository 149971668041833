import React, { Component } from 'react';
import { ProfileViewWrapper } from './styles';
import {
  Text,
  MainTitleDark,
  MobileContent,
} from '../../components/MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';
import ProfileShortViewContainer from '../ProfileShortViewContainer/ProfileShortViewContainer';

export default class ProfileViewContainer extends Component {
  render() {
    return (
      <ProfileViewWrapper as={MobileContent}>
        <MainTitleDark>Profile</MainTitleDark>
        <Text color={mainTheme.colors.grey}>
          Your profile will be how users of the app see your venue.
          <br />
          <br />
          Complete this form and see how your profile will appear on the app in
          the preview below.
        </Text>
        <ProfileShortViewContainer />
      </ProfileViewWrapper>
    );
  }
}
