import React from 'react';
import PropTypes from 'prop-types';
import { PeriodsWrapper, PeriodButton } from './styles';
import { chartPeriods } from '../../constants/periods';

export default class PeriodsComponent extends React.PureComponent {
  static propTypes = {
    active: PropTypes.number,
    onPeriodPress: PropTypes.func,
  };

  static defaultProps = {
    onPeriodPress: () => {},
    active: null,
  };

  onPeriodPress = (id) => (event) => {
    this.props.onPeriodPress(id);
  };

  renderPeriod = (period) => (
    <PeriodButton
      className={period.id === this.props.active && 'active'}
      key={period.id}
      onClick={this.onPeriodPress(period.id)}
    >
      {period.label}
    </PeriodButton>
  );

  render() {
    return (
      <PeriodsWrapper>{chartPeriods.map(this.renderPeriod)}</PeriodsWrapper>
    );
  }
}
