import { fork, put, takeLatest } from 'redux-saga/effects';
import client, { handleRequestError } from '../../utils/client';
import postActions from './post.actions';

function* fetchPost(action) {
  try {
    const response = yield client.get(`posts/${action.payload._id}`);
    yield put(postActions.fetchPostSuccess(response.data.post));
  } catch (error) {
    yield handleRequestError(error, postActions.fetchFailed, fetchPost, action);
  }
}
function* createPost(action) {
  try {
    const response = yield client.post('posts', action.payload.post);
    yield put(postActions.fetchCreatePostSuccess(response.data.post));
  } catch (error) {
    yield handleRequestError(
      error,
      postActions.fetchCreatePostFailed,
      createPost,
      action
    );
  }
}

function* deletePost(action) {
  try {
    yield client.delete(`posts/${action.payload._id}`);
    yield put(postActions.fetchDeletePostSuccess(action.payload._id));
  } catch (error) {
    yield handleRequestError(
      error,
      postActions.fetchDeletePostFailed,
      deletePost,
      action
    );
  }
}
function* PostFlow() {
  yield takeLatest(postActions.fetchPostPending, fetchPost);
  yield takeLatest(postActions.fetchCreatePostPending, createPost);
  yield takeLatest(postActions.fetchDeletePostPending, deletePost);
}

function* postWorker() {
  yield fork(PostFlow);
}

export default postWorker;
