import styled from 'styled-components';
import { Column } from '../../components/MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';

export const LoaderContent = styled(Column)`
  background: ${mainTheme.colors.main};
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
`;
