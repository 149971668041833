import React, { useCallback, useEffect, useState } from 'react';
import ImagePickerComponent from '../ImagePickerComponent/ImagePickerComponent';
import {
  RowOffsetBottom,
  FieldLabel,
  SectionLabel,
  InputMain,
  Column,
} from '../MainComponents/MainComponents';

const ImageHighlightsComponent = (props) => {
  const [highlights, setHighlights] = useState(['', '', '']);

  const handleUpload = (index, value) => {
    const temp = [...highlights];
    temp[index] = value;
    setHighlights(temp);
  };

  useEffect(() => {
    props.handleChange('highlights', highlights);
  }, [highlights]);

  useEffect(() => {
    if (props.data) {
      setHighlights(props.data);
    }
  }, [props.data]);

  return (
    <RowOffsetBottom>
      <ImagePickerComponent
        imageWidth={154}
        defaultValue={highlights ? highlights[0] : ''}
        imageHeight={154}
        onChange={(value) => handleUpload(0, value)}
        name="logo"
      />
      <ImagePickerComponent
        imageWidth={154}
        defaultValue={highlights ? highlights[1] : ''}
        imageHeight={154}
        onChange={(value) => handleUpload(1, value)}
        name="logo"
      />
      <ImagePickerComponent
        imageWidth={154}
        defaultValue={highlights ? highlights[2] : ''}
        imageHeight={154}
        onChange={(value) => handleUpload(2, value)}
        name="logo"
      />
    </RowOffsetBottom>
  );
};

export default ImageHighlightsComponent;
