import React, { Component } from 'react';
import ChartComponent from 'react-chartjs-2';
import styled from 'styled-components';
import {
  PaperComponent,
  SmallBlock,
  ColumnOffsetBottom,
  FieldLabel,
} from '../../components/MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const Wrapper = styled(PaperComponent)`
  min-width: 100%;
`;

const Title = styled(FieldLabel)`
  font-size: 14px;
  text-align: center;
`;

const options = {
  responsive: true,
  legend: {
    position: 'left',
    labels: {
      fontFamily: 'Avenir Medium',
      fontColor: mainTheme.colors.black,
      usePointStyle: true,
      boxWidth: 10,
      boxHeight: 10,
    },
  },
  tooltips: {
    callbacks: {
      label(tooltipItem, data) {
        const dataset = data.datasets[tooltipItem.datasetIndex];
        const meta = dataset._meta[Object.keys(dataset._meta)[0]];
        const { total } = meta;
        const currentValue = dataset.data[tooltipItem.index];
        const percentage = parseFloat(
          ((currentValue / total) * 100).toFixed(1)
        );
        return `${percentage}%`;
      },
      title(tooltipItem, data) {
        return data.labels[tooltipItem[0].index];
      },
    },
  },
};

const testData = {
  labels: ['Red', 'Green', 'Yellow'],
  datasets: [
    {
      data: [
        getRandomInt(50, 200),
        getRandomInt(100, 150),
        getRandomInt(150, 250),
      ],
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
    },
  ],
};

class DoughnutChart extends Component {
  static defaultProps = {
    title: 'Chart Title',
    data: testData,
  };

  render() {
    const { title, data } = this.props;
    return (
      <Wrapper>
        <ColumnOffsetBottom>
          <Title>{title}</Title>
        </ColumnOffsetBottom>

        <ChartComponent
          type="pie"
          data={data}
          options={options}
          style={{ width: '100%' }}
        />
      </Wrapper>
    );
  }
}

export default DoughnutChart;
