import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectAuthErrors,
  selectAuthMessage,
  selectAuthPending,
} from '../../redux/auth/auth.selectors';
import authActions from '../../redux/auth/auth.actions';
import { serializeForm } from '../../utils/selectors';
import ForgotPasswordComponent from '../../components/ForgotPasswordComponent-v2/ForgotPasswordComponent';
import { StyledSigninPageWrapper } from '../SigninContainer-v2/styles';

import bgImage from '../../assets/images/backgrounds/signInSignUp.jpg';
import { StyledLayout } from '../../screens/AuthScreen-v2/styles';

export class ForgotPasswordContainer extends Component {
  state = {
    salt: '',
  };

  resetPassword = (event) => {
    event.preventDefault();
    const values = serializeForm(event.target);
    this.props.fetchResetPasswordPending({ ...values });
  };

  render() {
    return (
      <>
        <StyledLayout login bgImage={bgImage}>
          <StyledSigninPageWrapper>
            <ForgotPasswordComponent
              resetPassword={this.resetPassword}
              email={this.props.email}
              errors={this.props.errors}
              message={this.props.message}
            />
          </StyledSigninPageWrapper>
        </StyledLayout>

        {/* old version */}
        {/* <AuthForm fullWidth as={'form'} onSubmit={this.resetPassword} maxWidth={465}>
					<Column>
						<InputValidation
							defaultValue={this.props.email}
							type={'email'}
							error={this.props.errors['username']}
							name={'email'}
							autoFocus
							placeholder={'Email'}
						/>

						<MessageComponent type="danger" text={this.props.message} />

						<AuthButton type={'submit'}>Reset password</AuthButton>

						<Divider />
						<HelperLink to={'signup'}>
							<AuthLabelText>
								Don’t have an account? <span className={'text-medium'}>Sign up</span>
							</AuthLabelText>
						</HelperLink>
					</Column>
				</AuthForm> */}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  errors: selectAuthErrors,
  message: selectAuthMessage,
  pending: selectAuthPending,
});

const mapDispatchToProps = {
  fetchResetPasswordPending: authActions.fetchForgotPasswordPending,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordContainer);
