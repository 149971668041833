class Storage {
  writeMethod = null;

  set = null;

  get = null;

  clear = null;

  delete = null;

  test = ['{', '[', '"'];

  constructor() {
    this.checkWriteMethod();
    this.set = this.writeMethod === 'cookie' ? this.cookieSet : this.storageSet;
    this.get = this.writeMethod === 'cookie' ? this.cookieGet : this.storageGet;
    this.clear =
      this.writeMethod === 'cookie' ? this.cookieClear : this.storageClear;
  }

  getCookie = (name) => {
    const matches = document.cookie.match(
      new RegExp(
        `(?:^|; )${name.replace(
          /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
          '\\$1'
        )}=([^;]*)`
      )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  };

  setCookie = (name, value, options) => {
    options = options || {};

    let { expires } = options;

    if (typeof expires === 'number' && expires) {
      const d = new Date();
      d.setTime(d.getTime() + expires * 1000);
      expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
      options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    let updatedCookie = `${name}=${value}`;

    for (const propName in options) {
      updatedCookie += `; ${propName}`;
      const propValue = options[propName];
      if (propValue !== true) {
        updatedCookie += `=${propValue}`;
      }
    }

    document.cookie = updatedCookie;
  };

  checkWriteMethod = () => {
    try {
      localStorage.setItem('', '');
      localStorage.getItem('');
      localStorage.removeItem('');
      this.writeMethod = 'storage';
    } catch (error) {
      this.writeMethod = 'cookie';
    }
  };

  storageSet = (key, value) => {
    if (typeof key !== 'string')
      console.warn(
        'Storage error: set(key: string, value: any) key must be a string'
      );
    try {
      if (!key)
        console.warn(
          'Storage error: set(key: string, value: any) key is required parameter'
        );
      const json = JSON.stringify(value);
      localStorage.setItem(key, json);
    } catch (error) {
      console.warn(
        `Storage error: can't set 'set(key: string, value: any) key=${key} value=${value}`
      );
    }
  };

  sessionStorageSet = (key, value) => {
    if (typeof key !== 'string')
      console.warn(
        'SessionStorage error: set(key: string, value: any) key must be a string'
      );
    try {
      if (!key)
        console.warn(
          'SessionStorage error: set(key: string, value: any) key is required parameter'
        );
      const json = JSON.stringify(value);
      sessionStorage.setItem(key, json);
    } catch (error) {
      console.warn(
        `SessionStorage error: can't set 'set(key: string, value: any) key=${key} value=${value}`
      );
    }
  };

  storageClear = (key) => {
    try {
      if (!key)
        console.warn(
          'Storage error: clear(key: string) key is required argument'
        );
      if (typeof key !== 'string')
        console.warn('Storage error: clear(key: string) key must be a string');
      localStorage.removeItem(key);
    } catch (error) {
      console.warn(`Storage error: can't set clear(key: string) key=${key}`);
    }
  };

  sessionStorageClear = (key) => {
    try {
      if (!key)
        console.warn(
          'SessionStorage error: clear(key: string) key is required argument'
        );
      if (typeof key !== 'string')
        console.warn(
          'SessionStorage error: clear(key: string) key must be a string'
        );
      sessionStorage.removeItem(key);
    } catch (error) {
      console.warn(
        `SessionStorage error: can't set clear(key: string) key=${key}`
      );
    }
  };

  storageGet = (key, expected) => {
    let json = null;
    try {
      if (!key)
        console.warn(
          'Storage error: get(key: string, expected: any) key is required parameter'
        );
      json = localStorage.getItem(key);
      if (json === null) return expected || json;
      if (this.test.some((ch) => ch === json[0])) {
        const value = JSON.parse(json);
        if (expected !== undefined && typeof value !== typeof expected)
          console.warn(
            `Storage warn:  'get(key: string, expected: any) expected type !== value type,  expected=${typeof expected} value=${typeof value}`
          );
        return value;
      }
      if (expected !== undefined) return expected;
      return json;
    } catch (error) {
      console.warn(
        `Storage error: can't get 'get(key: string, expected: any) key=${key} expected=${expected}`
      );
      if (expected !== undefined) return expected;
      return json;
    }
  };

  sessionStorageGet = (key, expected) => {
    let json = null;
    try {
      if (!key)
        console.warn(
          'SessionStorage error: get(key: string, expected: any) key is required parameter'
        );
      json = sessionStorage.getItem(key);
      if (json === null) return expected || json;
      if (this.test.some((ch) => ch === json[0])) {
        const value = JSON.parse(json);
        if (expected !== undefined && typeof value !== typeof expected)
          console.warn(
            `SessionStorage warn:  'get(key: string, expected: any) expected type !== value type,  expected=${typeof expected} value=${typeof value}`
          );
        return value;
      }
      if (expected !== undefined) return expected;
      return json;
    } catch (error) {
      console.warn(
        `SessionStorage error: can't get 'get(key: string, expected: any) key=${key} expected=${expected}`
      );
      if (expected !== undefined) return expected;
      return json;
    }
  };

  cookieSet = (key, value) => {
    try {
      if (!key)
        console.warn(
          'Storage error: set(key: string, value: any) key is required parameter'
        );
      if (typeof key !== 'string')
        console.warn(
          'Storage error: set(key: string, value: any) key must be a string'
        );
      const json = JSON.stringify(value);
      document.cookie = `${key}=${json}`;
    } catch (error) {
      console.warn(
        `Storage error: can't set 'set(key: string, value: any) key=${key} value=${value}`
      );
    }
  };

  cookieClear = (key) => {
    try {
      if (!key)
        console.warn(
          'Storage error: clear(key: string) key is required argument'
        );
      if (typeof key !== 'string')
        console.warn('Storage error: clear(key: string) key must be a string');
      localStorage.removeItem(key);
      this.setCookie(key, '', {
        expires: -1,
      });
    } catch (error) {
      console.warn(`Storage error: can't clear(key: string) key=${key}`);
    }
  };

  cookieGet = (key, expected) => {
    let json = null;
    try {
      if (!key)
        console.warn(
          'Storage error: get(key: string, expected: any) key is required parameter'
        );
      if (typeof key !== 'string')
        console.warn(
          'Storage error: set(key: string, value: any) key must be a string'
        );
      json = this.getCookie(key);
      if (json === undefined) return expected || json;
      // if (json === undefined && expected !== null && expected === undefined)
      // 	console.warn(`Storage error: can't get(key: string, expected: any) no value with key=${key}`);
      if (this.test.some((ch) => ch === json[0])) {
        const value = JSON.parse(json);
        if (expected !== undefined && typeof value !== typeof expected)
          console.warn(
            `Storage warn:  'get(key: string, expected: any) expected type !== value type,  expected=${typeof expected} value=${typeof value}`
          );
        return value;
      }
      if (expected !== undefined && typeof value !== typeof expected)
        console.warn(
          `Storage warn:  'get(key: string, expected: any) expected type !== value type,  expected=${typeof expected} value=${typeof value}`
        );
      return json;
    } catch (error) {
      console.warn(
        `Storage error: can't get 'get(key: string, expected: any) key=${key} expected=${expected}`
      );
      if (expected !== undefined) return expected;
      return json;
    }
  };
}

const storage = new Storage();

export default storage;
