import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import ColorPickerComponent from '../../components/ColorPickerComponent-v2/ColorPickerComponent';
import DropDownComponent from '../../components/DropDownComponent-v2/DropDownComponent';
import DropDownImagePickerComponent from '../../components/DropDownImagePickerComponent-v2/DropDownImagePickerComponent';

import {
  selectTemp,
  selectProfileStamp,
  selectProfileErrors,
  selectProfilePending,
} from '../../redux/profile/profile.selectors';

import { StyledInputLabel } from '../../components/MainComponents/MainComponents';
import { StyledContainer, StyledHalfContainer } from './styles';

import { patterns } from '../../constants/patterns';

export class StampControlContainer extends Component {
  state = {
    total: 8,
    color: '#F5CC28',
    backgroundColor: '#FFFFFF',
    pattern: '26',
    textColor: '#2f2f2f',
  };

  componentDidUpdate(prevProps) {
    const { color, backgroundColor, pattern, textColor } = this.props;
    if (color && color !== this.state.color) {
      this.setState({ color });
    }
    if (backgroundColor && backgroundColor !== this.state.backgroundColor) {
      this.setState({ backgroundColor });
    }
    if (pattern && pattern !== this.state.pattern) {
      this.setState({ pattern });
    }
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
    this.props.handleChange(key, value);
  };

  getTotal = () => {
    const {
      total,
      temp: { stamp },
    } = this.props;
    let t = '';
    if (total) {
      t = total;
    }
    if (stamp.total) {
      t = stamp.total;
      if (stamp.total > 10) {
        t = 10;
      }
      // if (stamp.total < 3) {
      // 	t = 3;
      // }
    }
    return t;
  };

  getTextColor = () => {
    const {
      textColor,
      temp: { stamp },
    } = this.props;
    let color = textColor;
    if (stamp.textColor) {
      color = stamp.textColor;
    }
    return color;
  };

  render() {
    const patternOptions = [...patterns.slice(-1), ...patterns.slice(0, -1)];
    return (
      <div>
        <div style={{ marginBottom: '24px' }}>
          <StyledInputLabel>Total Stamps</StyledInputLabel>
          <DropDownComponent
            error={'textColor' in this.props.errors}
            onSelect={(event) => this.props.handleChange('total', event.value)}
            defaultValue={this.props.defaultValues.stamps[0].value}
            name="stamp.total"
            placeholder="Text Colour"
            options={this.props.defaultValues.stamps}
            value={this.props.defaultValues.stamps.filter(
              (option) => option.value === this.props.temp.stamp.total
            )}
            extractor="value"
          />
        </div>

        <StyledContainer>
          <StyledHalfContainer>
            <StyledInputLabel>Text Color</StyledInputLabel>
            <DropDownComponent
              error={'textColor' in this.props.errors}
              onSelect={(event) =>
                this.props.handleChange('textColor', event.value)
              }
              defaultValue={
                this.props.defaultValues.textColorOptions.filter(
                  (option) => option.value === this.getTextColor()
                )[0]
              }
              name="stamp.textColor"
              placeholder="Text Color"
              options={this.props.defaultValues.textColorOptions}
              value={this.props.defaultValues.textColorOptions.filter(
                (option) => option.value === this.props.temp.stamp.textColor
              )}
              extractor="value"
            />
          </StyledHalfContainer>
          <StyledHalfContainer>
            <StyledInputLabel>Stamp Color</StyledInputLabel>
            <ColorPickerComponent
              value={this.props.temp.stamp.color}
              defaultValue={this.state.color}
              name="stamp.color"
              onChange={(value) => this.props.handleChange('color', value)}
            />
          </StyledHalfContainer>
        </StyledContainer>

        <StyledContainer>
          <StyledHalfContainer>
            <StyledInputLabel>Background Pattern</StyledInputLabel>
            <DropDownImagePickerComponent
              defaultValue={this.state.pattern}
              name="stamp.pattern"
              onChange={(value) => this.props.handleChange('pattern', value)}
              error={'pattern' in this.props.errors}
              options={patternOptions}
              placeholder="Total Stamps"
              value={patternOptions.filter(
                (option) => option.id === this.props.temp.stamp.pattern
              )}
            />
          </StyledHalfContainer>
          <StyledHalfContainer>
            <StyledInputLabel>Background Color</StyledInputLabel>
            <ColorPickerComponent
              value={this.props.temp.stamp.backgroundColor}
              onChange={(value) =>
                this.props.handleChange('backgroundColor', value)
              }
              defaultValue={this.state.backgroundColor}
              name="stamp.background"
            />
          </StyledHalfContainer>
        </StyledContainer>
      </div>
    );
  }
}

const stampControlSelector = createSelector(
  selectTemp,
  selectProfileErrors,
  selectProfileStamp,
  selectProfilePending,
  (temp, errors, stamp, pending) => ({
    temp,
    errors,
    ...stamp,
    pending,
  })
);

const mapStateToProps = stampControlSelector;

export default connect(mapStateToProps)(StampControlContainer);
