import { combineActions, handleActions } from 'redux-actions';
import storage from '../../utils/storage';
import authActions from '../auth/auth.actions';
import paymentsActions from '../payments/payments.actions';
import profileActions from '../profile/profile.actions';
import userActions from './user.actions';

const user =
  window.user ||
  storage.get('user', {
    firstName: '',
    lastName: '',
    email: '',
  });
export const userInitialState = {
  message: '',
  errors: {},
  pending: false,

  isLogin:
    Boolean(storage.sessionStorageGet('bearer_token')) ||
    Boolean(storage.get('bearer_token')),

  subscription: {},
  profile: user.profile,
  approved: false,
  active: user.ative,
  _id: '',
  email: user.email,
  firstName: user.firstName | '',
  lastName: user.lastName | '',
  card: '',
  stripe: {},
};

const userReducer = handleActions(
  {
    // AUTH SUCCESS
    // down pending count,  set new user object and check login
    [combineActions(
      authActions.fetchSigninSuccess,
      authActions.fetchSignupSuccess
      // authActions.fetchSigninFacebookSuccess,
      // authActions.fetchSigninGoogleSuccess
    )]: (state, action) => ({
      ...state,
      ...action.payload.user,
      // pending: state.pending - 1,
      isLogin: !action.payload.step,
    }),

    // up pending count
    [combineActions(
      // appActions.init,
      userActions.fetchMePending,
      // userActions.fetchUpdateActivePending,
      userActions.updateAccountFetchPending,
      userActions.deletePending,
      userActions.updateAccountPasswordFetchPending,
      userActions.feedbackPending
    )]: (state, action) => ({ ...state, pending: true }),

    // down pending count and set new user object
    [combineActions(
      userActions.fetchMeSuccess,
      userActions.updateAccountFetchSuccess
    )]: (state, action) => ({
      ...state,
      ...action.payload.user,

      pending: false,
      isLogin: true,
    }),

    // down pending count
    [combineActions(
      userActions.fetchMeFailed,
      userActions.fetchUpdateActiveFailed,
      userActions.deleteFailed,
      userActions.updateAccountFetchFailed,
      userActions.updateAccountPasswordFetchFailed,
      userActions.feedbackFailed
    )]: (state, action) => ({
      ...state,
      errors: action.payload.errors,
      message: action.payload.message,

      pending: false,
    }),

    // CLEAR USER STATE ON LOGOUT or DELETING ACCOUNT
    [combineActions(
      userActions.clearUserState,
      authActions.logout,
      userActions.deleteSuccess
    )]: () => ({
      ...userInitialState,
      isLogin: false,
    }),

    [userActions.fetchUpdateActiveSuccess]: (state, action) => ({
      ...state,
      active: action.payload.active,
    }),

    [paymentsActions.fetchSubscribeSuccess]: (state, action) => ({
      // update user subscription on create
      ...state,
      subscription: action.payload.subscription,
    }),

    [profileActions.fetchCreateSuccess]: (state, action) => ({
      // update user subscription on create
      ...state,
      profile: action.payload.profile._id,
    }),

    [userActions.clearUserErrors]: (state, action) => ({
      ...state,
      errors: {},
    }),
    [combineActions(
      userActions.updateAccountPasswordFetchSuccess,
      userActions.feedbackSuccess
    )]: (state) => ({
      ...state,
      pending: false,
      errors: {},
    }),
    [paymentsActions.fetchCreateStripeCardSuccess]: (state, action) => ({
      ...state,
      card: action.payload.card,
    }),
  },
  userInitialState
);

export default userReducer;
