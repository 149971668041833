import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';

import {
  BoardComponent,
  Column,
  FieldLabel,
  HalfContent,
  HalfContentWrapper,
  InputMain,
  RightBlock,
  RowOffsetBottom,
  MainButton,
  Row,
  FromControls,
} from '../../components/MainComponents/MainComponents';
import userActions from '../../redux/user/user.actions';
import {
  selectUserErrors,
  selectUserObject,
  selectUserPending,
} from '../../redux/user/user.selectors';
import { serializeForm } from '../../utils/selectors';
import { mainTheme } from '../../mainStyles';
import { ClearButton } from '../../components/BottomControlPanel/styles';
import MessageComponent from '../../components/MessageComponent/MessageComponent';

const Separator = styled.div`
  width: 100%;
  height: 40px;
  display: none;
  visibility: hidden;

  @media (max-width: 645px) {
    display: block;
    visibility: visible;
  }
`;

class AccountFormContainer extends Component {
  updateUser = (event) => {
    event.preventDefault();
    const data = serializeForm(event.target);
    this.props.updateAccount(data);
  };

  render() {
    return (
      <RightBlock style={{ borderRadius: 7 }} as={BoardComponent}>
        <Column as="form" onSubmit={this.updateUser}>
          <RowOffsetBottom>
            <Column>
              <FieldLabel>EMAIL ADDRESS</FieldLabel>
              <InputMain
                name="email"
                type="email"
                error={'email' in this.props.errors}
                defaultValue={this.props.email || ''}
                placeholder="Email Address"
              />
            </Column>
          </RowOffsetBottom>

          <RowOffsetBottom>
            <HalfContentWrapper>
              <HalfContent style={{ marginBottom: 0 }}>
                <Column>
                  <FieldLabel>FIRST NAME</FieldLabel>
                  <InputMain
                    style={{ marginBottom: 0 }}
                    error={'firstName' in this.props.errors}
                    name="firstName"
                    defaultValue={this.props.firstName || ''}
                    placeholder="First Name"
                  />
                </Column>
              </HalfContent>
              <Separator />
              <HalfContent style={{ marginBottom: 0, marginRight: 0 }}>
                <Column>
                  <FieldLabel>LAST NAME</FieldLabel>
                  <InputMain
                    style={{ marginBottom: 0 }}
                    error={'lastName' in this.props.errors}
                    name="lastName"
                    defaultValue={this.props.lastName || ''}
                    placeholder="Last Name"
                  />
                </Column>
              </HalfContent>
            </HalfContentWrapper>
          </RowOffsetBottom>
          <Row>
            <FromControls>
              {/* <ClearButton color={mainTheme.colors.light} type={'reset'}>
								Clear
							</ClearButton> */}
              <MainButton type="submit">Update</MainButton>
            </FromControls>
          </Row>
          {/* <button type={'submit'} hidden /> */}
        </Column>
      </RightBlock>
    );
  }
}
const userAccountSelector = createSelector(
  selectUserErrors,
  selectUserPending,
  selectUserObject,
  (errors, pending, user) => ({
    errors,
    pending,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
  })
);
const mapStateToProps = userAccountSelector;

const mapDispatchToProps = {
  updateAccount: userActions.updateAccountFetchPending,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountFormContainer);
