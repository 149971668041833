import React from 'react';
import AddonCardItem from '../AddonCardItem/AddonCardItem';

import { StyledLayout } from '../../../../screens/AuthScreen-v2/styles';
import { StyledLayoutWrapper } from '../../../MainComponents/MainComponents';
import {
  StyledPageWrapper,
  StyledSection,
} from '../../../AuthSubscriptionPageComponent/styles';
import {
  StyledAddButton,
  StyledAddonContainer,
  StyledButtonContainer,
  StyledCancelButton,
  StyledCloseButton,
  StyledCloseIcon,
  StyledContainer,
  StyledDescriptionText,
  StyledSubDescriptionText,
  StyledSubTitleText,
  StyledTextSection,
  StyledTitleText,
} from './styles';

import forestBg from '../../../../assets/images/backgrounds/forestBg.jpg';

const TitleTextPopup = ({ addonData, handlePopup, handleSelectAddon }) => {
  return (
    <StyledPageWrapper>
      <StyledLayout height="100%">
        <StyledLayoutWrapper>
          <StyledSection paddingTop={30} paddingBot={30} bgImage={forestBg}>
            <StyledCloseButton onClick={() => handlePopup('')}>
              <StyledCloseIcon />
            </StyledCloseButton>
            <StyledContainer>
              <StyledTextSection>
                {addonData.popup.title && (
                  <StyledTitleText marginBot={16}>
                    {addonData.popup.title}
                  </StyledTitleText>
                )}

                {addonData.popup.description &&
                  addonData.popup.description.map((text) => (
                    <StyledDescriptionText>{text}</StyledDescriptionText>
                  ))}
                {addonData.popup.subTitle && (
                  <StyledSubDescriptionText>
                    {addonData.popup.subTitle}
                  </StyledSubDescriptionText>
                )}

                <StyledSubTitleText>Price</StyledSubTitleText>
                {/* addon  */}
                <StyledAddonContainer>
                  <AddonCardItem
                    title={addonData.card.title}
                    price={addonData.card.price}
                    period={addonData.card.period}
                    tags={addonData.card.tags}
                    badge={addonData.card.badge}
                  />
                </StyledAddonContainer>
                <StyledButtonContainer>
                  <StyledCancelButton onClick={() => handlePopup('')}>
                    Cancel
                  </StyledCancelButton>
                  <StyledAddButton
                    onClick={() => {
                      handleSelectAddon(addonData.id);
                      handlePopup('');
                    }}
                  >
                    Add +{' '}
                  </StyledAddButton>
                </StyledButtonContainer>
              </StyledTextSection>
            </StyledContainer>
          </StyledSection>
        </StyledLayoutWrapper>
      </StyledLayout>
    </StyledPageWrapper>
  );
};

export default TitleTextPopup;
