import React, { Component } from 'react';
import { connect } from 'react-redux';
import SideBarContainer from '../../containers/SideBarContainer/SideBarContainer';
import {
  MainScreenWrapper,
  MainContentWrapper,
  MainScrolledContent,
} from './styles';
import HeaderContainer from '../../containers/HeaderContainer/HeaderContainer';
import MainRouterContainer from '../../containers/MainRouterContainer/MainRouterContainer';
import { selectSomePending } from '../../redux/app/app.selectors';

class MainScreen extends Component {
  render() {
    return (
      <MainScreenWrapper>
        <SideBarContainer />
        <MainContentWrapper>
          <MainScrolledContent>
            <HeaderContainer />
            <MainRouterContainer />
          </MainScrolledContent>
        </MainContentWrapper>
      </MainScreenWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  pending: selectSomePending(state),
});

export default connect(mapStateToProps)(MainScreen);
