import styled from 'styled-components';
// import { Row } from '../MainComponents';
import { mainTheme } from '../../mainStyles';
import { Row } from '../MainComponents/MainComponents';

export const PeriodsWrapper = styled(Row)`
  > button {
    margin-left: ${mainTheme.offset.small}px;
  }
  & > :last-child {
    /* padding-right: 0; */
  }
  align-items: center;
  /* flex-wrap: wrap; */
  margin-left: auto;
  width: auto;
  flex-grow: 1;
  /* justify-content: flex-end; */
`;

export const PeriodButton = styled.button`
  border: none;

  color: ${mainTheme.colors.grey};
  background-color: transparent;
  cursor: pointer;
  font-family: 'Avenir Medium';
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1.38px;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 6px ${mainTheme.offset.small}px;

  &.active {
    color: ${mainTheme.colors.primary};
    background-color: #eaeaea;
  }
  &:focus {
    border: none;
    outline: none;
  }
`;
