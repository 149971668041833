import React from 'react';
import styled from 'styled-components';

import { StyledLink } from '../../screens/AuthScreen/styles';
import { AuthForm } from '../../screens/AuthScreen-v2/styles';
import {
  Divider,
  AuthButton,
  InputValidation,
  Column,
  SmallText,
  StyledLogoContainer,
  StyledFormTitle,
  StyledFormDescription,
  StyledInputContainer,
  StyledInputLabel,
  StyledInput,
  StyledButton,
  StyledLinesBlock,
  StyledLine,
  StyledLinesTitle,
  StyledContainer,
  StyledLinkContainer,
  StyledIconImage,
  ReCAPTCHAContainer,
} from '../MainComponents/MainComponents';
import MessageComponent from '../MessageComponent/MessageComponent';
import { mainTheme } from '../../mainStyles';
import logo2 from '../../assets/images/image4.svg';
import lock from '../../assets/images/icons/lock.svg';
import email from '../../assets/images/icons/еmail.svg';
import google from '../../assets/images/icons/google.svg';
import FormCheckBoxComponent from '../FormCheckBoxComponent/FormCheckBoxComponent';
import { AUTH_ROUTS } from '../../constants/routes';
import ReCAPTCHA from 'react-google-recaptcha';

const SignupFormComponent = ({
  signUp,
  signInFacebook,
  signInGoogle,
  errors,
  message,
  setReCaptchaToken,
  token,
}) => {
  return (
    <AuthForm style={{ borderRadius: 4 }} fullWidth width={555}>
      <Column
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* <SignUpTopLabel>
					Sign up with <SignUpText onClick={signInFacebook}>Facebook</SignUpText> or{' '}
					<SignUpText onClick={signInGoogle}>Google</SignUpText>
				</SignUpTopLabel>
				<Divider /> */}
        <StyledLogoContainer>
          <img src={logo2} alt="logo" />
        </StyledLogoContainer>
        <div>
          <StyledFormTitle>Sign Up to your RWRD account</StyledFormTitle>
          <StyledFormDescription>
            Enter your details below
          </StyledFormDescription>
        </div>
        <form style={{ width: '100%' }} onSubmit={signUp}>
          <StyledInputContainer marginBot={24}>
            <StyledInputLabel>Email</StyledInputLabel>
            <StyledInput
              // icon={email}
              type="email"
              errors={errors.email}
              name="email"
              placeholder="Your email"
            />
            <StyledIconImage src={email} alt="email" />
          </StyledInputContainer>
          <StyledInputContainer marginBot={5}>
            <StyledInputLabel>Password</StyledInputLabel>
            <StyledInput
              // icon={lock}
              placeholder="Your password"
              name="password"
              type="password"
              errors={errors.password}
            />
            <StyledIconImage src={lock} alt="lock" />
          </StyledInputContainer>
          {errors.password && (
            <MessageComponent
              type="danger"
              text="Password must be six characters long and contain at least one number"
            />
          )}
          <MessageComponent type="danger" text={message} />
          <FormCheckBoxComponent
            alignItems="center"
            flexStart
            newStyled
            text={() => (
              <>
                I agree to the{' '}
                <Link
                  href="https://www.rwrdapp.com/business-terms-and-conditions/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  terms and conditions
                </Link>{' '}
                and{' '}
                <Link
                  href="https://www.rwrdapp.com/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  privacy policy
                </Link>
              </>
            )}
            error={'terms_agree' in errors || ''}
            name="terms_agree"
          />
          <ReCAPTCHAContainer>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              onChange={setReCaptchaToken}
            />
          </ReCAPTCHAContainer>
          <StyledButton bgColor="#f5cc28" type="submit" disabled={!token}>
            Get started
          </StyledButton>
        </form>
        <StyledLinesBlock>
          <StyledLine />
          <StyledLinesTitle>OR</StyledLinesTitle>
          <StyledLine />
        </StyledLinesBlock>
        <StyledButton onClick={signInGoogle}>
          <StyledContainer>
            <img src={google} alt="" />
            <span>Continue with Google</span>
          </StyledContainer>
        </StyledButton>
        <StyledLinkContainer>
          Already have an RWRD account?{' '}
          <StyledLink to={AUTH_ROUTS.SIGN_IN}>Log in</StyledLink>
        </StyledLinkContainer>
      </Column>
    </AuthForm>
  );
};

export default SignupFormComponent;

const Link = styled.a`
  color: ${(props) => mainTheme.colors.textDark};
`;
