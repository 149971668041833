import moment from 'moment';

export const calculateRating = (ratings) => {
  const [total, count] = ratings.reduce(
    ([total, count], rating) => [total + Number(rating.rating), count + 1],
    [0, 0]
  );
  if (!count) return 0;
  return total / count;
};

/**
 * Simple linear regression
 *
 * @param {Array<number>} data
 * @return {Object}
 */
export const linearRegression = (data) => {
  let sum_x = 0;
  let sum_y = 0;
  let sum_xy = 0;
  let sum_xx = 0;
  let count = 0;
  let m;
  let b;

  if (data.length === 0) {
    return { m: 0, b: 0 };
  }

  // calculate sums
  for (let i = 0, len = data.length; i < len; i++) {
    const point = data[i];
    sum_x += i;
    sum_y += point;
    sum_xx += i * i;
    sum_xy += i * point;
    count++;
  }

  // calculate slope (m) and y-intercept (b) for f(x) = m * x + b
  m = (count * sum_xy - sum_x * sum_y) / (count * sum_xx - sum_x * sum_x);
  b = sum_y / count - (m * sum_x) / count;

  return { m, b };
};
