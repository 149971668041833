import React, { Component } from 'react';
import {
  StyledText,
  StyledLink,
} from '../../components/MainComponents/MainComponents';
import { StyledSignupPageWrapper } from '../SignupContainer-v2/styles';
import { AuthForm, StyledLayout } from '../../screens/AuthScreen-v2/styles';
import bgImage from '../../assets/images/backgrounds/signInSignUp.jpg';
import { AUTH_ROUTS } from '../../constants/routes';

class ForgotPasswordSent extends Component {
  render() {
    return (
      <StyledLayout login bgImage={bgImage}>
        <StyledSignupPageWrapper>
          <AuthForm
            style={{ borderRadius: 4 }}
            fullWidth
            width={465}
            padding={20}
          >
            <StyledText>
              An email has been sent to your email account with a link to reset
              your password.
            </StyledText>
            <StyledText>
              If you have any questions please contact{' '}
              <a href="mailto: abc@example.com">support@rwrdapp.com</a>.
            </StyledText>
            <StyledLink bgColor="#f5cc28" to={AUTH_ROUTS.SIGN_IN}>
              Log in
            </StyledLink>
          </AuthForm>
        </StyledSignupPageWrapper>
      </StyledLayout>
    );
  }
}

export default ForgotPasswordSent;
