import React from 'react';

import { StyledLayout } from '../../../../screens/AuthScreen-v2/styles';
import { StyledLayoutWrapper } from '../../../MainComponents/MainComponents';
import {
  StyledPageWrapper,
  StyledSection,
} from '../../../AuthSubscriptionPageComponent/styles';

import mobile from '../../../../assets/images/mobile.png';
import popupBg from '../../../../assets/images/backgrounds/popupBg.png';

import analyze from '../../../../assets/images/analyze.png';
import retain from '../../../../assets/images/retain.png';
import customise from '../../../../assets/images/customise.png';
import AddonCardItem from '../AddonCardItem/AddonCardItem';

import { ReactComponent as CloseIcon } from '../../../../assets/images/popup-close-icon.svg';
import {
  StyledAddButton,
  StyledAddonContainer,
  StyledButtonContainer,
  StyledCancelButton,
  StyledCloseButton,
  StyledColumn,
  StyledColumnDescriptionText,
  StyledColumnsContainer,
  StyledColumnTitleText,
  StyledContainer,
  StyledDescriptionText,
  StyledImageSection,
  StyledSubTitleText,
  StyledTextSection,
  StyledTitleText,
} from './styles';

const images = {
  analyze,
  customise,
  retain,
};

const ThreeColumnsTitleImagePopup = ({
  addonData,
  handlePopup,
  handleSelectAddon,
}) => {
  const columns = addonData.popup.columns.map((col) => (
    <StyledColumn key={col.id}>
      <img src={images[col.image]} width="64px" alt={col.image} />
      <StyledColumnTitleText>{col.title}</StyledColumnTitleText>
      <StyledColumnDescriptionText>
        {col.description}
      </StyledColumnDescriptionText>
    </StyledColumn>
  ));

  return (
    <StyledPageWrapper>
      <StyledLayout height="100%">
        <StyledLayoutWrapper>
          <StyledSection paddingTop={30} paddingBot={30} bgImage={popupBg}>
            <StyledCloseButton onClick={() => handlePopup('')}>
              <CloseIcon />
            </StyledCloseButton>
            <StyledContainer>
              <StyledTextSection>
                {addonData.popup.title && (
                  <StyledTitleText marginBot={16}>
                    {addonData.popup.title}
                  </StyledTitleText>
                )}
                {addonData.popup.description && (
                  <StyledDescriptionText>
                    {addonData.popup.description}
                  </StyledDescriptionText>
                )}
                {addonData.popup.subTitle && (
                  <StyledSubTitleText>
                    {addonData.popup.subTitle}
                  </StyledSubTitleText>
                )}
                <StyledColumnsContainer>{columns}</StyledColumnsContainer>
                <StyledSubTitleText>Price</StyledSubTitleText>
                {/* addon  */}
                <StyledAddonContainer>
                  <AddonCardItem
                    title={addonData.card.title}
                    price={addonData.card.price}
                    period={addonData.card.period}
                    tags={addonData.card.tags}
                    badge={addonData.card.badge}
                  />
                </StyledAddonContainer>
                <StyledButtonContainer>
                  <StyledCancelButton onClick={() => handlePopup('')}>
                    Cancel
                  </StyledCancelButton>
                  <StyledAddButton
                    onClick={() => {
                      handleSelectAddon(addonData.id);
                      handlePopup('');
                    }}
                  >
                    Add +{' '}
                  </StyledAddButton>
                </StyledButtonContainer>
              </StyledTextSection>
              {/* right side */}
              <StyledImageSection>
                <img src={mobile} alt="phone" />
              </StyledImageSection>
            </StyledContainer>
          </StyledSection>
        </StyledLayoutWrapper>
      </StyledLayout>
    </StyledPageWrapper>
  );
};

export default ThreeColumnsTitleImagePopup;
