import styled from 'styled-components';

export const StyledSlider = styled.div`
  width: 100%;
  max-width: 660px;
  min-height: 300px;
  input {
    display: none;
  }

  @media (max-width: 1003px) {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const Styledtestimonials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 300px;
  perspective: 500px;
  overflow: hidden;

  @media (max-width: 1112px) {
    min-height: 290px;
  }
  @media (max-width: 1003px) {
    width: 100%;
  }
`;

export const StyledItem = styled.label`
  /* top: 0; */
  position: absolute;
  background-color: #fff;
  width: 397px;
  transition: transform 0.2s;
  transform-style: preserve-3d;
  user-select: none;
  cursor: pointer;

  @media (max-width: 1275px) {
    width: 306px;
  }

  @media (max-width: 1003px) {
    right: auto;
    left: auto;
    top: auto;
    width: 283px;
  }
`;

export const StyledMyCard = styled.div`
  height: 100%;
  width: 100%;
  max-width: 24rem;
`;

export const StyledCardTitle = styled.p`
  font-weight: bold;
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
  text-align: center;
`;

export const StyledCardImg = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
  max-height: 6rem;
  border-radius: 9999px;
  width: 100px;
  border-radius: 50%;
  border: 13px solid #3b344d;
`;

export const StyledCardDescription = styled.p`
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  text-align: center;
`;

export const StyledDots = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    display: block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #413b52;
    margin: 7px;
    transition: transform 0.2s, color 0.2s;
  }

  @media (max-width: 1003px) {
    flex-direction: column-reverse;
  }
`;

export const StyledRadioInput2 = styled.input`
  &:checked ~ .dots label[for='t-2'] {
    transform: scale(2);
  }
`;
