import React from 'react';
import {
  StyledCard,
  StyledCardBadge,
  StyledCardContent,
  StyledCardPrice,
  StyledCardTag,
  StyledCardTitle,
  StyledCardValue,
} from './styles';

const SubscriptionCardComponent = ({
  isActive,
  handleActiveCarouselItemId,
  subscriptionId,
  badge,
  title,
  price,
  period,
  tags,
}) => {
  return (
    <StyledCard
      isActive={isActive}
      onClick={() => {
        handleActiveCarouselItemId(subscriptionId);
      }}
    >
      {badge && <StyledCardBadge>{badge}</StyledCardBadge>}
      <StyledCardContent isActive={isActive}>
        <StyledCardTitle>{title}</StyledCardTitle>
        <StyledCardValue>
          <StyledCardPrice>£{price}</StyledCardPrice>
          {period === 1
            ? '/month, excl VAT'
            : period === 12
            ? '/annum, excl VAT'
            : ''}
        </StyledCardValue>
        {tags.map((tag) => (
          <StyledCardTag color={tag.color}>{tag.text}</StyledCardTag>
        ))}
      </StyledCardContent>
    </StyledCard>
  );
};

export default SubscriptionCardComponent;
