import { createSelector } from 'reselect';

const rootSelector = (state) => state.posts;
export const selectPostsList = createSelector(
  rootSelector,
  (posts) => posts.posts
);
// Sort posts by created date
export const selectPostsListSorted = createSelector(rootSelector, (posts) =>
  posts.posts.sort((first, second) =>
    new Date(first.createdAt) > new Date(second.createdAt) ? -1 : 1
  )
);
export const selectPostsPending = createSelector(
  rootSelector,
  (posts) => posts.pending
);
export const selectPostsErrors = createSelector(
  rootSelector,
  (posts) => posts.errors
);
export const selectPostsMessage = createSelector(
  rootSelector,
  (posts) => posts.message
);
