import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import CheckOptionComponent from '../CheckOptionComponent/CheckOptionComponent';
import { Row } from '../MainComponents/MainComponents';
import { selectUserObject } from '../../redux/user/user.selectors';

import postActions from '../../redux/post/post.actions';

const ProfileCheckboxComponent = (props) => {
  const [allProfiles, setAllProfiles] = useState([]);
  const [selectedProfiles, setSelectedProfiles] = useState([]);

  useEffect(() => {
    if (allProfiles.length == 0) {
      setAllProfiles(props.profiles);
    }
  }, [props.profiles]);

  useEffect(() => {
    props.changePostTemp('profiles', selectedProfiles);
  }, [selectedProfiles]);

  const handleCheckboxChange = useCallback(
    (id, checked) => {
      if (checked && !selectedProfiles.includes(id)) {
        setSelectedProfiles((prevState) => {
          return [...prevState, id];
        });
      } else if (!checked && selectedProfiles.includes(id)) {
        setSelectedProfiles((prevState) => {
          const updatedArray = [...prevState];
          const index = updatedArray.indexOf(id);
          updatedArray.splice(index, 1);
          return [...updatedArray];
        });
      }
    },
    [selectedProfiles]
  );

  const ProfilesRenderer = useMemo(
    () =>
      allProfiles.map((option) => (
        <CheckOptionComponent
          handleCheckboxChange={handleCheckboxChange}
          data={option}
        />
      )),
    [allProfiles, handleCheckboxChange]
  );

  return <Row wrap>{allProfiles.length && ProfilesRenderer}</Row>;
};

const ProfilesSelector = createSelector(selectUserObject, (user) => ({
  profiles: user.profile,
}));
const mapStateToProps = ProfilesSelector;
const mapDispatchToProps = {
  changePostTemp: postActions.changeTemp,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileCheckboxComponent);
