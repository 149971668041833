import React, { Component } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  SideBarWrapper,
  SideBarLogo,
  SideBarNavLink,
  SideBarNavLinkLabel,
  NavIcon,
  SideBarCloseIcon,
} from './styles';

import gridIcon from '../../assets/images/icons/grid.svg';
import usersIcon from '../../assets/images/icons/users.svg';
import shapeIcon from '../../assets/images/icons/shape.svg';
import editIcon from '../../assets/images/icons/edit.svg';
import shapeSqureIcon from '../../assets/images/icons/shape-squre.svg';
import coinsIcon from '../../assets/images/icons/coins.svg';
import cardIcon from '../../assets/images/icons/card.png';
import { selectAppSidebar } from '../../redux/app/app.selectors';
import appActions from '../../redux/app/app.actions';
import { mainTheme } from '../../mainStyles';
import LogoComponent from '../../components/LogoComponent/LogoComponent';

class SideBarContainer extends Component {
  toggleSidebar = () => {
    if (window.innerWidth <= mainTheme.sizes.large) {
      this.props.toggleSidebar();
    }
  };

  render() {
    return (
      <>
        <SideBarWrapper active={this.props.sidebar}>
          <SideBarCloseIcon onClick={this.toggleSidebar} />
          <SideBarLogo>
            <LogoComponent />
          </SideBarLogo>
          <SideBarNavLink onClick={this.toggleSidebar} to="/dashboard">
            <NavIcon src={gridIcon} />{' '}
            <SideBarNavLinkLabel>Dashboard</SideBarNavLinkLabel>
          </SideBarNavLink>
          <SideBarNavLink onClick={this.toggleSidebar} to="/profile">
            <NavIcon src={usersIcon} />{' '}
            <SideBarNavLinkLabel>Profile</SideBarNavLinkLabel>
          </SideBarNavLink>
          <SideBarNavLink onClick={this.toggleSidebar} to="/posts">
            <NavIcon src={shapeIcon} />{' '}
            <SideBarNavLinkLabel>Posts</SideBarNavLinkLabel>
          </SideBarNavLink>
          <SideBarNavLink onClick={this.toggleSidebar} to="/account">
            <NavIcon src={editIcon} />{' '}
            <SideBarNavLinkLabel>Account</SideBarNavLinkLabel>
          </SideBarNavLink>
          <SideBarNavLink onClick={this.toggleSidebar} to="/support">
            <NavIcon src={shapeSqureIcon} />
            <SideBarNavLinkLabel>Support</SideBarNavLinkLabel>
          </SideBarNavLink>
          <SideBarNavLink onClick={this.toggleSidebar} to="/team-tips">
            <NavIcon src={coinsIcon} />
            <SideBarNavLinkLabel>Team Tips</SideBarNavLinkLabel>
          </SideBarNavLink>
          <SideBarNavLink onClick={this.toggleSidebar} to="/stamp-card">
            <NavIcon src={cardIcon} />
            <SideBarNavLinkLabel>Card Design</SideBarNavLinkLabel>
          </SideBarNavLink>
        </SideBarWrapper>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  sidebar: selectAppSidebar,
});

const mapDispatchToProps = {
  toggleSidebar: appActions.toggleSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBarContainer);
