import styled from 'styled-components';

import { FieldLabel } from '../MainComponents/MainComponents';

export const Title = styled(FieldLabel)`
  font-size: 14px;
  color: ${(props) => props.color || '#212121'};
  margin-bottom: ${(props) => (props.noMargin ? 0 : 12)};
  z-index: 22;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SplitBarWrapper = styled.div`
  height: 80px;
  width: 100%;
  background-color: #dfe6e9;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
`;
