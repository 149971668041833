/**
 * Do NOT change the following values without changing existing database records, and
 * mobile app app/views/userprofile/formData.js options, because filtering uses
 * these values in Dashboard charts - you could mess up the whole thing
 */

export const milkChoicesOptions = [
  'Dairy Milk',
  'Oat Milk',
  'Soya Milk',
  'Almond Milk',
  'Coconut Milk',
  'Hemp Milk',
  'No Milk at all',
];

export const customerDietsOptions = [
  'Vegan',
  'Vegetarian',
  'Pescatarian',
  'No Preference',
];

export const favouriteCoffeeOptions = [
  'Black Americano',
  'White Americano',
  'Latte',
  'Mocha',
  'Espresso',
  'Cappuccino',
  'Flat White',
  'Macchiato',
  'Filter Coffee',
  'Cortado',
  'Iced Coffee',
  "Don't Drink Coffee",
];

export const customerAgesOptions = ['18-24', '25-34', '35-44', '45+'];

export const drinkMostOftenOptions = [
  'Coffee',
  'Tea',
  'Smoothies',
  'Juice',
  'Cold press shots',
];

export const coffeesPerDayOptions = ['0', '1-2', '3-5', '5+'];

export const remoteWorkOptions = ['Always', 'Sometimes', 'Never'];

export const takeoutSitInOptions = ['Sit in', 'Takeaway'];

export const buyForHomeOptions = ['Beans', 'Ground', 'Instant', 'None'];

export const genderOptions = ['Male', 'Female', 'Prefer to not say'];

export const statsWithMessageTitles = {
  customerFavourites: 'Customer Favourites',
  returningCustomers: 'Returning Customers',
  lostCustomers: 'Lost Customers',
  wonBackCustomers: 'Won-Back Customers',
  newCustomers: 'New Customers',
  customersToWin: 'Customers to win',
  milkChoices: 'Milk choices',
  customerDiets: 'Customer Diets',
  customerAges: 'Customer Age',
  coffeesPerDay: 'Average Number Of Coffees Per Day',
  drinkMostOften: 'What Do Customers Drink More Often?',
  buyForHome: 'What Coffee Do Customers Buy For Home?',
  favouriteCoffee: 'Favourite Coffee',
  customerGender: 'Customer Gender Split',
  customersWorkingRemotely: 'Customers Working Remotely',
  sitInTakeout: 'Sit-in vs Takeout',
};
