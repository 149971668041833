import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import eye from '../../assets/images/icons/eye@2x.png';
import cup from '../../assets/images/icons/cup@2x.png';
import qr from '../../assets/images/icons/qr-code@2x.png';
import heart from '../../assets/images/icons/like.png';
import user from '../../assets/images/icons/001-user.png';
import news from '../../assets/images/icons/newspaper.png';
import StatisticViewComponent, {
  regionalFilters,
} from '../../components/StatisticViewComponent/StatisticViewComponent';
import { flurryEvents } from '../../constants/flurry';
import withBubbleChat from '../../hoc/WithBubbleChat/WithBubbleChat';
import profileActions from '../../redux/profile/profile.actions';
import { Wrapper } from './styles';
import { statsWithMessageTitles } from '../../constants/dashboard';
import { PostTitle } from '../../components/PostComponent/styles';

const StatisticViewComponentWithBubbleChat = withBubbleChat(
  StatisticViewComponent
);

export class ChartsStatisticViewsContainer extends Component {
  state = { regionalFilter: regionalFilters.region };

  toggleRegionalFilter = (filter) => {
    this.setState({ regionalFilter: filter });
    if (
      filter === regionalFilters.total &&
      this.props.analyticsTotalUsers === 0
    ) {
      this.props.fetchAnalyticsTotalUsers();
    }
  };

  render() {
    const {
      analytics,
      analyticsData: { stampsHistory, favoritesHistory, favoritesDiff },
    } = this.props;
    const profileViews = analytics.filter(
      (an) => an.eventName === flurryEvents.profileViews
    );
    const cardViews = analytics.filter(
      (an) => an.eventName === flurryEvents.cardViews
    );
    const newsFeedToProfile = analytics.filter(
      (an) => an.eventName === flurryEvents.newsFeedToProfile
    );
    const { stamps } = stampsHistory.data;
    const redeemed = stampsHistory.data.redeems;
    const returningCustomers = analytics.find(
      (an) => an.statName === 'returningCustomers'
    );
    const lostCustomers = analytics.find(
      (an) => an.statName === 'lostCustomers'
    );
    const wonBackCustomers = analytics.find(
      (an) => an.statName === 'wonBackCustomers'
    );
    const newCustomers = analytics.find((an) => an.statName === 'newCustomers');

    return (
      <Wrapper>
        <StatisticViewComponent
          name="Profile Views"
          value={profileViews.length ? _.sumBy(profileViews, 'count') : 0}
          icon={eye}
          diff={0}
        />
        <StatisticViewComponent
          name="Total Stamps"
          value={stamps}
          icon={qr}
          diff={0}
        />
        <StatisticViewComponent
          name="Redemptions"
          value={redeemed}
          icon={cup}
          diff={0}
        />
        <StatisticViewComponentWithBubbleChat
          name={statsWithMessageTitles.customerFavourites}
          value={favoritesHistory.data.length}
          icon={heart}
          diff={favoritesDiff}
        />
        <StatisticViewComponent
          name="Card Views in Wallet"
          value={cardViews.length ? _.sumBy(cardViews, 'count') : 0}
          icon={eye}
          diff={0}
        />
        <StatisticViewComponent
          name="Post clicks"
          value={
            newsFeedToProfile.length ? _.sumBy(newsFeedToProfile, 'count') : 0
          }
          icon={news}
          diff={0}
        />
        <StatisticViewComponentWithBubbleChat
          name={statsWithMessageTitles.newCustomers}
          value={newCustomers ? newCustomers.count : 0}
          icon={user}
          diff={0}
        />
        <StatisticViewComponentWithBubbleChat
          name={statsWithMessageTitles.returningCustomers}
          value={returningCustomers ? returningCustomers.count : 0}
          icon={user}
          diff={0}
        />
        <StatisticViewComponentWithBubbleChat
          name={statsWithMessageTitles.lostCustomers}
          value={lostCustomers ? lostCustomers.count : 0}
          icon={user}
          diff={0}
        />
        <StatisticViewComponentWithBubbleChat
          name={statsWithMessageTitles.wonBackCustomers}
          value={wonBackCustomers ? wonBackCustomers.count : 0}
          icon={user}
          diff={0}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    analytics: state.profile.analytics,
    totalStamps: state.profile.stamps,
    analyticsTotalUsers: state.profile.analyticsTotalUsers,
  };
};

const mapDispatchToProps = {
  fetchAnalyticsTotalUsers: profileActions.fetchAnalyticsTotalUsersPending,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChartsStatisticViewsContainer);
