import styled from 'styled-components';

import { RowOffsetBottom } from '../../components/MainComponents/MainComponents';

export const Wrapper = styled(RowOffsetBottom)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 220px);
  grid-gap: 20px;
  justify-content: space-between;
  @media (max-width: 500px) {
    grid-template-columns: repeat(auto-fill, 100%);
  }
  @media (max-width: 345px) {
    margin-right: 20px;
  }
`;
