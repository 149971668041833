import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  Row,
  RightBlock,
  PaperComponent,
} from '../../components/MainComponents/MainComponents';
import { Text, Link, CheckBox, SmallText } from './styles';
import profileActions from '../../redux/profile/profile.actions';

class OptInInsightsContainer extends Component {
  onChangeHandler = (event) => {
    const { profiles, updateOptInCustomerInsights, freeStamp } = this.props;

    if (!freeStamp) {
      const { target } = event;
      const value =
        target.name === 'optInInsights' ? target.checked : target.value;
      updateOptInCustomerInsights({
        profiles: _.map(profiles, '_id'),
        freeStamp: value,
      });
    }
  };

  render() {
    const { freeStamp } = this.props;
    return (
      <RightBlock style={{ borderRadius: 7 }} as={PaperComponent}>
        <Row>
          <CheckBox
            type="checkbox"
            name="optInInsights"
            checked={freeStamp}
            onChange={this.onChangeHandler}
          />
          <Text>
            I agree to the{' '}
            <Link
              href="https://www.rwrdapp.com/venue-terms-conditions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </Link>{' '}
            when opting into the Customer Insights Analytics
          </Text>
        </Row>
        <SmallText>
          To opt out of the Customer Insights please contact{' '}
          <Link
            href="mailto:support@rwrdapp.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@rwrdapp.com
          </Link>
        </SmallText>
      </RightBlock>
    );
  }
}

function mapStateToProps(state) {
  return {
    profiles: state.user.profile,
    freeStamp: state.user.profile[0].freeStamp,
  };
}
const mapDispatchToProps = {
  updateOptInCustomerInsights:
    profileActions.fetchUpdateOptInCustomerInsightsPending,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OptInInsightsContainer);
