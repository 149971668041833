import styled from 'styled-components';
import { newTheme } from '../../mainStyles';

export const StyledPhoneSection = styled.div`
  font-family: 'Outfit Medium';
  font-size: 10px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0.08em;
  text-align: left;
  color: #959492;

  padding-top: ${({ paddingTop }) => paddingTop && `${paddingTop}px`};
  padding-bottom: ${({ paddingBot }) => paddingBot && `${paddingBot}px`};
  padding-left: ${({ paddingLeft }) => paddingLeft && `${paddingLeft}px`};
  padding-right: ${({ paddingRight }) => paddingRight && `${paddingRight}px`};

  height: ${({ height }) => height && `${height}`};
  max-height: ${({ maxHeight }) => maxHeight && `${maxHeight}`};
  border-bottom: 4px solid #efefef;
`;

export const StyledPhoneLogoImage = styled.div`
  position: absolute;
  bottom: -32px;
  left: 31px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : 'red')};
  background-size: contain;
  background-repeat: no-repeat;
`;

export const StyledPhoneBgImage = styled.div`
  position: relative;
  width: 100%;
  height: 135px;
  background: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : 'gray')};
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 40px 40px 0 0;
`;

export const StyledMobilePhone = styled.div`
  height: 795px;
  width: 390px;
  padding: 15px;
`;

export const StyledPageDescription = styled.div`
  font-family: ${newTheme.fontFamily.primary.light};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: ${({ marginBot }) => (marginBot ? `${marginBot}px` : '24px')};
`;

export const StyledProgressBarComponent = styled.div`
  width: 450px;
  height: 4px;
  background: #efefef;
  margin-bottom: ${({ marginBot }) => (marginBot ? `${marginBot}px` : '48px')};
  @media (max-width: 1000px) {
    width: 350px;
  }
`;

export const StyledFormWrapper = styled.div`
  width: 450px;

  @media (max-width: 1000px) {
    width: 350px;
  }
`;

export const StyledProgress = styled.div`
  background: #221c02;
  height: 100%;
  width: ${({ progress }) => progress && `${(100 / 7) * progress}%`};
`;
