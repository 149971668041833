import React from 'react';
import OneItemsCarousel from '../../../AddonsCarousels/OneItemsCarousel/OneItemsCarousel';
import ThreeItemsCarousel from '../../../AddonsCarousels/ThreeItemsCarousel/ThreeItemsCarousel';
import TwoItemsCarousel from '../../../AddonsCarousels/TwoItemsCarousel/TwoItemsCarousel';

const CarouselContainer = ({
  carouselActiveItem,
  notSelectedAddons,
  getAddonData,
  handlePopup,
  handleCarouselActiveItem,
}) => {
  if (notSelectedAddons.length === 1) {
    return (
      <OneItemsCarousel
        items={notSelectedAddons}
        getAddonData={getAddonData}
        handlePopup={handlePopup}
        handleCarouselActiveItem={handleCarouselActiveItem}
      />
    );
  }

  if (notSelectedAddons.length === 2) {
    return (
      <TwoItemsCarousel
        carouselActiveItem={carouselActiveItem}
        items={notSelectedAddons}
        getAddonData={getAddonData}
        handlePopup={handlePopup}
        handleCarouselActiveItem={handleCarouselActiveItem}
      />
    );
  }
  if (notSelectedAddons.length > 2) {
    return (
      <ThreeItemsCarousel
        carouselActiveItem={carouselActiveItem}
        items={notSelectedAddons}
        getAddonData={getAddonData}
        handlePopup={handlePopup}
        handleCarouselActiveItem={handleCarouselActiveItem}
      />
    );
  }
};

export default CarouselContainer;
