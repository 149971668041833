import { handleActions } from 'redux-actions';
import statisticActions from './statistic.actions';

export const statisticInitialState = {
  message: '',
  errors: {},
  pending: 0,

  period: 4, // Default, corresponds to "TOTAL" ("all") filter
};

const statisticReducer = handleActions(
  {
    [statisticActions.setPeriod]: (state, action) => ({
      ...state,
      period: action.payload.id,
    }),
  },
  statisticInitialState
);

export default statisticReducer;
