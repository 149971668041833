import styled from 'styled-components';
import cardImage from '../../assets/images/mastercard.png';
import {
  FieldLabel,
  PaperComponent,
} from '../../components/MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';
import downloadIcon from '../../assets/images/icons/download.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const InvoiceWrapper = styled(PaperComponent)`
  height: 55px;
  border-radius: 7px;
  padding: 0 45px;
  flex-grow: 1;
  max-width: initial;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
`;

export const DownloadIcon = styled.img.attrs({ src: downloadIcon })`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
