export const AUTH_ROUTS = {
  SIGN_IN: '/auth/signin',
  SIGN_UP: '/auth/signup',
  FORGOT_PASSWORD: '/auth/forgot-password',
  FORGOT_PASSWORD_SENT: '/auth/forgot-password-sent',
  RESET_PASSWORD: '/auth/reset-password',
  SUBSCRIPTIONS: '/auth/subscriptions',
  PROFILE: '/auth/profile',
  PROFILE_STEP1: '/auth/profile-step1',
  PROFILE_STEP2: '/auth/profile-step2',
  PROFILE_STEP3: '/auth/profile-step3',
  PROFILE_STEP4: '/auth/profile-step4',
  PROFILE_STEP5: '/auth/profile-step5',
  PROFILE_STEP6: '/auth/profile-step6',
  PROFILE_STEP7: '/auth/profile-step7',
  ADDONS: '/auth/addons',
  PAYMENTS: '/auth/payments',
  CONFIRMATION: '/auth/confirmation',
  CONGRATULATIONS: '/auth/congratulations',
};
