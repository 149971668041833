import { createAction } from 'redux-actions';

// ## FETCH_TIPS
const fetchPending = createAction('tips--fetchPending');
const fetchSuccess = createAction('tips--fetchSuccess', (tips = []) => ({
  tips,
}));
const fetchFailed = createAction(
  'tips--fetchFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

// ## FETCH_PAYOUTS
const fetchPayoutPending = createAction('tips--fetchPayoutPending');
const fetchPayoutSuccess = createAction(
  'tips--fetchPayoutSuccess',
  ({ payouts = [], nextPayoutAmount = 0 }) => ({ payouts, nextPayoutAmount })
);
const fetchPayoutFailed = createAction(
  'tips--fetchPayoutFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

// ## FETCH_STATS
const fetchTipStatsPending = createAction('tips--fetchTipStatsPending');
const fetchTipStatsSuccess = createAction(
  'tips--fetchTipStatsSuccess',
  ({ allTips = [], totalSum = 0, averageTip = 0, customersCount = 0 }) => ({
    allTips,
    totalSum,
    averageTip,
    customersCount,
  })
);
const fetchTipStatsFailed = createAction(
  'tips--fetchTipStatsFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

// ## FETCH_BANK
const fetchBankPending = createAction('tips--fetchBankPending');
const fetchBankSuccess = createAction(
  'tips--fetchBankSuccess',
  ({ bank_name = '', currency = '', last4 = '', routing_number = '' }) => ({
    bank_name,
    currency,
    last4,
    routing_number,
  })
);
const fetchBankFailed = createAction(
  'tips--fetchBankFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

const tipsActions = {
  fetchPending,
  fetchSuccess,
  fetchFailed,

  fetchPayoutPending,
  fetchPayoutSuccess,
  fetchPayoutFailed,

  fetchTipStatsPending,
  fetchTipStatsSuccess,
  fetchTipStatsFailed,

  fetchBankPending,
  fetchBankSuccess,
  fetchBankFailed,
};

export default tipsActions;
