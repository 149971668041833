import styled from 'styled-components';
import { newTheme } from '../../mainStyles';

export const StyledCarouselContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  /* margin-top: 90px; */
`;

export const StyledAddButton = styled.button`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  padding: 16px 36px;
  border: 0;
  border-radius: 4px;
  margin: 0 0 16px 0;
  z-index: 10;
  cursor: pointer;

  color: white;
  background: #131312;

  &:hover {
    background: #212121;
  }
`;

export const StyledCongratsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledCongratsImage = styled.div`
  display: flex;
  padding: 30px;

  border-radius: 50%;
  box-shadow: 0px 16px 32px 0px #eec10b29;

  margin-bottom: 30px;
`;
