import styled from 'styled-components';
import { PaperComponent, MainButton } from '../MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';

export const BottomControlPanelWrapper = styled(PaperComponent).attrs({
  direction: 'row',
})`
  padding: ${mainTheme.offset.small}px ${mainTheme.offset.xlarge}px 0;
  box-shadow: ${(props) =>
    props.transparent ? 'none' : '0 -15px 30px 0 rgba(150, 150, 150, 0.3)'};
  justify-content: flex-end;
  max-width: initial;
  margin-top: ${mainTheme.offset.small}px;
`;

export const ClearButton = styled(MainButton)`
  background-color: ${mainTheme.colors.main};
  color: ${mainTheme.colors.primary};
  margin-right: ${mainTheme.offset.xsmall}px;
`;
