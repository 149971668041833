import React, { useCallback, useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import styled from 'styled-components';
import CheckOptionComponent from '../CheckOptionComponent-v2/CheckOptionComponent';
import profileActions from '../../redux/profile/profile.actions';
import {
  selectProfileObject,
  selectProfileErrors,
} from '../../redux/profile/profile.selectors';
import { newTheme } from '../../mainStyles';

const TopFeaturesComponent = ({ temp, handleChange, ...props }) => {
  const [allTopFeatures, setAllTopFeatures] = useState([]);

  // useEffect(() => {
  // 	props.fetchTopFeatures();
  // }, []);

  useEffect(() => {
    if (allTopFeatures.length == 0) {
      setAllTopFeatures(props.allTopFeatures);
    }
  }, [props.allTopFeatures]);

  useEffect(() => {
    const selected = props.allTopFeatures.map((option) => {
      if (temp.top_features && temp.top_features.includes(option._id)) {
        option = { ...option, checked: true };
      } else {
        option = { ...option, checked: false };
      }
      return option;
    });
    setAllTopFeatures(selected);
  }, [props.allTopFeatures, JSON.stringify(temp.top_features)]);

  const TopFeaturesRenderer = useCallback(() => {
    const options = allTopFeatures;
    let optionsList = [];
    if (options && options.length !== 0) {
      optionsList = options
        .sort()
        .map((option) => (
          <CheckOptionComponent
            handleCheckboxChange={handleCheckboxChange}
            data={option}
          />
        ));
    }
    return optionsList;
  }, [allTopFeatures, temp.top_features]);

  const handleCheckboxChange = (id, checked) => {
    const updatedArray = temp.top_features;
    if (checked && temp.top_features && !temp.top_features.includes(id)) {
      updatedArray.push(id);
      handleChange('top_features', updatedArray);
    } else if (!checked) {
      while (temp.top_features.includes(id)) {
        const index = updatedArray.indexOf(id);
        updatedArray.splice(index, 1);
      }
      handleChange('top_features', updatedArray);
    }
  };

  return (
    <StyledSection>
      <StyledGridSection>
        <TopFeaturesRenderer />
      </StyledGridSection>
    </StyledSection>
  );
};

const TopFeaturesSelector = createSelector(
  selectProfileObject,
  selectProfileErrors,
  (profile, errors) => ({
    errors,
    selectedTopFeatures: profile.selectedTopFeatures,
    allTopFeatures: profile.allTopFeatures,
  })
);
const mapStateToProps = TopFeaturesSelector;
const mapDispatchToProps = {
  fetchTopFeatures: profileActions.fetchTopFeaturesPending,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopFeaturesComponent);

export const StyledSection = styled.div`
  margin-bottom: 16px;

  ${({ flex }) => flex && `display: flex; justify-content: space-between;`};
`;

export const StyledHalfSection = styled.div`
  width: 48%;
`;

export const StyledInputLabel = styled.div`
  font-family: ${newTheme.fontFamily.primary.light};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
`;

export const StyledGridSection = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  justify-content: space-between;
`;
