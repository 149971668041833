import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectUserObject } from '../../redux/user/user.selectors';
import profileActions from '../../redux/profile/profile.actions';
import { history } from '../../containers/RouterContainer/RouterContainer';
import storage from '../../utils/storage';
import client from '../../utils/client';
import { AUTH_ROUTS } from '../../constants/routes';

import StepsProgresComponent from '../StepsProgresComponent/StepsProgresComponent';
import TotalAddonsComponent from './components/TotalAddonsComponent/TotalAddonsComponent';
import PopupComponent from './components/PopupComponent/PopupComponent';
import CarouselContainer from './components/CarouselContainer/CarouselContainer';

import { StyledLayout } from '../../screens/AuthScreen-v2/styles';
import {
  StyledAddButton,
  StyledCarouselContainer,
  StyledCongratsContainer,
  StyledCongratsImage,
} from './styles';
import {
  StyledButton,
  StyledPageWrapper,
  StyledSection,
  StyledTitle,
} from '../AuthSubscriptionPageComponent/styles';
import {
  StyledLayoutWrapper,
  StyledPageDescription,
  StyledProgress,
  StyledProgressBarComponent,
  StyledSectionWrapper,
} from '../MainComponents/MainComponents';

import bgImage from '../../assets/images/backgrounds/stepsPreview.png';
import congrats from '../../assets/images/congrats.png';

const subscrNew = [
  {
    id: 'price_1LUJdfA8HcztP7hSC0M0I5fy',
    price: 25,
    period: 1,
    card: {
      title: 'KICKSTART PAKAGE',
      price: 25,
      badge: '🔥 Most Popular',
      package_tags: [
        {
          id: 1,
          color: 'green',
          text: '£259 annually - 14 days free trial',
        },
        {
          id: 2,
          color: 'yellow',
          text: '+£12.50 per additional site',
        },
      ],
    },
    accordion: [
      {
        id: 1,
        type: 'list',
        header: "What's included in Kickstart Package?",
        content: {
          included: {
            title: 'included',
            list: [
              {
                id: 1,
                text: 'Starter Pack',
              },
              {
                id: 2,
                text: 'Smart loyalty',
              },
              {
                id: 3,
                text: 'Custom card design',
              },
              {
                id: 4,
                text: 'Feature on speciality coffee guide',
              },
              {
                id: 5,
                text: 'Customer support',
              },
              {
                id: 6,
                text: 'Loyalty analytics',
              },
              {
                id: 7,
                text: 'Customer insights',
              },
              {
                id: 8,
                text: 'Unlimited instant marketing posts',
              },
              {
                id: 9,
                text: '1x Email campaign per month',
              },
              {
                id: 10,
                text: '1x Push notifications per month',
              },
            ],
          },
          not_included: {
            title: 'Not included',
            list: [
              {
                id: 1,
                text: 'In-app digital tipping',
              },
              {
                id: 2,
                text: 'Dedicated Marketing Manager',
              },
              {
                id: 3,
                text: 'Included in B2B newsletter - access industry updates and samples',
              },
              {
                id: 4,
                text: 'Bespoke data reports',
              },
            ],
          },
        },
      },
      {
        id: 2,
        header: 'Cancel Subscription',
        type: 'text',
        content: {
          text: [
            'You are signing up for a FREE TRIAL with RWRD. There is no obligation to continue your subscription and you can cancel at any time. No payment will be taken.',
            'After your free trial, the subscription will renew automatically on a monthly basis.',
          ],
        },
      },
      {
        id: 3,
        header: 'What happens next',
        type: 'text_list',
        content: [
          {
            text: [
              'RWRD will provide all assets required to ensure your subscription runs seamlessly:',
            ],
          },
          {
            list: [
              {
                id: 1,
                text: 'RWRD QR code',
              },
              {
                id: 2,
                text: 'Window Vinyl',
              },
              {
                id: 3,
                text: 'Promotional Materials',
              },
            ],
          },
          {
            text: [
              'Once you click confirm you will be taken to set up your venue profile. Once this is complete the RWRD Team will contact you to arrange your in venue on-boarding. Your free trial will not start until the on-boarding is complete, and your venue is live on the RWRD app.',
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'price_1LVlgjA8HcztP7hStXaoe8i1',
    price: 259,
    period: 12,
    card: {
      title: 'KICKSTART PAKAGE',
      price: 259,
      badge: '🔥 Most Popular',
      package_tags: [
        {
          id: 1,
          color: 'green',
          text: '£25 /month - 14 days free trial',
        },
        {
          id: 2,
          color: 'yellow',
          text: '+£12.50 per additional site',
        },
      ],
    },
    accordion: [
      {
        id: 1,
        type: 'list',
        header: "What's included in Kickstart Package?",
        content: {
          included: {
            title: 'included',
            list: [
              {
                id: 1,
                text: 'Starter Pack',
              },
              {
                id: 2,
                text: 'Smart loyalty',
              },
              {
                id: 3,
                text: 'Custom card design',
              },
              {
                id: 4,
                text: 'Feature on speciality coffee guide',
              },
              {
                id: 5,
                text: 'Customer support',
              },
              {
                id: 6,
                text: 'Loyalty analytics',
              },
              {
                id: 7,
                text: 'Customer insights',
              },
              {
                id: 8,
                text: 'Unlimited instant marketing posts',
              },
              {
                id: 9,
                text: '1x Email campaign per month',
              },
              {
                id: 10,
                text: '1x Push notifications per month',
              },
            ],
          },
          not_included: {
            title: 'Not included',
            list: [
              {
                id: 1,
                text: 'In-app digital tipping',
              },
              {
                id: 2,
                text: 'Dedicated Marketing Manager',
              },
              {
                id: 3,
                text: 'Included in B2B newsletter - access industry updates and samples',
              },
              {
                id: 4,
                text: 'Bespoke data reports',
              },
            ],
          },
        },
      },
      {
        id: 2,
        header: 'Cancel Subscription',
        type: 'text',
        content: {
          text: [
            'You are signing up for a FREE TRIAL with RWRD. There is no obligation to continue your subscription and you can cancel at any time. No payment will be taken.',
            'After your free trial, the subscription will renew automatically on a monthly basis.',
          ],
        },
      },
      {
        id: 3,
        header: 'What happens next',
        type: 'text_list',
        content: [
          {
            text: [
              'RWRD will provide all assets required to ensure your subscription runs seamlessly:',
            ],
          },
          {
            list: [
              {
                id: 1,
                text: 'RWRD QR code',
              },
              {
                id: 2,
                text: 'Window Vinyl',
              },
              {
                id: 3,
                text: 'Promotional Materials',
              },
            ],
          },
          {
            text: [
              'Once you click confirm you will be taken to set up your venue profile. Once this is complete the RWRD Team will contact you to arrange your in venue on-boarding. Your free trial will not start until the on-boarding is complete, and your venue is live on the RWRD app.',
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'price_1LVdiMA8HcztP7hSu0bTUep4',
    price: 40,
    period: 1,
    addons: [
      { id: 'price_1LaTojA8HcztP7hSRoBHTpfA', name: 'In app digital tipping' },
    ],
    card: {
      title: 'ROCKET PAKAGE',
      price: 40,
      badge: '',
      package_tags: [
        {
          id: 1,
          color: 'green',
          text: '£400 annually - 2 months free',
        },
        {
          id: 2,
          color: 'yellow',
          text: '£20 per additional site',
        },
      ],
    },
    accordion: [
      {
        id: 1,
        type: 'list',
        header: "What's included in Rocket Package?",
        content: {
          included: {
            title: 'included',
            list: [
              {
                id: 1,
                text: 'Starter Pack',
              },
              {
                id: 2,
                text: 'Smart loyalty',
              },
              {
                id: 3,
                text: 'Custom card design',
              },
              {
                id: 4,
                text: 'Feature on speciality coffee guide',
              },
              {
                id: 5,
                text: 'Customer support',
              },
              {
                id: 6,
                text: 'Bespoke NFC tag',
              },
              {
                id: 7,
                text: 'Loyalty analytics',
              },
              {
                id: 8,
                text: 'Customer insights',
              },
              {
                id: 9,
                text: 'Unlimited instant marketing posts',
              },
              {
                id: 10,
                text: 'Unlimited Email campaigns',
              },
              {
                id: 11,
                text: 'Unlimited Push notifications',
              },
              {
                id: 12,
                text: 'Bespoke POS material',
              },
            ],
          },
          plus: {
            title: 'PLUS!',
            list: [
              {
                id: 1,
                text: 'In-app digital tipping',
              },
              {
                id: 2,
                text: 'Dedicated Marketing Manager',
              },
              {
                id: 3,
                text: 'Included in B2B newsletter - access industry updates and samples',
              },
              {
                id: 4,
                text: 'Bespoke data reports',
              },
            ],
          },
        },
      },
      {
        id: 2,
        header: 'Cancel Subscription',
        type: 'text',
        content: {
          text: [
            'You are signing up for a FREE TRIAL with RWRD. There is no obligation to continue your subscription and you can cancel at any time. No payment will be taken.',
            'After your free trial, the subscription will renew automatically on a monthly basis.',
          ],
        },
      },
      {
        id: 3,
        header: 'What happens next',
        type: 'text_list',
        content: [
          {
            text: [
              'RWRD will provide all assets required to ensure your subscription runs seamlessly:',
            ],
          },
          {
            list: [
              {
                id: 1,
                text: 'RWRD QR code',
              },
              {
                id: 2,
                text: 'Window Vinyl',
              },
              {
                id: 3,
                text: 'Promotional Materials',
              },
            ],
          },
          {
            text: [
              'Once you click confirm you will be taken to set up your venue profile. Once this is complete the RWRD Team will contact you to arrange your in venue on-boarding. Your free trial will not start until the on-boarding is complete, and your venue is live on the RWRD app.',
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'price_1LVljYA8HcztP7hSljLTesat',
    price: 400,
    period: 12,
    addons: [
      { id: 'price_1LaTojA8HcztP7hSu1aABVxE', name: 'In app digital tipping' },
    ],
    card: {
      title: 'ROCKET PAKAGE',
      price: 400,
      badge: '',
      package_tags: [
        {
          id: 1,
          color: 'green',
          text: '£40 /month - 2 months free',
        },
        {
          id: 2,
          color: 'yellow',
          text: '£20 per additional site',
        },
      ],
    },
    accordion: [
      {
        id: 1,
        type: 'list',
        header: "What's included in Rocket Package?",
        content: {
          included: {
            title: 'included',
            list: [
              {
                id: 1,
                text: 'Starter Pack',
              },
              {
                id: 2,
                text: 'Smart loyalty',
              },
              {
                id: 3,
                text: 'Custom card design',
              },
              {
                id: 4,
                text: 'Feature on speciality coffee guide',
              },
              {
                id: 5,
                text: 'Customer support',
              },
              {
                id: 6,
                text: 'Bespoke NFC tag',
              },
              {
                id: 7,
                text: 'Loyalty analytics',
              },
              {
                id: 8,
                text: 'Customer insights',
              },
              {
                id: 9,
                text: 'Unlimited instant marketing posts',
              },
              {
                id: 10,
                text: 'Unlimited Email campaigns',
              },
              {
                id: 11,
                text: 'Unlimited Push notifications',
              },
              {
                id: 12,
                text: 'Bespoke POS material',
              },
            ],
          },
          plus: {
            title: 'PLUS!',
            list: [
              {
                id: 1,
                text: 'In-app digital tipping',
              },
              {
                id: 2,
                text: 'Dedicated Marketing Manager',
              },
              {
                id: 3,
                text: 'Included in B2B newsletter - access industry updates and samples',
              },
              {
                id: 4,
                text: 'Bespoke data reports',
              },
            ],
          },
        },
      },
      {
        id: 2,
        header: 'Cancel Subscription',
        type: 'text',
        content: {
          text: [
            'You are signing up for a FREE TRIAL with RWRD. There is no obligation to continue your subscription and you can cancel at any time. No payment will be taken.',
            'After your free trial, the subscription will renew automatically on a monthly basis.',
          ],
        },
      },
      {
        id: 3,
        header: 'What happens next',
        type: 'text_list',
        content: [
          {
            text: [
              'RWRD will provide all assets required to ensure your subscription runs seamlessly:',
            ],
          },
          {
            list: [
              {
                id: 1,
                text: 'RWRD QR code',
              },
              {
                id: 2,
                text: 'Window Vinyl',
              },
              {
                id: 3,
                text: 'Promotional Materials',
              },
            ],
          },
          {
            text: [
              'Once you click confirm you will be taken to set up your venue profile. Once this is complete the RWRD Team will contact you to arrange your in venue on-boarding. Your free trial will not start until the on-boarding is complete, and your venue is live on the RWRD app.',
            ],
          },
        ],
      },
    ],
  },
];

const addonsNew = [
  {
    id: 'price_1LaTojA8HcztP7hSu1aABVxE',
    name: 'DIGITAL TIPPING YEAR',
    type: 'stripe',
    price: 100,
    period: 12,
    popup: {
      type: 'three_columns_title_image_popup',
      title: 'What is in-app Digital tipping?',
      description:
        'A service for independent coffee shops that allows customers to leave tips for staff without having to scramble for cash.',
      subTitle: 'What are the benefits?',
      columns: [
        {
          id: 1,
          title: 'Retain',
          description:
            'Staff for longer by increasing earnings through digital tips',
          image: 'retain',
        },
        {
          id: 2,
          title: 'Analyse',
          description:
            'Direct pay-outs and tip breakdown (by day/week/month) on your dashboard',
          image: 'analyze',
        },
        {
          id: 3,
          title: 'Customise',
          description: 'Post-purchase “tipping” prompts to customers',
          image: 'customise',
        },
      ],
    },
    card: {
      title: 'IN APP DIGITAL TIPPING',
      price: 100,
      period: '/annum, excl VAT',
      badge: '',
      tags: [
        {
          id: 1,
          tag_color: 'green',
          tag_text: '£120 annually',
        },
      ],
    },
  },
  {
    id: 'price_1LaTojA8HcztP7hSRoBHTpfA',
    name: 'DIGITAL TIPPING MONTH',
    type: 'stripe',
    price: 10,
    period: 1,
    popup: {
      type: 'three_columns_title_image_popup',
      title: 'What is in-app Digital tipping?',
      description:
        'A service for independent coffee shops that allows customers to leave tips for staff without having to scramble for cash.',
      subTitle: 'What are the benefits?',
      columns: [
        {
          id: 1,
          title: 'Retain',
          description:
            'Staff for longer by increasing earnings through digital tips',
          image: 'retain',
        },
        {
          id: 2,
          title: 'Analyse',
          description:
            'Direct pay-outs and tip breakdown (by day/week/month) on your dashboard',
          image: 'analyze',
        },
        {
          id: 3,
          title: 'Customise',
          description: 'Post-purchase “tipping” prompts to customers',
          image: 'customise',
        },
      ],
    },
    card: {
      title: 'IN APP DIGITAL TIPPING',
      price: 10,
      period: '/month, excl VAT',
      badge: '',
      tags: [
        {
          id: 1,
          tag_color: 'green',
          tag_text: '£120 annually',
        },
      ],
    },
  },
  {
    id: 'positive_impact',
    name: 'POSITIVE IMPACT',
    type: 'internal',
    price: 0,
    period: null,
    popup: {
      type: 'title_text_popup',
      title:
        'We enable you to offer customers two tangible initiatives that make a big difference.',
      description: [
        'Planting Trees - As part of our Positive Impact mission to make better choices more accessible for our community, we’ve partnered with Eden Reforestation Projects to plant trees for coffees purchased.',
        'Offsetting Carbon - When we talk about carbon offsetting, what we mean is purchasing carbon credits, in order to counter the carbon created by the production of the coffee you are serving.',
      ],
      subTitle: 'Every day we are supporting forestation by planting trees.',
    },
    card: {
      title: 'POSITIVE IMPACT PARTNERSHIP',
      price: '0.15p',
      period: '/tree, excl VAT',
      badge: '',
      tags: [
        {
          id: 1,
          tag_color: 'green',
          tag_text: '+ Offset carbon £0.05p / Coffee',
        },
        {
          id: 2,
          tag_color: 'yellow',
          tag_text: 'Charged based on stamps',
        },
      ],
    },
  },
  {
    id: 'price_1Lpg94A8HcztP7hSdHw9IE5s',
    name: 'TIP TERMINAL',
    type: 'stripe',
    price: 15,
    period: 1,
    popup: {
      type: 'four_columns_title_image_popup',
      title: 'What is Tip Terminal?',
      description:
        'A service for independent coffee shops that allows customers to leave tips for staff without having to scramble for cash.',
      subTitle: 'What are the benefits?',
      columns: [
        {
          id: 1,
          title: 'Happy Staff',
          description: 'Boost retention, performance & earnings',
          image: 'noCost',
        },
        {
          id: 2,
          title: 'Collect tips',
          description: 'Collect tips via card & mobile',
          image: 'collectTips',
        },
        {
          id: 3,
          title: 'Plug & Play',
          description: 'Plug and play in any location',
          image: 'plugAndPlay',
        },
        {
          id: 4,
          title: 'Set amount',
          description: 'Customise the set amount',
          image: 'setAmount',
        },
      ],
    },
    card: {
      title: 'TIP TERMINAL',
      price: 15,
      period: '/month, excl VAT',
      tags: [
        {
          id: 1,
          tag_color: 'green',
          tag_text: 'Additional transaction fees apply',
        },
        {
          id: 2,
          tag_color: 'yellow',
          tag_text: 'Covered by customers',
        },
      ],
    },
  },
];

// type 'internal' - always + type 'stripe' (may be without 1 addon if addon id matched with subscr id) + 2 we need filter by period
// + one with type stripe we need to show always and manipulate price

const initialState = {
  subscribtionData: null,
  addonsData: '',
  carouselActiveItem: '',
  selectedAddons: [],
  notSelectedAddons: [],
  popupAddonId: '',
  totalPrice: '',
  isShowPopup: false,
};

const AuthAddonsComponent = () => {
  const step = storage.storageGet('step');
  if (step && step !== AUTH_ROUTS.ADDONS) history.push(step);
  if (!step) history.push(AUTH_ROUTS.SIGN_IN);

  const dispatch = useDispatch();
  const { subscription } = useSelector(selectUserObject);

  const [state, setState] = useState(initialState);

  // addons filter for state
  const getFilteredAddons = (addons, subscription) => {
    // transformation of addons array in case if we have only monthly addon but
    // need to show it as monthly in annual case, and calculate it as annual
    // just use (addon.type: 'stripe', addon.isPair: false) for transforming addon
    const addonsTransform = addons.reduce((acc, addon) => {
      if (addon.type === 'internal') return [...acc, addon];
      if (addon.type === 'stripe' && addon.isPair) return [...acc, addon];
      if (addon.type === 'stripe' && !addon.isPair) {
        const factor = 12 / addon.period;

        const period =
          addon.period < 12 ? addon.period * factor : addon.period / 12;
        const price =
          addon.period < 12 ? addon.price * factor : addon.price / 12;

        return [...acc, addon, { ...addon, price, period }];
      }
      return [...acc];
    }, []);

    // filtering addons if we already have addons in subscription
    const withAddon = (addon) => {
      const subcriptionsAddonsIds = subscription.addons.map((obj) => obj.id);
      const result =
        addon.type === 'internal' ||
        (addon.period === subscription.period &&
          addon.type === 'stripe' &&
          !subcriptionsAddonsIds.includes(addon.id));
      return result;
    };

    // filtering addons if we don`t have addons in subscription
    const withoutAddon = (addon) => {
      const result =
        addon.type === 'internal' ||
        (addon.type === 'stripe' && addon.period === subscription.period);
      return result;
    };
    return subscription.addons
      ? addonsTransform.filter(withAddon)
      : addonsTransform.filter(withoutAddon);
  };

  // setting data from BE
  const getSubscriptions = async () => {
    dispatch(profileActions.isFetching(true));
    try {
      const subscriptions = await client.get('/payments/subscriptions');
      const addons = await client.get('/payments/addons');
      const userSubscription = subscriptions.data.subscriptions.find(
        (el) => el.id === subscription.subscriptions_id
      );
      const filteredAddons = getFilteredAddons(
        addons.data.addons,
        userSubscription
      );
      const addonsIds = filteredAddons.map((addon) => addon.id);

      setState((state) => {
        return {
          ...state,
          subscribtionData: userSubscription,
          totalPrice: userSubscription.price,
          notSelectedAddons: addonsIds,
          carouselActiveItem: addonsIds[0],
          addonsData: filteredAddons,
        };
      });
    } catch (error) {
      console.log(error);
    }

    dispatch(profileActions.isFetching(false));
  };

  useEffect(() => {
    if (subscription.subscriptions_id) {
      getSubscriptions();
    }
  }, [subscription]);

  const handlePopup = (id) => {
    if (id) {
      return setState((state) => {
        return { ...state, popupAddonId: id, isShowPopup: true };
      });
    }
    return setState((state) => {
      return { ...state, isShowPopup: false };
    });
  };

  const handleCarouselActiveItem = (id) => {
    setState((state) => {
      return { ...state, carouselActiveItem: id };
    });
  };

  const handleSelectAddon = (id) => {
    const notSelectedAddonsArray = state.notSelectedAddons.filter(
      (el) => el !== id
    );
    const selectedAddon = state.addonsData.find((addon) => addon.id === id);
    const newTotalPrice = state.totalPrice + selectedAddon.price;

    setState((state) => {
      return {
        ...state,
        selectedAddons: [...state.selectedAddons, id],
        notSelectedAddons: notSelectedAddonsArray,
        carouselActiveItem: notSelectedAddonsArray[0],
        totalPrice: newTotalPrice,
      };
    });
  };

  const handleRemoveAddon = (id) => {
    const newArr = state.selectedAddons.filter((el) => el !== id);
    const selectedAddon = state.addonsData.find((addon) => addon.id === id);
    const newTotalPrice = state.totalPrice - selectedAddon.price;

    setState((state) => {
      return {
        ...state,
        selectedAddons: [...newArr],
        notSelectedAddons: [...state.notSelectedAddons, id],
        carouselActiveItem: id,
        totalPrice: newTotalPrice,
      };
    });
  };

  const getAddonData = (id) => {
    return state.addonsData.filter((addon) => id === addon.id);
  };

  const setNextPage = async () => {
    dispatch(profileActions.isFetching(true));
    // const responseData = state.subscribtionData.addons ? [state.subscribtionData.addons[0].id, ...state.selectedAddons] : state.selectedAddons;
    try {
      const response = await client.post('/user/addons', {
        addons: state.selectedAddons,
      });
    } catch (error) {
      console.log(error);
    }
    dispatch(profileActions.isFetching(false));
  };

  const skipThisPage = async () => {
    dispatch(profileActions.isFetching(true));
    try {
      const response = await client.post('/user/addons', { addons: [] });
    } catch (error) {
      console.log(error);
    }
    dispatch(profileActions.isFetching(false));
  };

  if (state.isShowPopup) {
    return (
      <PopupComponent
        popupAddonId={state.popupAddonId}
        getAddonData={getAddonData}
        handlePopup={handlePopup}
        handleSelectAddon={handleSelectAddon}
      />
    );
  }

  return (
    <StyledPageWrapper>
      <StyledLayout height="100%">
        <StyledLayoutWrapper>
          <StyledSection paddingTop={26} paddingBot={26}>
            {/* leftside */}
            <StyledSectionWrapper flexGrow="0">
              <StepsProgresComponent currentStep={2} />
              {state.addonsData && state.notSelectedAddons.length > 0 && (
                <>
                  <StyledTitle marginBot={8}>
                    Select Add On to your plan
                  </StyledTitle>
                  <StyledPageDescription>
                    This adds more features to your existing plan
                  </StyledPageDescription>
                  <StyledProgressBarComponent>
                    <StyledProgress progress={7} />
                  </StyledProgressBarComponent>
                </>
              )}
            </StyledSectionWrapper>

            <StyledCarouselContainer>
              {state.addonsData &&
                state.notSelectedAddons.length === 0 &&
                state.selectedAddons.length > 0 && (
                  <StyledCongratsContainer>
                    <StyledCongratsImage>
                      <img src={congrats} alt="congrats" />
                    </StyledCongratsImage>
                    <StyledTitle marginBot={8}>Thank you!</StyledTitle>
                    <StyledPageDescription>
                      Review your package.
                    </StyledPageDescription>
                    <StyledProgressBarComponent>
                      <StyledProgress progress={7} />
                    </StyledProgressBarComponent>
                  </StyledCongratsContainer>
                )}
              {state.addonsData && state.notSelectedAddons.length > 0 && (
                <CarouselContainer
                  carouselActiveItem={state.carouselActiveItem}
                  notSelectedAddons={state.notSelectedAddons}
                  getAddonData={getAddonData}
                  handlePopup={handlePopup}
                  handleCarouselActiveItem={handleCarouselActiveItem}
                />
              )}
              {state.addonsData && state.notSelectedAddons.length > 0 && (
                <StyledAddButton
                  onClick={() => handleSelectAddon(state.carouselActiveItem)}
                >
                  Add +{' '}
                </StyledAddButton>
              )}
            </StyledCarouselContainer>

            <div>
              <StyledButton onClick={skipThisPage}>Skip</StyledButton>
              <StyledButton prymary onClick={setNextPage}>
                Continue
              </StyledButton>
            </div>
          </StyledSection>
          <StyledSection paddingTop={56} paddingBot={56} bgImage={bgImage}>
            {/* rightside */}
            {state.subscribtionData && state.addonsData && (
              <TotalAddonsComponent
                isActive
                badge={state.subscribtionData.card.badge}
                title={state.subscribtionData.card.title}
                period={state.subscribtionData.period}
                price={state.subscribtionData.card.price}
                tags={state.subscribtionData.card.package_tags}
                totalPrice={state.totalPrice}
                selectedAddons={state.selectedAddons}
                getAddonData={getAddonData}
                handleRemoveAddon={handleRemoveAddon}
              />
            )}
          </StyledSection>
        </StyledLayoutWrapper>
      </StyledLayout>
    </StyledPageWrapper>
  );
};

export default AuthAddonsComponent;
