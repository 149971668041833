import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import socialImage from '../../assets/images/icons/social.svg';
import { mainTheme } from '../../mainStyles';
import { Text } from '../../components/MainComponents/MainComponents';

class FooterContainer extends Component {
  render() {
    return (
      <FooterWrapper>
        <FooterContent>
          <SocialWrapper>
            <a href="https://www.instagram.com/rwrdapp">
              <FooterSocial
                style={{ backgroundPositionX: 0, backgroundPositionY: 0 }}
              />
            </a>
            <a href="https://twitter.com/rwrdapp">
              <FooterSocial
                style={{ backgroundPositionX: -34, backgroundPositionY: 0 }}
              />
            </a>

            <a href="https://www.facebook.com/rwrdapp">
              <FooterSocial
                style={{ backgroundPosition: -63, backgroundPositionY: 0 }}
              />
            </a>
          </SocialWrapper>

          <SocialWrapper>
            <a
              style={{ textDecoration: 'none' }}
              href="https://www.rwrdapp.com/venue-faq/"
            >
              <LinkText>FAQ</LinkText>
            </a>
            <a
              style={{ marginLeft: 50, textDecoration: 'none' }}
              href="mailto:support@rwrdapp.com"
            >
              <LinkText> CONTACT US</LinkText>
            </a>
          </SocialWrapper>
        </FooterContent>
      </FooterWrapper>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);

const FooterWrapper = styled.div`
  width: 100%;
  height: 65px;
  margin-top: 25px;
  display: flex;
  justify-content: center;

  background-color: ${mainTheme.colors.textDark};
`;

const FooterContent = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 65px;
  padding: 0 25px;
`;

const FooterSocial = styled.span`
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  display: flex;
  background-repeat: no-repeat;

  background-image: url(${socialImage});
`;

const SocialWrapper = styled.div`
  height: 65px;
  align-items: center;
  display: flex;
`;

const LinkText = styled(Text)`
  color: #ffffff;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  letter-spacing: 2.25px;
  text-transform: uppercase;
`;
