import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  InputMain,
  Row,
  FieldLabel,
  TimeInput,
} from '../MainComponents/MainComponents';
import {
  selectProfileObject,
  selectProfileErrors,
} from '../../redux/profile/profile.selectors';

import './styles.css';

const OpeningTimesComponent = (props) => {
  const [timingsInfo, setTimingsInfo] = useState({
    monOpen: '',
    monClose: '',
    monOperational: false,
    tueOpen: '',
    tueClose: '',
    tueOperational: false,
    wedOpen: '',
    wedClose: '',
    wedOperational: false,
    thuOpen: '',
    thuClose: '',
    thuOperational: false,
    friOpen: '',
    friClose: '',
    friOperational: false,
    satOpen: '',
    satClose: '',
    satOperational: false,
    sunOpen: '',
    sunClose: '',
    sunOperational: false,
  });

  useEffect(() => {
    if (props.openingHours) {
      setTimingsInfo(props.openingHours);
    }
  }, [props.openingHours]);
  useEffect(() => {
    if (props.tempHours) {
      setTimingsInfo(props.tempHours);
    }
  }, [props.tempHours]);
  useEffect(() => {
    props.handleChange(timingsInfo);
  }, [timingsInfo]);

  const identifiers = {
    0: 'mon',
    1: 'tue',
    2: 'wed',
    3: 'thu',
    4: 'fri',
    5: 'sat',
    6: 'sun',
  };
  const OpeningTimesSection = () => {
    const days = [0, 1, 2, 3, 4, 5, 6];
    const listItems = days.map((day) => (
      <li>
        <OpeningTimesRow day={day} />
      </li>
    ));

    return <ul style={{ listStyleType: 'none' }}>{listItems}</ul>;
  };

  const OpeningTimesRow = (props) => {
    const { day } = props;
    const identifier = identifiers[day];
    let dayString = 'Monday';

    const operational = timingsInfo[`${identifier}Operational`];
    let open = timingsInfo[`${identifier}Open`];
    let close = timingsInfo[`${identifier}Close`];

    switch (day) {
      case 0:
        dayString = 'Monday';
        break;
      case 1:
        dayString = 'Tuesday';
        break;
      case 2:
        dayString = 'Wednesday';
        break;
      case 3:
        dayString = 'Thursday';
        break;
      case 4:
        dayString = 'Friday';
        break;
      case 5:
        dayString = 'Saturday';
        break;
      case 6:
        dayString = 'Sunday';
        break;
      default:
        break;
    }

    open = open ? `${open.slice(0, 2)}:${open.slice(2)}` : '';
    close = close ? `${close.slice(0, 2)}:${close.slice(2)}` : '';
    const openString = operational ? 'Open' : 'Closed';

    function handleTimeChange(event, name) {
      let { value } = event.target;
      value = value.slice(0, 2) + value.slice(3);
      setTimingsInfo({ ...timingsInfo, [name]: value });
    }

    return (
      <Row
        style={{
          flex: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <div style={{ minWidth: '120px', paddingRight: '10px' }}>
          <p>{dayString}</p>
        </div>
        <Row style={{ width: '20%', alignItems: 'center' }}>
          <Switch
            onChange={() =>
              setTimingsInfo({
                ...timingsInfo,
                [`${identifier}Operational`]: !operational,
              })
            }
            checked={operational}
            uncheckedIcon={false}
            checkedIcon={false}
            height={22}
            width={48}
            onColor="#4DC84B"
          />
          <FieldLabel
            style={{
              marginLeft: '10px',
              marginBottom: '0px',
              minWidth: '80px',
            }}
          >
            {openString}
          </FieldLabel>
        </Row>

        <Row style={{ minWidth: '50%', alignItems: 'center' }}>
          <TimeInput
            value={operational ? open : ''}
            style={{ minWidth: '20%', maxWidth: '30%', marginBottom: '0px' }}
            type="time"
            disabled={!operational}
            onChange={(event) => handleTimeChange(event, `${identifier}Open`)}
          />
          <p>{' - '} </p>
          <TimeInput
            value={operational ? close : ''}
            style={{ minWidth: '20%', maxWidth: '30%', marginBottom: '0px' }}
            type="time"
            onChange={(event) => handleTimeChange(event, `${identifier}Close`)}
            disabled={!operational}
          />
        </Row>
      </Row>
    );
  };
  return (
    <>
      <OpeningTimesSection data={props.data} />
    </>
  );
};

const openingTimesSelector = createSelector(
  selectProfileObject,
  selectProfileErrors,
  (profile, errors) => ({
    errors,
    tempHours: profile.temp.openingHours,
    openingHours: profile.openingHours,
  })
);
const mapStateToProps = openingTimesSelector;
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpeningTimesComponent);
