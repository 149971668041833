import styled from 'styled-components';

import { FieldLabel } from '../../components/MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';

export const Text = styled(FieldLabel)`
  text-decoration: none;
  margin-bottom: 0;
  line-height: 20px;
  min-width: 65px;
  text-transform: none;
  letter-spacing: 0.5px;
  font-size: 11px;
  font-weight: 300;
`;

export const SmallText = styled(Text)`
  line-height: 10px;
  font-size: 10px;
  margin-top: 15px;
`;

export const Link = styled.a`
  font-weight: 800;
  color: ${mainTheme.colors.black};
  text-decoration: underline;
  cursor: pointer;
`;

export const CheckBox = styled.input`
  height: 20px;
  width: 20px;
  margin-right: 15px;
  appearance: none;
  border: 1px solid ${mainTheme.colors.textDark};
  border-radius: 3px;
  outline: none;
  position: relative;

  &:checked {
    background: ${mainTheme.colors.textDark};

    &:after {
      content: '✔';
      font-size: 14px;
      position: absolute;
      top: 0px;
      left: 3px;
      color: #ffffff;
    }
  }
`;
