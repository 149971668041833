import styled from 'styled-components';
import { newTheme } from '../../mainStyles';

export const StyledContainer = styled.div`
  border-radius: 4px;
  overflow: hidden;
`;

export const AccordionToggle = styled.div`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  justify-content: space-between;
  transition: 0.3s;
  padding: 32px;
  background: white;

  @media (max-width: 1112px) {
    padding: 22px;
  }
`;

export const StyledCard = styled.div`
  border: 1px solid #131312;
  border-radius: 4px;
  margin-bottom: 8px;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledAccordionTitle = styled.h5`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 21px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  transition: 0.3s;
  color: #131312;

  @media (max-width: 1112px) {
    font-size: 1rem;
  }
`;

export const AccordionBody = styled.div`
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0 32px 32px 32px;

  p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #727e8c;
  }

  @media (max-width: 1112px) {
    padding: 0 22px 22px 22px;
  }
`;

export const Collapse = styled.div`
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
  background: white;

  ${({ active, id }) => active === id && 'height: auto'};
`;

export const StyledText = styled.div`
  font-family: ${newTheme.fontFamily.primary.regular};
  font-size: 17px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0;
  text-align: left;
  margin: 0 0 30px 0;

  &:last-child {
    margin: 0;
  }

  @media (max-width: 1112px) {
    font-size: 1em;
  }
`;

export const StyledList = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, 50%);
`;
export const StyledListItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
`;
export const StyledListItemText = styled.span`
  display: block;
  padding-left: 12px;
  font-family: ${newTheme.fontFamily.primary.regular};
  font-size: 17px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0;
  text-align: left;
  color: #5d5d5b;

  @media (max-width: 1112px) {
    font-size: 1em;
  }
`;

export const StyledNotIncludedTitle = styled.div`
  font-family: ${newTheme.fontFamily.primary.semiBold};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 25px 0;
`;
