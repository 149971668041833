import React from 'react';
import FourColumnsTitleImagePopup from '../FourColumnsTitleImagePopup copy/FourColumnsTitleImagePopup';
import ThreeColumnsTitleImagePopup from '../ThreeColumnsTitleImagePopup/ThreeColumnsTitleImagePopup';
import TitleTextPopup from '../TitleTextPopup/TitleTextPopup';

const PopupComponent = ({
  popupAddonId,
  getAddonData,
  handlePopup,
  handleSelectAddon,
}) => {
  const addonItem = getAddonData(popupAddonId);
  switch (addonItem[0].popup.type) {
    case 'three_columns_title_image_popup':
      return (
        <ThreeColumnsTitleImagePopup
          addonData={addonItem[0]}
          handlePopup={handlePopup}
          handleSelectAddon={handleSelectAddon}
        />
      );
    case 'title_text_popup':
      return (
        <TitleTextPopup
          addonData={addonItem[0]}
          handlePopup={handlePopup}
          handleSelectAddon={handleSelectAddon}
        />
      );
    case 'four_columns_title_image_popup':
      return (
        <FourColumnsTitleImagePopup
          addonData={addonItem[0]}
          handlePopup={handlePopup}
          handleSelectAddon={handleSelectAddon}
        />
      );
    default:
      return null;
  }
};

export default PopupComponent;
