import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Switch, Router } from 'react-router/esm/react-router';
import { createBrowserHistory } from 'history';
import NotFoundScreen from '../../screens/NotFoundScreen/NotFoundScreen';
import PrivateRouteContainer from '../PrivateRouteContainer/PrivateRouteContainer';
import MainScreen from '../../screens/MainScreen/MainScreen';
import appActions from '../../redux/app/app.actions';
import { selectSomePending } from '../../redux/app/app.selectors';
import storage from '../../utils/storage';
import { selectUserLogin } from '../../redux/user/user.selectors';
import { AuthScreen } from '../../screens/AuthScreen-v2/AuthScreen';
import { saveUser } from '../../redux/auth/auth.saga';
import userActions from '../../redux/user/user.actions';
import client from '../../utils/client';

export const history = createBrowserHistory({
  basename: '/',
});

class RouterContainer extends React.PureComponent {
  componentDidMount() {
    this.props.init();
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.isLogin &&
      this.props.isLogin &&
      window.location.pathname === '/auth/signin'
    ) {
      const path = storage.storageGet('step') || '/dashboard';
      history.push(path);
    }
  }

  render() {
    const params = new URLSearchParams(window.location.search);

    const [token, user, step] = [
      params.get('token'),
      params.get('user'),
      params.get('step'),
    ];
    if (token) {
      client.setToken(token);
    }
    if (user) {
      const parsed = JSON.parse(user);
      saveUser(parsed);
      userActions.fetchMeSuccess(parsed);
      // setTimeout(() => history.push('/dashboard'), 1000);
      if (step) {
        history.push(step);
      } else {
        history.push('/dashboard');
      }
    }

    return (
      <Router history={history}>
        <Switch>
          <Route path="/auth" component={AuthScreen} />

          <PrivateRouteContainer component={MainScreen} />
          <Route path="*" component={NotFoundScreen} />
        </Switch>
      </Router>
    );
  }
}

const mapDispatchToProps = {
  init: appActions.init,
};

const mapStateToProps = (state) => ({
  pending: selectSomePending(state),
  isLogin: selectUserLogin(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
