import styled from 'styled-components';

import { mainTheme } from '../../mainStyles';

export const SignUpTopLabel = styled.p`
  color: ${mainTheme.colors.primary};
  font-family: ${mainTheme.fontFamily.light};
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1.13px;
  margin: 0 auto;
  margin-bottom: 10px;
`;

export const SignUpText = styled.span.attrs({
  className: 'text-medium',
})`
  cursor: pointer;
`;

export const NameInputWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: -10px;

  & > div {
    width: 50px;
  }

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;
