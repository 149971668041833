import { handleActions, combineActions } from 'redux-actions';
import tipsActions from './tips.actions';

export const tipsInitialState = {
  message: '',
  errors: {},
  pending: false,

  tips: [],
  // total: 0,

  payouts: [],
  nextPayoutAmount: 0,

  allTips: [],
  totalSum: 0,
  averageTip: 0,
  customersCount: 0,

  bankName: '',
  currency: '',
  last4: '',
  routingNumber: '',
};

const tipsReducer = handleActions(
  {
    [combineActions(
      tipsActions.fetchPending,
      tipsActions.fetchPayoutPending,
      tipsActions.fetchTipStatsPending,
      tipsActions.fetchBankPending
    )]: (state) => ({
      ...state,
      pending: true,
    }),

    [tipsActions.fetchSuccess]: (state, action) => ({
      ...state,
      tips: action.payload.tips,
      // total: action.payload.total,
      pending: false,
    }),

    [tipsActions.fetchPayoutSuccess]: (state, action) => ({
      ...state,
      payouts: action.payload.payouts,
      nextPayoutAmount: action.payload.nextPayoutAmount,
      pending: false,
    }),

    [tipsActions.fetchTipStatsSuccess]: (state, action) => ({
      ...state,
      allTips: action.payload.allTips,
      totalSum: action.payload.totalSum,
      averageTip: action.payload.averageTip,
      customersCount: action.payload.customersCount,
      pending: false,
    }),

    [tipsActions.fetchBankSuccess]: (state, action) => ({
      ...state,
      bankName: action.payload.bank_name,
      currency: action.payload.currency,
      last4: action.payload.last4,
      routingNumber: action.payload.routing_number,
      pending: false,
    }),

    [combineActions(
      tipsActions.fetchFailed,
      tipsActions.fetchPayoutFailed,
      tipsActions.fetchTipStatsFailed,
      tipsActions.fetchBankFailed
    )]: (state, action) => ({
      ...state,
      pending: false,
      error: action.payload.error,
      message: action.payload.message,
    }),
  },
  tipsInitialState
);

export default tipsReducer;
