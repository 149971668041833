import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { StyledLayout } from '../../screens/AuthScreen-v2/styles';
import Accordion from '../Accordion/Accordion';
import StepsProgresComponent from '../StepsProgresComponent/StepsProgresComponent';
import SubscriptionCardComponent from '../SubscriptionCardComponent/SubscriptionCardComponent';
import TwoCardsCarousel from '../TwoCardsCarousel/TwoCardsCarousel';
import MessageComponent from '../MessageComponent/MessageComponent';

import {
  StyledLayoutWrapper,
  StyledLinkButton,
  StyledPreviewTitle,
} from '../MainComponents/MainComponents';
import {
  StyledBadge,
  StyledButton,
  StyledCardContainer,
  StyledCarouselContainer,
  StyledSection,
  StyledTab,
  StyledTabs,
  StyledTabTitle,
  StyledTitle,
  StyledPageWrapper,
} from './styles';

import bgImage from '../../assets/images/backgrounds/stepsPreview.png';
import client from '../../utils/client';
import profileActions from '../../redux/profile/profile.actions';
import storage from '../../utils/storage';
import { AUTH_ROUTS } from '../../constants/routes';
import { history } from '../../containers/RouterContainer/RouterContainer';

const initialState = {
  allSubscriptions: '',
  carouselItemsIds: '',
  carouselActiveItemId: '',
  period: 1,
  isPreview: false,
};

const AuthSubscriptionPageComponent = ({ handleSubscribe, message }) => {
  const step = storage.storageGet('step');
  if (step && step !== AUTH_ROUTS.SUBSCRIPTIONS) history.push(step);
  if (!step) history.push(AUTH_ROUTS.SIGN_IN);
  const dispatch = useDispatch();

  const [state, setState] = useState(initialState);

  const getSubscriptionsData = async () => {
    dispatch(profileActions.isFetching(true));
    const subscriptionsData = await client.get('/payments/subscriptions');
    setState((state) => {
      return {
        ...state,
        allSubscriptions: subscriptionsData.data.subscriptions,
      };
    });
    dispatch(profileActions.isFetching(false));
  };

  useEffect(() => {
    if (!state.allSubscriptions) {
      getSubscriptionsData();
    }
  }, []);

  useEffect(() => {
    if (!state.allSubscriptions) {
      return;
    }
    const index =
      state.carouselActiveItemId &&
      state.carouselItemsIds.findIndex(
        (el) => el === state.carouselActiveItemId
      );

    const carouselItemsIds = state.allSubscriptions
      .filter((el) => el.period === state.period)
      .map((el) => el.id);

    setState((state) => {
      return {
        ...state,
        carouselItemsIds,
        carouselActiveItemId: carouselItemsIds[index] || carouselItemsIds[0],
      };
    });
  }, [state.period, state.allSubscriptions]);

  const getsubscriptionData = (id) => {
    if (state.allSubscriptions) {
      return state.allSubscriptions.find((el) => el.id === id);
    }
  };

  const handleActiveCarouselItemId = (id) => {
    if (state.isPreview) {
      return;
    }
    setState((state) => {
      return {
        ...state,
        carouselActiveItemId: id,
      };
    });
  };

  const handlePeriod = (period) => {
    if (state.isPreview) {
      return;
    }

    setState((state) => {
      return {
        ...state,
        period,
      };
    });
  };
  const handlePreview = (isPreview) => {
    setState((state) => {
      return {
        ...state,
        isPreview,
      };
    });
  };

  const postSubscriptionData = () => {
    const chosenSubsription = getsubscriptionData(state.carouselActiveItemId);
    handleSubscribe({ subscription: chosenSubsription.id });
  };

  const activeItem = getsubscriptionData(state.carouselActiveItemId);

  return (
    <StyledPageWrapper>
      <StyledLayout height="100%">
        <StyledLayoutWrapper>
          <StyledSection paddingTop={26} paddingBot={26}>
            {/* leftside */}

            <StepsProgresComponent currentStep={1} />
            <StyledTitle>
              {state.isPreview ? 'Your chosen plan' : 'Choose your plan'}
            </StyledTitle>
            <StyledTabs>
              <StyledTab
                term={state.period === 1}
                onClick={() => handlePeriod(1)}
              >
                <StyledTabTitle>Bill Monthly</StyledTabTitle>
              </StyledTab>
              <StyledTab
                term={state.period === 12}
                onClick={() => handlePeriod(12)}
              >
                <StyledTabTitle>Bill Annually</StyledTabTitle>
                <StyledBadge>2 months free</StyledBadge>
              </StyledTab>
            </StyledTabs>
            {!state.isPreview && (
              <StyledCarouselContainer>
                {state.carouselItemsIds && state.carouselActiveItemId && (
                  <TwoCardsCarousel
                    carouselItemsIds={state.carouselItemsIds}
                    carouselActiveItemId={state.carouselActiveItemId}
                    getsubscriptionData={getsubscriptionData}
                    handleActiveCarouselItemId={handleActiveCarouselItemId}
                  />
                )}
              </StyledCarouselContainer>
            )}

            {state.isPreview && (
              <StyledCardContainer>
                {state.carouselItemsIds && (
                  <SubscriptionCardComponent
                    isActive
                    subscriptionId={state.carouselItemsIds[0]}
                    title={activeItem.card.title}
                    badge={activeItem.card.badge}
                    price={activeItem.card.price}
                    period={activeItem.period}
                    tags={activeItem.card.package_tags}
                    handleActiveCarouselItemId={handleActiveCarouselItemId}
                  />
                )}
              </StyledCardContainer>
            )}
            <div style={{ marginBottom: '16px' }}>
              <MessageComponent text={message} type="danger" />
            </div>
            {!state.isPreview && (
              <div>
                <StyledLinkButton
                  href="https://calendly.com/rwrd/introduction-to-rwrd-1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Book a call
                </StyledLinkButton>
                <StyledButton prymary onClick={() => handlePreview(true)}>
                  Confirm
                </StyledButton>
              </div>
            )}
            {state.isPreview && (
              <div>
                <StyledButton onClick={() => handlePreview(false)}>
                  Change plan
                </StyledButton>
                <StyledButton prymary onClick={postSubscriptionData}>
                  Confirm
                </StyledButton>
              </div>
            )}
          </StyledSection>
          <StyledSection bgImage={bgImage}>
            {/* rightside */}
            <StyledPreviewTitle>Description</StyledPreviewTitle>
            <div>
              {state.carouselItemsIds && state.carouselActiveItemId && (
                <Accordion
                  preview={state.isPreview}
                  carouselActiveItemId={state.carouselActiveItemId}
                  getsubscriptionData={getsubscriptionData}
                />
              )}
            </div>
          </StyledSection>
        </StyledLayoutWrapper>
      </StyledLayout>
    </StyledPageWrapper>
  );
};

export default AuthSubscriptionPageComponent;
