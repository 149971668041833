import { createSelector } from 'reselect';
import { selectAuthPending } from '../auth/auth.selectors';
import { selectPostPending } from '../post/post.selectors';
import { selectProfilePending } from '../profile/profile.selectors';
import { selectStatisticPending } from '../statistic/statistic.selectors';
import { selectPaymentsPending } from '../payments/payments.selectors';
import { selectPostsPending } from '../posts/posts.selectors';
import { selectUserPending } from '../user/user.selectors';

const rootSelector = (state) => state.app;

export const selectAppSidebar = createSelector(
  rootSelector,
  (app) => app.sidebar
);

export const selectAppPending = createSelector(rootSelector, (app) =>
  Boolean(app.pending)
);
export const selectSomePending = createSelector(
  selectAppPending,
  selectAuthPending,
  selectPaymentsPending,
  selectPostPending,
  selectPostsPending,
  selectProfilePending,
  selectUserPending,
  selectStatisticPending,
  (app, auth, payments, post, posts, profile, user, statistic) =>
    Boolean(
      app || auth || payments || post || posts || profile || user || statistic
    )
);
