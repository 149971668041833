import React, { Component, useState } from 'react';

import styled from 'styled-components';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  injectStripe,
  StripeProvider,
} from 'react-stripe-elements';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import BrandingBoosterCard from '../BrandingBoosterCard/BrandingBoosterCard';

import { StyledLayout } from '../../../../screens/AuthScreen-v2/styles';
import {
  StyledErrorMessage,
  StyledInputContainer,
  StyledLayoutWrapper,
} from '../../../MainComponents/MainComponents';
import {
  StyledPageWrapper,
  StyledSection,
} from '../../../AuthSubscriptionPageComponent/styles';
import {
  StyledAddButton,
  StyledAddonContainer,
  StyledButtonContainer,
  StyledCancelButton,
  StyledCloseButton,
  StyledContainer,
  StyledDescriptionText,
  StyledTextSection,
  StyledTitleText,
  StyledLine,
  StyledCardContainer,
  StyledBackButton,
} from './styles';

import { ReactComponent as CloseIcon } from '../../../../assets/images/popup-close-icon.svg';
import { ReactComponent as ArrowLeft } from '../../../../assets/images/icons/arrow-left.svg';
import popupBg from '../../../../assets/images/backgrounds/popupBg.png';
import { history } from '../../../../containers/RouterContainer/RouterContainer';
import { AUTH_ROUTS } from '../../../../constants/routes';
import { newTheme } from '../../../../mainStyles';
import { selectUserObject } from '../../../../redux/user/user.selectors';
import paymentsActions from '../../../../redux/payments/payments.actions';
import {
  selectPaymentsErrors,
  selectPaymentsMessage,
  selectPaymentsPending,
} from '../../../../redux/payments/payments.selectors';

const style = {
  base: {
    iconColor: '#c4f0ff',
    color: '#131312',
    fontWeight: '300',
    fontFamily: 'inherit',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    ':-webkit-autofill': {
      color: '#fce883',
    },
    '::placeholder': {
      color: '#959492',
    },
  },
  // invalid: {
  // 	iconColor: '#FFC7EE',
  // 	color: '#FFC7EE'
  // }
};

const BrandingBoosterCardDetailPopup = ({
  handleBrandingBoostPopup,
  stripe,
  user,
  message,
  pending,
  fetchStripeCardPending,
  fetchUpdateStripeCardPending,
}) => {
  const [state, setState] = useState({ stripeError: '', stripeLoading: false });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (stripe) {
      setState((state) => {
        return {
          ...state,
          stripeLoading: true,
        };
      });
      try {
        const payload = await stripe.createToken();

        if (payload.error && payload.error.message) {
          setState((state) => {
            return {
              ...state,
              stripeError: payload.error.message,
            };
          });
        } else {
          setState((state) => {
            return {
              ...state,
              stripeError: '',
            };
          });

          if (user && typeof user.card === 'string') {
            // User is updating his card details
            fetchUpdateStripeCardPending(payload.token);
          } else {
            fetchStripeCardPending(payload.token);
          }
        }
      } catch (error) {
        console.log('// Stripe error', error);
        setState((state) => {
          return {
            ...state,
            stripeError: 'Oops. There has been a problem, please try again.',
          };
        });
      }
      setState((state) => {
        return {
          ...state,
          stripeLoading: false,
        };
      });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  const errorMessage = state.stripeError || message;

  return (
    <StyledPageWrapper>
      <StyledLayout height="100%">
        <StyledLayoutWrapper>
          <StyledSection paddingTop={30} paddingBot={30} bgImage={popupBg}>
            <StyledCloseButton
              onClick={() => handleBrandingBoostPopup(false, 1)}
            >
              <CloseIcon />
            </StyledCloseButton>
            <StyledBackButton onClick={() => handleBrandingBoostPopup(true, 1)}>
              <ArrowLeft />
            </StyledBackButton>
            <StyledContainer as="form" onSubmit={onSubmit}>
              <StyledTextSection>
                <StyledTitleText marginBot={16}>
                  Your Card Details
                </StyledTitleText>
                <StyledDescriptionText>
                  Benefit from a branding boost
                </StyledDescriptionText>
                <StyledLine />
                {/* addon  */}
                <StyledCardContainer>
                  <StyledAddonContainer>
                    <BrandingBoosterCard
                      isActive
                      title="BRANDING BOOST"
                      price={120}
                      term="excl VAT, One off cost"
                      badge="One time payment"
                    />
                  </StyledAddonContainer>
                  <StyledFormWrapper>
                    <StyledInputContainer marginBot={0}>
                      {/* <CreditCardDetails /> */}
                      <StyledInputLabel>Name</StyledInputLabel>
                      <StyledInput
                        maxLength={50}
                        placeholder="Enter card holder’s name"
                      />
                      <StyledInputLabel>Card Number</StyledInputLabel>
                      <StyledInput
                        as={CardNumberElement}
                        style={style}
                        maxLength={50}
                        placeholder="Enter card number"
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <div style={{ width: '48%' }}>
                          <StyledInputLabel>Expiry</StyledInputLabel>
                          <StyledInput
                            as={CardExpiryElement}
                            style={style}
                            placeholder="MM / YY"
                          />
                        </div>
                        <div style={{ width: '48%' }}>
                          <StyledInputLabel>CVV</StyledInputLabel>
                          <StyledInput
                            as={CardCVCElement}
                            style={style}
                            placeholder="CVV"
                          />
                        </div>
                      </div>
                      {errorMessage && (
                        <div>
                          <StyledErrorMessage>{message}</StyledErrorMessage>
                        </div>
                      )}
                    </StyledInputContainer>
                  </StyledFormWrapper>
                </StyledCardContainer>
              </StyledTextSection>
              <StyledButtonContainer>
                <StyledCancelButton
                  onClick={() => handleBrandingBoostPopup(true, 1)}
                >
                  Discard
                </StyledCancelButton>
                <StyledAddButton
                  //  onClick={() => history.push(AUTH_ROUTS.CONGRATULATIONS)}
                  disabled={pending || state.stripeLoading}
                  type="Submit"
                >
                  Checkout
                </StyledAddButton>
              </StyledButtonContainer>
            </StyledContainer>
          </StyledSection>
        </StyledLayoutWrapper>
      </StyledLayout>
    </StyledPageWrapper>
  );
};

// export default BrandingBoosterCardDetailPopup;

class BrandingBoosterCardDetailPopupContainer extends Component {
  render() {
    return (
      <StripeProvider StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <Elements>
          <BrandingBoosterCardDetailPopup
            stripe={this.props.stripe}
            user={this.props.user}
            pending={this.props.pending}
            message={this.props.message}
            handleBrandingBoostPopup={this.props.handleBrandingBoostPopup}
            fetchStripeCardPending={this.props.fetchStripeCardPending}
            fetchUpdateStripeCardPending={
              this.props.fetchUpdateStripeCardPending
            }
          />
        </Elements>
      </StripeProvider>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: selectUserObject,
  pending: selectPaymentsPending,
  message: selectPaymentsMessage,
  errors: selectPaymentsErrors,
});

const mapDispatchToProps = {
  fetchStripeCardPending: paymentsActions.fetchCreateStripeCardPending,
  fetchUpdateStripeCardPending: paymentsActions.fetchUpdateStripeCardPending,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectStripe(BrandingBoosterCardDetailPopupContainer));

export const StyledFormWrapper = styled.div`
  width: 450px;

  @media (max-width: 1190px) {
    width: 330px;
  }
  @media (max-width: 850px) {
    width: 450px;
  }
`;

export const StyledInputLabel = styled.div`
  font-family: ${newTheme.fontFamily.primary.regular};
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
`;

export const StyledInput = styled.input`
  font-family: ${newTheme.fontFamily.primary.light};
  width: 100%;
  padding: 17px 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  /* color: ${({ value }) => (value ? '#131312' : '#959492')}; */
  color: black;
  /* border: 1px solid #959492; */
  ${({ borderColor }) => borderColor && 'border: #DFDFDE;'}
  margin-bottom: 24px;

  border: 1px solid ${({ errors }) => (errors ? `red;` : '#DFDFDE;')};
  border: 1px solid
    ${({ borderColor, errors }) =>
      borderColor && errors ? `red;` : '#DFDFDE;'};
  border: 1px solid
    ${({ errorMessage }) => (errorMessage ? `red;` : '#DFDFDE;')};

  ${({ icon }) =>
    icon && `background: url(${icon}) no-repeat scroll 23px 15px;`}
  ${({ icon, vertical }) =>
    icon &&
    vertical &&
    `background: url(${icon}) no-repeat scroll 23px ${vertical}px;`}

	&:hover {
    border: 1px solid black;
  }
  &:focus {
    border: 1px solid black;
    outline: none;
  }
  &:active {
    border: 1px solid black;
  }
  &:focus-visible {
    border: 1px solid black;
    color: black;
  }

  @media (max-width: 450px) {
    ${({ icon }) =>
      icon && `background: url(${icon}) no-repeat scroll 8px 15px;`}
    padding-left: 34px;
  }
`;
