import styled from 'styled-components';
import { mainTheme, newTheme } from '../../mainStyles';
import { RowOffsetBottom } from '../MainComponents/MainComponents';

export const ImagePickerWrapper = styled(RowOffsetBottom)`
  position: relative;
  display: flex;
  width: 100%;
`;

export const UploadImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #9f9f9f;
  overflow: hidden;
  margin-bottom: 16px;
  max-width: 140px;
  height: 140px;

  @media (max-width: 1000px) {
    height: 130px;
    max-width: 130px;
  }
`;

export const UploadImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.image || mainTheme.images.default});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  max-width: 140px;
  height: 140px;

  @media (max-width: 1000px) {
    height: 130px;
    max-width: 130px;
  }
`;

export const StyledButton = styled.button`
  font-family: ${newTheme.fontFamily.primary.regular};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  background: ${({ remove }) => (remove ? '#fff;' : '#131312;')};
  border: 1px solid #131312;
  color: ${({ remove }) => (remove ? '#131312;' : '#fff;')};
  border-radius: 4px;
  cursor: pointer;

  padding: 10px 24px;
`;

export const StyledImgInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledInputDescriptionBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 16px;
`;
export const StyledInputDescription = styled.div`
  font-family: ${newTheme.fontFamily.primary.light};
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #959492;
`;
