import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import bubbleIcon from '../../assets/images/ellipsis-bubble.png';
import { Wrapper, BubbleButton } from './styles';
import { emailTemplateMapToName } from './emailTemplates';

function withBubbleChat(WrappedComponent) {
  class WithBubbleChat extends React.Component {
    goToSupport = (e) => {
      const { name, title, venueName } = this.props;
      // this.props.history.push('/support', { subject: this.props.name || this.props.title });
      e.preventDefault();
      const titleName = name || title || '';

      const email = 'SUPPORT@RWRD.CO.UK';
      const { subject, body } = emailTemplateMapToName[titleName](
        titleName,
        venueName
      );

      window.location.target = '_blank';
      window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
    };

    render() {
      return (
        <Wrapper>
          <BubbleButton onClick={this.goToSupport}>
            <img src={bubbleIcon} alt="Send Message" />
          </BubbleButton>

          <WrappedComponent {...this.props} />
        </Wrapper>
      );
    }
  }

  function mapStateToProps(state) {
    return { venueName: state.profile.name };
  }

  return connect(mapStateToProps)(withRouter(WithBubbleChat));
}

export default withBubbleChat;
