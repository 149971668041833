import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectAuthErrors,
  selectAuthMessage,
  selectAuthPending,
  selectAuthSuccessMessage,
} from '../../redux/auth/auth.selectors';
import authActions from '../../redux/auth/auth.actions';
import { serializeForm } from '../../utils/selectors';
import { parseQuery } from '../../utils/client';
import ResetPasswordFormComponent from '../../components/ResetPasswordFormComponent-v2/ResetPasswordFormComponent';

import bgImage from '../../assets/images/backgrounds/signInSignUp.jpg';
import { StyledLayout } from '../../screens/AuthScreen-v2/styles';

export class SigninContainer extends Component {
  state = {
    salt: '',
    member: '',
  };

  componentDidMount() {
    const { salt, member } = parseQuery(window.location.search);
    this.setState({ salt, member });
  }

  resetPassword = (event) => {
    event.preventDefault();
    const { salt, member } = this.state;
    const values = serializeForm(event.target);
    this.props.fetchResetPasswordPending({ ...values, salt, member });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.successMessage !== this.props.successMessage &&
      this.props.successMessage &&
      this.form
    ) {
      this.form.reset();
    }
  }

  render() {
    return (
      <StyledLayout login bgImage={bgImage}>
        <ResetPasswordFormComponent
          resetPassword={this.resetPassword}
          errors={this.props.errors}
          message={this.props.message}
          successMessage={this.props.successMessage}
        />
      </StyledLayout>

      // old version
      // <AuthForm
      // 	fullWidth
      // 	as={'form'}
      // 	onSubmit={this.resetPassword}
      // 	maxWidth={465}
      // 	ref={(form) => (this.form = form)}>
      // 	<Column>
      // 		<InputValidation
      // 			placeholder={'Password'}
      // 			name={'password'}
      // 			type={'password'}
      // 			error={this.props.errors['password']}
      // 		/>

      // 		<InputValidation
      // 			placeholder={'Password confirmation'}
      // 			name={'password_confirmation'}
      // 			type={'password'}
      // 			error={this.props.errors['password']}
      // 		/>
      // 		<MessageComponent type="danger" text={this.props.message} />
      // 		<MessageComponent type="success" text={this.props.successMessage} />

      // 		<AuthButton type={'submit'}>Reset password</AuthButton>

      // 		<Divider />
      // 		<HelperLink to={'signup'}>
      // 			<AuthLabelText>
      // 				Don’t have an account? <span className={'text-medium'}>Sign up</span>
      // 			</AuthLabelText>
      // 		</HelperLink>
      // 	</Column>
      // </AuthForm>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  errors: selectAuthErrors,
  message: selectAuthMessage,
  pending: selectAuthPending,
  successMessage: selectAuthSuccessMessage,
});

const mapDispatchToProps = {
  fetchResetPasswordPending: authActions.fetchResetPasswordPending,
};

export default connect(mapStateToProps, mapDispatchToProps)(SigninContainer);
