import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Avatar } from '../../components/MainComponents/MainComponents';
import { selectUserAvatarName } from '../../redux/user/user.selectors';
import authActions from '../../redux/auth/auth.actions';
import {
  AvatarContainerWrapper,
  AvatarDropDown,
  AvatarDropDownItem,
} from './styles';
import { selectProfileShortName } from '../../redux/profile/profile.selectors';

export class UserAvatarContainer extends Component {
  static propTypes = {
    avatarName: PropTypes.string,
  };

  static defaultProps = {
    avatarName: '',
  };

  state = {
    active: false,
    avatar: this.props.userName,
  };

  componentDidMount() {
    if (!this.props.avatarName) {
      this.setState({ avatar: this.props.userName });
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.avatarName !== this.props.avatarName) {
      this.setState({ avatar: this.props.avatarName || this.props.userName });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  toggle = () => this.setState((state) => ({ active: !state.active }));

  logout = () => {
    this.props.logout();
  };

  handleClickOutside = (event) => {
    if (
      this.logoutDropdown &&
      this.avatarContainer &&
      !this.logoutDropdown.contains(event.target) &&
      !this.avatarContainer.contains(event.target)
    ) {
      if (this.state.active) {
        this.toggle();
      }
    }
  };

  render() {
    return (
      <AvatarContainerWrapper
        onClick={this.toggle}
        ref={(container) => (this.avatarContainer = container)}
      >
        {this.state.avatar.includes('http') ? (
          <Avatar backgroundImage={this.state.avatar} />
        ) : this.state.avatar ? (
          <Avatar>{this.state.avatar}</Avatar>
        ) : (
          <Avatar backgroundImage="https://rwrd-image-storage.s3.eu-west-2.amazonaws.com/images/defaults/default-user-logo.png" />
        )}
        <AvatarDropDown
          active={this.state.active}
          ref={(dropdown) => (this.logoutDropdown = dropdown)}
        >
          <AvatarDropDownItem onClick={this.logout}>Logout</AvatarDropDownItem>
        </AvatarDropDown>
      </AvatarContainerWrapper>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  avatarName: selectProfileShortName,
  userName: selectUserAvatarName,
});

const mapDispatchToProps = {
  logout: authActions.logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAvatarContainer);
