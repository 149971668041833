import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import triangleRed from '../../assets/images/icons/triangle-red.png';
import triangleGreen from '../../assets/images/icons/triangle-green.png';

const Triangle = styled.img`
  width: 10px;
  height: 8px;
`;

export default class DiffArrowComponent extends Component {
  static propTypes = {
    diff: PropTypes.number,
  };

  render() {
    return <Triangle src={this.props.diff < 0 ? triangleRed : triangleGreen} />;
  }
}
