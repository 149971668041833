import styled from 'styled-components';
import { Column, FieldLabel, Row } from '../MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';
import stampCheckbox from '../../assets/images/icons/check-mark.svg';
import starImage from '../../assets/images/icons/star.svg';
import { patterns } from '../../constants/patterns';

const getPattern = (patternId) =>
  patterns.find((pattern) => pattern.id == patternId) || {};

export const StampComponentWrapper = styled(Column)`
  border: 1px solid ${mainTheme.colors.grey};
  border-radius: ${mainTheme.radius.small}px;
  width: 100%;
  max-width: 290px;
  padding: ${mainTheme.offset.xsmall}px;
  overflow: hidden;
  position: relative;
  background-color: ${(props) => props.background};
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: url(${(props) => getPattern(props.pattern).name});
    background-repeat: repeat;
    box-shadow: 0 3px 5px 0 rgba(180, 180, 180, 0.2);
  }
`;

export const StampImage = styled.div`
  width: 50px;
  height: 50px;
  margin-right: ${mainTheme.offset.xsmall}px;
  background-image: url(${(props) => props.image});
  background-size: contain;
`;

export const StampsRow = styled(Row)`
  justify-content: space-between;
  flex-wrap: wrap;

  &:after {
    content: '';
    flex: auto;
    flex-basis: 37px;
  }
`;

export const StampTitle = styled(FieldLabel)`
  margin: 0;
`;

export const StampCheckbox = styled.div`
  width: 37px;
  height: 37px;
  border: 1px solid #2f2f2f;
  border-radius: ${mainTheme.radius.xsmall}px;
  position: relative;
  background-color: ${(props) =>
    props.checked ? props.stamp_color : mainTheme.colors.white};
  margin: ${mainTheme.offset.small}px 7.5px 0;
  &:after {
    content: '';
    background-image: ${(props) =>
      props.checked ? `url(${stampCheckbox})` : 'none'};
    background-size: 80%;
    background-position: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;
  }
`;

export const StampStar = styled.div`
  width: 10px;
  height: 10px;
  color: #f9bf3a;
  background-image: url(${starImage});
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 2px;
`;

export const StampSubtitle = styled(StampTitle)`
  font-size: 10px;
  text-transform: none;
  letter-spacing: normal;
`;
