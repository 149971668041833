import { fork, put, takeLatest } from 'redux-saga/effects';
import client, { handleRequestError } from '../../utils/client';
import userActions from './user.actions';
import appActions from '../app/app.actions';
import { saveUser } from '../auth/auth.saga';
import storage from '../../utils/storage';

function* fetchUpdateActive(action) {
  try {
    yield client.put('/user/active', {
      active: Boolean(action.payload.active),
    });
    yield put(userActions.fetchUpdateActiveSuccess(action.payload.active));
  } catch (error) {
    handleRequestError(
      error,
      userActions.fetchUpdateActiveFailed,
      fetchUpdateActive,
      action
    );
  }
}
function* fetchMe(action) {
  try {
    const token =
      storage.get('bearer_token') || sessionStorage.getItem('bearer_token');
    if (!token) {
      yield put(userActions.fetchMeFailed());
      return;
    }
    const response = yield client.get('user/me');
    saveUser(response.data);
    yield put(userActions.fetchMeSuccess(response.data.user));
  } catch (error) {
    handleRequestError(error, userActions.fetchMeFailed, fetchMe, action);
  }
}

function* updateAccount(action) {
  try {
    const { firstName, lastName, email } = action.payload;
    const response = yield client.put('user', { firstName, lastName, email });
    saveUser(response.data);
    yield put(userActions.updateAccountFetchSuccess(response.data.user));
  } catch (error) {
    yield handleRequestError(
      error,
      userActions.updateAccountFetchFailed,
      updateAccount,
      action
    );
  }
}

function* connectAccount(action) {
  try {
    const user = storage.get('user');
    if (!user.stripe.connectAccountId) {
      const response = yield client.post('user/connect-account');
      saveUser(response.data);
    }
    const {
      stripe: { connectAccountId },
    } = storage.get('user');
    const onboardingResponse = yield client.get(
      `user/connect-onboarding/${connectAccountId}`
    );
    window.open(onboardingResponse.data, '_self');
    yield put(userActions.connectAccountSuccess());
  } catch (error) {
    yield handleRequestError(
      error,
      userActions.connectAccountFailed,
      connectAccount,
      action
    );
  }
}

function* deleteAccount(action) {
  try {
    yield client.delete('user');

    yield put(userActions.deleteSuccess());
  } catch (error) {
    handleRequestError(error, userActions.deleteFailed, deleteAccount, action);
  }
}

function* updateAccountPassword(action) {
  try {
    const { password, password_confirmation, currentPassword } = action.payload;
    yield client.put('user/password', {
      password,
      password_confirmation,
      currentPassword,
    });
    yield put(userActions.updateAccountPasswordFetchSuccess());
  } catch (error) {
    yield handleRequestError(
      error,
      userActions.updateAccountPasswordFetchFailed,
      updateAccountPassword,
      action
    );
  }
}

function* feedback(action) {
  try {
    const { form } = action.payload;
    yield client.post('feedback', { ...form });
    yield put(userActions.feedbackSuccess());
  } catch (error) {
    yield handleRequestError(
      error,
      userActions.feedbackFailed,
      feedback,
      action
    );
  }
}

function* UserFlow() {
  yield takeLatest(userActions.fetchUpdateActivePending, fetchUpdateActive);
  yield takeLatest([userActions.fetchMePending, appActions.init], fetchMe);
  yield takeLatest(userActions.updateAccountFetchPending, updateAccount);
  yield takeLatest(userActions.deletePending, deleteAccount);
  yield takeLatest(userActions.feedbackPending, feedback);
  yield takeLatest(
    userActions.updateAccountPasswordFetchPending,
    updateAccountPassword
  );
  yield takeLatest(userActions.connectAccountPending, connectAccount);
}

function* userWorker() {
  yield fork(UserFlow);
}

export default userWorker;
