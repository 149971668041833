import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import React from 'react';
import { MapContainerWrapper } from './styles';
import LoaderComponent from '../../components/LoaderComponent/LoaderComponent';

const style = {
  width: '100%',
  height: '100%',
};
export class MapContainer extends React.Component {
  static defaultProps = {
    lat: 51.5287336,
    lng: -0.38246,
    name: 'London, UK',
  };

  onMarkerClick = () => {
    console.log('marker clicked');
  };

  onInfoWindowClose = () => {
    console.log('closed');
  };

  render() {
    return (
      <MapContainerWrapper>
        <Map
          style={style}
          disableDefaultUI
          draggable={false}
          zoomControl={false}
          scrollWheel={false}
          initialCenter={{
            lat: parseFloat(this.props.city.lat),
            lng: parseFloat(this.props.city.lng),
          }}
          google={this.props.google}
          zoom={14}
        >
          <Marker onClick={this.onMarkerClick} name="Current location" />

          <InfoWindow onClose={this.onInfoWindowClose}>
            <div>
              <h1>{this.props.city.label}</h1>
            </div>
          </InfoWindow>
        </Map>
      </MapContainerWrapper>
    );
  }
}

export default GoogleApiWrapper((props) => ({
  apiKey: props.apiKey,
  LoadingContainer: LoaderComponent,
  city: props.city,
}))(MapContainer);
