import styled from 'styled-components';
import { newTheme } from '../../mainStyles';

export const StyledCongratsImage = styled.div`
  display: flex;
  padding: 30px;
  background-color: white;

  border-radius: 50%;
  box-shadow: 0px 16px 32px rgba(238, 193, 11, 0.16);

  margin-bottom: 30px;
`;

export const StyledSection = styled.div`
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  ${({ phone }) => phone && 'justify-content: flex-start;'};
  display: flex;
  flex-direction: column;
  padding: 0 30px;

  padding-top: ${({ paddingTop }) => paddingTop && `${paddingTop}px;`};
  padding-bottom: ${({ paddingBot }) => paddingBot && `${paddingBot}px;`};
  padding-left: ${({ paddingLeft }) => paddingLeft && `${paddingLeft}px;`};
  padding-right: ${({ paddingRight }) => paddingRight && `${paddingRight}px;`};

  ${({ bgImage }) =>
    bgImage && `background: url(${bgImage}) center center/cover no-repeat;`};

  @media (max-width: 925px) {
    padding: 25px 5px;
  }
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 450px;

  @media (max-width: 1000px) {
    width: 350px;
  }
`;

export const StyledTitle = styled.div`
  font-family: ${newTheme.fontFamily.primary.regular};
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;

  margin-bottom: ${({ marginBot }) => (marginBot ? `${marginBot}px` : '48px')};
`;

export const StyledPageDescription = styled.div`
  font-family: ${newTheme.fontFamily.primary.light};
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: ${({ marginBot }) => (marginBot ? `${marginBot}px` : '24px')};
`;
