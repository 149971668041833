import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import {
  HeaderContainerWrapper,
  ToggleSidebarIcon,
  HeaderControls,
  HeaderIcon,
  HeaderControlSection,
  // HeaderText,
  HeaderHideOnMobile,
} from './styles';
// import notificationIcon from '../../assets/images/icons/bell.svg';
import appActions from '../../redux/app/app.actions';
// import SwitchComponent from '../../components/SwitchComponent/SwitchComponent';
import { selectUserActive } from '../../redux/user/user.selectors';
import userActions from '../../redux/user/user.actions';
import UserAvatarContainer from '../UserAvatarContainer/UserAvatarContainer';

class HeaderContainer extends Component {
  toggleSidebar = () => {
    this.props.toggleSidebar();
  };

  toggleActive = (active) => {
    this.props.toggleActive(active);
  };

  get isShowToggleButton() {
    return this.props.location.pathname === '/account';
  }

  render() {
    return (
      <HeaderContainerWrapper>
        <ToggleSidebarIcon onClick={this.toggleSidebar} />

        <HeaderControls>
          {this.isShowToggleButton ? (
            <HeaderHideOnMobile>
              {/* <HeaderText>hidden</HeaderText>
							<SwitchComponent
								name={'user_visible'}
								onSwitchChange={this.toggleActive}
								defaultValue={this.props.active}
							/>
							<HeaderText>visible</HeaderText> */}
            </HeaderHideOnMobile>
          ) : null}

          <HeaderControlSection>
            <UserAvatarContainer />
          </HeaderControlSection>
        </HeaderControls>
      </HeaderContainerWrapper>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  active: selectUserActive,
});

const mapDispatchToProps = {
  toggleSidebar: appActions.toggleSidebar,
  toggleActive: userActions.fetchUpdateActivePending,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(HeaderContainer);
