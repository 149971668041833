import React from 'react';
import PropTypes from 'prop-types';

import { StyledInputLabel } from '../MainComponents/MainComponents';
import client from '../../utils/client';
import {
  UploadImage,
  ImagePickerWrapper,
  UploadImageWrapper,
  StyledImgInput,
  StyledInputDescriptionBlock,
  StyledInputDescription,
  StyledButton,
} from './styles';
import LoaderComponent from '../LoaderComponent-v2/LoaderComponent';
import MessageComponent from '../MessageComponent/MessageComponent';
import DropNCropComponent from '../DropNCropComponent-v2/DropNCropComponent';

const makeblob = function (dataURL) {
  const BASE64_MARKER = ';base64,';
  if (dataURL.indexOf(BASE64_MARKER) == -1) {
    var parts = dataURL.split(',');
    var contentType = parts[0].split(':')[1];
    var raw = decodeURIComponent(parts[1]);
    return new Blob([raw], { type: contentType });
  }
  var parts = dataURL.split(BASE64_MARKER);
  var contentType = parts[0].split(':')[1];
  var raw = window.atob(parts[1]);
  const rawLength = raw.length;

  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
};
export default class HighLightsPickerComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pending: false,
      message: '',
      image: '',
      changed: false,
    };
  }

  componentDidMount() {
    if (this.props.value) {
      this.setState({ image: this.props.value, changed: true });
    }
  }

  static propTypes = {
    name: PropTypes.string,
    ration: PropTypes.string,
    label: PropTypes.string,
    imageWidth: PropTypes.number,
    imageHeight: PropTypes.number,
    onChange: () => {},
    imageStyles: PropTypes.object,
    defaultValue: PropTypes.string,
  };

  static defaultProps = {
    imageHeight: 300,
    imageWidth: 300,
    imageStyles: {},
  };

  onImageSelect = async (image) => {
    try {
      if (!image) return;
      this.setState({ pending: true });

      const formData = new FormData();

      formData.append('1', makeblob(image, 'image/png'));

      const response = await client.post('upload/profile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const imagePath = response.data.uploads.path;
      if (imagePath) {
        this.setState({ image: imagePath, pending: false, message: '' });
        this.props.onChange(imagePath);
      } else if (response.data.uploads.error) {
        this.setState({ message: response.data.uploads.error, pending: false });
      }
    } catch (error) {
      if (error.response) {
        this.setState({ pending: false, message: error.response.data.message });
        return;
      }
      this.setState({ pending: false });
    }
  };

  clear = () => {
    this.setState({ image: this.props.defaultValue });
    this.props.onClear([this.props.stateKey]);
  };

  openPicker = () => this.picker.open();

  render() {
    return (
      <>
        <LoaderComponent
          WrapperElement={ImagePickerWrapper}
          loading={this.state.pending}
          backdrop
        >
          <StyledInputLabel>{this.props.label}</StyledInputLabel>
          <StyledImgInput>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '96%',
              }}
            >
              <UploadImageWrapper
                imageWidth={this.props.imageWidth}
                imageHeight={this.props.imageHeight}
              >
                <UploadImage
                  imageWidth={this.props.imageWidth}
                  imageHeight={this.props.imageHeight}
                  image={
                    this.props.value ||
                    this.props.image ||
                    this.props.defaultValue
                  }
                  style={this.props.imageStyles}
                />
              </UploadImageWrapper>
              {/* <HiddenInput name={this.props.name} onClear={this.clear} value={this.state.image} /> */}
              <StyledInputDescriptionBlock>
                <StyledInputDescription>
                  {this.props.maxFileSizeDescr}
                </StyledInputDescription>{' '}
                <StyledInputDescription>
                  {this.props.maxImgSizeDescr}
                </StyledInputDescription>
              </StyledInputDescriptionBlock>
            </div>
            {!this.props.value && (
              <StyledButton
                onClick={(event) => {
                  event.preventDefault();
                  this.openPicker();
                }}
              >
                Upload
              </StyledButton>
            )}
            {this.props.value && (
              <StyledButton
                remove
                onClick={(event) => {
                  event.preventDefault();
                  this.props.clearImage(this.props.stateKey, '');
                }}
              >
                Remove
              </StyledButton>
            )}
          </StyledImgInput>
          <MessageComponent
            type="danger"
            text={this.state.message || this.props.message}
          />
        </LoaderComponent>

        <DropNCropComponent
          maxFileSize={this.props.maxFileSize}
          aspectRatio={this.props.ratio}
          image={this.state.image}
          onSelect={this.onImageSelect}
          ref={(picker) => (this.picker = picker)}
        />
      </>
    );
  }
}
