import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import styled from 'styled-components';
import { RowOffsetBottom } from '../../components/MainComponents/MainComponents';
import PostComponent from '../../components/PostComponent/PostComponent';
import {
  selectPostsPending,
  selectPostsListSorted,
} from '../../redux/posts/posts.selectors';
import postActions from '../../redux/post/post.actions';
import postsActions from '../../redux/posts/posts.actions';
import { mainTheme } from '../../mainStyles';

class PostsListContainer extends Component {
  componentDidMount() {
    this.props.fetchPosts();
  }

  onPostClick = (post) => {};

  onPostDelete = (_id) => this.props.deletePost(_id);

  renderPost = (post) =>
    !post.type &&
    !!post.profile && (
      <PostComponent
        onPostClick={this.onPostClick}
        onPostDelete={this.onPostDelete}
        key={post._id}
        _id={post._id}
        address={post.profile ? post.profile.address : ''}
        logo={post.profile ? post.profile.logo : post.logo}
        name={post.profile ? post.profile.name : post.name}
        title={post.title}
        description={post.description}
        image={post.image ? post.image : post.profile.backgroundImage}
      />
    );

  render() {
    return (
      <PostsListWrapper>
        {this.props.posts.map(this.renderPost)}
      </PostsListWrapper>
    );
  }
}

const PostsListWrapper = styled(RowOffsetBottom)`
  overflow-x: auto;
  min-height: 280px;
  padding: ${mainTheme.offset.small}px;
  width: 100%;

  @media (max-width: 710px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const mapStateToProps = createStructuredSelector({
  posts: selectPostsListSorted,
  postsPending: selectPostsPending,
});

const mapDispatchToProps = {
  selectPost: postActions.selectPost,
  deletePost: postActions.fetchDeletePostPending,
  fetchPosts: postsActions.fetchPending,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withRouter, withConnect)(PostsListContainer);
