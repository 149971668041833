import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { Row } from '../MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';
import storage from '../../utils/storage';

const items = [
  {
    route: '/auth/signup',
    label: 'SIGN UP',
    pointerEvents: 'none',
  },
  {
    route: '/auth/subscriptions',
    label: 'SELECT',
    pointerEvents: 'auto',
  },
  { route: '/auth/payments', label: 'PAY', pointerEvents: 'auto' },
  { route: '/auth/registered', label: 'COMPLETE', pointerEvents: 'auto' },
];

class BreadCrumbs extends Component {
  get done() {
    const stepsDone = {
      'SIGN UP': '',
      SELECT: '',
      PAY: '',
      COMPLETE: '',
    };
    const registrationStep = storage.storageGet('step');
    if (registrationStep === '/auth/subscriptions') {
      stepsDone['SIGN UP'] = 'true';
    } else if (registrationStep === '/auth/payments') {
      stepsDone['SIGN UP'] = 'true';
      stepsDone.SELECT = 'true';
    } else if (registrationStep === '/auth/registered') {
      stepsDone['SIGN UP'] = 'true';
      stepsDone.SELECT = 'true';
      stepsDone.PAY = 'true';
      stepsDone.COMPLETE = 'true';
    }
    return stepsDone;
  }

  get active() {
    return items.find(
      (item) => item.route === this.props.history.location.pathname
    );
  }

  getPointerEvents = (label) => {
    let pointerEvents = 'none';
    switch (label) {
      case 'SELECT': {
        if (this.done['SIGN UP'] !== '') {
          pointerEvents = 'none';
        }
      }
      case 'PAY': {
        if (this.done.SELECT !== '') {
          pointerEvents = 'none';
        }
      }
      case 'COMPLETE': {
        if (this.done.PAY !== '') {
          pointerEvents = 'none';
        }
      }
    }
    return pointerEvents;
  };

  renderItem = (item) => (
    <ItemWrapper
      key={item.route}
      as={Link}
      to={item.route}
      style={{
        textDecoration: 'none',
        pointerEvents: this.getPointerEvents(item.label),
      }}
      done={this.done[item.label]}
    >
      <Item
        active={this.active.route === item.route}
        done={this.done[item.label]}
      >
        {item.label}
      </Item>
    </ItemWrapper>
  );

  render() {
    if (!this.active) return null;
    return <Wrapper>{items.map(this.renderItem)}</Wrapper>;
  }
}

export default withRouter(BreadCrumbs);

const Wrapper = styled(Row)`
  width: auto;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  &:before {
    content: ' ';
    display: flex;
    position: absolute;
    top: calc(50% - 2px);
    background-color: ${mainTheme.colors.white};
    height: 2px;
    left: 0;
    right: 0;

    @media (max-width: 768px) {
      top: 50px;
      width: 2px;
      height: 200px;
      left: calc(50% - 2px);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ItemWrapper = styled.div`
  margin: 15px 35px 0;
  z-index: 2;
  position: relative;

  & > div {
    width: ${(props) => (props.done ? '137px' : '127px')};
  }

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0;

    & > div {
      width: ${(props) => (props.done ? '147px' : '127px')} !important;
    }
  }

  &:after {
    content: '✔';
    color: ${(props) =>
      props.done ? mainTheme.colors.textDark : mainTheme.colors.white};
    display: ${(props) => (props.done ? 'inline-block' : 'none')};
    position: absolute;
    top: 8px;
    right: 20px;
  }

  &:last-child:after {
    right: 15px;
  }

  @media (max-width: 769px) {
    align-self: center;
    margin: 20px 35px;

    & > div {
      width: 147px !important;
    }

    &:first-child {
      margin-left: 35px;
    }

    &:last-child {
      margin-right: 35px;

      & > div {
        width: 147px !important;
      }
    }
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${mainTheme.colors.white};
  color: ${(props) =>
    props.active || props.done
      ? mainTheme.colors.textDark
      : mainTheme.colors.white};
  background-color: ${(props) =>
    props.active || props.done
      ? mainTheme.colors.white
      : mainTheme.colors.textDark};
  font-family: Raleway;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.75px;
  height: 36px;
  text-transform: uppercase;
  text-decoration: none;
  padding-right: ${(props) => (props.done ? '20px' : '0px')};
`;
