import React from 'react';

import AddonCardItem from '../AddonCardItem/AddonCardItem';

import {
  StyledPageWrapper,
  StyledSection,
} from '../../../AuthSubscriptionPageComponent/styles';
import { StyledLayout } from '../../../../screens/AuthScreen-v2/styles';
import { StyledLayoutWrapper } from '../../../MainComponents/MainComponents';
import {
  StyledAddButton,
  StyledAddonContainer,
  StyledButtonContainer,
  StyledCancelButton,
  StyledCloseButton,
  StyledCloseIcon,
  StyledColumn,
  StyledColumnDescriptionText,
  StyledColumnsContainer,
  StyledColumnTitleText,
  StyledContainer,
  StyledDescriptionText,
  StyledImageSection,
  StyledSubTitleText,
  StyledTextSection,
  StyledTitleText,
  StyledAddonWrapper,
  StyledVideoWrapper,
  StyledVideo,
} from './styles';

import popupBg from '../../../../assets/images/backgrounds/popupBg.png';
import sayThanks from '../../../../assets/images/sayThanks.jpg';
import collectTips from '../../../../assets/images/analyze.png';
import noCost from '../../../../assets/images/retain.png';
import setAmount from '../../../../assets/images/customise.png';
import plugAndPlay from '../../../../assets/images/plug-and-play.png';
import video from '../../../../assets/video/RWRD-tip-box.mp4';

const images = { collectTips, noCost, setAmount, plugAndPlay };

const FourColumnsTitleImagePopup = ({
  addonData,
  handlePopup,
  handleSelectAddon,
}) => {
  const columns = addonData.popup.columns.map((col) => (
    <StyledColumn key={col.id}>
      <img src={images[col.image]} width="64px" alt={col.image} />
      <StyledColumnTitleText>{col.title}</StyledColumnTitleText>
      <StyledColumnDescriptionText>
        {col.description}
      </StyledColumnDescriptionText>
    </StyledColumn>
  ));
  return (
    <StyledPageWrapper>
      <StyledLayout height="100%">
        <StyledLayoutWrapper>
          <StyledSection
            paddingTop={0}
            paddingBot={0}
            paddingLeft={0}
            paddingRight={0}
            bgImage={popupBg}
          >
            <StyledCloseButton onClick={() => handlePopup('')}>
              <StyledCloseIcon />
            </StyledCloseButton>
            <StyledContainer>
              <StyledTextSection>
                {addonData.popup.title && (
                  <StyledTitleText marginBot={16}>
                    {addonData.popup.title}
                  </StyledTitleText>
                )}

                {addonData.popup.description && (
                  <StyledDescriptionText>
                    {addonData.popup.description}
                  </StyledDescriptionText>
                )}

                {addonData.popup.subTitle && (
                  <StyledSubTitleText>
                    {addonData.popup.subTitle}
                  </StyledSubTitleText>
                )}

                {addonData.popup.columns && (
                  <StyledColumnsContainer>{columns}</StyledColumnsContainer>
                )}

                <StyledAddonWrapper>
                  <StyledSubTitleText>Price</StyledSubTitleText>
                  {/* addon  */}
                  <StyledAddonContainer>
                    <AddonCardItem
                      title={addonData.card.title}
                      price={addonData.card.price}
                      period={addonData.card.period}
                      tags={addonData.card.tags}
                      badge={addonData.card.badge}
                    />
                  </StyledAddonContainer>
                </StyledAddonWrapper>
                <StyledButtonContainer>
                  <StyledCancelButton onClick={() => handlePopup('')}>
                    Cancel
                  </StyledCancelButton>
                  <StyledAddButton
                    onClick={() => {
                      handleSelectAddon(addonData.id);
                      handlePopup('');
                    }}
                  >
                    Add +{' '}
                  </StyledAddButton>
                </StyledButtonContainer>
              </StyledTextSection>
              {/* right side */}
              <StyledImageSection bgImage={sayThanks}>
                {/* video */}
                <StyledVideoWrapper>
                  <StyledVideo src={video} autoPlay loop muted />
                </StyledVideoWrapper>
              </StyledImageSection>
            </StyledContainer>
          </StyledSection>
        </StyledLayoutWrapper>
      </StyledLayout>
    </StyledPageWrapper>
  );
};

export default FourColumnsTitleImagePopup;
