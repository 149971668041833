import styled from 'styled-components';
import { Link } from 'react-router-dom';
import CoffeeImage from '../../assets/images/coffee.png';
import { mainTheme } from '../../mainStyles';
import { PaperComponent } from '../../components/MainComponents/MainComponents';

export const AuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  &:before {
    content: '';
    background-color: ${mainTheme.colors.textDark};
    height: 450px;
    width: 100%;
    position: absolute;
    display: flex;

    @media (max-width: 768px) {
      height: 720px;
    }
  }
`;

export const AuthTopContent = styled.div`
  /* background-image: url(${CoffeeImage});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 0% 100%; */
  background-color: ${mainTheme.colors.textDark};
  height: 450px;
  display: flex;
  /* flex: 4.5; */
`;

export const AuthTitle = styled.h1`
  color: ${mainTheme.colors.white};
  font-family: Raleway;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 4.06px;
  text-transform: uppercase;
`;

export const AuthBottomContent = styled.div`
  position: relative;
  display: flex;
  /* flex: 5.5; */
  justify-content: center;
  /* height: calc(100vh - 425px); */
  /* height: 500px; */
`;

export const AuthContent = styled.div`
  position: relative;
  /* top: -345px; */
  width: 100%;
  /* max-width: 990px; */
  max-width: ${(props) => props.maxWidth};
`;

export const HelperLink = styled(Link)`
  color: #404040;
  margin: 0 auto;
  font-family: 'Avenir Light';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.88px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const AuthLabelText = styled.p`
  color: ${mainTheme.colors.primary};
  font-family: ${mainTheme.fontFamily.light};
  font-size: ${mainTheme.fontSizes.medium}px;
  letter-spacing: 1.25px;
`;

export const AuthFormLabel = styled.label`
  display: flex;
  justify-content: flex-start;
  /* margin-bottom: 20px; */
  margin-bottom: 25px;
  margin-top: 10px;
`;

export const AuthForm = styled(PaperComponent)`
  margin: 0 auto;
  /* margin-bottom: 25px; */
  /* padding: 60px; */
  padding: ${({ padding }) => (padding ? `padding: ${padding}px;` : '60px;')};

  ${({ width }) =>
    width ? `max-width: ${width}px; width: 100%;` : 'width: auto;'}

  @media (max-width: 450px) {
    padding: 20px;
  }
`;

export const StyledLayout = styled.div`
  width: 100vw;
  /* max-width: 1440px; */
  /* height: 100vh; */
  height: ${({ height }) => (height ? `${height}` : 'auto')};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 30px solid white;
  ${({ bgImage }) =>
    bgImage && `background: url(${bgImage}) 0 0 / cover no-repeat;`};
  ${({ bgColor }) => bgColor && `background: ${bgColor};`};
  ${({ login }) => login && 'max-width: none;'};

  @media (max-width: 450px) {
    padding: 10px;
    border: 5px solid white;
  }
`;
