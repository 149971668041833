import React from 'react';

import { useDispatch } from 'react-redux';
import { history } from '../../containers/RouterContainer/RouterContainer';
import storage from '../../utils/storage';

import StepsProgresComponent from '../StepsProgresComponent/StepsProgresComponent';

import { AUTH_ROUTS } from '../../constants/routes';

import { StyledLayout } from '../../screens/AuthScreen-v2/styles';
import { StyledPageWrapper } from '../AuthSubscriptionPageComponent/styles';
import {
  StyledLayoutWrapper,
  StyledLinkButton,
  StyledSectionWrapper,
} from '../MainComponents/MainComponents';
import {
  StyledSection,
  StyledTitle,
  StyledTitleContainer,
  StyledPageDescription,
  StyledCongratsImage,
  StyledIconContainer,
  StyledListItem,
  StyledListTitle,
  StyledListDescription,
  StyledContentBlock,
  StyledVideoBlock,
  StyledButton,
  StyledImageTitle,
} from './styles';

import digitalMarketing from '../../assets/images/icons/digitalMarketingIcon.svg';
import smartLoyalty from '../../assets/images/icons/smartLoyaltyIcon.svg';
import digitalTipping from '../../assets/images/icons/digitalTippingIcon.svg';

import bgImage from '../../assets/images/backgrounds/congratulationsBg.png';
import congrats from '../../assets/images/congrats.png';
import underlay from '../../assets/images/videoUnderlay.png';
import profileActions from '../../redux/profile/profile.actions';
import client from '../../utils/client';
import userActions from '../../redux/user/user.actions';

const icons = {
  digitalMarketing,
  smartLoyalty,
  digitalTipping,
};

const featuresList = [
  {
    id: 1,
    title: 'Digital Marketing',
    icon: 'digitalMarketing',
    description:
      'When it comes to grabbing the attention of customers, we know what works. With RWRD you have access to a complete suite of marketing tools to boost engagement.',
  },
  {
    id: 2,
    title: 'Smart Loyalty',
    icon: 'smartLoyalty',
    description:
      'Introducing Smart Loyalty removes the old paper card system and replaces it with an instant, contactless solution that customers love.',
  },
  {
    id: 3,
    title: 'Digital Tipping',
    icon: 'digitalTipping',
    description:
      'Capitalise on high value moments with customers. RWRD offers the option to leave a tip when customers receive a reward or enjoy a free coffee.',
  },
];

const AuthCongratulationsComponent = () => {
  const step = storage.storageGet('step');
  if (step && step !== AUTH_ROUTS.CONGRATULATIONS) history.push(step);
  if (!step) history.push(AUTH_ROUTS.SIGN_IN);

  const dispatch = useDispatch();

  const registrationAccept = async () => {
    dispatch(profileActions.isFetching(true));
    try {
      const response = await client.post('/user/complete-auth');

      if (response.data.success) {
        storage.storageClear('step');
        storage.storageClear('temp');
        dispatch(userActions.fetchMePending());
        history.push('/dashboard');
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(profileActions.isFetching(false));
  };

  const list = featuresList.map((item) => (
    <StyledListItem key={item.id}>
      <StyledIconContainer>
        <img src={icons[item.icon]} />
      </StyledIconContainer>
      <div>
        <StyledListTitle>{item.title}</StyledListTitle>
        <StyledListDescription>{item.description}</StyledListDescription>
      </div>
    </StyledListItem>
  ));
  return (
    <StyledPageWrapper>
      <StyledLayout height="100%">
        <StyledLayoutWrapper>
          <StyledSection paddingTop={26} paddingBot={26}>
            {/* leftside */}
            <StyledSectionWrapper>
              <StepsProgresComponent currentStep={4} />
              <StyledTitleContainer>
                <StyledTitle marginBot={22}>
                  Your plan has been activated
                </StyledTitle>

                <StyledContentBlock>
                  <StyledVideoBlock>
                    <iframe
                      // width="560"
                      // height="315"
                      src="https://www.youtube-nocookie.com/embed/MkR1uDGMmYE"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    />
                  </StyledVideoBlock>
                  {list}
                </StyledContentBlock>
              </StyledTitleContainer>
            </StyledSectionWrapper>
            <div>
              <StyledLinkButton
                href="https://calendly.com/rwrd/chat-about-rwrd-with-holly"
                target="_blank"
                rel="noopener noreferrer"
              >
                Book a call
              </StyledLinkButton>
              <StyledButton prymary onClick={registrationAccept}>
                Go to Dashboard
              </StyledButton>
            </div>
          </StyledSection>
          <StyledSection paddingTop={20} bgImage={bgImage}>
            <StyledCongratsImage>
              <img src={congrats} alt="congrats" />
            </StyledCongratsImage>
            <StyledImageTitle>Congratulations</StyledImageTitle>
            <StyledPageDescription>
              Welcome to RWRD Community
            </StyledPageDescription>
          </StyledSection>
        </StyledLayoutWrapper>
      </StyledLayout>
    </StyledPageWrapper>
  );
};

export default AuthCongratulationsComponent;
