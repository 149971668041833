import { createSelector } from 'reselect';

const rootSelector = (state) => state.payments;

export const selectSubscriptions = createSelector(
  rootSelector,
  (payments) => payments.subscriptions
);

export const selectPaymentsPending = createSelector(rootSelector, (payments) =>
  Boolean(payments.pending)
);

export const selectPaymentsErrors = createSelector(
  rootSelector,
  (payments) => payments.errors
);

export const selectPaymentsMessage = createSelector(
  rootSelector,
  (payments) => payments.message
);
