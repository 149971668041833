import { createAction } from 'redux-actions';

const toggleSidebar = createAction('app--toggleSidebar');
const init = createAction('app--init');

const appActions = {
  toggleSidebar,
  init,
};
export default appActions;
