import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';

import userActions from '../../redux/user/user.actions';
import {
  PaperComponent,
  MainTitleDark,
  Text,
} from '../../components/MainComponents/MainComponents';

class DeleteAccountButton extends Component {
  state = { open: false };

  toggle = () => this.setState({ open: !this.state.open });

  delete = () => {
    this.props.delete();
  };

  render() {
    return (
      <>
        <Button onClick={this.toggle}>DELETE ACCOUNT</Button>
        <Modal
          open={this.state.open}
          onClose={this.toggle}
          // showCloseIcon={false}
          // focusTrapped={false}
          styles={{
            overlay: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
            modal: {
              maxWidth: 420,
            },
            closeButton: {
              top: 30,
              right: 30,
              outline: 'none',
              cursor: 'pointer',
            },
          }}
        >
          <PaperComponent>
            <MainTitleDark>Are you sure?</MainTitleDark>
            <Text>
              By deleting your account, your profile and all data (including
              stamps & promotions) will be deleted instantly. See full
              cancellation terms at
              <a
                href="https://www.rwrdapp.com/venue-terms-conditions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                rwrdapp.com
              </a>
              .
            </Text>
            <ButtonContainer>
              <Button
                onClick={this.toggle}
                style={{ backgroundColor: '#404040' }}
              >
                GO BACK
              </Button>
              <Button onClick={this.delete}>DELETE ACCOUNT</Button>
            </ButtonContainer>
          </PaperComponent>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  delete: userActions.deletePending,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteAccountButton);

const Button = styled.div`
  color: #ffffff;
  padding: 15px 20px;
  background-color: #f14141;
  font-family: 'Avenir Medium';
  font-size: 11px;
  font-weight: 300;
  letter-spacing: 2.55px;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  margin-top: 30px;

  @media (max-width: 420px) {
    flex-direction: column;
    width: 174px;
    height: fit-content;

    & > div:last-child {
      margin-top: 20px;
    }
  }
`;
