import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import isEqual from 'lodash.isequal';

import { selectProfileCurrent } from '../../redux/profile/profile.selectors';
import ProfileViewComponent from '../../components/ProfileViewComponent/ProfileViewComponent';
import { selectPostsListSorted } from '../../redux/posts/posts.selectors';
import { selectTemp } from '../../redux/post/post.selectors';

class ProfileShortViewContainer extends React.PureComponent {
  state = { posts: this.props.posts };

  componentDidUpdate(prevProps) {
    const { postTemp, posts, address, name, logo, backgroundImage } =
      this.props;

    if (!isEqual(prevProps.postTemp, postTemp)) {
      // User is editing post form component
      if (postTemp.title || postTemp.description || postTemp.image) {
        const tempPostObject = {
          ...postTemp,
          profile: { logo, name, address, backgroundImage },
          id: new Date().getTime(),
        };
        this.setState({ posts: [tempPostObject, ...posts] });
      } else {
        this.setState({ posts });
      }
    }

    if (!isEqual(prevProps.posts, posts)) {
      this.setState({ posts });
    }
  }

  render() {
    return (
      <ProfileViewComponent
        backgroundImage={this.props.backgroundImage}
        name={this.props.name}
        address={this.props.address}
        image={this.props.logo}
        description={this.props.description}
        // posts={this.props.posts}
        posts={this.state.posts}
      />
    );
  }
}
const ProfileShortViewSelector = createSelector(
  selectProfileCurrent,
  selectPostsListSorted,
  selectTemp,
  (profile, posts, temp) => ({
    ...profile,
    posts,
    postTemp: temp,
  })
);

const mapStateToProps = ProfileShortViewSelector;

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileShortViewContainer);
