import styled from 'styled-components';
import DropDownComponent from '../../components/DropDownComponent/DropDownComponent';
import { Column, Row } from '../../components/MainComponents/MainComponents';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProfileScreenWrapper = styled(Column)`
  width: 100%;
  margin: 0 auto;
`;
export const ProfileScreenContentWrapper = styled(Column)`
  width: 100%;
  margin: 0 auto;
`;

export const ProfilePicker = styled(DropDownComponent)`
  width: 30%;
`;
