import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Column,
  FieldLabel,
  InputMainCharacters,
  MainButton,
  RowOffsetBottom,
  TextAreaWrapper,
  Row,
  FromControls,
} from '../MainComponents/MainComponents';
import ProfileCheckboxComponent from '../ProfileCheckboxComponent/ProfileCheckboxComponent';
import ImagePickerComponent from '../ImagePickerComponent/ImagePickerComponent';
import TextAreaComponent from '../TextAreaComponent/TextAreaComponent';

export default class PostFormComponent extends Component {
  state = {
    value: '',
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value });
    // Update post.temp.title redux state
    this.props.changePostTemp(event.target.name, event.target.value);
  };

  render() {
    return (
      <Column>
        <RowOffsetBottom>
          <Column>
            <FieldLabel>POST TITLE</FieldLabel>
            <TextAreaWrapper input data-max={100 - this.state.value.length}>
              <InputMainCharacters
                error={'title' in this.props.errors}
                name="title"
                maxLength={100}
                onChange={this.handleChange}
                defaultValue={this.props.title}
                placeholder="Post Title"
              />
            </TextAreaWrapper>
          </Column>
        </RowOffsetBottom>

        <RowOffsetBottom>
          <Column>
            <FieldLabel>POST DESCRIPTION</FieldLabel>
            <TextAreaComponent
              maxLength={400}
              error={'description' in this.props.errors}
              name="description"
              defaultValue={this.props.description}
              placeholder="Post Description"
              onChange={(value) =>
                this.props.changePostTemp('description', value)
              }
            />
          </Column>
        </RowOffsetBottom>

        <RowOffsetBottom>
          <FieldLabel>
            <FieldLabel>FEATURE IMAGE</FieldLabel>
            <ImagePickerComponent
              imageWidth={218}
              error={'image' in this.props.errors}
              message={this.props.errors.image}
              defaultValue={this.props.image}
              imageHeight={136}
              label="Upload post featured image. If you dont have an image leave this blank"
              name="image"
              ratio={16 / 6}
              imageStyles={{ backgroundSize: 'contain' }}
              onChange={(value) => this.props.changePostTemp('image', value)}
            />
          </FieldLabel>
        </RowOffsetBottom>

        <RowOffsetBottom>
          <Column>
            <FieldLabel>POST FROM</FieldLabel>
            <ProfileCheckboxComponent />
          </Column>
        </RowOffsetBottom>

        <Row>
          <FromControls>
            <CreatePostButton type="submit">Post Now</CreatePostButton>
          </FromControls>
        </Row>
        {/* <input type="button" hidden /> */}
      </Column>
    );
  }
}

const CreatePostButton = styled(MainButton)`
  margin-left: auto;
`;
