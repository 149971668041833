import React from 'react';
import styled from 'styled-components';
import { newTheme } from '../../mainStyles';

const StepsProgresComponent = ({ currentStep }) => {
  return (
    <StyledSteps>
      <StyledStepItem step={1} currentStep={currentStep}>
        1
      </StyledStepItem>
      <StyledStepLine step={2} currentStep={currentStep} />
      <StyledStepItem step={2} currentStep={currentStep}>
        2
      </StyledStepItem>
      <StyledStepLine step={3} currentStep={currentStep} />
      <StyledStepItem step={3} currentStep={currentStep}>
        3
      </StyledStepItem>
      <StyledStepLine step={4} currentStep={currentStep} />
      <StyledStepItem step={4} currentStep={currentStep}>
        4
      </StyledStepItem>
    </StyledSteps>
  );
};

export default StepsProgresComponent;

const StyledSteps = styled.div`
  display: flex;

  width: 100%;
  max-width: 390px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
`;

const StyledStepItem = styled.div`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 16px;
  line-height: 22px;
  padding: 13px 20px;
  border-radius: 50%;

  background: ${({ step, currentStep }) =>
    step > currentStep ? '#EFEFEF' : '#f5cc28'};
`;

const StyledStepLine = styled.div`
  height: 1px;
  width: 100%;

  background: ${({ step, currentStep }) =>
    step > currentStep ? '#EFEFEF' : '#f5cc28'};
`;
