import React, { Component } from 'react';
import ChartComponent from 'react-chartjs-2';
import { mainTheme } from '../../mainStyles';

const options = {
  responsive: true,
  cornerRadius: 5,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
          drawBorder: false,
        },
        // ticks: {
        //   min: 0,
        //   max: 200,
        //   stepSize: 50
        // }
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
    ],
  },
  legend: { display: false },
};

class TipsSegmentsBarChart extends Component {
  data = {
    labels: [
      '£0.00-0.50',
      '£0.51-1.00',
      '£1.01-1.50',
      '£1.51-2.00',
      '£2.00-5.00',
      '£5.00+',
    ],
    datasets: [
      {
        backgroundColor: mainTheme.colors.greyMid,
      },
    ],
  };

  render() {
    const { data } = this.props;
    const segments = [
      data.filter((i) => i.amount <= 50).length,
      data.filter((i) => i.amount > 50 && i.amount <= 100).length,
      data.filter((i) => i.amount > 100 && i.amount <= 150).length,
      data.filter((i) => i.amount > 150 && i.amount <= 200).length,
      data.filter((i) => i.amount > 200 && i.amount <= 500).length,
      data.filter((i) => i.amount > 500).length,
    ];
    this.data.datasets[0].data = segments;
    return (
      <ChartComponent type="horizontalBar" data={this.data} options={options} />
    );
  }
}

export default TipsSegmentsBarChart;
