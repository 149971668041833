import styled from 'styled-components';
import {
  PaperComponent,
  FieldLabel,
  Row,
} from '../MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';
import starImage from '../../assets/images/icons/star.svg';
import cancelImage from '../../assets/images/icons/cancel.svg';

export const PostComponentWrapper = styled(PaperComponent)`
  flex-basis: 260px;
  max-width: 260px;
  min-height: 225px;
  margin-right: ${mainTheme.offset.large * 2}px;
  padding: 0;
  position: relative;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 710px) {
    min-width: 260px;
    margin-top: ${mainTheme.offset.large * 2}px;
  }
`;

export const PostLogo = styled.div`
  width: 50px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: ${mainTheme.offset.xsmall}px;
  background-image: url(${(props) => props.image || mainTheme.images.default});
`;

export const PostImage = styled.div`
  width: 100%;
  height: 85px;
  background-image: url(${(props) =>
    props.image || mainTheme.images.postImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const PostRow = styled(Row)`
  justify-content: space-between;
  flex-wrap: wrap;
  padding: ${mainTheme.offset.xsmall}px;
`;

export const PostTitle = styled(FieldLabel)`
  margin: 0;
`;

export const StampStar = styled.div`
  width: 10px;
  height: 10px;
  color: #f9bf3a;
  background-image: url(${starImage});
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 2px;
`;

export const PostSubtitle = styled(PostTitle)`
  font-size: 10px;
  text-transform: none;
  letter-spacing: normal;
`;

export const PostDeleteIcon = styled.img.attrs({ src: cancelImage })`
  width: 20px;
  height: 20px;

  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
`;
