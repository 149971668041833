import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PeriodsComponent from '../../components/PeriodsComponent/PeriodsComponent';
import { selectStatisticPeriod } from '../../redux/statistic/statistic.selectors';
import statisticActions from '../../redux/statistic/statistic.actions';

export class ChartsPeriods extends Component {
  static propTypes = {
    period: PropTypes.number,
  };

  static defaultProps = {
    period: 2,
  };

  onPeriodPress = (id) => {
    // change period in redux state
    this.props.setPeriod(id);
  };

  render() {
    return (
      <PeriodsComponent
        active={this.props.period}
        onPeriodPress={this.onPeriodPress}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  period: selectStatisticPeriod,
});

const mapDispatchToProps = {
  setPeriod: statisticActions.setPeriod,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartsPeriods);
