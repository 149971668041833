import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfileObject } from '../../redux/profile/profile.selectors';
import { history } from '../../containers/RouterContainer/RouterContainer';
import storage from '../../utils/storage';
import StepsProgresComponent from '../StepsProgresComponent/StepsProgresComponent';
import MobilePreviewComponent from '../MobilePreviewComponent/MobilePreviewComponent';
import MapAdress from '../../containers/MapAdress-v2/MapAdress';
import profileActions from '../../redux/profile/profile.actions';

import { AUTH_ROUTS } from '../../constants/routes';

import {
  StyledButton,
  StyledPageWrapper,
  StyledSection,
  StyledTitle,
} from '../AuthSubscriptionPageComponent/styles';
import {
  StyledProgress,
  StyledProgressBarComponent,
  StyledPageDescription,
  StyledFormWrapper,
} from './styles';
import { StyledLayout } from '../../screens/AuthScreen-v2/styles';
import {
  StyledTextArea,
  StyledInputContainer,
  StyledInputLabel,
  StyledErrorMessage,
  StyledLayoutWrapper,
  StyledSectionWrapper,
} from '../MainComponents/MainComponents';

import bgImage from '../../assets/images/backgrounds/phoneBg.png';

const AuthProfileComponentStep2 = () => {
  const step = storage.storageGet('step');
  if (step && step !== AUTH_ROUTS.PROFILE_STEP1) history.push(step);
  if (!step) history.push(AUTH_ROUTS.SIGN_IN);

  const { temp } = useSelector(selectProfileObject);
  const dispatch = useDispatch();

  const [touched, setTouched] = useState({
    description: false,
    address: false,
    state: false,
    country: false,
    postcode: false,
    coordinates: false,
  });
  const [errors, setErrors] = useState({
    description: '',
    address: '',
    state: '',
    country: '',
    postcode: '',
    coordinates: '',
  });
  console.log(errors);

  const setStorageData = (key, value) => {
    const storageTemp = storage.storageGet('temp');
    if (storageTemp) {
      const temp = JSON.parse(storageTemp);
      const newValue = { ...temp, [key]: value };
      storage.storageSet('temp', JSON.stringify(newValue));
    } else {
      const newValue = { [key]: value };
      storage.storageSet('temp', JSON.stringify(newValue));
    }
  };

  const deleteStorageData = (keys) => {
    const storageTemp = storage.storageGet('temp');
    if (storageTemp) {
      const values = Object.entries(JSON.parse(storageTemp));
      const result = values.filter((el) => !keys.includes(el[0]));
      const newTemp = result.reduce((acc, el) => {
        return { ...acc, [el[0]]: el[1] };
      }, {});
      storage.storageSet('temp', JSON.stringify(newTemp));
    }
  };

  useEffect(() => {
    if (temp.description) {
      setTouched((touched) => {
        return { ...touched, description: true };
      });
    }
    if (temp.postcode) {
      setTouched((touched) => {
        return { ...touched, postcode: true };
      });
    }
    if (temp.address) {
      setTouched((touched) => {
        return { ...touched, address: true };
      });
    }
    if (temp.state) {
      setTouched((touched) => {
        return { ...touched, state: true };
      });
    }
    if (temp.country) {
      setTouched((touched) => {
        return { ...touched, country: true };
      });
    }
  }, []);

  const isValid = (key, value, maxLenght) => {
    if (maxLenght) {
      if (value.length > maxLenght) {
        return setErrors((errors) => {
          return {
            ...errors,
            [key]: `Maximum length is ${maxLenght} characters`,
          };
        });
      }
    }
    if (value.length === 0) {
      return setErrors((errors) => {
        return { ...errors, [key]: 'Field is required' };
      });
    }
    if (!value.replace(/\s/g, '').length) {
      return setErrors((errors) => {
        return { ...errors, [key]: 'Field is required' };
      });
    }
    return setErrors((errors) => {
      return { ...errors, [key]: '' };
    });
  };

  const handleTouched = (key) => {
    setTouched((touched) => {
      return { ...touched, [key]: true };
    });
    if (!temp[key]) {
      setErrors((errors) => {
        return { ...errors, [key]: 'Field is required' };
      });
    }
  };

  const handleChange = (key, value) => {
    if (key === 'description') {
      isValid(key, value, 500);
    }
    if (key === 'postcode') {
      isValid(key, value);
    }
    if (key === 'state') {
      isValid(key, value);
    }
    if (key === 'country') {
      isValid(key, value);
    }
    if (key === 'address') {
      isValid(key, value, 50);
    }
    dispatch(profileActions.changeTemp(key, value));
    setStorageData(key, value);
  };

  const setNextPage = () => {
    if (
      errors.address ||
      errors.description ||
      errors.postcode ||
      errors.state ||
      errors.country
    )
      return;
    if (!temp.description) {
      setTouched((touched) => {
        return { ...touched, description: true };
      });
      setErrors((errors) => {
        return { ...errors, description: 'Field is required' };
      });
      return;
    }
    if (!temp.address) {
      setTouched((touched) => {
        return { ...touched, address: true };
      });
      setErrors((errors) => {
        return { ...errors, address: 'Field is required' };
      });
      return;
    }
    if (!temp.postcode) {
      setTouched((touched) => {
        return { ...touched, postcode: true };
      });
      setErrors((errors) => {
        return { ...errors, postcode: 'Field is required' };
      });
      return;
    }
    if (!temp.state) {
      setTouched((touched) => {
        return { ...touched, state: true };
      });
      setErrors((errors) => {
        return { ...errors, state: 'Field is required' };
      });
      return;
    }
    if (!temp.country) {
      setTouched((touched) => {
        return { ...touched, country: true };
      });
      setErrors((errors) => {
        return { ...errors, country: 'Field is required' };
      });
      return;
    }
    if (!temp.lat || !temp.lng) {
      setTouched((touched) => {
        return { ...touched, coordinates: true };
      });
      setErrors((errors) => {
        return {
          ...errors,
          coordinates:
            'Address not valid, please choose exact address/building from the autocomplete suggestions',
        };
      });
      return;
    }
    history.push(AUTH_ROUTS.PROFILE_STEP3);
  };

  const skipThisPage = () => {
    if (
      errors.address ||
      errors.description ||
      errors.postcode ||
      errors.state ||
      errors.country
    )
      return;
    if (!temp.description) {
      setTouched((touched) => {
        return { ...touched, description: true };
      });
      setErrors((errors) => {
        return { ...errors, description: 'Field is required' };
      });
      return;
    }
    if (!temp.address) {
      setTouched((touched) => {
        return { ...touched, address: true };
      });
      setErrors((errors) => {
        return { ...errors, address: 'Field is required' };
      });
      return;
    }
    if (!temp.postcode) {
      setTouched((touched) => {
        return { ...touched, postcode: true };
      });
      setErrors((errors) => {
        return { ...errors, postcode: 'Field is required' };
      });
      return;
    }
    if (!temp.state) {
      setTouched((touched) => {
        return { ...touched, state: true };
      });
      setErrors((errors) => {
        return { ...errors, state: 'Field is required' };
      });
      return;
    }
    if (!temp.country) {
      setTouched((touched) => {
        return { ...touched, country: true };
      });
      setErrors((errors) => {
        return { ...errors, country: 'Field is required' };
      });
      return;
    }
    if (!temp.lat || !temp.lng) {
      setTouched((touched) => {
        return { ...touched, coordinates: true };
      });
      setErrors((errors) => {
        return {
          ...errors,
          coordinates:
            'Address not valid, please choose exact address/building from the autocomplete suggestions',
        };
      });
      return;
    }
    dispatch(profileActions.changeTemp('lat', ''));
    dispatch(profileActions.changeTemp('lng', ''));
    dispatch(profileActions.changeTemp('city', ''));
    deleteStorageData(['lat', 'lng', 'city']);
    history.push(AUTH_ROUTS.PROFILE_STEP3);
  };

  return (
    <StyledPageWrapper>
      <StyledLayout height="100%">
        <StyledLayoutWrapper>
          <StyledSection paddingTop={26} paddingBot={26}>
            {/* leftside */}
            <StyledSectionWrapper>
              <StepsProgresComponent currentStep={2} />
              <StyledTitle marginBot={8}>Create your venue profile</StyledTitle>
              <StyledPageDescription>
                More than one site? Don't worry you can add more later!
              </StyledPageDescription>
              <StyledProgressBarComponent>
                <StyledProgress progress={2} />
              </StyledProgressBarComponent>
              <StyledFormWrapper>
                <StyledInputContainer marginBot={24}>
                  <StyledInputLabel>Description</StyledInputLabel>
                  <StyledTextArea
                    onBlur={() => handleTouched('description')}
                    maxLength={500}
                    borderColor
                    type="text"
                    value={temp.description}
                    onChange={(event) =>
                      handleChange('description', event.target.value)
                    }
                    name="Description"
                    placeholder="Tell more about your coffee shop, what is special about your venue?"
                    vertical={18}
                    errors={
                      (touched.description && errors.description) ||
                      (touched.description && !temp.description)
                    }
                  />
                  {errors.description && (
                    <StyledErrorMessage>
                      {errors.description}
                    </StyledErrorMessage>
                  )}
                </StyledInputContainer>
                <StyledInputContainer marginBot={24}>
                  <StyledInputLabel>Address</StyledInputLabel>
                  <MapAdress
                    temp={temp}
                    toggleFocused={() => {}}
                    focused
                    touched={touched}
                    errorsMessages={errors}
                    handleTouched={handleTouched}
                    handleChange={(data, value) => {
                      handleChange(data, value);
                      setErrors((errors) => {
                        return { ...errors, coordinates: '' };
                      });
                    }}
                  />
                </StyledInputContainer>
              </StyledFormWrapper>
            </StyledSectionWrapper>
            <div>
              <StyledButton onClick={skipThisPage}>Skip</StyledButton>
              <StyledButton prymary onClick={setNextPage}>
                Save & Continue
              </StyledButton>
            </div>
          </StyledSection>
          <StyledSection phone paddingTop={56} bgImage={bgImage}>
            {/* rightside */}
            <div>
              <MobilePreviewComponent step={1} />
            </div>
          </StyledSection>
        </StyledLayoutWrapper>
      </StyledLayout>
    </StyledPageWrapper>
  );
};

export default AuthProfileComponentStep2;
