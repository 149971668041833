import styled from 'styled-components';
import { mainTheme } from '../../mainStyles';
import { RowOffsetBottom } from '../MainComponents/MainComponents';

export const ImagePickerWrapper = styled(RowOffsetBottom)`
  position: relative;
  display: flex;
  width: 100%;
`;

export const UploadImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #9f9f9f;
  overflow: hidden;
  max-width: ${(props) => props.imageWidth || 320}px;
  height: ${(props) => props.imageHeight || 320}px;
  margin-right: ${mainTheme.offset.small}px;
`;

export const UploadImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.image || mainTheme.images.default});
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  background-position: center;
  max-width: ${(props) => props.imageWidth || 320}px;
  height: ${(props) => props.imageHeight || 320}px;
  margin-right: ${mainTheme.offset.small}px;
`;
