import { fork, put, takeLatest } from 'redux-saga/effects';
import authActions from './auth.actions';
import client, { handleRequestError } from '../../utils/client';
import storage from '../../utils/storage';
import { history } from '../../containers/RouterContainer/RouterContainer';
import userActions from '../user/user.actions';

export const saveUser = (data, rememberMe = true) => {
  if (rememberMe) {
    storage.set('user', data.user);
    if (!data.step) {
      storage.set('isLogin', true);
    } else {
      storage.set('isLogin', false);
      storage.set('step', data.step);
    }
  } else {
    storage.sessionStorageSet('user', data.user);
    if (!data.step) {
      storage.set('isLogin', true);
    } else {
      storage.set('isLogin', false);
      storage.sessionStorageSet('step', data.step);
    }
  }
};

export const saveUserFromSocialLogin = (user, step) => {
  storage.set('user', user);
  storage.set('isLogin', true);
  if (step) storage.set('step', step);
};

const clearUser = () => {
  storage.clear('user');
  storage.set('isLogin', false);
  storage.clear('bearer_token');
  storage.sessionStorageClear('user');
  storage.sessionStorageSet('isLogin', false);
  storage.sessionStorageClear('bearer_token');
};

function* setAuthInfoFromSocialLogin(action) {
  const { token, user, step } = action.payload;
  if (token && user) {
    client.setToken(token);
    saveUserFromSocialLogin(user, step);
  }
}

function* signup(action) {
  try {
    const {
      email,
      firstName,
      lastName,
      password,
      password_confirmation,
      terms_agree,
      year_agree,
      token,
    } = action.payload.inputs;

    const response = yield client.post('auth/signup', {
      email,
      firstName,
      lastName,
      password,
      password_confirmation,
      terms_agree,
      year_agree,
      token,
    });
    client.setToken(response.data.token);
    saveUser(response.data, true);

    yield put(authActions.fetchSignupSuccess(response.data.user));
  } catch (error) {
    yield handleRequestError(
      error,
      authActions.fetchSignupFailed,
      signup,
      action
    );
  }
}

function* signin(action) {
  try {
    const { email, password, rememberMe } = action.payload.inputs;
    const response = yield client.post('auth/signin', {
      username: email,
      password,
    });
    client.setToken(response.data.token, rememberMe);
    saveUser(response.data, rememberMe);
    yield put(authActions.rememberMe(rememberMe));
    yield put(authActions.fetchSigninSuccess(response.data.user));
  } catch (error) {
    console.log(error);
    yield handleRequestError(
      error,
      authActions.fetchSignupFailed,
      signin,
      action
    );
  }
}

function logout() {
  history.push('/auth/signin');
  localStorage.clear();
  clearUser();
  client.clearToken();
}

function* signinGoogle(action) {
  try {
    window.location.href = `${process.env.REACT_APP_SERVER_API_URL}auth/google`;
  } catch (error) {
    yield handleRequestError(
      error,
      authActions.fetchSigninGoogleFailed,
      signinGoogle,
      action
    );
  }
}

function* signinFacebook(action) {
  try {
    window.location.href = `${process.env.REACT_APP_SERVER_API_URL}auth/facebook`;
  } catch (error) {
    yield handleRequestError(
      error,
      authActions.fetchSigninFacebookFailed,
      signinFacebook,
      action
    );
  }
}

function* forgotPassword(action) {
  try {
    const { email } = action.payload;

    yield client.post('auth/forgot-password', {
      email,
    });

    yield put(authActions.fetchForgotPasswordSuccess());
    history.push('forgot-password-sent');
  } catch (error) {
    yield handleRequestError(
      error,
      authActions.fetchForgotPasswordFailed,
      forgotPassword,
      action
    );
  }
}

function* resetPassword(action) {
  try {
    const { password, password_confirmation, salt, member } = action.payload;

    if (member) {
      // Reset password of mobile member
      yield client.post('auth/member/reset-password', {
        password,
        password_confirmation,
        salt,
      });
      yield put(
        authActions.fetchResetPasswordSuccess('Password Reset Successful')
      );
    } else {
      yield client.post('auth/reset-password', {
        password,
        password_confirmation,
        salt,
      });
      yield put(authActions.fetchResetPasswordSuccess());
      history.push('auth/signin');
    }
  } catch (error) {
    yield handleRequestError(
      error,
      authActions.fetchResetPasswordFailed,
      resetPassword,
      action
    );
  }
}

function* AuthFlow() {
  yield takeLatest(authActions.fetchSigninPending, signin);
  yield takeLatest(authActions.fetchSignupPending, signup);
  // google OAuth
  yield takeLatest(authActions.fetchSigninGooglePending, signinGoogle);
  yield takeLatest(
    authActions.setAuthInfoFromSocialLogin,
    setAuthInfoFromSocialLogin
  );

  yield takeLatest(authActions.fetchForgotPasswordPending, forgotPassword);
  yield takeLatest(authActions.fetchResetPasswordPending, resetPassword);
  yield takeLatest(authActions.fetchSigninFacebookPending, signinFacebook);
  // yield takeLatest(authActions.fetchSigninFacebookPending, signupFacebook);
  yield takeLatest([authActions.logout, userActions.deleteSuccess], logout);
}

function* authWorker() {
  yield fork(AuthFlow);
}

export default authWorker;
