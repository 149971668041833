import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import {
  RowOffsetBottom,
  Column,
  SmallBlock,
  Text,
  ColumnOffsetBottom,
  MainTitleDark,
  RightBlock,
  BoardComponent,
  MobileContent,
} from '../../components/MainComponents/MainComponents';
import PostFormContainer from '../../containers/PostFormContainer/PostFormContainer';
import { mainTheme } from '../../mainStyles';
import PostsListContainer from '../../containers/PostsListContainer/PostsListContainer';
import { MainContentOffset } from '../MainScreen/styles';
import ProfileShortViewContainer from '../../containers/ProfileShortViewContainer/ProfileShortViewContainer';

export default class PostsScreen extends Component {
  render() {
    return (
      <MainContentOffset>
        <Column>
          <RowOffsetBottom wrap>
            <MobileContent style={{ flexBasis: 330, padding: '0 32px' }}>
              <SmallBlock>
                <MainTitleDark>Create Post</MainTitleDark>
                <Text
                  color={mainTheme.colors.grey}
                  style={{ paddingRight: 25 }}
                >
                  Create and view your post for the newsfeed.
                </Text>
              </SmallBlock>
              <ProfileShortViewContainer />
            </MobileContent>

            <RightBlock as={BoardComponent}>
              <Route
                path={['/posts/:_id', '/posts']}
                component={PostFormContainer}
              />
            </RightBlock>
          </RowOffsetBottom>
          <MobileContent>
            <ColumnOffsetBottom>
              <ColumnOffsetBottom>
                <MainTitleDark>Previous Posts</MainTitleDark>
                <Text color={mainTheme.colors.grey}>Your post history</Text>
              </ColumnOffsetBottom>

              <PostsListContainer />
            </ColumnOffsetBottom>
          </MobileContent>
        </Column>
      </MainContentOffset>
    );
  }
}
