import styled from 'styled-components';

import {
  RowOffsetBottom,
  ColumnOffsetBottom,
} from '../../components/MainComponents/MainComponents';

export const ChartsWrapper = styled(RowOffsetBottom)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 48%);
  grid-gap: 20px;
  justify-content: space-between;
  margin-bottom: 40px;

  @media (max-width: 1040px) {
    grid-template-columns: repeat(auto-fill, 100%);
  }

  @media (max-width: 990px) {
    grid-template-columns: repeat(auto-fill, 80%);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, 100%);
  }

  & > div {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const HorizontalBarsWrapper = styled(ColumnOffsetBottom)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 48%);
  grid-gap: 20px;
  justify-content: space-between;
  margin-bottom: 40px;

  @media (max-width: 1040px) {
    grid-template-columns: repeat(auto-fill, 100%);
  }

  @media (max-width: 990px) {
    grid-template-columns: repeat(auto-fill, 80%);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, 100%);
  }
`;
