import React, { Component } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import NotFoundScreen from '../../screens/NotFoundScreen/NotFoundScreen';
import ProfileScreen from '../../screens/ProfileScreen/ProfileScreen';
import PostsScreen from '../../screens/PostsScreen/PostsScreen';
import AccountScreen from '../../screens/AccountScreen/AccountScreen';
import DashboardScreen from '../../screens/DashboardScreen/DashboardScreen';
import FeedBackScreen from '../../screens/FeedBackScreen/FeedBackScreen';
import TipsScreen from '../../screens/TipsScreen/TipsScreen';
import CardScreen from '../../screens/CardScreen/CardScreen';
import StaffTipsScreen from '../../screens/StaffTipsScreen/StaffTipsScreen';
import {
  selectUserStripe,
  selectUserPending,
} from '../../redux/user/user.selectors';
import {
  selectSomePending,
  selectAppPending,
} from '../../redux/app/app.selectors';
import { selectAuthPending } from '../../redux/auth/auth.selectors';
import { selectPostPending } from '../../redux/post/post.selectors';
import {
  selectProfileAnalyticsPending,
  selectProfileDataPending,
} from '../../redux/profile/profile.selectors';
import { selectPaymentsPending } from '../../redux/payments/payments.selectors';
import { selectPostsPending } from '../../redux/posts/posts.selectors';
import { LoaderContent } from './styles';
import LoaderComponent from '../../components/LoaderComponent/LoaderComponent';

class MainRouterContainer extends Component {
  render() {
    const { connectOnboarded } = this.props.userStripe;
    const {
      appPending,
      authPending,
      paymentsPending,
      postPending,
      postsPending,
      profileAnalyticsPending,
      profileDataPending,
      userPending,
      anyPending,
    } = this.props;
    const notStatistics =
      appPending ||
      authPending ||
      paymentsPending ||
      postPending ||
      postsPending ||
      profileDataPending ||
      userPending;

    return (
      <Switch>
        <Route
          path={['/', '/dashboard']}
          exact
          render={() => (
            <LoaderComponent backdrop loading={profileAnalyticsPending}>
              <LoaderContent>
                <DashboardScreen />
              </LoaderContent>
            </LoaderComponent>
          )}
        />
        <Route
          path="/profile"
          render={() => (
            <LoaderComponent backdrop loading={profileDataPending}>
              <ProfileScreen />
            </LoaderComponent>
          )}
        />
        <Route
          path="/posts"
          render={() => (
            <LoaderComponent backdrop loading={postsPending}>
              <PostsScreen />
            </LoaderComponent>
          )}
        />
        <Route
          path="/account"
          render={() => (
            <LoaderComponent backdrop loading={notStatistics}>
              <AccountScreen />
            </LoaderComponent>
          )}
        />
        <Route path="/support" render={() => <FeedBackScreen />} />
        <Route
          path="/team-tips"
          render={() =>
            connectOnboarded ? <StaffTipsScreen /> : <TipsScreen />
          }
        />
        <Route
          path="/stamp-card"
          render={() => (
            <LoaderComponent backdrop loading={notStatistics}>
              <CardScreen />
            </LoaderComponent>
          )}
        />

        <Route path="*" render={() => <NotFoundScreen />} />
      </Switch>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userStripe: selectUserStripe,
  appPending: selectAppPending,
  authPending: selectAuthPending,
  paymentsPending: selectPaymentsPending,
  postPending: selectPostPending,
  postsPending: selectPostsPending,
  profileDataPending: selectProfileDataPending,
  userPending: selectUserPending,
  anyPending: selectSomePending,
  profileAnalyticsPending: selectProfileAnalyticsPending,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainRouterContainer);
