import React from 'react';

import {
  StyledButton,
  StyledCardBadge,
  StyledCardContainer,
  StyledCardContent,
  StyledCardPrice,
  StyledCardTag,
  StyledCardTitle,
  StyledCardValue,
} from './styles';

import { ReactComponent as DeleteIcon } from '../../../../assets/images/icons/addon-delete.svg';

const AddonCardItem = ({ title, price, period, tags, badge }) => {
  return (
    <StyledCardContainer>
      {badge && <StyledCardBadge>{badge}</StyledCardBadge>}
      <StyledCardContent>
        {/* <StyledButton>
                    <DeleteIcon />
                </StyledButton> */}
        <StyledCardTitle>{title}</StyledCardTitle>
        <StyledCardValue>
          <StyledCardPrice>£{price}</StyledCardPrice>
          {period}
        </StyledCardValue>
        {tags.map((tag) => (
          <StyledCardTag key={tag.id} color={tag.tag_color}>
            {tag.tag_text}
          </StyledCardTag>
        ))}
      </StyledCardContent>
    </StyledCardContainer>
  );
};

export default AddonCardItem;
