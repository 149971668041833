export const crashReporter = (store) => (next) => (action) => {
  try {
    return next(action);
  } catch (err) {
    console.error('Caught an exception!', err);
    //   TODO: add error reporter
    // SENTRY example
    // Raven.captureException(err, {
    //     extra: {
    //       action,
    //       state: store.getState()
    //     }
    //   })
    throw err;
  }
};
