import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import StepsProgresComponent from '../StepsProgresComponent/StepsProgresComponent';

import storage from '../../utils/storage';
import { history } from '../../containers/RouterContainer/RouterContainer';
import { AUTH_ROUTS } from '../../constants/routes';

import {
  StyledButton,
  StyledPageWrapper,
} from '../AuthSubscriptionPageComponent/styles';
import { StyledLayout } from '../../screens/AuthScreen-v2/styles';
import {
  StyledLayoutWrapper,
  StyledSectionWrapper,
} from '../MainComponents/MainComponents';
import {
  StyledSection,
  StyledTitleContainer,
  StyledTitle,
  StyledPageDescription,
  StyledCongratsImage,
} from './styles';

import bgImage from '../../assets/images/backgrounds/confirmationBg.png';
import congrats from '../../assets/images/congrats.png';
import BrandingBoosterPopup from './components/BrandingBoosterPopup/BrandingBoosterPopup';
import BrandingBoosterCardDetailPopup from './components/BrandingBoosterCardDetailPopup/BrandingBoosterCardDetailPopup';
import BrandingBoosterCardDetailPopupContainer from './components/BrandingBoosterCardDetailPopup/BrandingBoosterCardDetailPopup';
import client from '../../utils/client';
import profileActions from '../../redux/profile/profile.actions';

const initialState = {
  isPopupShow: false,
  isPopupPage: 1,
  brandingBooster: '',
};

const AuthConfirmationComponent = () => {
  const step = storage.storageGet('step');
  if (step && step !== AUTH_ROUTS.CONFIRMATION) history.push(step);
  if (!step) history.push(AUTH_ROUTS.SIGN_IN);

  const dispatch = useDispatch();

  const [state, setState] = useState(initialState);

  const setBrandigBoosterData = async () => {
    dispatch(profileActions.isFetching(true));
    const response = await client.get('/payments/branding-booster');
    setState((state) => {
      return {
        ...state,
        brandingBooster: response.data.brandingBooster,
      };
    });
    dispatch(profileActions.isFetching(false));
  };

  useEffect(() => {
    setBrandigBoosterData();
  }, []);

  const handleBrandingBoostPopup = (bool, page) => {
    setState((state) => {
      return {
        ...state,
        isPopupShow: bool,
        isPopupPage: page,
      };
    });
  };

  const acceptOffer = async () => {
    dispatch(profileActions.isFetching(true));
    try {
      const response = await client.post('/payments/branding-booster', {
        priceId: state.brandingBooster.id,
      });
    } catch (error) {
      console.log(error);
    }

    dispatch(profileActions.isFetching(false));
  };

  const skipOffer = async () => {
    dispatch(profileActions.isFetching(true));
    try {
      const response = await client.post('/payments/branding-booster', {
        priceId: null,
      });
    } catch (error) {
      console.log(error);
    }
    dispatch(profileActions.isFetching(false));
  };

  if (state.isPopupShow && state.isPopupPage === 2) {
    return (
      <BrandingBoosterCardDetailPopupContainer
        handleBrandingBoostPopup={handleBrandingBoostPopup}
      />
    );
  }

  if (state.isPopupShow && state.brandingBooster) {
    return (
      <BrandingBoosterPopup
        card={state.brandingBooster}
        handleBrandingBoostPopup={handleBrandingBoostPopup}
        acceptOffer={acceptOffer}
        skipOffer={skipOffer}
      />
    );
  }
  return (
    <StyledPageWrapper>
      <StyledLayout height="100%">
        <StyledLayoutWrapper>
          <StyledSection paddingTop={26} paddingBot={26}>
            {/* leftside */}
            <StyledSectionWrapper>
              <StepsProgresComponent currentStep={3} />
              <StyledTitleContainer>
                <StyledTitle marginBot={16}>Welcome to RWRD</StyledTitle>
                <StyledPageDescription>
                  One of the team will be in touch within 24 hours to get
                  started.
                </StyledPageDescription>
              </StyledTitleContainer>
            </StyledSectionWrapper>
            <div>
              <StyledButton prymary onClick={handleBrandingBoostPopup}>
                Go to Dashboard
              </StyledButton>
            </div>
          </StyledSection>
          <StyledSection paddingTop={20} bgImage={bgImage}>
            <StyledCongratsImage>
              <img src={congrats} alt="congrats" />
            </StyledCongratsImage>
            <StyledTitle marginBot={24}>Thank you!</StyledTitle>
            <StyledPageDescription>
              Welcome to the RWRD Community
            </StyledPageDescription>
          </StyledSection>
        </StyledLayoutWrapper>
      </StyledLayout>
    </StyledPageWrapper>
  );
};

export default AuthConfirmationComponent;
