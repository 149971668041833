import React, { useState, useEffect } from 'react';
import AccordionListItem from './AccordionListItem/AccordionListItem';
import AccordionTextItem from './AccordionTextItem/AccordionTextItem';
import AccordionTextListItem from './AccordionTextListItem/AccordionTextListItem';
import { StyledContainer } from './styles';

const Accordion = ({ preview, carouselActiveItemId, getsubscriptionData }) => {
  const [active, setActive] = useState(null);

  const accordionData = getsubscriptionData(carouselActiveItemId);

  useEffect(() => {
    if (preview) {
      if (active === 1) {
        return;
      }
      handleToggle(1);
    }
  }, [preview]);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  return (
    <StyledContainer>
      {accordionData.accordion.map((item) => {
        if (item.type === 'list') {
          return (
            <AccordionListItem
              key={item.id}
              active={active}
              handleToggle={handleToggle}
              item={item}
            />
          );
        }
        if (item.type === 'text') {
          return (
            <AccordionTextItem
              key={item.id}
              active={active}
              handleToggle={handleToggle}
              item={item}
            />
          );
        }
        if (item.type === 'text_list') {
          return (
            <AccordionTextListItem
              key={item.id}
              active={active}
              handleToggle={handleToggle}
              item={item}
            />
          );
        }
      })}
    </StyledContainer>
  );
};

export default Accordion;
