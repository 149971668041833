import { createSelector } from 'reselect';

const rootSelector = (state) => state.auth;

export const selectSigninFields = createSelector(rootSelector, (auth) => ({
  email: auth.email,
  password: auth.password,
}));

export const selectSignupFields = createSelector(rootSelector, (auth) => ({
  email: auth.email,
  fist_name: auth.first_name,
  last_name: auth.last_name,
  password: auth.password,
  password_confirmation: auth.password_confirmation,
  terms_agree: auth.terms_agree,
  year_agree: auth.year_agree,
}));

export const selectAuthPending = createSelector(
  rootSelector,
  (auth) => auth.pending
);

export const selectAuthErrors = createSelector(
  rootSelector,
  (auth) => auth.errors
);

export const selectAuthMessage = createSelector(
  rootSelector,
  (auth) => auth.message
);

export const selectAuthSuccessMessage = createSelector(
  rootSelector,
  (auth) => auth.successMessage
);

export const selectAuthAction = createSelector(
  rootSelector,
  (auth) => auth.action
);
