import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AuthFormLabel } from '../../screens/AuthScreen/styles';
import { Checkbox, SmallText } from '../MainComponents/MainComponents';
import { mainTheme, newTheme } from '../../mainStyles';

export default class FormCheckBoxComponent extends React.PureComponent {
  static propTypes = {
    text: PropTypes.any,
    name: PropTypes.string,
    error: PropTypes.string,
  };

  renderText() {
    const { text, error } = this.props;
    return typeof text === 'string' ? text : text();
  }

  render() {
    return (
      <AuthFormLabel
        flexStart={this.props.flexStart}
        alignItems={this.props.alignItems}
      >
        <Checkbox name={this.props.name} />{' '}
        <Text newStyled error={this.props.error}>
          {this.renderText()}
        </Text>
      </AuthFormLabel>
    );
  }
}

const Text = styled(SmallText)`
  color: ${(props) =>
    props.error ? mainTheme.colors.danger : mainTheme.colors.text};
  line-height: 16px;

  ${({ newStyled }) =>
    newStyled &&
    `
	font-family: ${newTheme.fontFamily.primary.regular};
	font-size: 12px;
	letter-spacing: 0;
	`};
`;
