import styled from 'styled-components';
import { newTheme } from '../../mainStyles';

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

export const BackDrop = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(40, 40, 40, 0.4);
`;

export const StyledButton = styled.div`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  padding: 16px 40px;
  border: 0;
  border-radius: 4px;
  margin-right: 16px;
  z-index: 10;
  cursor: pointer;

  background: ${({ prymary }) => (prymary ? '#F5CC28' : '#efefef;')};

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: ${({ prymary }) => (prymary ? '#fedc54' : '#f3f3f3;')};
  }
`;
