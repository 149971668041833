import styled from 'styled-components';
import { Row } from '../../components/MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';

export const StampRow = styled(Row)`
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    max-width: 280px;
  }
  > :first-child {
    margin-right: ${mainTheme.offset.xsmall}px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    > div {
      max-width: initial;
    }
    > :first-child {
      margin-right: 0;
    }
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const StyledHalfContainer = styled.div`
  width: 48%;
`;
