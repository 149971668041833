import { fork, put, takeLatest } from 'redux-saga/effects';
import client, { handleRequestError } from '../../utils/client';
import tipsActions from './tips.actions';

function* fetchTips(action) {
  try {
    const response = yield client.get('payments/connect-transactions');
    yield put(tipsActions.fetchSuccess(response.data));
  } catch (error) {
    yield handleRequestError(error, tipsActions.fetchFailed, fetchTips, action);
  }
}

function* fetchPayouts(action) {
  try {
    const response = yield client.get('payments/connect-payouts');
    yield put(tipsActions.fetchPayoutSuccess(response.data));
  } catch (error) {
    yield handleRequestError(
      error,
      tipsActions.fetchPayoutFailed,
      fetchPayouts,
      action
    );
  }
}

function* fetchTipStats(action) {
  try {
    const response = yield client.get('payments/tips');
    yield put(tipsActions.fetchTipStatsSuccess(response.data));
  } catch (error) {
    yield handleRequestError(
      error,
      tipsActions.fetchTipStatsFailed,
      fetchTipStats,
      action
    );
  }
}

function* fetchBank(action) {
  try {
    const response = yield client.get('payments/connect-bank');
    yield put(tipsActions.fetchBankSuccess(response.data));
  } catch (error) {
    yield handleRequestError(
      error,
      tipsActions.fetchBankFailed,
      fetchBank,
      action
    );
  }
}

function* TipsFlow() {
  yield takeLatest(tipsActions.fetchPending, fetchTips);
  yield takeLatest(tipsActions.fetchPayoutPending, fetchPayouts);
  yield takeLatest(tipsActions.fetchTipStatsPending, fetchTipStats);
  yield takeLatest(tipsActions.fetchBankPending, fetchBank);
}

function* tipsWorker() {
  yield fork(TipsFlow);
}

export default tipsWorker;
