import { createAction } from 'redux-actions';

const fetchPending = createAction('profile--fetchPending');
const fetchSuccess = createAction('profile--fetchSuccess', (profile = {}) => ({
  profile,
}));
const fetchFailed = createAction(
  'profile--fetchFailed',
  (errors = {}, message = '') => ({ errors, message })
);

const fetchUpdatePending = createAction(
  'profile--fetchUpdatePending',
  (profileId, profile) => ({
    profileId,
    profile,
  })
);
const fetchUpdateSuccess = createAction(
  'profile--fetchUpdateSuccess',
  (profile = {}) => ({ profile })
);
const fetchUpdateFailed = createAction(
  'profile--fetchUpdateFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

const fetchCreatePending = createAction(
  'profile--fetchCreatePending',
  (profile) => ({ profile })
);
const fetchCreateSuccess = createAction(
  'profile--fetchCreateSuccess',
  (profile = {}) => ({ profile })
);
const fetchCreateFailed = createAction(
  'profile--fetchCreateFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

const changeTemp = createAction('profile--changeTemp', (key, value) => ({
  key,
  value,
}));
const changeStampTemp = createAction(
  'profile--changeStampTemp',
  (key, value) => ({ key, value })
);
const clearTemp = createAction('profile--clearTemp');
const clearProfile = createAction('profile--clearProfile');
const clearProfileAddress = createAction('profile--clearProfileAddress');
const clearTempImage = createAction(
  'profile--clearTempImage',
  (key, value) => ({ key, value })
);

const fetchAnalyticsAllPending = createAction(
  'profile--fetchAnalyticsAllPending',
  (timeGrain, timeLapse, regionalFilter) => ({
    timeGrain,
    timeLapse,
    regionalFilter,
  })
);
const fetchAnalyticsAllSuccess = createAction(
  'profile--fetchAnalyticsAllSuccess',
  (analytics = []) => ({ analytics })
);
const fetchAnalyticsAllFailed = createAction(
  'profile--fetchAnalyticsAllFailed'
);

const fetchAnalyticsByIdPending = createAction(
  'profile--fetchAnalyticsByIdPending',
  (timeGrain, timeLapse, regionalFilter, profileId) => ({
    timeGrain,
    timeLapse,
    regionalFilter,
    profileId,
  })
);
const fetchAnalyticsByIdSuccess = createAction(
  'profile--fetchAnalyticsByIdSuccess',
  (analytics = []) => ({
    analytics,
  })
);
const fetchAnalyticsByIdFailed = createAction(
  'profile--fetchAnalyticsByIdFailed'
);

const fetchAnalyticsTotalUsersPending = createAction(
  'profile--fetchAnalyticsTotalUsersPending'
);
const fetchAnalyticsTotalUsersSuccess = createAction(
  'profile--fetchAnalyticsTotalUsersSuccess',
  (analyticsTotalUsers = 0) => ({
    analyticsTotalUsers,
  })
);
const fetchAnalyticsTotalUsersFailed = createAction(
  'profile--fetchAnalyticsTotalUsersFailed'
);

const fetchUpdateOptInCustomerInsightsPending = createAction(
  'profile--fetchUpdateOptInCustomerInsightsPending',
  ({ profiles, freeStamp }) => ({ profiles, freeStamp })
);
const fetchUpdateOptInCustomerInsightsSuccess = createAction(
  'profile--fetchUpdateOptInCustomerInsightsSuccess',
  (freeStamp = false) => ({ freeStamp })
);
const fetchUpdateOptInCustomerInsightsFailed = createAction(
  'profile--fetchUpdateOptInCustomerInsightsFailed'
);

const fetchCoffeeOptionsPending = createAction(
  'profile--fetchCoffeeOptionsPending'
);
const fetchCoffeeOptionsSuccess = createAction(
  'profile--fetchCoffeeOptionsSuccess',
  (coffeeOptions = []) => ({
    coffeeOptions,
  })
);
const fetchCoffeeOptionsFailed = createAction(
  'profile--fetchCoffeeOptionsFailed'
);

const fetchCoffeeGrindersPending = createAction(
  'profile--fetchCoffeeGrindersPending'
);
const fetchCoffeeGrindersSuccess = createAction(
  'profile--fetchCoffeeGrindersSuccess',
  (coffeeGrinders = []) => ({
    coffeeGrinders,
  })
);
const fetchCoffeeGrindersFailed = createAction(
  'profile--fetchCoffeeGrindersFailed'
);

const fetchCoffeeMachinesPending = createAction(
  'profile--fetchCoffeeMachinesPending'
);
const fetchCoffeeMachinesSuccess = createAction(
  'profile--fetchCoffeeMachinesSuccess',
  (coffeeMachines = []) => ({
    coffeeMachines,
  })
);
const fetchCoffeeMachinesFailed = createAction(
  'profile--fetchCoffeeMachinesFailed'
);

const fetchCoffeeBeansPending = createAction(
  'profile--fetchCoffeeBeansPending'
);
const fetchCoffeeBeansSuccess = createAction(
  'profile--fetchCoffeeBeansSuccess',
  (coffeeBeans = []) => ({
    coffeeBeans,
  })
);
const fetchCoffeeBeansFailed = createAction('profile--fetchCoffeeBeansFailed');

const fetchTopFeaturesPending = createAction(
  'profile--fetchTopFeaturesPending'
);
const fetchTopFeaturesSuccess = createAction(
  'profile--fetchTopFeaturesSuccess',
  (topFeatures = []) => ({
    topFeatures,
  })
);
const fetchTopFeaturesFailed = createAction('profile--fetchTopFeaturesFailed');

const fetchUpdateStampPending = createAction(
  'profile--fetchUpdateStampPending',
  (stamp) => stamp
);
const fetchUpdateStampSuccess = createAction(
  'profile--fetchUpdateStampSuccess',
  (profile = {}) => ({ profile })
);
const fetchUpdateStampFailed = createAction(
  'profile--fetchUpdateStampFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

const isFetching = createAction('profile--isFetching', (value) => value);

const profileActions = {
  changeTemp,
  changeStampTemp,
  clearTemp,
  clearProfile,
  clearProfileAddress,
  clearTempImage,

  fetchPending,
  fetchSuccess,
  fetchFailed,

  fetchUpdatePending,
  fetchUpdateSuccess,
  fetchUpdateFailed,

  fetchCreateFailed,
  fetchCreatePending,
  fetchCreateSuccess,

  fetchAnalyticsAllPending,
  fetchAnalyticsAllSuccess,
  fetchAnalyticsAllFailed,

  fetchAnalyticsByIdPending,
  fetchAnalyticsByIdSuccess,
  fetchAnalyticsByIdFailed,

  fetchAnalyticsTotalUsersPending,
  fetchAnalyticsTotalUsersSuccess,
  fetchAnalyticsTotalUsersFailed,

  fetchUpdateOptInCustomerInsightsPending,
  fetchUpdateOptInCustomerInsightsSuccess,
  fetchUpdateOptInCustomerInsightsFailed,

  fetchCoffeeOptionsPending,
  fetchCoffeeOptionsSuccess,
  fetchCoffeeOptionsFailed,

  fetchCoffeeGrindersPending,
  fetchCoffeeGrindersSuccess,
  fetchCoffeeGrindersFailed,

  fetchCoffeeMachinesPending,
  fetchCoffeeMachinesSuccess,
  fetchCoffeeMachinesFailed,

  fetchCoffeeBeansPending,
  fetchCoffeeBeansSuccess,
  fetchCoffeeBeansFailed,

  fetchTopFeaturesPending,
  fetchTopFeaturesSuccess,
  fetchTopFeaturesFailed,

  fetchUpdateStampPending,
  fetchUpdateStampSuccess,
  fetchUpdateStampFailed,

  isFetching,
};

export default profileActions;
