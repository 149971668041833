import { handleActions, combineActions } from 'redux-actions';
import profileActions from './profile.actions';
import authActions from '../auth/auth.actions';
import userActions from '../user/user.actions';
// import appActions from '../app/app.actions';

export const profileInitialState = {
  message: '',
  errors: {},
  pending: false,
  pendingAnalytics: false,
  name: '',
  logo: '',
  backgroundImage: '',
  description: '',
  address: '',
  postcode: '',
  city: '',
  country: '',
  state: '',
  lat: '',
  lng: '',
  stamp: {
    total: null,
    color: null,
    backgroundColor: null,
    pattern: null,
    textColor: null,
  },
  coffee_options: [],

  temp: {
    name: '',
    logo: '',
    backgroundImage: '',
    description: '',
    address: '',
    postcode: '',
    city: '',
    country: '',
    state: '',
    lat: '',
    lng: '',
    stamp: {
      total: null,
      color: null,
      backgroundColor: null,
      pattern: null,
      textColor: null,
    },
    coffee_options: [],
    top_features: [],
    coffeeMachine: '',
    coffeeGrinder: '',
    coffeeBeans: '',
    openingHours: {
      monOpen: '',
      monClose: '',
      monOperational: false,
      tueOpen: '',
      tueClose: '',
      tueOperational: false,
      wedOpen: '',
      wedClose: '',
      wedOperational: false,
      thuOpen: '',
      thuClose: '',
      thuOperational: false,
      friOpen: '',
      friClose: '',
      friOperational: false,
      satOpen: '',
      satClose: '',
      satOperational: false,
      sunOpen: '',
      sunClose: '',
      sunOperational: false,
    },
    highlights: [],
  },

  analytics: [],
  analyticsTotalUsers: 0,
  stamps: 0,
  allCoffeeOptions: [],
  allCoffeeGrinders: [],
  allCoffeeMachines: [],
  allCoffeeBeans: [],
  allTopFeatures: [],
  // Opt in to give free stamps (Customer Insights)
  freeStamp: false,
};

const profileReducer = handleActions(
  {
    [combineActions(
      profileActions.fetchPending,
      profileActions.fetchUpdatePending,
      profileActions.fetchCreatePending,
      profileActions.fetchUpdateOptInCustomerInsightsPending,
      profileActions.fetchCoffeeOptionsPending,
      profileActions.fetchTopFeaturesPending
    )]: (state) => ({ ...state, pending: true }),

    [combineActions(
      profileActions.fetchAnalyticsAllPending,
      profileActions.fetchAnalyticsByIdPending,
      profileActions.fetchAnalyticsTotalUsersPending
    )]: (state) => ({ ...state, pendingAnalytics: true }),

    [combineActions(
      profileActions.fetchSuccess,
      profileActions.fetchCreateSuccess,
      profileActions.fetchUpdateSuccess
    )]: (state, action) => ({
      ...state,
      ...action.payload.profile,
      pending: false,
      errors: {},
      message: '',
    }),

    [combineActions(
      profileActions.fetchAnalyticsAllSuccess,
      profileActions.fetchAnalyticsByIdSuccess
    )]: (state, action) => ({
      ...state,
      analytics: action.payload.analytics,
      pendingAnalytics: false,
      errors: {},
      message: '',
    }),

    [combineActions(
      profileActions.fetchFailed,
      profileActions.fetchUpdateFailed,
      profileActions.fetchCreateFailed,
      profileActions.fetchUpdateOptInCustomerInsightsFailed,
      profileActions.fetchCoffeeOptionsFailed,
      profileActions.fetchCoffeeMachinesFailed,
      profileActions.fetchCoffeeGrindersFailed,
      profileActions.fetchCoffeeBeansFailed,
      profileActions.fetchTopFeaturesFailed
    )]: (state, action) => ({
      ...state,
      pending: false,
      errors: action.payload.errors,
      message: action.payload.message,
    }),

    [combineActions(
      profileActions.fetchAnalyticsAllFailed,
      profileActions.fetchAnalyticsByIdFailed,
      profileActions.fetchAnalyticsTotalUsersFailed
    )]: (state, action) => ({
      ...state,
      pendingAnalytics: false,
      errors: action.payload.errors,
      message: action.payload.message,
    }),

    [profileActions.fetchCoffeeOptionsSuccess]: (state, action) => ({
      ...state,
      allCoffeeOptions: action.payload.coffeeOptions,
      pending: false,
      errors: {},
      message: '',
    }),

    [profileActions.fetchCoffeeMachinesSuccess]: (state, action) => ({
      ...state,
      allCoffeeMachines: action.payload.coffeeMachines,
      pending: false,
      errors: {},
      message: '',
    }),
    [profileActions.fetchCoffeeGrindersSuccess]: (state, action) => ({
      ...state,
      allCoffeeGrinders: action.payload.coffeeGrinders,
      pending: false,
      errors: {},
      message: '',
    }),
    [profileActions.fetchCoffeeBeansSuccess]: (state, action) => ({
      ...state,
      allCoffeeBeans: action.payload.coffeeBeans,
      pending: false,
      errors: {},
      message: '',
    }),
    [profileActions.fetchTopFeaturesSuccess]: (state, action) => ({
      ...state,
      allTopFeatures: action.payload.topFeatures,
      pending: false,
      errors: {},
      message: '',
    }),

    [profileActions.changeTemp]: (state, action) => ({
      ...state,
      temp: {
        ...state.temp,
        [action.payload.key]: action.payload.value,
      },
    }),

    [profileActions.changeStampTemp]: (state, action) => ({
      ...state,
      temp: {
        ...state.temp,
        stamp: {
          ...state.temp.stamp,
          [action.payload.key]: action.payload.value,
        },
      },
    }),

    [profileActions.clearTemp]: (state, action) => ({
      ...state,
      temp: {
        ...profileInitialState.temp,
      },
    }),
    [profileActions.clearTempImage]: (state, action) => ({
      ...state,
      temp: {
        ...state.temp,
        [action.payload.key]: '',
      },
    }),

    [profileActions.clearProfile]: (state, action) => ({
      ...profileInitialState,
    }),

    [profileActions.clearProfileAddress]: (state, action) => ({
      ...state,
      address: '',
      postcode: '',
      city: '',
      country: '',
      state: '',
      lat: '',
      lng: '',
    }),

    [combineActions(authActions.logout, userActions.deleteSuccess)]: (
      state,
      action
    ) => ({
      ...profileInitialState,
    }),

    [profileActions.fetchAnalyticsSuccess]: (state, action) => ({
      ...state,
      analytics: action.payload.analytics,
      pending: false,
      errors: {},
      message: '',
    }),

    [profileActions.fetchAnalyticsTotalUsersSuccess]: (state, action) => ({
      ...state,
      analyticsTotalUsers: action.payload.analyticsTotalUsers,
      pending: false,
      errors: {},
      message: '',
    }),

    [profileActions.fetchUpdateOptInCustomerInsightsSuccess]: (
      state,
      action
    ) => ({
      ...state,
      freeStamp: action.payload.freeStamp,
      pending: false,
      errors: {},
      message: '',
    }),

    [profileActions.isFetching]: (state, action) => ({
      ...state,
      // pending: true
      pending: action.payload,
    }),
  },
  profileInitialState
);

export default profileReducer;
