import { createSelector } from 'reselect';

const rootSelector = (state) => state.user;

export const selectUserErrors = createSelector(
  rootSelector,
  (user) => user.errors
);

export const selectUserMessage = createSelector(
  rootSelector,
  (user) => user.message
);

export const selectUserCard = createSelector(rootSelector, (user) => user.card);

export const selectUserPending = createSelector(rootSelector, (user) =>
  Boolean(user.pending)
);

export const selectUserObject = createSelector(rootSelector, (user) => ({
  subscription: user.subscription,
  approved: user.approved,
  profile: user.profile,
  active: user.active,
  _id: user._id,
  email: user.email,
  firstName: user.firstName,
  lastName: user.lastName,
  card: user.card,
}));

export const selectUserInvoices = createSelector(
  rootSelector,
  (user) => user.invoices || []
);

export const selectUserActive = createSelector(
  rootSelector,
  (user) => user.active
);

export const selectUserStripe = createSelector(
  rootSelector,
  (user) => user.stripe
);

export const selectUserLogin = createSelector(
  rootSelector,
  (root) => root.isLogin
);

export const selectUserRegistrationStep = createSelector(
  rootSelector,
  (root) => root.step
);

export const selectUserFullName = createSelector(
  rootSelector,
  (user) => `${user.firstName} ${user.lastName}`
);

export const selectUserAvatarName = createSelector(
  rootSelector,
  (user) => `${user.firstName[0] || ''}${user.lastName[0] || ''}`
);
