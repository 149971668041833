import React, { Component } from 'react';
import ChartComponent from 'react-chartjs-2';
import moment from 'moment';

import { mainTheme } from '../../mainStyles';

export default class TipsLineChart extends Component {
  render() {
    const options = {
      legend: { display: false },
      scales: {
        xAxes: [{ gridLines: { display: false, drawBorder: false } }],
        yAxes: [
          {
            gridLines: { display: false, drawBorder: false },
            ticks: { display: false },
          },
        ],
      },
      layout: {
        padding: {
          top: 5,
        },
      },
      tooltips: {
        callbacks: {
          label(tooltipItem, data) {
            return tooltipItem.yLabel.toFixed(2);
          },
        },
      },
    };

    const { tips, range } = this.props;

    const labels = [];
    const data = [];
    if (range === 'day') {
      const tipsToday = tips.filter((tip) =>
        moment().isSame(tip.date * 1000, 'day')
      );
      for (let hour = 0; hour < 24; hour++) {
        labels.push(moment({ hour }).format('HH:mm'));
        const totalByHour = tipsToday.reduce((acc, tip) => {
          if (moment({ hour }).isSame(tip.date * 1000, 'hour'))
            acc += tip.amount;
          return acc;
        }, 0);
        data.push((totalByHour / 100).toFixed(2));
      }
    } else {
      const tomorrow = moment().add(1, 'day');
      const startDate = moment().subtract(1, range);
      let sumPerDay = 0;
      while (startDate.isBefore(tomorrow)) {
        labels.push(startDate.format('DD MMM'));
        tips.map(
          (tip) =>
            startDate.isSame(tip.date * 1000, 'day') &&
            (sumPerDay += tip.amount)
        );
        data.push((sumPerDay / 100).toFixed(2));
        sumPerDay = 0;
        startDate.add(1, 'day');
      }
      labels.pop();
      labels.push('Today');
    }

    const config = {
      labels,
      datasets: [
        {
          data,
          borderColor: mainTheme.colors.blue,
          backgroundColor: mainTheme.colors.blue,
          fill: false,
          lineTension: 0,
        },
      ],
    };

    return <ChartComponent type="line" data={config} options={options} />;
  }
}
