import React, { Component } from 'react';
import styled from 'styled-components';

import SubscriptionDetailsContainer from '../SubscriptionDetailsContainer/SubscriptionDetailsContainer';

class CardPaymentModal extends Component {
  state = {
    open: false,
  };

  open = () => this.setState({ open: true });

  close = () => this.setState({ open: false });

  render() {
    if (!this.state.open) return null;
    return (
      <Wrapper>
        <BackDrop onClick={this.close} />

        <SubscriptionDetailsContainer description={false} />
      </Wrapper>
    );
  }
}

export default CardPaymentModal;

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const BackDrop = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(40, 40, 40, 0.4);
`;
