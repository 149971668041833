import React, { useCallback, useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import CheckOptionComponent from '../CheckOptionComponent/CheckOptionComponent';
import { Row } from '../MainComponents/MainComponents';
import profileActions from '../../redux/profile/profile.actions';
import {
  selectProfileObject,
  selectProfileErrors,
} from '../../redux/profile/profile.selectors';

const TopFeaturesComponent = (props) => {
  const [allTopFeatures, setAllTopFeatures] = useState([]);
  const [selectedTopFeatures, setSelectedTopFeatures] = useState([]);

  useEffect(() => {
    props.fetchTopFeatures();
  }, []);

  useEffect(() => {
    if (allTopFeatures.length == 0) {
      setAllTopFeatures(props.allTopFeatures);
    }
    if (props.selectedTopFeatures) {
      setSelectedTopFeatures(props.selectedTopFeatures);
    }
  }, [props.allTopFeatures, props.selectedTopFeatures]);

  useEffect(() => {
    const selected = props.allTopFeatures.map((option) => {
      if (selectedTopFeatures && selectedTopFeatures.includes(option._id)) {
        option = { ...option, checked: true };
      } else {
        option = { ...option, checked: false };
      }
      return option;
    });
    setAllTopFeatures(selected);
  }, [props.allTopFeatures, JSON.stringify(selectedTopFeatures)]);
  useEffect(() => {
    if (props.handleChange) {
      props.handleChange('top_features', selectedTopFeatures);
    }
  }, [selectedTopFeatures]);

  const TopFeaturesRenderer = useCallback(() => {
    const options = allTopFeatures;
    let optionsList = [];
    if (options && options.length !== 0) {
      optionsList = options.map((option) => (
        <CheckOptionComponent
          handleCheckboxChange={handleCheckboxChange}
          data={option}
        />
      ));
    }
    return optionsList;
  }, [allTopFeatures, selectedTopFeatures]);

  const handleCheckboxChange = (id, checked) => {
    const updatedArray = selectedTopFeatures;
    if (checked && selectedTopFeatures && !selectedTopFeatures.includes(id)) {
      updatedArray.push(id);
      setSelectedTopFeatures(updatedArray);
    } else if (!checked) {
      while (selectedTopFeatures.includes(id)) {
        const index = updatedArray.indexOf(id);
        updatedArray.splice(index, 1);
      }
      setSelectedTopFeatures(updatedArray);
    }
    if (props.handleChange) {
      props.handleChange('top_features', updatedArray);
    }
  };

  return (
    <Row wrap>
      <TopFeaturesRenderer />
    </Row>
  );
};

const TopFeaturesSelector = createSelector(
  selectProfileObject,
  selectProfileErrors,
  (profile, errors) => ({
    errors,
    selectedTopFeatures: profile.selectedTopFeatures,
    allTopFeatures: profile.allTopFeatures,
  })
);
const mapStateToProps = TopFeaturesSelector;
const mapDispatchToProps = {
  fetchTopFeatures: profileActions.fetchTopFeaturesPending,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopFeaturesComponent);
