import { createAction } from 'redux-actions';

const fetchPending = createAction('posts--fetchPending');
const fetchSuccess = createAction('posts--fetchSuccess', (posts = []) => ({
  posts,
}));

const fetchFailed = createAction(
  'posts--fetchFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

const clearPostsState = createAction('posts--clearPostsState');

const postsActions = {
  fetchPending,
  fetchSuccess,
  fetchFailed,
  clearPostsState,
};

export default postsActions;
