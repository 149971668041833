export const patterns = [
  {
    id: 0,
    name: '/patterns/beige-tiles.png',
  },
  {
    id: 1,
    name: '/patterns/circuit.png',
  },
  {
    id: 2,
    name: '/patterns/floor-tile.png',
  },
  {
    id: 3,
    name: '/patterns/folk-pattern-black.png',
  },
  {
    id: 4,
    name: '/patterns/houndstooth-pattern.png',
  },
  {
    id: 6,
    name: '/patterns/just-waves.png',
  },
  {
    id: 7,
    name: '/patterns/memphis-mini.png',
  },
  {
    id: 8,
    name: '/patterns/papyrus.png',
  },
  {
    id: 9,
    name: '/patterns/white-waves.png',
  },
  {
    id: 10,
    name: '/patterns/bananas.png',
  },
  {
    id: 11,
    name: '/patterns/country-quilt.png',
  },
  {
    id: 12,
    name: '/patterns/circles-and-roundabouts.png',
  },
  {
    id: 13,
    name: '/patterns/circles-light.png',
  },
  {
    id: 14,
    name: '/patterns/hypnotize.png',
  },
  {
    id: 15,
    name: '/patterns/concrete-texture.png',
  },
  {
    id: 16,
    name: '/patterns/congruent_outline.png',
  },
  {
    id: 17,
    name: '/patterns/light-grey-terrazzo.png',
  },
  {
    id: 18,
    name: '/patterns/new_year_background.png',
  },
  {
    id: 19,
    name: '/patterns/organic-tiles.png',
  },
  {
    id: 20,
    name: '/patterns/polka-dots.png',
  },
  {
    id: 21,
    name: '/patterns/stonehaven.png',
  },
  {
    id: 22,
    name: '/patterns/stripes-light.png',
  },
  {
    id: 23,
    name: '/patterns/swirl_pattern.png',
  },
  {
    id: 24,
    name: '/patterns/tic-tac-toe.png',
  },
  {
    id: 25,
    name: '/patterns/topography.png',
  },
  {
    id: 26,
    name: '',
  },
];
