import { createAction } from 'redux-actions';

const selectPost = createAction('post--selectPost', (post) => ({ post }));

const fetchPostPending = createAction('post--fetchPostPending', (_id) => ({
  _id,
}));
const fetchPostSuccess = createAction('post--fetchPostSuccess', (post) => ({
  post,
}));
const fetchPostFailed = createAction(
  'post--fetchPostFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

const fetchCreatePostPending = createAction(
  'post--fetchCreatePostPending',
  (post) => ({ post })
);
const fetchCreatePostSuccess = createAction(
  'post--fetchCreatePostSuccess',
  (post) => ({ post })
);
const fetchCreatePostFailed = createAction(
  'post--fetchCreatePostFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

const fetchUpdatePostPending = createAction(
  'post--fetchUpdatePostPending',
  (post) => ({ post })
);
const fetchUpdatePostSuccess = createAction(
  'post--fetchUpdatePostSuccess',
  (post) => ({ post })
);
const fetchUpdatePostFailed = createAction(
  'post--fetchUpdatePostFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

const fetchDeletePostPending = createAction(
  'post--fetchDeletePostPending',
  (_id) => ({ _id })
);
const fetchDeletePostSuccess = createAction(
  'post--fetchDeletePostSuccess',
  (_id) => ({ _id })
);
const fetchDeletePostFailed = createAction(
  'post--fetchDeletePostFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

const changeTemp = createAction('post--changeTemp', (key, value) => ({
  key,
  value,
}));
const clearTemp = createAction('post--clearTem');

const postActions = {
  selectPost,

  fetchPostPending,
  fetchPostSuccess,
  fetchPostFailed,

  fetchCreatePostPending,
  fetchCreatePostSuccess,
  fetchCreatePostFailed,

  fetchUpdatePostPending,
  fetchUpdatePostSuccess,
  fetchUpdatePostFailed,

  fetchDeletePostPending,
  fetchDeletePostSuccess,
  fetchDeletePostFailed,

  changeTemp,
  clearTemp,
};

export default postActions;
