import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  BoardComponent,
  MainTitleDark,
  MobileContent,
  RightBlock,
  RowOffsetBottom,
  SmallBlock,
  Text,
} from '../../components/MainComponents/MainComponents';
import SupportFormContainer from '../../containers/SupportFormContainer/SupportFormContainer';
import { mainTheme } from '../../mainStyles';
import { MainContentOffset } from '../MainScreen/styles';

class FeedBackScreen extends Component {
  render() {
    const { state } = this.props.location;
    const subject = state && state.subject ? state.subject : '';
    const email = subject ? this.props.email : '';
    const firstName = subject ? this.props.firstName : '';
    const lastName = subject ? this.props.lastName : '';

    return (
      <MainContentOffset>
        <RowOffsetBottom wrap>
          <MobileContent>
            <SmallBlock style={{ paddingRight: 20 }}>
              <MainTitleDark>Support</MainTitleDark>
              <Text color={mainTheme.colors.grey}>
                Contact the RWRD venues team
              </Text>
            </SmallBlock>
          </MobileContent>

          <RightBlock as={BoardComponent}>
            <SupportFormContainer
              reason={subject}
              email={email}
              firstName={firstName}
              lastName={lastName}
            />
          </RightBlock>
        </RowOffsetBottom>
      </MainContentOffset>
    );
  }
}

function mapStateToProps(state) {
  return {
    email: state.user.email,
    firstName: state.user.firstName,
    lastName: state.user.lastName,
  };
}
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeedBackScreen));
