import styled from 'styled-components';
import logo from '../../assets/images/icons/rwrd.png';
import { Column, Title } from '../MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';

export const AuthLabelsWrapper = styled(Column)`
  align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
`;
export const AuthIcon = styled.img.attrs({
  src: logo,
})`
  width: 85px;
  height: 105px;
  margin-bottom: ${mainTheme.offset.xsmall}px;
`;

export const AuthLabel = styled(Title)`
  text-align: center;
  word-break: break-word;
  font-family: Raleway;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 24px;
  max-width: 740px;
`;

export const AuthSubtitle = styled(AuthLabel)`
  text-align: center;
  word-break: break-word;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 24px;
  margin: 30px 0 0;
`;
