export const serializeForm = (form) => {
  return Array.from(form.elements).reduce((acc, item) => {
    if (
      (item.nodeName === 'INPUT' || item.nodeName === 'TEXTAREA') &&
      item.type !== 'file'
    ) {
      if (!item.name) {
        return acc;
      }
      if (item.type === 'checkbox' || item.type === 'radio') {
        acc[item.name] = item.checked;
        return acc;
      }
      acc[item.name] = item.value;
    }
    return acc;
  }, {});
};

export const clearControllerFrom = (form) => {
  const inputs = form.querySelectorAll('input.hiddenInput');
  inputs.forEach((input) => input.click());
};
