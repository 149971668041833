import React, { Component } from 'react';
import styled from 'styled-components';
import rateStarActive from '../../assets/images/icons/rate-star-active.svg';
import rateStarInactive from '../../assets/images/icons/rate-star-inactive.svg';
import { Row } from '../MainComponents/MainComponents';
// import starImage from '../../assets/images/icons/star.svg';

const max = 5;
const stars = Array.from('s'.repeat(max));

export const Star = styled.div`
  width: 10px;
  height: 10px;
  color: #f9bf3a;
  background-image: url(${(props) =>
    props.active ? rateStarActive : rateStarInactive});
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 2px;
`;

export default class StarsComponent extends Component {
  static defaultProps = {
    count: 4,
  };

  render() {
    return (
      <Row>
        {stars.map((star, idx) => (
          <Star key={`${star}_${idx}`} active={idx < this.props.count} />
        ))}
      </Row>
    );
  }
}
