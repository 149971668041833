import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Checkbox, FieldLabel, Row } from '../MainComponents/MainComponents';

const CheckOptionComponent = (props) => {
  const [checked, setChecked] = useState(false);
  const { data } = props;
  if (data.checked == null) {
    data.checked = false;
  }

  useEffect(() => {
    setChecked(data.checked);
  }, [data.checked]);
  const handleCheckboxChange = () => {
    props.handleCheckboxChange(data._id, !checked);
    setChecked(!checked);
  };
  return (
    <Row style={window.innerWidth > 728 ? largeScreenStyle : smallScreenStyle}>
      <input
        style={checkboxStyle}
        type="checkbox"
        checked={checked}
        onClick={() => handleCheckboxChange()}
      />
      {data.icon && <Image src={data.icon} />}
      <FieldLabel>{data.name}</FieldLabel>
    </Row>
  );
};

const largeScreenStyle = { width: '33%', paddingBottom: '10px' };
const smallScreenStyle = { width: '50%', paddingBottom: '10px' };
const checkboxStyle = {
  width: '20px',
  height: '20px',
  marginRight: '10px',
  minWidth: '20px',
};
const Image = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;

export default CheckOptionComponent;
