import React, { useRef } from 'react';

import shevronOpened from '../../../assets/images/icons/shevron-opened.svg';
import shevronClosed from '../../../assets/images/icons/shevron-closed.svg';
import includedIcon from '../../../assets/images/icons/included.svg';

import {
  AccordionBody,
  AccordionToggle,
  Collapse,
  StyledAccordionTitle,
  StyledCard,
  StyledList,
  StyledListItem,
  StyledListItemText,
  StyledText,
} from '../styles';

const AccordionTextListItem = ({ handleToggle, active, item }) => {
  const contentEl = useRef();
  const { header, id, content } = item;

  const contentBody = content.map((el) => {
    if (el.text) {
      return el.text.map((text) => <StyledText>{text}</StyledText>);
    }
    if (el.list) {
      return el.list.map((el) => (
        <StyledListItem key={el.id}>
          <img src={includedIcon} alt="" />{' '}
          <StyledListItemText>{el.text}</StyledListItemText>
        </StyledListItem>
      ));
    }
  });

  return (
    <StyledCard>
      <AccordionToggle active={active} id={id} onClick={() => handleToggle(id)}>
        <StyledAccordionTitle>{header}</StyledAccordionTitle>
        <img
          src={active === id ? shevronOpened : shevronClosed}
          width="24px"
          height="24px"
          alt="shevron"
        />
      </AccordionToggle>

      <Collapse
        active={active}
        id={id}
        ref={contentEl}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: '0px' }
        }
      >
        <AccordionBody>{contentBody}</AccordionBody>
      </Collapse>
    </StyledCard>
  );
};

export default AccordionTextListItem;
