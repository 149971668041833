import React, { useRef } from 'react';

import shevronOpened from '../../../assets/images/icons/shevron-opened.svg';
import shevronClosed from '../../../assets/images/icons/shevron-closed.svg';
import includedIcon from '../../../assets/images/icons/included.svg';
import notIncludedIcon from '../../../assets/images/icons/not-included.svg';

import {
  AccordionBody,
  AccordionToggle,
  Collapse,
  StyledAccordionTitle,
  StyledCard,
  StyledList,
  StyledListItem,
  StyledListItemText,
  StyledNotIncludedTitle,
} from '../styles';

const AccordionListItem = ({ handleToggle, active, item }) => {
  const contentEl = useRef();
  const { header, id, content } = item;

  return (
    <StyledCard>
      <AccordionToggle active={active} id={id} onClick={() => handleToggle(id)}>
        <StyledAccordionTitle>{header}</StyledAccordionTitle>
        <img
          src={active === id ? shevronOpened : shevronClosed}
          width="24px"
          height="24px"
          alt="shevron"
        />
      </AccordionToggle>

      <Collapse
        active={active}
        id={id}
        ref={contentEl}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: '0px' }
        }
      >
        <AccordionBody>
          <StyledList>
            {content.included.list.map((item) => (
              <StyledListItem key={item.id}>
                <img src={includedIcon} alt="" />{' '}
                <StyledListItemText>{item.text}</StyledListItemText>
              </StyledListItem>
            ))}
          </StyledList>

          {content.not_included && (
            <>
              <div>
                <StyledNotIncludedTitle>
                  {content.not_included.title}
                </StyledNotIncludedTitle>
              </div>
              <StyledList>
                {content.not_included.list.map((item) => (
                  <StyledListItem key={item.id}>
                    <img src={notIncludedIcon} alt="" />{' '}
                    <StyledListItemText>{item.text}</StyledListItemText>
                  </StyledListItem>
                ))}
              </StyledList>
            </>
          )}
          {content.plus && (
            <>
              <div>
                <StyledNotIncludedTitle>
                  {content.plus.title}
                </StyledNotIncludedTitle>
              </div>
              <StyledList>
                {content.plus.list.map((item) => (
                  <StyledListItem key={item.id}>
                    <img src={includedIcon} alt="" />{' '}
                    <StyledListItemText>{item.text}</StyledListItemText>
                  </StyledListItem>
                ))}
              </StyledList>
            </>
          )}
        </AccordionBody>
      </Collapse>
    </StyledCard>
  );
};

export default AccordionListItem;
