import styled from 'styled-components';
import { Row, Column } from '../../components/MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';

export const MainScreenWrapper = styled(Row)`
  width: 100%;
  height: 100%;
  height: 100vh;
`;
export const MainContentWrapper = styled(Column)`
  background: ${mainTheme.colors.main};
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: scroll;
`;

export const MainScrolledContent = styled(Column)`
  background: ${mainTheme.colors.main};
  width: 100%;

  display: block;
`;

export const MainContentOffset = styled.div`
  flex-direction: column;
  padding: 0 ${mainTheme.offset.small}px;
  @media (max-width: ${mainTheme.sizes.small}px) {
    padding: 0;
  }
`;
