import React from 'react';
import {
  StyledCard,
  StyledCardBadge,
  StyledCardContent,
  StyledCardPrice,
  StyledCardTitle,
  StyledCardValue,
} from './styles';

const BrandingBoosterCard = ({ isActive, title, price, term, badge }) => {
  return (
    <StyledCard isActive={isActive}>
      {badge && <StyledCardBadge>{badge}</StyledCardBadge>}
      <StyledCardContent isActive={isActive}>
        <StyledCardTitle>{title}</StyledCardTitle>
        <StyledCardValue>
          <StyledCardPrice>£{price}</StyledCardPrice>
          {!!term && term}
        </StyledCardValue>
      </StyledCardContent>
    </StyledCard>
  );
};

export default BrandingBoosterCard;
