import { fork, put, select, takeLatest } from 'redux-saga/effects';

import client, { handleRequestError } from '../../utils/client';
import profileActions from './profile.actions';
import { selectUserObject, selectUserLogin } from '../user/user.selectors';
// import appActions from '../app/app.actions';
import userActions from '../user/user.actions';
import storage from '../../utils/storage';
import { history } from '../../containers/RouterContainer/RouterContainer';

function* fetchProfile(action) {
  try {
    const isLogin = yield select(selectUserLogin);
    if (!isLogin || typeof action.payload !== 'string') {
      // TODO: double fetch with wrong payload workaround;
      yield put(profileActions.fetchFailed());
      return;
    }
    const response = yield client.get(`/profile/${action.payload}`);
    const { profile } = response.data;

    const openingHours = {
      monOpen: profile.monOpen ? profile.monOpen : '',
      monClose: profile.monClose ? profile.monClose : '',
      monOperational: profile.monOperational ? profile.monOperational : false,

      tueOpen: profile.tueOpen ? profile.tueOpen : '',
      tueClose: profile.tueClose ? profile.tueClose : '',
      tueOperational: profile.tueOperational ? profile.tueOperational : false,

      wedOpen: profile.wedOpen ? profile.wedOpen : '',
      wedClose: profile.wedClose ? profile.wedClose : '',
      wedOperational: profile.wedOperational ? profile.wedOperational : false,

      thuOpen: profile.thuOpen ? profile.thuOpen : '',
      thuClose: profile.thuClose ? profile.thuClose : '',
      thuOperational: profile.thuOperational ? profile.thuOperational : false,

      friOpen: profile.friOpen ? profile.friOpen : '',
      friClose: profile.friClose ? profile.friClose : '',
      friOperational: profile.friOperational ? profile.friOperational : false,

      satOpen: profile.satOpen ? profile.satOpen : '',
      satClose: profile.satClose ? profile.satClose : '',
      satOperational: profile.satOperational ? profile.satOperational : false,

      sunOpen: profile.sunOpen ? profile.sunOpen : '',
      sunClose: profile.sunClose ? profile.sunClose : '',
      sunOperational: profile.sunOperational ? profile.sunOperational : false,
    };

    profile.openingHours = openingHours;

    yield put(profileActions.fetchSuccess(profile));
  } catch (error) {
    yield handleRequestError(
      error,
      profileActions.fetchCreateFailed,
      fetchProfile,
      action
    );
  }
}

function* fetchCreateProfile(action) {
  try {
    const response = yield client.post('/profile', action.payload.profile);
    storage.storageClear('step');
    history.push('/dashboard');
    yield put(profileActions.fetchCreateSuccess(response.data.profile));
    yield put(userActions.fetchMePending());
  } catch (error) {
    yield handleRequestError(
      error,
      profileActions.fetchCreateFailed,
      fetchCreateProfile,
      action
    );
  }
}

function* fetchUpdateProfile(action) {
  try {
    const response = yield client.put(
      `/profile/${action.payload.profileId}`,
      action.payload.profile
    );

    yield put(profileActions.fetchUpdateSuccess(response.data.profile));
    yield put(userActions.fetchMePending());
  } catch (error) {
    yield handleRequestError(
      error,
      profileActions.fetchUpdateFailed,
      fetchUpdateProfile,
      action
    );
  }
}

function* fetchAnalyticsAll(action) {
  try {
    const { timeGrain, timeLapse, regionalFilter } = action.payload;
    const response = yield client.get(
      `/profile/analytics-all?timeGrain=${timeGrain}&timeLapse=${timeLapse}&regionalFilter=${regionalFilter}`
    );
    yield put(profileActions.fetchAnalyticsAllSuccess(response.data.analytics));
  } catch (error) {
    yield handleRequestError(
      error,
      profileActions.fetchAnalyticsAllFailed,
      fetchAnalyticsAll,
      action
    );
  }
}

function* fetchAnalyticsById(action) {
  try {
    const { timeGrain, timeLapse, regionalFilter, profileId } = action.payload;
    const response = yield client.get(
      `/profile/analytics/${profileId}?timeGrain=${timeGrain}&timeLapse=${timeLapse}&regionalFilter=${regionalFilter}`
    );
    yield put(
      profileActions.fetchAnalyticsByIdSuccess(response.data.analytics)
    );
  } catch (error) {
    yield handleRequestError(
      error,
      profileActions.fetchAnalyticsByIdFailed,
      fetchAnalyticsById,
      action
    );
  }
}
function* fetchCoffeeOptions(action) {
  try {
    const response = yield client.get('/profile/coffeeOptions');
    yield put(
      profileActions.fetchCoffeeOptionsSuccess(response.data.coffeeOptions)
    );
  } catch (error) {
    yield handleRequestError(
      error,
      profileActions.fetchCoffeeOptionsFailed,
      fetchCoffeeOptions,
      action
    );
  }
}
function* fetchCoffeeMachines(action) {
  try {
    const response = yield client.get('/profile/coffeeMachines');
    yield put(
      profileActions.fetchCoffeeMachinesSuccess(response.data.coffeeMachines)
    );
  } catch (error) {
    yield handleRequestError(
      error,
      profileActions.fetchCoffeeMachinesFailed,
      fetchCoffeeMachines,
      action
    );
  }
}
function* fetchCoffeeBeans(action) {
  try {
    const response = yield client.get('/profile/coffeeBeans');
    yield put(
      profileActions.fetchCoffeeBeansSuccess(response.data.coffeeBeans)
    );
  } catch (error) {
    yield handleRequestError(
      error,
      profileActions.fetchCoffeeBeansFailed,
      fetchCoffeeBeans,
      action
    );
  }
}
function* fetchCoffeeGrinders(action) {
  try {
    const response = yield client.get('/profile/coffeeGrinders');
    yield put(
      profileActions.fetchCoffeeGrindersSuccess(response.data.coffeeGrinders)
    );
  } catch (error) {
    yield handleRequestError(
      error,
      profileActions.fetchCoffeeGrindersFailed,
      fetchCoffeeGrinders,
      action
    );
  }
}
function* fetchTopFeatures(action) {
  try {
    const response = yield client.get('/profile/topFeatures');
    yield put(
      profileActions.fetchTopFeaturesSuccess(response.data.topFeatures)
    );
  } catch (error) {
    yield handleRequestError(
      error,
      profileActions.fetchTopFeaturesFailed,
      fetchTopFeatures,
      action
    );
  }
}

function* fetchAnalyticsTotalUsers(action) {
  try {
    const response = yield client.get('/profile/analyticsTotalUsers');
    yield put(
      profileActions.fetchAnalyticsTotalUsersSuccess(response.data.totalUsers)
    );
  } catch (error) {
    yield handleRequestError(
      error,
      profileActions.fetchAnalyticsTotalUsersFailed,
      fetchAnalyticsTotalUsers,
      action
    );
  }
}

function* fetchUpdateOptInCustomerInsights(action) {
  try {
    const { profiles, freeStamp } = action.payload;
    const response = yield client.put('/profile/updateOptInCustomerInsights', {
      profiles,
      freeStamp,
    });
    yield put(
      profileActions.fetchUpdateOptInCustomerInsightsSuccess(
        response.data.freeStamp
      )
    );
  } catch (error) {
    yield handleRequestError(
      error,
      profileActions.fetchUpdateOptInCustomerInsightsFailed,
      fetchUpdateOptInCustomerInsights,
      action
    );
  }
}

function* fetchUpdateStamp(action) {
  try {
    const response = yield client.put('/profile/stamp', action.payload.stamp);
    yield put(profileActions.fetchUpdateSuccess(response.data.profile));
  } catch (error) {
    yield handleRequestError(
      error,
      profileActions.fetchUpdateFailed,
      fetchUpdateProfile,
      action
    );
  }
}

function* ProfileFlow() {
  yield takeLatest(
    [profileActions.fetchPending, userActions.fetchMeSuccess],
    fetchProfile
  );
  yield takeLatest(profileActions.fetchUpdatePending, fetchUpdateProfile);
  yield takeLatest(profileActions.fetchCreatePending, fetchCreateProfile);
  yield takeLatest(profileActions.fetchAnalyticsAllPending, fetchAnalyticsAll);
  yield takeLatest(
    profileActions.fetchAnalyticsByIdPending,
    fetchAnalyticsById
  );
  yield takeLatest(
    profileActions.fetchAnalyticsTotalUsersPending,
    fetchAnalyticsTotalUsers
  );
  yield takeLatest(
    profileActions.fetchUpdateOptInCustomerInsightsPending,
    fetchUpdateOptInCustomerInsights
  );
  yield takeLatest(
    profileActions.fetchCoffeeOptionsPending,
    fetchCoffeeOptions
  );
  yield takeLatest(profileActions.fetchCoffeeBeansPending, fetchCoffeeBeans);
  yield takeLatest(
    profileActions.fetchCoffeeMachinesPending,
    fetchCoffeeMachines
  );
  yield takeLatest(
    profileActions.fetchCoffeeGrindersPending,
    fetchCoffeeGrinders
  );
  yield takeLatest(profileActions.fetchTopFeaturesPending, fetchTopFeatures);
  yield takeLatest(profileActions.fetchUpdateStampPending, fetchUpdateStamp);
}

function* profileWorker() {
  yield fork(ProfileFlow);
}

export default profileWorker;
