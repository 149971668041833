import { createSelector } from 'reselect';

const rootSelector = (state) => state.statistic;

export const selectStatisticErrors = createSelector(
  rootSelector,
  (statistic) => statistic.errors
);

export const selectStatisticMessage = createSelector(
  rootSelector,
  (statistic) => statistic.message
);

export const selectStatisticPending = createSelector(
  rootSelector,
  (statistic) => Boolean(statistic.pending)
);

export const selectStatisticPeriod = createSelector(
  rootSelector,
  (statistic) => statistic.period
);
