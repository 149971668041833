import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { selectUserObject } from '../../redux/user/user.selectors';
import storage from '../../utils/storage';
import CardDetailsComponent from '../../components/CardDetailsComponent/CardDetailsComponent';
import { AUTH_ROUTS } from '../../constants/routes';

class PaymentsContainer extends Component {
  static defaultProps = {
    description: true,
  };

  componentDidMount() {
    const step = storage.storageGet('step');
    if (!step && !!this.props.history)
      this.props.history.push(AUTH_ROUTS.SIGN_IN);
    if (step === 'registered') {
      this.props.history.push(AUTH_ROUTS.CONFIRMATION);
    }
    // if (step && step !== this.props.history.location.pathname) this.props.history.push(step);
    if (this.props.user.card) {
      // Step completed - navigate to next step
      storage.storageSet('registered');
      this.props.history.push(AUTH_ROUTS.CONFIRMATION);
    }
  }

  componentDidUpdate() {
    if (this.props.user.card) {
      // Step completed - navigate to next step
      storage.storageSet('registered');
      this.props.history.push(AUTH_ROUTS.CONFIRMATION);
    }
  }

  render() {
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <Elements>
          <CardDetailsComponent />
        </Elements>
      </StripeProvider>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: selectUserObject,
});

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsContainer);
