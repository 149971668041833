import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Column,
  FieldLabel,
  InputMain,
  RowOffsetBottom,
  MainButton,
} from '../../components/MainComponents/MainComponents';
import TextAreaComponent from '../../components/TextAreaComponent/TextAreaComponent';
import { serializeForm } from '../../utils/selectors';
import userActions from '../../redux/user/user.actions';
import {
  selectUserErrors,
  selectUserPending,
} from '../../redux/user/user.selectors';

class SupportFormContainer extends Component {
  static defaultProps = {
    email: '',
    firstName: '',
    lastName: '',
    reason: '',
    description: '',
  };

  state = {
    submissionComplete: false,
  };

  submit = (event) => {
    event.preventDefault();
    const data = serializeForm(event.target);

    this.props.giveFeedback(data);
  };

  componentDidUpdate(prevProps) {
    if (!this.props.pending && prevProps.pending) {
      this.setState({ submissionComplete: true });
    }
  }

  render() {
    return (
      <div>
        {this.state.submissionComplete ? (
          <SuccessMessageContainer />
        ) : (
          <form onSubmit={this.submit}>
            <RowOffsetBottom>
              <Column>
                <FieldLabel>EMAIL ADDRESS</FieldLabel>
                <InputMain
                  error={'email' in this.props.errors}
                  name="email"
                  maxLength={50}
                  defaultValue={this.props.email}
                  placeholder="Email Address"
                />
              </Column>
            </RowOffsetBottom>

            <NameRow>
              <Column>
                <FieldLabel>FIRST NAME</FieldLabel>
                <InputMain
                  error={'firstName' in this.props.errors}
                  name="firstName"
                  maxLength={50}
                  defaultValue={this.props.firstName}
                  placeholder="First Name"
                />
              </Column>
              <Column>
                <FieldLabel>LAST NAME</FieldLabel>
                <InputMain
                  error={'lastName' in this.props.errors}
                  name="lastName"
                  maxLength={50}
                  defaultValue={this.props.lastName}
                  placeholder="Last Name"
                />
              </Column>
            </NameRow>

            <RowOffsetBottom>
              <Column>
                <FieldLabel>SUBJECT</FieldLabel>
                <InputMain
                  error={'reason' in this.props.errors}
                  name="reason"
                  maxLength={50}
                  defaultValue={this.props.reason}
                  placeholder="Subject"
                />
              </Column>
            </RowOffsetBottom>

            <RowOffsetBottom>
              <Column>
                <FieldLabel>MESSAGE</FieldLabel>
                <TextAreaComponent
                  error={'description' in this.props.errors}
                  name="description"
                  maxLength={2000}
                  defaultValue={this.props.description}
                  placeholder="Message"
                />
              </Column>
            </RowOffsetBottom>

            <RowOffsetBottom
              style={{ justifyContent: 'flex-end', marginBottom: 0 }}
            >
              <MainButton type="submit" style={{ marginBottom: 0 }}>
                SEND
              </MainButton>
            </RowOffsetBottom>
          </form>
        )}
      </div>
    );
  }
}

const SuccessMessageContainer = () => (
  <div style={{ color: 'green', textAlign: 'center' }}>
    Support request sent successfully!
  </div>
);

const mapStateToProps = (state) => ({
  errors: selectUserErrors(state),
  pending: selectUserPending(state),
});

const mapDispatchToProps = {
  giveFeedback: userActions.feedbackPending,
  clearErrors: userActions.clearUserErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportFormContainer);

const NameRow = styled(RowOffsetBottom)`
  & > :first-child {
    margin-right: 20px;
  }
  & > :last-child {
    margin-left: 20px;
  }
`;
