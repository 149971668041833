import React, { useCallback, useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { HalfContent } from '../MainComponents/MainComponents';
import profileActions from '../../redux/profile/profile.actions';
import CheckOptionComponent from '../CheckOptionComponent-v2/CheckOptionComponent';
import {
  selectProfileObject,
  selectProfileErrors,
} from '../../redux/profile/profile.selectors';
import DropDownComponent from '../DropDownComponent-v2/DropDownComponent';
import {
  StyledHalfSection,
  StyledInputLabel,
  StyledSection,
} from '../../containers/MapAdress-v2/MapAdress';

const CoffeeSetupComponent = ({ temp, handleChange, ...props }) => {
  const [coffeeMachineLabel, setCoffeeMachineLabel] = useState('');
  const [coffeeGrinderLabel, setCoffeeGrinderLabel] = useState('');
  const [coffeeBeansLabel, setCoffeeBeansLabel] = useState('');
  const [coffeeOptions, setCoffeeOptions] = useState([]);
  const [machinePreRefreshLabel, setMachinePreRefreshLabel] = useState('');
  const [grinderPreRefreshLabel, setGrinderPreRefreshLabel] = useState('');
  const [beansPreRefreshLabel, setBeansPreRefreshLabel] = useState('');
  const [selectedCoffeeOptions, setSelectedCoffeeOptions] = useState([]);

  const listMapper = (array) => {
    const newArray = array.map((item) => ({
      value: item._id,
      label: item.name,
    }));
    return newArray;
  };

  const labelGetter = (field, id) => {
    let label = '';
    let found = false;
    switch (field) {
      case 'grinder':
        found = props.allCoffeeGrinders.find((entry) => entry._id === id);
        if (found) {
          label = props.allCoffeeGrinders.find(
            (entry) => entry._id === id
          ).name;
          setGrinderPreRefreshLabel(label);
        } else {
          label = grinderPreRefreshLabel;
        }

        break;
      case 'machine':
        found = props.allCoffeeMachines.find((entry) => entry._id === id);
        if (found) {
          label = props.allCoffeeMachines.find(
            (entry) => entry._id === id
          ).name;
          setMachinePreRefreshLabel(label);
        } else {
          label = machinePreRefreshLabel;
        }
        break;
      case 'bean':
        found = props.allCoffeeBeans.find((entry) => entry._id === id);
        if (found) {
          label = props.allCoffeeBeans.find((entry) => entry._id === id).name;
          setBeansPreRefreshLabel(label);
        } else {
          label = beansPreRefreshLabel;
        }
        break;
    }
    return label;
  };

  const CoffeeOptionsRenderer = useCallback(() => {
    const options = coffeeOptions;
    let optionsList = [];
    if (options && options.length !== 0) {
      optionsList = options.map((option) => (
        <CheckOptionComponent
          handleCheckboxChange={handleCheckboxChange}
          data={option}
        />
      ));
    }
    return optionsList;
  }, [coffeeOptions, temp.coffee_options]);

  const handleCheckboxChange = (id, checked) => {
    const updatedArray = temp.coffee_options;
    if (checked && !temp.coffee_options.includes(id)) {
      updatedArray.push(id);
      setSelectedCoffeeOptions(updatedArray);
    } else if (!checked) {
      while (temp.coffee_options.includes(id)) {
        const index = updatedArray.indexOf(id);
        updatedArray.splice(index, 1);
      }
      setSelectedCoffeeOptions(updatedArray);
    }
    if (handleChange) {
      handleChange('coffee_options', updatedArray);
    }
  };

  useEffect(() => {
    if (coffeeOptions.length == 0) {
      setCoffeeOptions(props.allCoffeeOptions);
    }
    setSelectedCoffeeOptions(props.selectedCoffeeOptions);
  }, [props.allCoffeeOptions, props.selectedCoffeeOptions]);

  useEffect(() => {
    if (props.handleChange) {
      props.handleChange('coffee_options', temp.coffee_options);
    }
  }, [temp.coffee_options]);

  useEffect(() => {
    const selected = props.allCoffeeOptions.map((option) => {
      if (temp.coffee_options.includes(option._id)) {
        option = { ...option, checked: true };
      } else {
        option = { ...option, checked: false };
      }
      return option;
    });
    setCoffeeOptions(selected);
  }, [props.allCoffeeOptions, JSON.stringify(temp.coffee_options)]);

  useEffect(() => {
    if (temp.coffeeMachine && temp.coffeeMachine.match(/^[0-9a-fA-F]{24}$/)) {
      setCoffeeMachineLabel(labelGetter('machine', temp.coffeeMachine));
    } else {
      setCoffeeMachineLabel(temp.coffeeMachine);
      setMachinePreRefreshLabel(temp.coffeeMachine);
    }
  }, [temp.coffeeMachine, props.allCoffeeMachines]);
  useEffect(() => {
    if (temp.coffeeGrinder && temp.coffeeGrinder.match(/^[0-9a-fA-F]{24}$/)) {
      setCoffeeGrinderLabel(labelGetter('grinder', temp.coffeeGrinder));
    } else {
      setCoffeeGrinderLabel(temp.coffeeGrinder);
      setGrinderPreRefreshLabel(temp.coffeeGrinder);
    }
  }, [temp.coffeeGrinder, props.allCoffeeGrinders]);
  useEffect(() => {
    if (temp.coffeeBeans && temp.coffeeBeans.match(/^[0-9a-fA-F]{24}$/)) {
      setCoffeeBeansLabel(labelGetter('bean', temp.coffeeBeans));
    } else {
      setCoffeeBeansLabel(temp.coffeeBeans);
      setBeansPreRefreshLabel(temp.coffeeBeans);
    }
  }, [temp.coffeeBeans, props.allCoffeeBeans]);

  const handleOptionChange = (field, value) => {
    switch (field) {
      case 'grinder':
        handleChange('coffeeGrinder', value);
        break;
      case 'machine':
        handleChange('coffeeMachine', value);
        break;
      case 'bean':
        handleChange('coffeeBeans', value);
        break;
    }
  };

  return (
    <>
      <StyledSection flex>
        <StyledHalfSection>
          <StyledInputLabel>Coffee Machine</StyledInputLabel>
          <DropDownComponent
            value={{
              value: temp.coffeeMachine,
              label: coffeeMachineLabel,
            }}
            name="Coffee Machine"
            error={'Coffee Machine' in props.errors}
            placeholder="Select Coffee Machine"
            onSelect={(item) => handleOptionChange('machine', item.value)}
            options={listMapper(props.allCoffeeMachines)}
            onFocus={props.toggleFocused}
            onBlur={props.toggleFocused}
            creatable
          />
        </StyledHalfSection>
        <StyledHalfSection>
          <StyledInputLabel>Coffee Grinder</StyledInputLabel>
          <DropDownComponent
            value={{
              value: temp.coffeeGrinder,
              label: coffeeGrinderLabel,
            }}
            name="Coffee Grinder"
            error={'Coffee Grinder' in props.errors}
            placeholder="Select Coffee Grinder"
            onSelect={(item) => handleOptionChange('grinder', item.value)}
            options={listMapper(props.allCoffeeGrinders)}
            onFocus={props.toggleFocused}
            onBlur={props.toggleFocused}
            creatable
          />
        </StyledHalfSection>
      </StyledSection>

      <StyledSection>
        <HalfContent>
          <StyledInputLabel>Coffee Beans</StyledInputLabel>
          <DropDownComponent
            value={{
              value: temp.coffeeBeans,
              label: coffeeBeansLabel,
            }}
            name="Coffee Beans"
            error={'Coffee Beans' in props.errors}
            placeholder="Select Coffee Beans"
            onSelect={(item) => handleOptionChange('bean', item.value)}
            options={listMapper(props.allCoffeeBeans)}
            onFocus={props.toggleFocused}
            onBlur={props.toggleFocused}
            creatable
          />
        </HalfContent>
      </StyledSection>
      <StyledSection>
        <StyledGridSection>
          <CoffeeOptionsRenderer />
        </StyledGridSection>
      </StyledSection>
    </>
  );
};

const CoffeeSetupSelector = createSelector(
  selectProfileObject,
  selectProfileErrors,
  (profile, errors) => ({
    errors,
    coffeeMachine: profile.coffeeMachine,
    coffeeGrinder: profile.coffeeGrinder,
    coffeeBeans: profile.coffeeBeans,
    allCoffeeOptions: profile.allCoffeeOptions,
    selectedCoffeeOptions: profile.selectedCoffeeOptions,
    allCoffeeBeans: profile.allCoffeeBeans,
    allCoffeeMachines: profile.allCoffeeMachines,
    allCoffeeGrinders: profile.allCoffeeGrinders,
  })
);
const mapStateToProps = CoffeeSetupSelector;
const mapDispatchToProps = {
  fetchCoffeeOptions: profileActions.fetchCoffeeOptionsPending,
  fetchCoffeeGrinders: profileActions.fetchCoffeeGrindersPending,
  fetchCoffeeMachines: profileActions.fetchCoffeeMachinesPending,
  fetchCoffeeBeans: profileActions.fetchCoffeeBeansPending,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoffeeSetupComponent);
// export default CoffeeSetupComponent;

export const StyledGridSection = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  justify-content: space-between;
`;
