import { createAction } from 'redux-actions';

// SIGNUP
const fetchSignupPending = createAction(
  'auth--fetchSignupPending',
  (inputs, action = 'signup') => ({
    inputs,
    action,
  })
);

const fetchSignupSuccess = createAction(
  'auth--fetchSignupSuccess',
  (user = {}, step = null) => ({
    user,
    step,
  })
);

const fetchSignupFailed = createAction(
  'auth--fetchSignupFailed',
  (errors = {}, message = '') => ({ errors, message })
);

// SIGNIN
const fetchSigninPending = createAction(
  'auth--fetchSigninPending',
  (inputs, action = 'signin') => ({
    inputs,
    action,
  })
);

const fetchSigninSuccess = createAction(
  'auth--fetchSigninSuccess',
  (user = {}, step = null) => ({
    user,
    step,
  })
);
const fetchSigninFailed = createAction(
  'auth--fetchSigninFailed',
  (errors = {}, message = '') => ({ errors, message })
);

// GOOGLE SIGNIN
const fetchSigninGooglePending = createAction('auth--fetchSigninGooglePending');
const fetchSigninGoogleSuccess = createAction(
  'auth--fetchSigninGoogleSuccess',
  (user) => ({
    user,
  })
);
const fetchSigninGoogleFailed = createAction(
  'auth--fetchSigninGoogleSuccess',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

const setAuthInfoFromSocialLogin = createAction(
  'auth--setAuthInfoFromSocialLogin',
  (token, user, step) => ({
    token,
    user,
    step,
  })
);

// FACEBOOK SIGNIN
const fetchSigninFacebookPending = createAction(
  'auth--fetchSigninFacebookPending'
);
const fetchSigninFacebookSuccess = createAction(
  'auth--fetchSigninFacebookSuccess',
  (user) => ({
    user,
  })
);
const fetchSigninFacebookFailed = createAction(
  'auth--fetchSigninFacebookSuccess',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

// FORGOT PASSWORD
const fetchForgotPasswordPending = createAction(
  'auth--fetchForgotPasswordPending'
);
const fetchForgotPasswordSuccess = createAction(
  'auth--fetchForgotPasswordSuccess'
);
const fetchForgotPasswordFailed = createAction(
  'auth--fetchForgotPasswordFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);
// RESET PASSWORD
const fetchResetPasswordPending = createAction(
  'auth--fetchResetPasswordPending'
);
const fetchResetPasswordSuccess = createAction(
  'auth--fetchResetPasswordSuccess',
  (successMessage = '') => ({
    successMessage,
  })
);
const fetchResetPasswordFailed = createAction(
  'auth--fetchResetPasswordFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);
// CLEAR

const rememberMe = createAction('auth--rememberMe', (rememberMe) => ({
  rememberMe,
}));
const logout = createAction('auth--logout');
const clearAuthState = createAction('auth--clearAuthState');

const authActions = {
  fetchSigninPending,
  fetchSigninSuccess,
  fetchSigninFailed,

  fetchSignupPending,
  fetchSignupSuccess,
  fetchSignupFailed,

  fetchSigninGooglePending,
  fetchSigninGoogleSuccess,
  fetchSigninGoogleFailed,

  fetchSigninFacebookPending,
  fetchSigninFacebookSuccess,
  fetchSigninFacebookFailed,

  fetchForgotPasswordPending,
  fetchForgotPasswordSuccess,
  fetchForgotPasswordFailed,

  fetchResetPasswordPending,
  fetchResetPasswordSuccess,
  fetchResetPasswordFailed,

  rememberMe,
  logout,
  clearAuthState,
  setAuthInfoFromSocialLogin,
};
export default authActions;
