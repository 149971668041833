import React from 'react';
import ReactDOM from 'react-dom';
import DropNCrop from '@synapsestudios/react-drop-n-crop';

import { PaperComponent, Row } from '../MainComponents/MainComponents';
import { BackDrop, StyledButton, Wrapper } from './styles';

const initialCropState = {
  result: null,
  filename: null,
  filetype: null,
  src: null,
  error: null,
};

const PICKER_ROOT = document.getElementById('image-picker-root');
const initialState = {
  open: false,
  ...initialCropState,
};
class DropNCropComponent extends React.Component {
  static defaultProps = {
    image: '',
    aspectRatio: 1 / 1,
    onClose: () => {},
    onSelect: () => {},
  };

  state = {
    ...initialState,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.image && !state.src && !state.open) {
      return {
        ...state,
        src: props.image,
      };
    }
    return state;
  }

  onChange = (value) => {
    this.setState(value);
  };

  onSelect = () => {
    this.props.onSelect(this.state.result);
    this.close();
  };

  close = () => {
    this.setState({ open: false, ...initialState });
    this.props.onClose();
  };

  open = () => {
    if (this.state.src) {
      this.setState({ ...initialCropState });
    }
    this.setState({ open: true });
  };

  render() {
    if (!this.state.open) return null;
    return ReactDOM.createPortal(
      <Wrapper>
        <BackDrop
          onClick={(e) => {
            e.preventDefault();
            this.close();
          }}
        />
        <PaperComponent
          style={{ borderRadius: 4, zIndex: 2, maxWidth: '100%' }}
        >
          <DropNCrop
            cropperOptions={{
              aspectRatio: this.props.aspectRatio,
            }}
            onChange={this.onChange}
            value={this.state}
            maxFileSize={this.props.maxFileSize}
          />
          <Row style={{ justifyContent: 'flex-end', marginTop: 25 }}>
            <StyledButton
              prymary
              disabled={!this.state.result}
              onClick={(e) => {
                e.preventDefault();
                this.onSelect();
              }}
            >
              Done
            </StyledButton>
          </Row>
        </PaperComponent>
      </Wrapper>,
      PICKER_ROOT
    );
  }
}

export default DropNCropComponent;
