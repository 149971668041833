import styled, { keyframes } from 'styled-components';
import { PaperComponent } from '../../components/MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';

export const AvatarContainerWrapper = styled.div`
  cursor: pointer;
  position: relative;
  z-index: 10;
`;
const open = keyframes`
	from {
		transform: scale(0.3);
		opacity: 0;
	}

	to {
		transform: scale(1);
		opacity: 1;
	}
`;

const hide = keyframes`
  from  {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(0);
    opacity: 0;
  }
`;
export const AvatarDropDown = styled(PaperComponent)`
  position: absolute;
  top: 100%;
  padding: 0;
  right: 0;
  width: 200px;
  margin-top: ${mainTheme.offset.xsmall}px;
  animation-duration: ${mainTheme.animation.fast}ms;
  animation-name: ${(props) => (props.active ? open : hide)};
  animation-fill-mode: forwards;
  overflow: hidden;
  /* transform-origin: 100% 0; */
  & > div {
    border-bottom: 1px solid rgba(90, 90, 90, 0.2);
  }
  & > div:last-child {
    border-bottom: none;
  }
`;

export const AvatarDropDownItem = styled.div`
  width: 100%;
  padding: ${mainTheme.offset.xsmall}px ${mainTheme.offset.small}px;
  color: ${mainTheme.colors.primary};
  font-family: 'Avenir Medium';
  font-weight: 600;
  transition-duration: ${mainTheme.animation.fast}ms;
  transition-property: background-color;
  letter-spacing: 1.2px;
  font-size: 13px;
  &:hover {
    background-color: rgba(90, 90, 90, 0.1);
  }
`;
