import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const BubbleButton = styled.button`
  position: absolute;
  top: -5px;
  right: -2.5px;
  padding: 5px;
  background-color: transparent;
  border-color: transparent;
  outline: none;
  cursor: pointer;
  z-index: 1;
  opacity: 0.66;

  & > img {
    width: 28px;
  }
`;
