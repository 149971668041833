import React, { useCallback, useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  FieldLabel,
  InputMain,
  Column,
  HalfContentWrapper,
  HalfContent,
  ColumnOffsetBottom,
  Row,
} from '../MainComponents/MainComponents';
import profileActions from '../../redux/profile/profile.actions';
import CheckOptionComponent from '../CheckOptionComponent/CheckOptionComponent';
import {
  selectProfileObject,
  selectProfileErrors,
} from '../../redux/profile/profile.selectors';
import DropDownComponent from '../DropDownComponent/DropDownComponent';

const CoffeeSetupComponent = (props) => {
  const [coffeeMachine, setCoffeeMachine] = useState('');
  const [coffeeGrinder, setCoffeeGrinder] = useState('');
  const [coffeeBeans, setCoffeeBeans] = useState('');
  const [coffeeMachineLabel, setCoffeeMachineLabel] = useState('');
  const [coffeeGrinderLabel, setCoffeeGrinderLabel] = useState('');
  const [coffeeBeansLabel, setCoffeeBeansLabel] = useState('');
  const [coffeeOptions, setCoffeeOptions] = useState([]);
  const [machinePreRefreshLabel, setMachinePreRefreshLabel] = useState('');
  const [grinderPreRefreshLabel, setGrinderPreRefreshLabel] = useState('');
  const [beansPreRefreshLabel, setBeansPreRefreshLabel] = useState('');
  const [selectedCoffeeOptions, setSelectedCoffeeOptions] = useState([]);

  const fetchCoffeeData = () => {
    props.fetchCoffeeOptions();
    props.fetchCoffeeGrinders();
    props.fetchCoffeeMachines();
    props.fetchCoffeeBeans();
  };

  const listMapper = (array) => {
    const newArray = array.map((item) => ({
      value: item._id,
      label: item.name,
    }));
    return newArray;
  };

  const labelGetter = (field, id) => {
    let label = '';
    let found = false;
    switch (field) {
      case 'grinder':
        found = props.allCoffeeGrinders.find((entry) => entry._id === id);
        if (found) {
          label = props.allCoffeeGrinders.find(
            (entry) => entry._id === id
          ).name;
          setGrinderPreRefreshLabel(label);
        } else {
          label = grinderPreRefreshLabel;
        }

        break;
      case 'machine':
        found = props.allCoffeeMachines.find((entry) => entry._id === id);
        if (found) {
          label = props.allCoffeeMachines.find(
            (entry) => entry._id === id
          ).name;
          setMachinePreRefreshLabel(label);
        } else {
          label = machinePreRefreshLabel;
        }
        break;
      case 'bean':
        found = props.allCoffeeBeans.find((entry) => entry._id === id);
        if (found) {
          label = props.allCoffeeBeans.find((entry) => entry._id === id).name;
          setBeansPreRefreshLabel(label);
        } else {
          label = beansPreRefreshLabel;
        }
        break;
    }
    return label;
  };

  const CoffeeOptionsRenderer = useCallback(() => {
    const options = coffeeOptions;
    let optionsList = [];
    if (options && options.length !== 0) {
      optionsList = options.map((option) => (
        <CheckOptionComponent
          handleCheckboxChange={handleCheckboxChange}
          data={option}
        />
      ));
    }
    return optionsList;
  }, [coffeeOptions, selectedCoffeeOptions]);

  const handleCheckboxChange = (id, checked) => {
    const updatedArray = selectedCoffeeOptions;
    if (checked && !selectedCoffeeOptions.includes(id)) {
      updatedArray.push(id);
      setSelectedCoffeeOptions(updatedArray);
    } else if (!checked) {
      while (selectedCoffeeOptions.includes(id)) {
        const index = updatedArray.indexOf(id);
        updatedArray.splice(index, 1);
      }
      setSelectedCoffeeOptions(updatedArray);
    }
    if (props.handleChange) {
      props.handleChange('coffee_options', updatedArray);
    }
  };

  useEffect(() => {
    fetchCoffeeData();
  }, []);

  useEffect(() => {
    if (coffeeOptions.length == 0) {
      setCoffeeOptions(props.allCoffeeOptions);
    }
    setSelectedCoffeeOptions(props.selectedCoffeeOptions);
  }, [props.allCoffeeOptions, props.selectedCoffeeOptions]);

  useEffect(() => {
    if (props.handleChange) {
      props.handleChange('coffeeMachine', coffeeMachine);
    }
  }, [coffeeMachine]);
  useEffect(() => {
    if (props.handleChange) {
      props.handleChange('coffeeGrinder', coffeeGrinder);
    }
  }, [coffeeGrinder]);
  useEffect(() => {
    if (props.handleChange) {
      props.handleChange('coffeeBeans', coffeeBeans);
    }
  }, [coffeeBeans]);
  useEffect(() => {
    if (props.handleChange) {
      props.handleChange('coffee_options', selectedCoffeeOptions);
    }
  }, [selectedCoffeeOptions]);

  useEffect(() => {
    const selected = props.allCoffeeOptions.map((option) => {
      if (selectedCoffeeOptions.includes(option._id)) {
        option = { ...option, checked: true };
      } else {
        option = { ...option, checked: false };
      }
      return option;
    });
    setCoffeeOptions(selected);
  }, [props.allCoffeeOptions, JSON.stringify(selectedCoffeeOptions)]);

  useEffect(() => {
    if (coffeeMachine && coffeeMachine.match(/^[0-9a-fA-F]{24}$/)) {
      setCoffeeMachineLabel(labelGetter('machine', coffeeMachine));
    } else {
      setCoffeeMachineLabel(coffeeMachine);
      setMachinePreRefreshLabel(coffeeMachine);
    }
  }, [coffeeMachine, props.allCoffeeMachines]);
  useEffect(() => {
    if (coffeeGrinder && coffeeGrinder.match(/^[0-9a-fA-F]{24}$/)) {
      setCoffeeGrinderLabel(labelGetter('grinder', coffeeGrinder));
    } else {
      setCoffeeGrinderLabel(coffeeGrinder);
      setGrinderPreRefreshLabel(coffeeGrinder);
    }
  }, [coffeeGrinder, props.allCoffeeGrinders]);
  useEffect(() => {
    if (coffeeBeans && coffeeBeans.match(/^[0-9a-fA-F]{24}$/)) {
      setCoffeeBeansLabel(labelGetter('bean', coffeeBeans));
    } else {
      setCoffeeBeansLabel(coffeeBeans);
      setBeansPreRefreshLabel(coffeeBeans);
    }
  }, [coffeeBeans, props.allCoffeeBeans]);

  useEffect(() => {
    setCoffeeMachine(props.coffeeMachine);
  }, [props.coffeeMachine]);

  useEffect(() => {
    setCoffeeGrinder(props.coffeeGrinder);
  }, [props.coffeeGrinder]);

  useEffect(() => {
    setCoffeeBeans(props.coffeeBeans);
  }, [props.coffeeBeans]);

  const handleOptionChange = (field, value) => {
    switch (field) {
      case 'grinder':
        setCoffeeGrinder(value);
        break;
      case 'machine':
        setCoffeeMachine(value);
        break;
      case 'bean':
        setCoffeeBeans(value);
        break;
    }
  };

  return (
    <ColumnOffsetBottom>
      <Column>
        <HalfContentWrapper>
          <HalfContent>
            <FieldLabel>COFFEE MACHINE</FieldLabel>
            <DropDownComponent
              value={{
                value: coffeeMachine,
                label: coffeeMachineLabel,
              }}
              name="Coffee Machine"
              error={'Coffee Machine' in props.errors}
              placeholder="Select Coffee Machine"
              onSelect={(item) => handleOptionChange('machine', item.value)}
              options={listMapper(props.allCoffeeMachines)}
              onFocus={props.toggleFocused}
              onBlur={props.toggleFocused}
              creatable
            />
          </HalfContent>
          <HalfContent>
            <FieldLabel>COFFEE GRINDER</FieldLabel>
            <DropDownComponent
              value={{
                value: coffeeGrinder,
                label: coffeeGrinderLabel,
              }}
              name="Coffee Grinder"
              error={'Coffee Grinder' in props.errors}
              placeholder="Select Coffee Grinder"
              onSelect={(item) => handleOptionChange('grinder', item.value)}
              options={listMapper(props.allCoffeeGrinders)}
              onFocus={props.toggleFocused}
              onBlur={props.toggleFocused}
              creatable
            />
          </HalfContent>
        </HalfContentWrapper>
      </Column>
      <Column>
        <HalfContentWrapper>
          <HalfContent>
            <FieldLabel>COFFEE BEANS</FieldLabel>
            <DropDownComponent
              value={{
                value: coffeeBeans,
                label: coffeeBeansLabel,
              }}
              name="Coffee Beans"
              error={'Coffee Beans' in props.errors}
              placeholder="Select Coffee Beans"
              onSelect={(item) => handleOptionChange('bean', item.value)}
              options={listMapper(props.allCoffeeBeans)}
              onFocus={props.toggleFocused}
              onBlur={props.toggleFocused}
              creatable
            />
          </HalfContent>
        </HalfContentWrapper>
      </Column>
      <Row wrap>
        <CoffeeOptionsRenderer />
      </Row>
    </ColumnOffsetBottom>
  );
};

const CoffeeSetupSelector = createSelector(
  selectProfileObject,
  selectProfileErrors,
  (profile, errors) => ({
    errors,
    coffeeMachine: profile.coffeeMachine,
    coffeeGrinder: profile.coffeeGrinder,
    coffeeBeans: profile.coffeeBeans,
    allCoffeeOptions: profile.allCoffeeOptions,
    selectedCoffeeOptions: profile.selectedCoffeeOptions,
    allCoffeeBeans: profile.allCoffeeBeans,
    allCoffeeMachines: profile.allCoffeeMachines,
    allCoffeeGrinders: profile.allCoffeeGrinders,
  })
);
const mapStateToProps = CoffeeSetupSelector;
const mapDispatchToProps = {
  fetchCoffeeOptions: profileActions.fetchCoffeeOptionsPending,
  fetchCoffeeGrinders: profileActions.fetchCoffeeGrindersPending,
  fetchCoffeeMachines: profileActions.fetchCoffeeMachinesPending,
  fetchCoffeeBeans: profileActions.fetchCoffeeBeansPending,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoffeeSetupComponent);
