import styled from 'styled-components';
import { newTheme } from '../../../../mainStyles';

export const StyledCloseButton = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;

  @media (max-width: 1207px) {
    top: 15px;
    right: 15px;
  }
`;
export const StyledBackButton = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
  cursor: pointer;

  @media (max-width: 1207px) {
    top: 15px;
    right: 15px;
  }
`;

export const StyledCardContainer = styled.div`
  display: flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 60px;

  background: #ffffff;

  @media (max-width: 1190px) {
    padding: 30px;
  }

  @media (max-width: 850px) {
    flex-direction: column;
    padding: 60px;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const StyledTitleText = styled.div`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #131312;
  margin-bottom: 16px;
`;

export const StyledAddonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 100px;

  @media (max-width: 1190px) {
    margin-right: 60px;
  }

  @media (max-width: 850px) {
    margin-right: 0;
    margin-bottom: 30px;
  }
`;

export const StyledTextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  justify-content: flex-start;
`;

export const StyledLine = styled.div`
  width: 450px;
  background: #221c02;
  height: 4px;
  margin-bottom: 64px;
`;

export const StyledDescriptionText = styled.div`
  font-family: ${newTheme.fontFamily.primary.light};
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  color: #131312;
  margin-bottom: 32px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 40px;
`;

export const StyledAddButton = styled.button`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  padding: 16px 36px;
  border: 0;
  border-radius: 4px;
  z-index: 10;
  cursor: pointer;

  color: #131312;
  background: #f5cc28;

  &:hover {
    background: #fedc54;
  }
`;

export const StyledCancelButton = styled.button`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  padding: 16px 36px;
  border: 0;
  border-radius: 4px;
  margin-right: 16px;
  z-index: 10;
  cursor: pointer;

  color: #131312;
  background: white;
`;
