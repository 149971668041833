import React, { Component } from 'react';
import _ from 'lodash';
import Axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';
import { InvoiceWrapper, Wrapper, DownloadIcon } from './styles';
import { Text } from '../../components/MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';
import { selectUserInvoices } from '../../redux/user/user.selectors';

const VAT = 0.2;

class InvoicesListContainer extends Component {
  downloadInvoice = async (invoice) => {
    if (invoice.path) {
      this.download(invoice.path);
    } else {
      try {
        console.log('invoice', invoice);
        const response = await Axios.post(
          `${process.env.REACT_APP_SERVER_API_URL}user/pdf`,
          { invoice },
          {
            headers: {
              'Access-Control-Allow-Origin': 'https://app-staging.rwrd.co.uk',
              'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
              'Access-Control-Allow-Headers': 'Content-Type',
            },
          }
        );
        console.log('response', response);
        const pdfLink = response.data.path;
        console.log('PDF link', pdfLink);

        this.download(pdfLink);
      } catch (error) {
        console.error('Error generating PDF link:', error);
      }
    }
  };

  download = (path) => {
    window.open(path);
  };

  renderInvoice = (invoice) => (
    <InvoiceWrapper key={invoice.name}>
      <Text color={mainTheme.colors.primary}>
        {moment(invoice.date, 'DD/MM/YYY').format('MMMM YYYY')} - £
        {((invoice.amount * (1 + VAT)) / 100).toFixed(2)}
      </Text>

      {/* <DownloadIcon onClick={() => this.download(invoice.path)} /> */}
      <DownloadIcon onClick={() => this.downloadInvoice(invoice)} />
    </InvoiceWrapper>
  );

  render() {
    const sortedByDate = _.orderBy(
      this.props.list,
      (o) => moment(o.date, 'DD/MM/YYY'),
      ['desc']
    );
    return <Wrapper>{sortedByDate.map(this.renderInvoice)}</Wrapper>;
  }
}

const mapStateToProps = (state) => ({
  list: selectUserInvoices(state),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicesListContainer);
