import React, { Component } from 'react';
import Select, { Creatable } from 'react-select';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HiddenInput from '../HiddenInput/HiddenInput';
import { newTheme } from '../../mainStyles';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: '#fff',
    color: '#333',
    fontFamily: `${newTheme.fontFamily.primary.light};`,
    cursor: 'pointer',
    padding: '9px 20px',
    ':active': {
      backgroundColor: 'none',
    },
    ':hover': {
      backgroundColor: 'none',
    },
  }),
};

const CustomSelect = (props) => (
  <Select styles={customStyles} indicatorSeparator={false} {...props} />
);
const CustomCreatable = (props) => (
  <Creatable styles={customStyles} indicatorSeparator={false} {...props} />
);

export default class DropDownComponent extends Component {
  static propTypes = {
    onSelect: PropTypes.func,
    extractor: PropTypes.string,
  };

  static defaultProps = {
    onSelect: () => {},
    extractor: '_id',
  };

  state = {
    selected: '',
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.selected && props.defaultValue) {
      return {
        ...state,
        selected: props.defaultValue,
      };
    }
    return state;
  }

  onSelect = (option) => {
    this.props.onSelect(option);
    this.setState({ selected: option });
  };

  clear = () => {
    this.setState({ selected: this.props.defaultValue });
  };

  render() {
    const { onSelect, name, ...props } = this.props;
    return (
      <>
        <StyledDropdown
          value={this.state.selected}
          onChange={this.onSelect}
          as={props.creatable ? CustomCreatable : CustomSelect}
          errors={this.props.errorMessage}
          {...props}
        />
        <HiddenInput
          name={this.props.name}
          value={
            this.state.selected ? this.state.selected[this.props.extractor] : ''
          }
          onClear={this.clear}
        />
      </>
    );
  }
}

export const StyledDropdown = styled.div`
  border-radius: 4px;
  font-family: ${newTheme.fontFamily.primary.light};

  &:hover,
  &:active,
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
    color: black;
    background: #fff;
  }
  box-shadow: none;
  padding: 0;
  background: #fff;

  > div {
    cursor: pointer;
    font-family: ${newTheme.fontFamily.primary.light};
    background: #fff;
    background-color: none;
    width: 100%;
    padding: 9px 0px 9px 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    border-radius: 4px;
    color: #959492;
    box-shadow: none;
    border: 1px solid #959492;
    ${({ borderColor }) => borderColor && 'border: #DFDFDE;'}
    border: 1px solid ${({ errors }) => (errors ? `red;` : '#DFDFDE;')};
    /* border: 1px solid ${({ borderColor, errors }) =>
      borderColor && errors ? `red;` : '#DFDFDE;'}; */

    ${({ icon }) =>
      icon && `background: url(${icon}) no-repeat scroll 23px 15px;`}
    ${({ icon, vertical }) =>
      icon &&
      vertical &&
      `background: url(${icon}) no-repeat scroll 23px ${vertical}px;`}

	&:hover {
      border: 1px solid black;
      box-shadow: none;
      background-color: none;
    }
    &:focus {
      border: 1px solid black;
      outline: none;
      box-shadow: none;
      background-color: none;
    }
    &:active {
      border: 1px solid black;
      box-shadow: none;
      background-color: none;
    }
    &:focus-visible {
      border: 1px solid black;
      color: black;
      box-shadow: none;
      background-color: none;
    }

    @media (max-width: 450px) {
      ${({ icon }) =>
        icon && `background: url(${icon}) no-repeat scroll 8px 15px;`}
      padding-left: 34px;
    }
  }
`;
