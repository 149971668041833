import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectProfileObject } from '../../redux/profile/profile.selectors';

import { patterns } from '../../constants/patterns';

import iphone_new from '../../assets/images/phone/iphone_new.png';
import { ReactComponent as FilledCheckStamp } from '../../assets/images/icons/filled-check-stamp.svg';
import { ReactComponent as EmptyCheckStamp } from '../../assets/images/icons/empty-check-stamp.svg';
import { newTheme } from '../../mainStyles';

const MobilePreviewComponent = ({ step }) => {
  const {
    temp,
    allCoffeeBeans,
    allCoffeeGrinders,
    allCoffeeMachines,
    allCoffeeOptions,
    allTopFeatures,
  } = useSelector(selectProfileObject);

  const { openingHours } = temp;
  const getName = (id, list) => {
    return list.filter((el) => el._id === id).map((el) => el.name);
  };
  const getOptions = (items, list) => {
    const result = list
      .filter((el) => items.includes(el._id))
      .map((el) => (
        <StyledTag key={el._id}>
          <img height="20px" src={el.icon} alt="" /> {el.name}
        </StyledTag>
      ));
    return result;
  };
  const getFeatures = (items, list) => {
    const result = list
      .filter((el) => items.includes(el._id))
      .map((el) => (
        <StyledListItem key={el._id}>
          <img height="20px" src={el.icon} alt="" /> {el.name}
        </StyledListItem>
      ));
    return result;
  };

  const stamps = [];

  for (let i = 2; i < temp.stamp.total; i++) {
    stamps.push(
      <StyledStamp stampsNumber={temp.stamp.total}>
        <StyledCheckboxContainer>
          <EmptyCheckStamp />
        </StyledCheckboxContainer>
      </StyledStamp>
    );
  }

  const patternImage = temp.stamp.pattern
    ? patterns.filter((el) => el.id === temp.stamp.pattern)
    : null;

  return (
    <StyledMobilePhoneBody step={step}>
      <StyledIphoneMask mask={iphone_new} />
      {step === 1 && (
        <>
          <StyledPhoneBgImage bgImage={temp.backgroundImage}>
            <StyledPhoneLogoImage bgImage={temp.logo} bottom={-29} left={31} />
          </StyledPhoneBgImage>
          <StyledPhoneSection
            businessName={temp.name}
            paddingTop={45}
            paddingBot={10}
            paddingLeft={28}
            paddingRight={28}
          >
            {temp.name || 'COFFEE SHOP NAME'}
          </StyledPhoneSection>
          <StyledPhoneSection
            withBorder
            height="100%"
            maxHeight="55px"
            paddingBot={25}
            paddingLeft={28}
            paddingRight={28}
          >
            <StyledPhoneDescription>
              {temp.description && temp.description}
            </StyledPhoneDescription>
          </StyledPhoneSection>
          <StyledPhoneSection
            withBorder
            paddingTop={22}
            paddingBot={10}
            paddingLeft={28}
            paddingRight={28}
            height="100%"
            maxHeight="100px"
          >
            ADDRESS
            <StyledPhoneDescription>
              {temp.address && `${temp.address}, `}
              {temp.city && `${temp.city}, `}
              {temp.postcode && `${temp.postcode}, `}
              {temp.state && `${temp.state}, `}
              {temp.country && `${temp.country}`}
            </StyledPhoneDescription>
          </StyledPhoneSection>
          <StyledPhoneSection
            withBorder
            flexRow
            paddingTop={22}
            paddingBot={10}
            paddingLeft={28}
            paddingRight={28}
            height="100%"
            maxHeight="83px"
          >
            <StyledPhoneSection style={{ width: '100%' }}>
              COFFEE MACHINE
              <StyledPhoneDescription>
                {temp.coffeeMachine &&
                  getName(temp.coffeeMachine, allCoffeeMachines)}
              </StyledPhoneDescription>
            </StyledPhoneSection>
            <StyledPhoneSection style={{ width: '100%' }}>
              COFFEE GRINDER
              {temp.coffeeGrinder && (
                <StyledPhoneDescription flexRow>
                  {getName(temp.coffeeGrinder, allCoffeeGrinders)}
                </StyledPhoneDescription>
              )}
            </StyledPhoneSection>
          </StyledPhoneSection>
          <StyledPhoneSection
            withBorder
            paddingTop={22}
            paddingBot={10}
            paddingLeft={28}
            paddingRight={28}
            height="100%"
            maxHeight="83px"
          >
            COFFEE BEANS
            <StyledPhoneDescription>
              {temp.coffeeBeans && getName(temp.coffeeBeans, allCoffeeBeans)}
            </StyledPhoneDescription>
          </StyledPhoneSection>
          <StyledPhoneSection
            withBorder
            paddingTop={10}
            paddingBot={10}
            paddingLeft={28}
            paddingRight={0}
            minHeight="65px"
          >
            <StyledTagsContainer>
              {temp.coffee_options.length > 0 ? (
                getOptions(temp.coffee_options, allCoffeeOptions)
              ) : (
                <>
                  <StyledTag empty />
                  <StyledTag empty />
                </>
              )}
            </StyledTagsContainer>
          </StyledPhoneSection>
          <StyledPhoneSection
            paddingTop={22}
            paddingBot={10}
            paddingLeft={28}
            paddingRight={28}
            height="100%"
            maxHeight="83px"
          >
            TOP FEATURES
          </StyledPhoneSection>
        </>
      )}
      {step === 2 && (
        <>
          <StyledPhoneSection
            withBorder
            paddingTop={25}
            paddingBot={10}
            paddingLeft={28}
            paddingRight={28}
            height="100%"
            maxHeight="80px"
          >
            COFFEE BEANS
            <StyledPhoneDescription>
              {temp.coffeeBeans && getName(temp.coffeeBeans, allCoffeeBeans)}
            </StyledPhoneDescription>
          </StyledPhoneSection>
          <StyledPhoneSection
            withBorder
            paddingTop={22}
            paddingBot={22}
            paddingLeft={28}
            paddingRight={0}
            minHeight="89px"
          >
            <StyledTagsContainer>
              {temp.coffee_options.length > 0 ? (
                getOptions(temp.coffee_options, allCoffeeOptions)
              ) : (
                <>
                  <StyledTag empty />
                  <StyledTag empty />
                </>
              )}
            </StyledTagsContainer>
          </StyledPhoneSection>
          <StyledPhoneSection
            features
            withBorder
            paddingTop={22}
            paddingBot={10}
            paddingLeft={28}
            paddingRight={28}
            height="100%"
            maxHeight="155px"
          >
            TOP FEATURES
            <StyledListContainer>
              {temp.top_features.length > 0
                ? getFeatures(temp.top_features, allTopFeatures)
                : null}
            </StyledListContainer>
          </StyledPhoneSection>
          <StyledPhoneSection
            highlights
            withBorder
            paddingTop={22}
            paddingBot={22}
            paddingLeft={28}
            paddingRight={28}
          >
            HIGHLIGHTS
            <StyledHighLitsContainer>
              <StyledHighlight bgImage={temp.highlights[0]} />
              <StyledHighlight bgImage={temp.highlights[1]} />
              <StyledHighlight bgImage={temp.highlights[2]} />
            </StyledHighLitsContainer>
          </StyledPhoneSection>
          <StyledPhoneSection
            openingTimes
            paddingTop={22}
            paddingBot={10}
            paddingLeft={28}
            paddingRight={28}
          >
            OPENING TIMES
            <div>
              <StyledDayContainer>
                <StyledDay>Monday</StyledDay>
                {openingHours.monOperational && (
                  <StyledTime>
                    {openingHours.monOpen[0]}
                    {openingHours.monOpen[1]}:{openingHours.monOpen[2]}
                    {openingHours.monOpen[3]} - {openingHours.monClose[0]}
                    {openingHours.monClose[1]}:{openingHours.monClose[2]}
                    {openingHours.monClose[3]}
                  </StyledTime>
                )}
              </StyledDayContainer>
              <StyledDayContainer>
                <StyledDay>Tuesday</StyledDay>
                {openingHours.tueOperational && (
                  <StyledTime>
                    {openingHours.tueOpen[0]}
                    {openingHours.tueOpen[1]}:{openingHours.tueOpen[2]}
                    {openingHours.tueOpen[3]} - {openingHours.tueClose[0]}
                    {openingHours.tueClose[1]}:{openingHours.tueClose[2]}
                    {openingHours.tueClose[3]}
                  </StyledTime>
                )}
              </StyledDayContainer>
              <StyledDayContainer>
                <StyledDay>Wednesday</StyledDay>
                {openingHours.wedOperational && (
                  <StyledTime>
                    {openingHours.wedOpen[0]}
                    {openingHours.wedOpen[1]}:{openingHours.wedOpen[2]}
                    {openingHours.wedOpen[3]} - {openingHours.wedClose[0]}
                    {openingHours.wedClose[1]}:{openingHours.wedClose[2]}
                    {openingHours.wedClose[3]}
                  </StyledTime>
                )}
              </StyledDayContainer>
              <StyledDayContainer>
                <StyledDay>Thursday</StyledDay>
                {openingHours.thuOperational && (
                  <StyledTime>
                    {openingHours.thuOpen[0]}
                    {openingHours.thuOpen[1]}:{openingHours.thuOpen[2]}
                    {openingHours.thuOpen[3]} - {openingHours.thuClose[0]}
                    {openingHours.thuClose[1]}:{openingHours.thuClose[2]}
                    {openingHours.thuClose[3]}
                  </StyledTime>
                )}
              </StyledDayContainer>
              <StyledDayContainer>
                <StyledDay>Friday</StyledDay>
                {openingHours.friOperational && (
                  <StyledTime>
                    {openingHours.friOpen[0]}
                    {openingHours.friOpen[1]}:{openingHours.friOpen[2]}
                    {openingHours.friOpen[3]} - {openingHours.friClose[0]}
                    {openingHours.friClose[1]}:{openingHours.friClose[2]}
                    {openingHours.friClose[3]}
                  </StyledTime>
                )}
              </StyledDayContainer>
              <StyledDayContainer>
                <StyledDay>Saturday</StyledDay>
                {openingHours.satOperational && (
                  <StyledTime>
                    {openingHours.satOpen[0]}
                    {openingHours.satOpen[1]}:{openingHours.satOpen[2]}
                    {openingHours.satOpen[3]} - {openingHours.satClose[0]}
                    {openingHours.satClose[1]}:{openingHours.satClose[2]}
                    {openingHours.satClose[3]}
                  </StyledTime>
                )}
              </StyledDayContainer>
              <StyledDayContainer>
                <StyledDay>Sunday</StyledDay>
                {openingHours.sunOperational && (
                  <StyledTime>
                    {openingHours.sunOpen[0]}
                    {openingHours.sunOpen[1]}:{openingHours.sunOpen[2]}
                    {openingHours.sunOpen[3]} - {openingHours.sunClose[0]}
                    {openingHours.sunClose[1]}:{openingHours.sunClose[2]}
                    {openingHours.sunClose[3]}
                  </StyledTime>
                )}
              </StyledDayContainer>
            </div>
          </StyledPhoneSection>
        </>
      )}
      {step === 3 && (
        <StyledStampsWrapper
          bgColor={temp.stamp.backgroundColor}
          bgImage={patternImage && patternImage[0].name}
        >
          <StyledPhoneStampLogoImage bgImage={temp.logo} />

          <StyledPhoneStampName textColor={temp.stamp.textColor}>
            {temp.name}
          </StyledPhoneStampName>
          <StyledPhoneStampAddress textColor={temp.stamp.textColor}>
            {temp.address && `${temp.address}, `}
            {temp.city && `${temp.city}, `}
            {temp.postcode && `${temp.postcode}, `}
            {temp.state && `${temp.state}, `}
            {temp.country && `${temp.country}`}
          </StyledPhoneStampAddress>
          <StyledStampsContainer stampsNumber={temp.stamp.total}>
            <StyledStamp
              stampsNumber={temp.stamp.total}
              bgColor={temp.stamp.color}
            >
              <StyledCheckboxContainer textColor={temp.stamp.textColor}>
                <StyledCheckbox2 textColor={temp.stamp.textColor} />
              </StyledCheckboxContainer>
            </StyledStamp>
            <StyledStamp
              stampsNumber={temp.stamp.total}
              bgColor={temp.stamp.color}
            >
              <StyledCheckboxContainer textColor={temp.stamp.textColor}>
                <StyledCheckbox2 textColor={temp.stamp.textColor} />
              </StyledCheckboxContainer>
            </StyledStamp>
            {stamps}
          </StyledStampsContainer>
        </StyledStampsWrapper>
      )}
    </StyledMobilePhoneBody>
  );
};

export default MobilePreviewComponent;

export const StyledCheckbox2 = styled(FilledCheckStamp)`
  fill: ${({ textColor }) => textColor && textColor};
  width: 20px;
  height: 20px;
`;
export const StyledCheckbox = styled.img`
  fill: #fff;
  width: 20px;
  height: 20px;
`;

export const StyledCheckboxContainer = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
`;

export const StyledStampsContainer = styled.div`
  width: 100%;
  ${({ stampsNumber }) =>
    stampsNumber < 6 &&
    `display: flex; 
		flex-direction: column; 
		align-items: center; 
		justify-content: center;`}

  ${({ stampsNumber }) =>
    stampsNumber > 5 &&
    `display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-content: center;
		flex-wrap: wrap;`} 
		height: 300px;

  @media (max-width: 925px) {
    height: 200px;
  }
`;
export const StyledStamp = styled.div`
  padding: 13px;
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${({ bgColor }) => (bgColor ? `${bgColor}` : '#cfcfce')};
  margin-bottom: 15px;

  ${({ bgColor }) => bgColor && `background: ${bgColor}`};
  ${({ bgColor }) => bgColor && `background: ${bgColor}`};

  @media (max-width: 925px) {
    padding: 3px;
    margin-bottom: 8px;
  }
`;

export const StyledPhoneStampName = styled.div`
  font-family: 'Outfit Medium';
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 8px;
  color: ${({ textColor }) => (textColor ? `${textColor}` : '#131312')};
`;

export const StyledPhoneStampLogoImage = styled.div`
  position: absolute;
  top: -27px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : 'gray')};
  background-size: cover;
  background-repeat: no-repeat;
  border: 4px solid white;
  /* margin-top: -26px;
	margin-bottom: 20px; */
`;

export const StyledStampsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 85%;
  padding: 0 15px;

  background: ${({ bgColor }) => (bgColor ? `${bgColor}` : '#fff')};
  background-image: ${({ bgImage }) => bgImage && `url(${bgImage})`};
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;

  height: 55%;

  height: 58%;
  padding-top: 25px;
`;

export const StyledPhoneStampAddress = styled.div`
  font-family: ${newTheme.fontFamily.secondary2.light};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 45px;

  color: ${({ textColor }) => (textColor ? `${textColor}` : '#959492')};

  @media (max-width: 925px) {
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 10px;
  }
`;

export const StyledPhoneStampLogo = styled.div``;

export const StyledDayContainer = styled.div`
  display: flex;
`;

export const StyledDay = styled.div`
  padding: 5px 0;
  color: #5d5d5b;
  width: 50%;
  text-align: left;

  font-family: ${newTheme.fontFamily.secondary2.light};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  color: #5d5d5b;

  @media (max-width: 925px) {
    font-size: 11px;
    line-height: 13px;
    padding-bottom: 0;
  }
`;
export const StyledTime = styled.div`
  padding: 5px 0;
  color: #5d5d5b;
  width: 50%;
  text-align: left;

  font-family: ${newTheme.fontFamily.secondary2.light};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  color: #5d5d5b;

  @media (max-width: 925px) {
    font-size: 11px;
    line-height: 13px;
    padding-bottom: 0;
  }
`;
export const StyledHighlight = styled.div`
  width: 95px;
  height: 95px;
  background: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : '#d9d9d9')};
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 925px) {
    width: 70px;
    height: 70px;
  }
`;

export const StyledHighLitsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

export const StyledListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #5d5d5b;
  word-wrap: break-word;
  word-break: break-word;

  font-family: ${newTheme.fontFamily.secondary2.light};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;

  padding: 5px 0;

  img {
    margin-right: 10px;
  }

  @media (max-width: 925px) {
    font-size: 11px;
    line-height: 15px;
    padding-bottom: 0;

    img {
      height: 15px;
      margin-right: 5px;
    }
  }
`;

export const StyledListContainer = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  justify-content: space-between;
  overflow: hidden;
`;

export const StyledPhoneSection = styled.div`
  font-family: 'Outfit Medium';
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0.08em;
  text-align: left;
  color: #959492;
  word-break: break-all;
  overflow: hidden;

  ${({ flexRow }) =>
    flexRow &&
    `display: flex;
	flex-direction: row;`};

  padding-top: ${({ paddingTop }) => paddingTop && `${paddingTop}px`};
  padding-bottom: ${({ paddingBot }) => paddingBot && `${paddingBot}px`};
  padding-left: ${({ paddingLeft }) => paddingLeft && `${paddingLeft}px`};
  padding-right: ${({ paddingRight }) => paddingRight && `${paddingRight}px`};

  height: ${({ height }) => height && `${height}`};
  max-height: ${({ maxHeight }) => maxHeight && `${maxHeight}`};
  min-height: ${({ minHeight }) => minHeight && `${minHeight}`};

  ${({ businessName }) => businessName && `font-size: 16px; color: #000;`};
  ${({ withBorder }) => withBorder && `border-bottom: 4px solid #efefef;`};

  @media (max-width: 925px) {
    font-size: 9px;
    line-height: 11px;
    max-height: 65px;

    padding-top: ${({ paddingTop }) => paddingTop && `${paddingTop / 2}px`};
    padding-bottom: ${({ paddingBot }) => paddingBot && `${paddingBot / 2}px`};
    padding-left: ${({ paddingLeft }) => paddingLeft && `${paddingLeft / 2}px`};
    padding-right: ${({ paddingRight }) =>
      paddingRight && `${paddingRight / 2}px`};

    ${({ businessName }) => businessName && `font-size: 13px; color: #000;`};

    min-height: ${({ minHeight }) => minHeight && `40px`};
    ${({ features }) => features && `max-height: 105px;`}
    ${({ highlights }) => highlights && `min-height: 120px;`}
		${({ openingTimes }) => openingTimes && `max-height: none;`}
  }
`;

export const StyledTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #221c02;
  white-space: nowrap;

  margin-right: 10px;
  font-family: ${newTheme.fontFamily.secondary2.light};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;

  padding: 10px 15px;
  background: #fdf2c9;
  border-radius: 40px;

  ${({ empty }) => empty && `width: 130px; max-heigth: 40px; height: 38px;`}

  @media (max-width: 925px) {
    align-items: self-start;
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    padding: 5px 10px;

    img {
      height: 13px;
    }
  }
`;

export const StyledTagsContainer = styled.div`
  display: flex;
  overflow: hidden;
`;

export const StyledPhoneDescription = styled.div`
  padding-top: 8px;
  font-family: ${newTheme.fontFamily.secondary2.light};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #5d5d5b;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: 925px) {
    font-size: 11px;
    line-height: 13px;
  }
`;

export const StyledPhoneLogoImage = styled.div`
  position: absolute;
  ${({ bottom }) => bottom && `bottom: ${bottom}px`};
  ${({ left }) => left && `bottom: ${left}px`};
  bottom: -29px;
  left: 31px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : 'gray')};
  background-size: cover;
  background-repeat: no-repeat;
  border: 4px solid white;

  @media (max-width: 925px) {
    bottom: -22px;
    width: 45px;
    height: 45px;
    left: 20px;
  }
`;

export const StyledPhoneBgImage = styled.div`
  position: relative;
  /* width: 100%; */
  /* height: 150px; */
  background: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : 'transparent')};
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 40px 40px 0 0;

  margin: 3px 10px 0 10px;
  height: 237px;

  @media (max-width: 925px) {
    margin: 0px 3px 0 3px;
    height: 168px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 25px 25px 0 0;
  }
`;

export const StyledMobilePhoneBody = styled.div`
  position: relative;
  height: 780px;
  width: 390px;
  padding: 15px;
  border-radius: 60px;
  background: ${({ step }) => (step === 3 ? '#221c02' : '#fff')};

  ${({ step }) =>
    step === 3 &&
    `display: flex;
	align-items: center;
	justify-content: center;`};

  @media (max-width: 925px) {
    height: 553px;
    width: 277px;
  }
`;

export const StyledIphoneMask = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: ${({ mask }) => (mask ? `url(${iphone_new});` : 'none')};
  background-size: cover;
  z-index: 2;
`;
