import React from 'react';
import {
  ProfileViewBlock,
  ProfileViewComponentWrapper,
  ProfileViewOffset,
  ProfileViewDescription,
  ProfileViewMobile,
  ProfileViewContent,
  ProfilePostsWrapper,
  ProfilePostWrapper,
  ProfileScrollWrapper,
} from './styles';
import ShortProfileComponent from '../ShortProfileComponent/ShortProfileComponent';
import PostComponent from '../PostComponent/PostComponent';
import { FieldLabel } from '../MainComponents/MainComponents';
import { postInitialState } from '../../redux/post/post.reduces';

export default class ProfileViewComponent extends React.PureComponent {
  static defaultProps = {
    posts: [],
  };

  renderPosts = (posts) => {
    const filteredPosts = [];
    posts.forEach((post) => {
      if (!post.type) {
        filteredPosts.push(post);
      }
    });
    if (filteredPosts.length == 0) {
      return (
        <PostComponent
          backdrop={false}
          address={this.props.address}
          name={this.props.name}
          title="Lorem ipsum"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do "
          logo={this.props.image}
        />
      );
    }
    return filteredPosts.map(this.renderPost);
  };

  renderPost = (post) => {
    return (
      <ProfilePostWrapper>
        <PostComponent
          onPostClick={this.onPostClick}
          onPostDelete={this.onPostDelete}
          key={post._id}
          _id={post._id}
          address={post.profile ? post.profile.address : ''}
          logo={post.profile ? post.profile.logo : post.logo}
          name={post.profile ? post.profile.name : post.name}
          title={post.title}
          description={post.description}
          image={
            post.image
              ? post.image
              : post.profile
              ? post.profile.backgroundImage
              : ''
          }
        />
      </ProfilePostWrapper>
    );
  };

  render() {
    return (
      <ProfileViewMobile>
        <ProfileViewComponentWrapper>
          <ProfileScrollWrapper>
            <ProfileViewContent>
              <ProfileViewBlock image={this.props.backgroundImage}>
                {/* <ProfileViewOffset> */}
                <ShortProfileComponent
                  rate={4}
                  title={this.props.name}
                  subtitle={this.props.address}
                  image={this.props.image}
                />
                {/* </ProfileViewOffset> */}
              </ProfileViewBlock>
              <ProfileViewOffset>
                <ProfileViewDescription>
                  {this.props.description}
                </ProfileViewDescription>
              </ProfileViewOffset>
              <ProfileViewOffset>
                <FieldLabel style={{ margin: 0 }}>Latest posts</FieldLabel>

                <ProfilePostsWrapper>
                  {this.props.posts.length != 0 ? (
                    this.renderPosts(this.props.posts)
                  ) : (
                    <PostComponent
                      backdrop={false}
                      address={this.props.address}
                      name={this.props.name}
                      title="Lorem ipsum"
                      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do "
                      logo={this.props.image}
                    />
                  )}
                </ProfilePostsWrapper>
              </ProfileViewOffset>
            </ProfileViewContent>
          </ProfileScrollWrapper>
        </ProfileViewComponentWrapper>
      </ProfileViewMobile>
    );
  }
}
