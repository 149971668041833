import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { selectUserLogin } from '../../redux/user/user.selectors';
import storage from '../../utils/storage';
import userActions from '../../redux/user/user.actions';
import { saveUser } from '../../redux/auth/auth.saga';
import client from '../../utils/client';
import appActions from '../../redux/app/app.actions';

class PrivateRouteContainer extends Component {
  render() {
    const { isLogin, component: Component, location, ...props } = this.props;
    let step = storage.storageGet('step');
    if (step == location.pathname) {
      storage.storageClear('step');
      step = storage.storageGet('step');
    }
    return (
      <Route
        {...props}
        render={(componentProps) =>
          isLogin ? (
            step ? (
              <Redirect
                to={{
                  pathname: step,
                  state: { from: props.location },
                }}
              />
            ) : (
              <Component {...componentProps} />
            )
          ) : (
            <Redirect
              to={{
                pathname: '/auth/signin',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLogin: selectUserLogin,
});

const mapDispatchToProps = {
  init: appActions.init,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRouteContainer);
