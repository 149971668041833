import styled from 'styled-components';
import { newTheme } from '../../mainStyles';

export const StyledImage = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 16px;

  border: 1px solid ${({ checked }) => (checked ? '#F5CC28;' : ' #efefef;')};
  background: ${({ checked }) => (checked ? '#FDF2C9;' : ' #efefef;')};
`;

export const StyledItemLabel = styled.label`
  font-family: ${newTheme.fontFamily.primary.light};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  cursor: pointer;
`;

export const StyledHiddenCheckbox = styled.input`
  opacity: 0;
  position: absolute;
  left: -9999px;
`;
