import { fork, put, takeLatest } from 'redux-saga/effects';
import client, { handleRequestError } from '../../utils/client';
import postsActions from './posts.actions';

function* fetchPosts(action) {
  try {
    const response = yield client.get('posts/my');

    yield put(postsActions.fetchSuccess(response.data.posts));
  } catch (error) {
    yield handleRequestError(
      error,
      postsActions.fetchFailed,
      fetchPosts,
      action
    );
  }
}

function* PostsFlow() {
  yield takeLatest(postsActions.fetchPending, fetchPosts);
}

function* postsWorker() {
  yield fork(PostsFlow);
}

export default postsWorker;
