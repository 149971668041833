export const flurryEvents = {
  profileViews: 'Profile_Views',
  rwrdUsers: 'RWRD_Users',
  rwrdUsersDelete: 'RWRD_Users_Delete',
  mapViews: 'Map_Views',
  customerRating: 'Customer_Rating',
  cardViews: 'Card_Views',
  newsFeedToProfile: 'NewsFeed_Profile',
  customerStamps: 'Customer_Stamps',
  customerRedemptions: 'Customer_Redemptions',
  customerFavorites: 'Customer_Favorites',
  venueDirections: 'Venue_Directions',
};
