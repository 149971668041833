import React, { useEffect, useState } from 'react';
import {
  StyledHiddenCheckbox,
  StyledImage,
  StyledItem,
  StyledItemLabel,
} from './styles';

const CheckOptionComponent = (props) => {
  const [checked, setChecked] = useState(false);
  const { data } = props;
  if (data.checked == null) {
    data.checked = false;
  }

  useEffect(() => {
    setChecked(data.checked);
  }, [data.checked]);
  const handleCheckboxChange = () => {
    props.handleCheckboxChange(data._id, !checked);
    setChecked(!checked);
  };
  return (
    <>
      <StyledItem checked={checked} onClick={() => handleCheckboxChange()}>
        <StyledHiddenCheckbox type="checkbox" checked={checked} />
        {data.icon && <StyledImage src={data.icon} />}
        <StyledItemLabel>{data.name}</StyledItemLabel>
      </StyledItem>
    </>
  );
};

export default CheckOptionComponent;
