import styled from 'styled-components';
import { newTheme } from '../../mainStyles';

export const StyledRememberContainer = styled.div`
  font-family: ${newTheme.fontFamily.primary.regular};
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 25px;

  & > div {
    font-family: ${newTheme.fontFamily.primary.regular};
    input {
      font-family: ${newTheme.fontFamily.primary.regular};
      margin-right: 8px;
    }
  }
`;
