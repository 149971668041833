import React from 'react';
import {
  CardElement,
  CardNumberElement,
  CardCVCElement,
  CardExpiryElement,
  injectStripe,
} from 'react-stripe-elements';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  Button,
  InputValidation,
} from '../../components/MainComponents/MainComponents';
import MessageComponent from '../../components/MessageComponent/MessageComponent';
import paymentsActions, {
  fetchUpdateStripeCardPending,
} from '../../redux/payments/payments.actions';
import {
  selectPaymentsPending,
  selectPaymentsErrors,
  selectPaymentsMessage,
} from '../../redux/payments/payments.selectors';
import { selectUserObject } from '../../redux/user/user.selectors';

const InputCard = styled(InputValidation)`
  margin-left: 30px;
`;

class CreditCardDetails extends React.Component {
  state = { stripeError: '', stripeLoading: false };

  onSubmit = async (e) => {
    e.preventDefault();
    if (this.props.stripe) {
      this.setState({ stripeLoading: true });
      try {
        const payload = await this.props.stripe.createToken();

        if (payload.error && payload.error.message) {
          this.setState({ stripeError: payload.error.message });
        } else {
          this.setState({ stripeError: '' });
          if (this.props.user && typeof this.props.user.card === 'string') {
            // User is updating his card details
            this.props.fetchUpdateStripeCardPending(payload.token);
          } else {
            this.props.fetchStripeCardPending(payload.token);
          }
        }
      } catch (error) {
        console.log('// Stripe error', error);
        this.setState({
          stripeError: 'Oops. There has been a problem, please try again.',
        });
      }
      this.setState({ stripeLoading: false });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    const message = this.state.stripeError || this.props.message;
    return (
      <form onSubmit={this.onSubmit}>
        <InputValidation as={CardNumberElement} />
        <div className="row" style={{ marginTop: 30, marginBottom: 10 }}>
          <InputValidation as={CardExpiryElement} />
          <InputCard as={CardCVCElement} />
        </div>
        <MessageComponent text={message} type="danger" />
        <Button
          type="submit"
          style={{ width: '100%' }}
          disabled={this.props.pending || this.state.stripeLoading}
        >
          Submit
        </Button>
      </form>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  pending: selectPaymentsPending,
  message: selectPaymentsMessage,
  errors: selectPaymentsErrors,
  user: selectUserObject,
});

const mapDispatchToProps = {
  fetchStripeCardPending: paymentsActions.fetchCreateStripeCardPending,
  fetchUpdateStripeCardPending: paymentsActions.fetchUpdateStripeCardPending,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectStripe(CreditCardDetails));
