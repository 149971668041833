import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfileObject } from '../../redux/profile/profile.selectors';
import { history } from '../../containers/RouterContainer/RouterContainer';
import storage from '../../utils/storage';
import profileActions from '../../redux/profile/profile.actions';
import userActions from '../../redux/user/user.actions';

import StepsProgresComponent from '../StepsProgresComponent/StepsProgresComponent';
import MobilePreviewComponent from '../MobilePreviewComponent/MobilePreviewComponent';
import ImagePickerComponent from '../ImagePickerComponent-v2/ImagePickerComponent';

import { AUTH_ROUTS } from '../../constants/routes';

import {
  StyledButton,
  StyledPageWrapper,
  StyledSection,
  StyledTitle,
} from '../AuthSubscriptionPageComponent/styles';
import { StyledLayout } from '../../screens/AuthScreen-v2/styles';
import {
  StyledProgress,
  StyledProgressBarComponent,
  StyledPageDescription,
  StyledFormWrapper,
} from './styles';
import {
  StyledErrorMessage,
  StyledIconImage,
  StyledInput,
  StyledInputContainer,
  StyledInputLabel,
  StyledLayoutWrapper,
  StyledSectionWrapper,
} from '../MainComponents/MainComponents';

import cup from '../../assets/images/icons/cupNew.svg';
import bgImage from '../../assets/images/backgrounds/phoneBg.png';

const AuthProfileComponentStep1 = () => {
  const step = storage.storageGet('step');
  if (step && step !== AUTH_ROUTS.PROFILE_STEP1) history.push(step);
  if (!step) history.push(AUTH_ROUTS.SIGN_IN);

  const { temp } = useSelector(selectProfileObject);
  const dispatch = useDispatch();
  const [touched, setTouched] = useState(false);
  const [errors, setErrors] = useState('');

  useEffect(() => {
    dispatch(userActions.fetchMePending());
    dispatch(profileActions.fetchCoffeeOptionsPending());
    dispatch(profileActions.fetchCoffeeGrindersPending());
    dispatch(profileActions.fetchCoffeeMachinesPending());
    dispatch(profileActions.fetchCoffeeBeansPending());
    dispatch(profileActions.fetchTopFeaturesPending());
  }, []);

  const setStorageData = (key, value) => {
    const storageTemp = storage.storageGet('temp');
    if (storageTemp) {
      const temp = JSON.parse(storageTemp);
      const newValue = { ...temp, [key]: value };
      storage.storageSet('temp', JSON.stringify(newValue));
    } else {
      const newValue = { [key]: value };
      storage.storageSet('temp', JSON.stringify(newValue));
    }
  };

  const checkStorageData = () => {
    const storageTemp = storage.storageGet('temp');
    if (storageTemp) {
      const values = Object.entries(JSON.parse(storageTemp));
      if (!values) {
        return;
      }
      values.forEach((el) => {
        dispatch(profileActions.changeTemp(el[0], el[1]));
      });
    }
  };

  const deleteStorageData = (keys) => {
    const storageTemp = storage.storageGet('temp');
    if (storageTemp) {
      const values = Object.entries(JSON.parse(storageTemp));
      const result = values.filter((el) => !keys.includes(el[0]));
      const newTemp = result.reduce((acc, el) => {
        return { ...acc, [el[0]]: el[1] };
      }, {});
      storage.storageSet('temp', JSON.stringify(newTemp));
    }
  };

  useEffect(() => {
    checkStorageData();

    if (temp.name) {
      setTouched(true);
    }
  }, []);

  const isValid = (value, maxLenght) => {
    if (value.length > maxLenght) {
      return setErrors(`Maximum length is ${maxLenght} characters`);
    }
    if (value.length === 0) {
      return setErrors('Field is required');
    }
    if (!value.replace(/\s/g, '').length) {
      return setErrors('Field is required');
    }
    return setErrors('');
  };

  const handleChange = (key, value) => {
    if (key === 'name') {
      isValid(value, 50);
    }
    dispatch(profileActions.changeTemp(key, value));
    setStorageData(key, value);
  };

  const setNextPage = () => {
    if (!temp.name) {
      setTouched(true);
      setErrors('Field is required');
      return;
    }
    history.push(AUTH_ROUTS.PROFILE_STEP2);
  };

  const skipThisPage = () => {
    if (!temp.name) {
      setTouched(true);
      setErrors('Field is required');
      return;
    }
    dispatch(profileActions.changeTemp('logo', ''));
    dispatch(profileActions.changeTemp('backgroundImage', ''));
    deleteStorageData(['logo', 'backgroundImage']);
    history.push(AUTH_ROUTS.PROFILE_STEP2);
  };

  return (
    <StyledPageWrapper>
      <StyledLayout height="100%">
        <StyledLayoutWrapper>
          <StyledSection paddingTop={26} paddingBot={26}>
            {/* leftside */}
            <StyledSectionWrapper>
              <StepsProgresComponent currentStep={2} />
              <StyledTitle marginBot={8}>Create your venue profile</StyledTitle>
              <StyledPageDescription>
                More than one site? Don't worry you can add more later!
              </StyledPageDescription>
              <StyledProgressBarComponent>
                <StyledProgress progress={1} />
              </StyledProgressBarComponent>
              <StyledFormWrapper>
                <StyledInputContainer marginBot={24}>
                  <StyledInputLabel>Business name</StyledInputLabel>
                  <StyledInput
                    onBlur={() => {
                      setTouched(true);
                      if (!temp.name) {
                        setErrors('Field is required');
                      }
                    }}
                    maxLength={50}
                    borderColor
                    // icon={cup}
                    type="text"
                    value={temp.name}
                    onChange={(event) =>
                      handleChange('name', event.target.value)
                    }
                    name="BusinessName"
                    placeholder="Coffee shop name"
                    vertical={18}
                    errors={(touched && errors) || (touched && !temp.name)}
                  />
                  <StyledIconImage top={47} src={cup} alt="cup" />
                  {errors && <StyledErrorMessage>{errors}</StyledErrorMessage>}
                </StyledInputContainer>

                <ImagePickerComponent
                  label="Upload Logo (Optional)"
                  maxFileSizeDescr="Max file size 4MB"
                  maxImgSizeDescr="Min 300px x 300px"
                  maxFileSize={4194304}
                  imageHeight={154}
                  imageWidth={154}
                  value={temp.logo}
                  defaultValue={temp.logo}
                  stateKey="logo"
                  onChange={(value) => handleChange('logo', value)}
                  onClear={deleteStorageData}
                  clearImage={(key, value) =>
                    dispatch(profileActions.clearTempImage(key, value))
                  }
                />
                <ImagePickerComponent
                  label="Upload Background Image (Optional)"
                  maxFileSizeDescr="Max file size 10MB"
                  maxImgSizeDescr="Min 300px x 300px"
                  maxFileSize={10485760}
                  ratio={10 / 7}
                  imageWidth={320}
                  imageHeight={154}
                  stateKey="backgroundImage"
                  value={temp.backgroundImage}
                  onChange={(value) => handleChange('backgroundImage', value)}
                  onClear={deleteStorageData}
                  clearImage={(key, value) =>
                    dispatch(profileActions.clearTempImage(key, value))
                  }
                />
              </StyledFormWrapper>
            </StyledSectionWrapper>
            <div>
              <StyledButton onClick={skipThisPage}>Skip</StyledButton>
              <StyledButton prymary onClick={setNextPage}>
                Save & Continue
              </StyledButton>
            </div>
          </StyledSection>
          <StyledSection phone paddingTop={56} bgImage={bgImage}>
            {/* rightside */}
            <div>
              <MobilePreviewComponent step={1} />
            </div>
          </StyledSection>
        </StyledLayoutWrapper>
      </StyledLayout>
    </StyledPageWrapper>
  );
};

export default AuthProfileComponentStep1;
