import { statsWithMessageTitles } from '../../constants/dashboard';

const getSubject = (titleName, venueName) =>
  encodeURIComponent(`${venueName.trim()} Notification Request; ${titleName}`);

const dateInfoBody = `\nPreferred notification send date:
  \nPreferred notification send time:\n`;

const commonInfoBody = `\nMessage Title* (up to 20 characters):
  \nMessage Subtitle (up to 20 characters):
  \nMessage Body* (up to 60 characters):
  \nURL:
  \nImage (Please attach image)`;

const emailTemplateMapToName = {};

emailTemplateMapToName[statsWithMessageTitles.customerFavourites] = (
  titleName,
  venueName
) => ({
  subject: getSubject(titleName, venueName),
  body: encodeURIComponent(
    `${venueName.trim()} wants to send a notification to the devices of the audience that has favourited the venue on RWRD app;
      ${dateInfoBody}
      ${commonInfoBody}`
  ),
});

emailTemplateMapToName[statsWithMessageTitles.returningCustomers] = (
  titleName,
  venueName
) => ({
  subject: getSubject(titleName, venueName),
  body: encodeURIComponent(
    `${venueName.trim()} wants to send a notification to the devices of the audience that has returned to the venue for more than one stamp.
      ${dateInfoBody}
      ${commonInfoBody}`
  ),
});

emailTemplateMapToName[statsWithMessageTitles.lostCustomers] = (
  titleName,
  venueName
) => ({
  subject: getSubject(titleName, venueName),
  body: encodeURIComponent(
    `${venueName.trim()} wants to send a notification to the devices of the audience that have collected at least one stamp, but have not returned to the venue for over 4 weeks.
			${dateInfoBody}
      ${commonInfoBody}`
  ),
});

emailTemplateMapToName[statsWithMessageTitles.newCustomers] = (
  titleName,
  venueName
) => ({
  subject: getSubject(titleName, venueName),
  body: encodeURIComponent(
    `${venueName.trim()} wants to send a notification to the devices of the audience that have collected one stamp only within the previous 4 weeks.
			${dateInfoBody}
      ${commonInfoBody}`
  ),
});

emailTemplateMapToName[statsWithMessageTitles.customersToWin] = (
  titleName,
  venueName
) => ({
  subject: getSubject('Customers you could win', venueName),
  body: encodeURIComponent(
    `${venueName.trim()} wants to send a notification to the devices of the audience that are within the same city and have never collected a stamp at the venue.
			${dateInfoBody}
      ${commonInfoBody}`
  ),
});

emailTemplateMapToName[statsWithMessageTitles.milkChoices] = (
  titleName,
  venueName
) => ({
  subject: getSubject('Milk Choice Segment', venueName),
  body: encodeURIComponent(
    `${venueName.trim()} wants to send a notification to the devices of the audience that prefer the following type of milk
    \nDelete as appropriate:
		Dairy
		Oat
		Soya
		Almond
		Coconut
		Hemp
		No Milk 
		${dateInfoBody}
    ${commonInfoBody}`
  ),
});

emailTemplateMapToName[statsWithMessageTitles.customerDiets] = (
  titleName,
  venueName
) => ({
  subject: getSubject('Customer Dietary Choices', venueName),
  body: encodeURIComponent(
    `${venueName.trim()} wants to send a notification to the devices of the audience that have the following dietary preferences
    \nDelete as appropriate:
		Vegan
		Vegetarian 
		Pescatarian 
		No Preference
		${dateInfoBody}
    ${commonInfoBody}`
  ),
});

emailTemplateMapToName[statsWithMessageTitles.favouriteCoffee] = (
  titleName,
  venueName
) => ({
  subject: getSubject('Favourite Coffee Preference', venueName),
  body: encodeURIComponent(
    `${venueName.trim()} wants to send a notification to the devices of the audience that have the following preference of favourite coffee
    \nDelete as appropriate:
		Black Americano
		White Americano 
		Latte
		Mocha
		Espresso
		Cappuccino 
		Flat White
		Macchiato
		Filter Coffee
		Quortardo
		Iced Coffee
		Don’t Drink Coffee
		${dateInfoBody}
    ${commonInfoBody}`
  ),
});

emailTemplateMapToName[statsWithMessageTitles.sitInTakeout] = (
  titleName,
  venueName
) => ({
  subject: getSubject('Take Away or Sit In customers', venueName),
  body: encodeURIComponent(
    `${venueName.trim()} wants to send a notification to the devices of the audience that have the following preference of sitting in or take away.
    \nDelete as appropriate:\nTake Away\nSit In
    ${dateInfoBody}
    ${commonInfoBody}`
  ),
});

emailTemplateMapToName[statsWithMessageTitles.buyForHome] = (
  titleName,
  venueName
) => ({
  subject: getSubject('What coffee do you buy for home', venueName),
  body: encodeURIComponent(
    `${venueName.trim()} wants to send a notification to the devices of the audience that have the following preference for purchasing coffee at home.
    \nDelete as appropriate:\nBeans\nGround\nInstant\nNone
    ${dateInfoBody}
    ${commonInfoBody}`
  ),
});

emailTemplateMapToName[statsWithMessageTitles.customersWorkingRemotely] = (
  titleName,
  venueName
) => ({
  subject: getSubject('Do you work remotely', venueName),
  body: encodeURIComponent(
    `${venueName.trim()} wants to send a notification to the devices of the audience that have the following preference for working remotely
    \nDelete as appropriate:\nAlways\nSometimes\nNever
    ${dateInfoBody}
    ${commonInfoBody}`
  ),
});

emailTemplateMapToName[statsWithMessageTitles.drinkMostOften] = (
  titleName,
  venueName
) => ({
  subject: getSubject('Drink most often', venueName),
  body: encodeURIComponent(
    `${venueName.trim()} wants to send a notification to the devices of the audience who have the following preference for the drink they order most often.
    \nDelete as appropriate:\nCoffee\nTea\nJuice\nSmoothies\nCold Press Shots
    ${dateInfoBody}
    ${commonInfoBody}`
  ),
});

emailTemplateMapToName[statsWithMessageTitles.customerAges] = (
  titleName,
  venueName
) => ({
  subject: getSubject(titleName, venueName),
  body: encodeURIComponent(
    `${venueName.trim()} wants to send a notification to the devices of the audience who fall within the following age bracket;
    \nDelete as appropriate:\n18-24\n25-34\n35-44\n45+
    ${dateInfoBody}
    ${commonInfoBody}`
  ),
});

emailTemplateMapToName[statsWithMessageTitles.coffeesPerDay] = (
  titleName,
  venueName
) => ({
  subject: getSubject(titleName, venueName),
  body: encodeURIComponent(
    `${venueName.trim()} wants to send a notification to the devices of the audience who drink the following number of coffees per day;
    \nDelete as appropriate:\n0\n1-2\n3-5\n5+
    ${dateInfoBody}
    ${commonInfoBody}`
  ),
});

emailTemplateMapToName[statsWithMessageTitles.customerGender] = (
  titleName,
  venueName
) => ({
  subject: getSubject('Customer Gender', venueName),
  body: encodeURIComponent(
    `${venueName.trim()} wants to send a notification to the devices of the following audience;
    \nDelete as appropriate:\nMale\nFemale
    ${dateInfoBody}
    ${commonInfoBody}`
  ),
});

export { emailTemplateMapToName };
