import React from 'react';
import { useDispatch } from 'react-redux';
import { history } from '../../containers/RouterContainer/RouterContainer';
import storage from '../../utils/storage';
import StepsProgresComponent from '../StepsProgresComponent/StepsProgresComponent';
import MobilePreviewComponent from '../MobilePreviewComponent/MobilePreviewComponent';
import ImageHighlightsComponent from '../ImageHighlightsComponent-v2/ImageHighlightsComponent';
import profileActions from '../../redux/profile/profile.actions';

import { AUTH_ROUTS } from '../../constants/routes';

import {
  StyledButton,
  StyledPageWrapper,
  StyledSection,
  StyledTitle,
} from '../AuthSubscriptionPageComponent/styles';
import { StyledLayout } from '../../screens/AuthScreen-v2/styles';
import {
  StyledProgress,
  StyledProgressBarComponent,
  StyledPageDescription,
  StyledDescription,
  StyledFormWrapper,
} from './styles';
import {
  StyledInputContainer,
  StyledInputLabel,
  StyledLayoutWrapper,
  StyledSectionWrapper,
} from '../MainComponents/MainComponents';

import bgImage from '../../assets/images/backgrounds/phoneBg.png';

const AuthProfileComponentStep5 = () => {
  const step = storage.storageGet('step');
  if (step && step !== AUTH_ROUTS.PROFILE_STEP1) history.push(step);
  if (!step) history.push(AUTH_ROUTS.SIGN_IN);

  const dispatch = useDispatch();

  const setStorageData = (key, value) => {
    const storageTemp = storage.storageGet('temp');
    if (storageTemp) {
      const temp = JSON.parse(storageTemp);
      const newValue = { ...temp, [key]: value };
      storage.storageSet('temp', JSON.stringify(newValue));
    } else {
      const newValue = { [key]: value };
      storage.storageSet('temp', JSON.stringify(newValue));
    }
  };

  const deleteStorageData = (keys) => {
    const storageTemp = storage.storageGet('temp');
    if (storageTemp) {
      const values = Object.entries(JSON.parse(storageTemp));
      const result = values.filter((el) => !keys.includes(el[0]));
      const newTemp = result.reduce((acc, el) => {
        return { ...acc, [el[0]]: el[1] };
      }, {});
      storage.storageSet('temp', JSON.stringify(newTemp));
    }
  };

  const handleChange = (key, value) => {
    setStorageData(key, value);
    dispatch(profileActions.changeTemp(key, value));
  };

  const setNextPage = () => {
    history.push(AUTH_ROUTS.PROFILE_STEP6);
  };

  const skipThisPage = () => {
    dispatch(profileActions.changeTemp('highlights', ['', '', '']));
    deleteStorageData(['highlights']);
    history.push(AUTH_ROUTS.PROFILE_STEP6);
  };

  return (
    <StyledPageWrapper>
      <StyledLayout height="100%">
        <StyledLayoutWrapper>
          <StyledSection paddingTop={26} paddingBot={26}>
            {/* leftside */}
            <StyledSectionWrapper>
              <StepsProgresComponent currentStep={2} />
              <StyledTitle marginBot={8}>Create your venue profile</StyledTitle>
              <StyledPageDescription>
                More than one site? Don't worry you can add more later!
              </StyledPageDescription>
              <StyledProgressBarComponent>
                <StyledProgress progress={5} />
              </StyledProgressBarComponent>
              <StyledFormWrapper>
                <StyledInputContainer marginBot={24}>
                  <StyledInputLabel>Highlights</StyledInputLabel>
                  <StyledDescription>
                    Add upto three highlights to your profile (Optional)
                  </StyledDescription>
                  <ImageHighlightsComponent handleChange={handleChange} />
                </StyledInputContainer>
              </StyledFormWrapper>
            </StyledSectionWrapper>
            <div>
              <StyledButton onClick={skipThisPage}>Skip</StyledButton>
              <StyledButton prymary onClick={setNextPage}>
                Save & Continue
              </StyledButton>
            </div>
          </StyledSection>
          <StyledSection phone paddingTop={56} bgImage={bgImage}>
            <div>
              <MobilePreviewComponent step={2} />
            </div>
          </StyledSection>
        </StyledLayoutWrapper>
      </StyledLayout>
    </StyledPageWrapper>
  );
};

export default AuthProfileComponentStep5;
