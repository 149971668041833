import { createSelector } from 'reselect';

const rootSelector = (state) => state.post;

export const selectPost = rootSelector;

export const selectPostPending = createSelector(
  rootSelector,
  (post) => post.pending
);
export const selectPostErrors = createSelector(
  rootSelector,
  (post) => post.errors
);
export const selectPostMessage = createSelector(
  rootSelector,
  (post) => post.message
);

export const selectPostId = createSelector(rootSelector, (post) => post._id);

export const selectPostTitle = createSelector(
  rootSelector,
  (post) => post.title
);

export const selectPostDescription = createSelector(
  rootSelector,
  (post) => post.description
);

export const selectPostImage = createSelector(
  rootSelector,
  (post) => post.image
);

export const selectTemp = createSelector(rootSelector, (post) => post.temp);
