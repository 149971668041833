import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  RowOffsetBottom,
  Column,
  SmallBlock,
  Text,
  MainTitleDark,
  MobileContent,
} from '../../components/MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';
import { MainContentOffset } from '../MainScreen/styles';
import AccountFormContainer from '../../containers/AccountFormContainer/AccountFormContainer';
import PasswordFormContainer from '../../containers/PasswordFormContainer/PasswordFormContainer';
import PaymentDetailContainers from '../../containers/PaymentDetailContainers/PaymentDetailContainers';
import InvoicesListContainer from '../../containers/InvoicesListContainer/InvoicesListContainer';
import DeleteAccountButton from '../../containers/DeleteAccountButton/DeleteAccountButton';
import userActions from '../../redux/user/user.actions';
import OptInInsightsContainer from '../../containers/OptInInsightsContainer/OptInInsightsContainer';

class AccountScreen extends Component {
  componentDidMount() {
    this.props.fetchMe();
  }

  render() {
    return (
      <MainContentOffset>
        <Column>
          <RowOffsetBottom wrap>
            <SmallBlock as={MobileContent}>
              <MainTitleDark>Account</MainTitleDark>
              <Text color={mainTheme.colors.grey}>
                Your private account information
              </Text>
            </SmallBlock>

            <AccountFormContainer />
          </RowOffsetBottom>

          <RowOffsetBottom wrap>
            <SmallBlock as={MobileContent}>
              <MainTitleDark>Password</MainTitleDark>
              <Text color={mainTheme.colors.grey}>
                Reset you current password. At least 5 letters long and one
                number
              </Text>
            </SmallBlock>

            <PasswordFormContainer />
          </RowOffsetBottom>

          <RowOffsetBottom wrap>
            <SmallBlock as={MobileContent}>
              <MainTitleDark>Card Details</MainTitleDark>
              <Text color={mainTheme.colors.grey}>
                Your payment card details
              </Text>
            </SmallBlock>

            <PaymentDetailContainers />
          </RowOffsetBottom>

          <RowOffsetBottom wrap>
            <SmallBlock as={MobileContent}>
              <MainTitleDark>Invoices</MainTitleDark>
              <Text color={mainTheme.colors.grey}>
                Your subscription invoices
              </Text>
            </SmallBlock>

            <InvoicesListContainer />
          </RowOffsetBottom>

          <RowOffsetBottom wrap style={{ marginBottom: 150 }}>
            <SmallBlock as={MobileContent}>
              <MainTitleDark>Access Customer Insights</MainTitleDark>
              <Text color={mainTheme.colors.grey}>
                Opt in for Customer Insights
              </Text>
            </SmallBlock>

            <OptInInsightsContainer />
          </RowOffsetBottom>

          <RowOffsetBottom wrap style={{ justifyContent: 'flex-end' }}>
            <DeleteAccountButton />
          </RowOffsetBottom>
        </Column>
      </MainContentOffset>
    );
  }
}

export default connect(null, { fetchMe: userActions.fetchMePending })(
  AccountScreen
);
