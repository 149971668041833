import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  BoardComponent,
  Column,
  FieldLabel,
  HalfContent,
  HalfContentWrapper,
  InputMain,
  RightBlock,
  RowOffsetBottom,
  Row,
  FromControls,
  MainButton,
} from '../../components/MainComponents/MainComponents';
import userActions from '../../redux/user/user.actions';
import {
  selectUserErrors,
  selectUserPending,
} from '../../redux/user/user.selectors';
import { serializeForm } from '../../utils/selectors';
import MessageComponent from '../../components/MessageComponent/MessageComponent';

const Separator = styled.div`
  width: 100%;
  height: 40px;
  display: none;
  visibility: hidden;

  @media (max-width: 645px) {
    display: block;
    visibility: visible;
  }
`;

class PasswordFormContainer extends Component {
  static defaultProps = {
    errors: {},
  };

  updatePassword = (event) => {
    event.preventDefault();
    const data = serializeForm(event.target);
    this.props.updatePassword(data);
  };

  render() {
    return (
      <RightBlock style={{ borderRadius: 7 }} as={BoardComponent}>
        <Column as="form" onSubmit={this.updatePassword}>
          <RowOffsetBottom>
            <Column>
              <FieldLabel>
                CURRENT PASSWORD{' '}
                <a href="/auth/forgot-password">Forgot Password?</a>
              </FieldLabel>
              <InputMain
                name="currentPassword"
                type="password"
                error={
                  'currentPassword' in this.props.errors ||
                  'updatingPassword' in this.props.errors
                }
                defaultValue=""
                placeholder="Current Password"
              />
            </Column>
          </RowOffsetBottom>

          <RowOffsetBottom>
            <HalfContentWrapper>
              <HalfContent style={{ marginBottom: 0 }}>
                <Column>
                  <FieldLabel>NEW PASSWORD</FieldLabel>
                  <InputMain
                    style={{ marginBottom: 0 }}
                    name="password"
                    type="password"
                    error={'password' in this.props.errors}
                    defaultValue=""
                    placeholder="New Password"
                  />
                </Column>
              </HalfContent>
              <Separator />
              <HalfContent style={{ marginBottom: 0, marginRight: 0 }}>
                <Column>
                  <FieldLabel>CONFIRM NEW PASSWORD</FieldLabel>
                  <InputMain
                    style={{ marginBottom: 0 }}
                    type="password"
                    name="password_confirmation"
                    defaultValue=""
                    error={'password_confirmation' in this.props.errors}
                    placeholder="Confirm New Password"
                  />
                </Column>
              </HalfContent>
            </HalfContentWrapper>
          </RowOffsetBottom>
          <Row>
            <MessageComponent
              type="danger"
              text={this.props.errors.updatingPassword}
            />
            {'password' in this.props.errors && (
              <MessageComponent
                type="danger"
                text="Password must be six characters long and contain at least one number"
              />
            )}
            <FromControls>
              {/* <ClearButton color={mainTheme.colors.light} type={'reset'}>
								Clear
							</ClearButton> */}
              <MainButton type="submit">Update</MainButton>
            </FromControls>
          </Row>
        </Column>
      </RightBlock>
    );
  }
}

const mapDispatchToProps = {
  updatePassword: userActions.updateAccountPasswordFetchPending,
};

const mapStateToProps = (state) => ({
  pending: selectUserPending(state),
  errors: selectUserErrors(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordFormContainer);
