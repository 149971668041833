import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Column,
  MainTitleDark,
  MobileContent,
  RightBlock,
  RowOffsetBottom,
  SmallBlock,
  Text,
} from '../../components/MainComponents/MainComponents';
import {
  Link,
  Title,
  StepBlock,
  Circle,
  Line,
  CheckBox,
  TermsCheckboxContainer,
  OnboardingButton,
} from './styles';
import { mainTheme } from '../../mainStyles';
import { MainContentOffset } from '../MainScreen/styles';
import dashboardMockup from '../../assets/images/dashboard-mockup.png';
import stripeBadge from '../../assets/images/stripe-badge.png';

import userActions from '../../redux/user/user.actions';
import { selectUserStripe } from '../../redux/user/user.selectors';

class TipsScreen extends Component {
  state = {
    termsAccepted: false,
  };

  onChangeHandler = () => {
    this.setState({ termsAccepted: true });
  };

  render() {
    const { connectAccountId } = this.props.userStripe;
    return (
      <MainContentOffset>
        <Column>
          <RowOffsetBottom
            wrap
            style={{ alignItems: 'flex-end', marginBottom: 30 }}
          >
            <SmallBlock as={MobileContent} style={{ paddingRight: 25 }}>
              <MainTitleDark>Team Tips</MainTitleDark>
              <Text color={mainTheme.colors.grey}>
                Upgrade today to start collecting tips via RWRD app
              </Text>
              <br />
              <Link
                href="https://www.rwrdapp.com/rwrd-digital-tipping/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Find out more about Digital Tips
              </Link>
            </SmallBlock>
            <OnboardingButton
              disabled={!this.state.termsAccepted && !connectAccountId}
              onClick={() => this.props.connectAccount()}
            >
              Complete verification
            </OnboardingButton>
          </RowOffsetBottom>
          <RowOffsetBottom wrap style={{ marginTop: 50 }}>
            <SmallBlock
              as={MobileContent}
              style={{ textTransform: 'uppercase' }}
            >
              <StepBlock>
                <Circle />
                <div>
                  <Title>Complete kyc verification</Title>
                  <Link
                    style={{ fontSize: 10 }}
                    href="https://www.rwrdapp.com/rwrd-digital-tipping/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    What is Kyc?
                  </Link>
                </div>
              </StepBlock>
              <Line />
              <StepBlock>
                <Circle />
                <div>
                  <Title>Accept T&CS</Title>
                  <TermsCheckboxContainer>
                    <CheckBox
                      type="checkbox"
                      name="acceptTC"
                      checked={connectAccountId}
                      onChange={this.onChangeHandler}
                    />
                    <Link
                      style={{ fontSize: 10 }}
                      href="https://www.rwrdapp.com/business-terms-and-conditions/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Tick here to accept Terms & conditions
                    </Link>
                  </TermsCheckboxContainer>
                </div>
              </StepBlock>
              <Line />
              <StepBlock>
                <Circle />
                <div>
                  <Title>Go live check</Title>
                  <div style={{ fontSize: 10, color: mainTheme.colors.grey }}>
                    The team will be in touch to kick off
                  </div>
                </div>
              </StepBlock>
              <Line />
              <StepBlock>
                <Circle />
                <div>
                  <Title>start earning tips!</Title>
                  <Link
                    style={{ fontSize: 10 }}
                    href="https://www.rwrdapp.com/rwrd-digital-tipping/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    How do customers leave a tip?
                  </Link>
                </div>
              </StepBlock>
            </SmallBlock>
            <RightBlock style={{ paddingLeft: 30, paddingRight: 30 }}>
              <img src={dashboardMockup} alt="mockup" />
            </RightBlock>
          </RowOffsetBottom>
          <RowOffsetBottom wrap>
            <img src={stripeBadge} alt="stripe-badge" />
          </RowOffsetBottom>
        </Column>
      </MainContentOffset>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userStripe: selectUserStripe,
});

export default connect(mapStateToProps, {
  connectAccount: userActions.connectAccountPending,
})(TipsScreen);
