import React, { useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  MainTitleDark,
  MobileContent,
  RowOffsetBottom,
  SmallBlock,
  Text,
  PaperComponent,
  Row,
  Column,
} from '../../components/MainComponents/MainComponents';
import { MainContentOffset } from '../MainScreen/styles';
import {
  ChartsWrapper,
  Wrapper,
  FullWidthWrapper,
  FullWidthPaper,
  BankAccountWrapper,
  FilterContainer,
  TipsAmountContainer,
  LineChartWrapper,
  SelectContainer,
  CardNameWrapper,
  BankAccountContainer,
  BankName,
  ProfilePicker,
} from './styles';
import stripeBadge from '../../assets/images/stripe-badge.png';
import arrowRight from '../../assets/images/icons/arrow-right.svg';
import stripeBankIcon from '../../assets/images/icons/stripe-bank.svg';
import stripeDelimiter from '../../assets/images/icons/stripe-delimiter.svg';

import DropDownComponent from '../../components/DropDownComponent/DropDownComponent';
import TipsLineChart from '../../containers/TipsLineChart/TipsLineChart';
import tipsAction from '../../redux/tips/tips.actions';
import {
  selectTipsList,
  selectTipsErrors,
  selectTipsPending,
  selectPayoutsList,
  selectPayoutsNextAmount,
  selectAllTipsList,
  selectTotalSum,
  selectAverageTip,
  selectCustomersCount,
  selectBankName,
  selectBankCurrency,
  selectLast4,
  selectRoutingNumber,
} from '../../redux/tips/tips.selectors';
import { selectUserObject } from '../../redux/user/user.selectors';
import TipsSegmentsBarChart from '../../components/TipsSegmentsBarChart/TipsSegmentsBarChart';

const StaffTipsScreen = (props) => {
  const {
    fetchTips,
    fetchPayouts,
    fetchTipStats,
    fetchBank,
    payouts,
    nextPayoutAmount,
    allTips,
    totalSum,
    averageTip,
    customersCount,
    bankName,
    currency,
    last4,
    routingNumber,
    profiles,
  } = props;

  const tipsRangeOptions = [
    { value: 'day', label: 'DAY' },
    { value: 'week', label: 'WEEK' },
    { value: 'month', label: 'MONTH' },
  ];
  const [tipsRange, setTipsRange] = useState(tipsRangeOptions[0]);
  const [totalTipsPerRange, setTotalTipsPerRAnge] = useState(0);
  const [tips, setTips] = useState([]);

  const [latestPayout, setLatestPayout] = useState({ label: 'No payouts yet' });

  const [selectedProfile, setSelectedProfile] = useState(null);
  const [profileOptions, setProfileOptions] = useState([
    { value: null, label: 'All profiles' },
  ]);

  useEffect(() => {
    profiles.length > 0 &&
      setProfileOptions([
        ...profileOptions,
        ...profiles.map((p) => ({
          value: p._id,
          label: p.name,
        })),
      ]);
  }, [profiles]);

  useEffect(() => {
    payouts.length > 0 &&
      setLatestPayout({
        label: `£${(payouts[0].amount / 100).toFixed(2)} [${
          payouts[0].status
        }]`,
      });
  }, [payouts]);

  useEffect(() => {
    payouts.length > 0 &&
      setLatestPayout({
        label: `£${(payouts[0].amount / 100).toFixed(2)} [${
          payouts[0].status
        }]`,
      });
  }, [payouts]);

  useEffect(() => {
    getTotalTipsPerRange(tips, tipsRange.value);
  }, [tips, tipsRange]);

  const onTipsRangeSelect = (option) => {
    setTipsRange(option);
  };

  const handleProfileChange = (item) => {
    setSelectedProfile({
      _id: item.value,
      name: item.label,
    });
  };

  useEffect(() => {
    setTips(props.tips);
  }, [props.tips]);

  useEffect(() => {
    !!selectedProfile &&
      setTips(props.tips.filter((t) => t.venueId === selectedProfile._id));
  }, [selectedProfile]);

  const getTotalTipsPerRange = (tips, range) => {
    let sumPerRange = 0;
    if (range === 'day') {
      tips.map(
        (tip) =>
          moment().isSame(tip.date * 1000, 'day') && (sumPerRange += tip.amount)
      );
    } else {
      const startDate = moment().subtract(1, range).startOf('day');
      tips.map((tip) => {
        moment(tip.date * 1000).isBetween(startDate, moment()) &&
          (sumPerRange += tip.amount);
      });
    }
    setTotalTipsPerRAnge((sumPerRange / 100).toFixed(2));
  };

  useEffect(() => {
    fetchTips(tipsRange.value);
    fetchPayouts();
    fetchTipStats();
    fetchBank();
  }, []);

  return (
    <MainContentOffset>
      <ProfilePicker
        value={
          selectedProfile
            ? {
                value: selectedProfile._id,
                label: selectedProfile.name,
              }
            : profileOptions[0]
        }
        name="selectedProfile"
        placeholder="Select profile"
        onSelect={(item) => handleProfileChange(item)}
        options={profileOptions}
      />
      <RowOffsetBottom style={{ marginBottom: 30 }}>
        <SmallBlock as={MobileContent}>
          <MainTitleDark>Staff Tips</MainTitleDark>
        </SmallBlock>
      </RowOffsetBottom>

      <ChartsWrapper>
        <PaperComponent>
          <MainTitleDark style={{ marginBottom: 15 }}>Total Tips</MainTitleDark>
          <Text>
            Gross volume
            {/* <PercentLabel>9.3%</PercentLabel> */}
          </Text>
          <TipsAmountContainer style={{ marginTop: 25 }}>
            <FilterContainer>
              <DropDownComponent
                onSelect={onTipsRangeSelect}
                defaultValue={tipsRange}
                name="tipsRange"
                options={tipsRangeOptions}
                value={tipsRange}
              />
            </FilterContainer>
            <Row>
              <MainTitleDark>£{totalTipsPerRange}</MainTitleDark>
            </Row>
            <Row style={{ justifyContent: 'flex-end' }}>
              <Text style={{ fontSize: 18 }}>£{totalSum}</Text>
            </Row>
          </TipsAmountContainer>
          <LineChartWrapper>
            <TipsLineChart tips={tips} range={tipsRange.value} />
          </LineChartWrapper>
        </PaperComponent>

        <Wrapper>
          <PaperComponent>
            <CardNameWrapper>
              <MainTitleDark>Average Tips</MainTitleDark>
              <Text>Average of all tips collected</Text>
            </CardNameWrapper>
            <Column>
              <MainTitleDark style={{ fontSize: 48 }}>
                £{averageTip}
              </MainTitleDark>
            </Column>
          </PaperComponent>
          <PaperComponent>
            <CardNameWrapper>
              <MainTitleDark>Payout</MainTitleDark>
              <Text>Monthly 23rd — 7 day rolling basis</Text>
              {/* TODO: pull from stripe account */}
            </CardNameWrapper>
            <Column>
              <MainTitleDark style={{ fontSize: 48 }}>
                £{nextPayoutAmount}
              </MainTitleDark>
            </Column>
          </PaperComponent>
          <PaperComponent>
            <CardNameWrapper>
              <MainTitleDark>Customers who tip</MainTitleDark>
              <Text>How many customers are leaving tips</Text>
            </CardNameWrapper>
            <Column>
              <MainTitleDark style={{ fontSize: 48 }}>
                {customersCount}
              </MainTitleDark>
            </Column>
          </PaperComponent>
          <PaperComponent>
            <CardNameWrapper>
              <MainTitleDark>Previous payouts</MainTitleDark>
              <Text>Review your previous payouts</Text>
            </CardNameWrapper>

            <Column
              style={{ textTransform: 'capitalize', justifyContent: 'center' }}
            >
              <SelectContainer>
                <DropDownComponent
                  style={{ marginBottom: 0 }}
                  onSelect={() => null}
                  value={latestPayout}
                  name="payoutsList"
                  options={payouts.map((item) => {
                    return {
                      label: `£${(item.amount / 100).toFixed(2)} [${
                        item.status
                      }]`,
                    };
                  })}
                />
              </SelectContainer>
            </Column>
          </PaperComponent>
        </Wrapper>
      </ChartsWrapper>

      <FullWidthWrapper>
        <FullWidthPaper>
          <CardNameWrapper>
            <MainTitleDark>Customer tip segments</MainTitleDark>
          </CardNameWrapper>
          <TipsSegmentsBarChart data={allTips} />
        </FullWidthPaper>
      </FullWidthWrapper>

      <BankAccountWrapper>
        <PaperComponent>
          <CardNameWrapper>
            <MainTitleDark>Connected bank acc.</MainTitleDark>
            <Text>Your connected bank account</Text>
          </CardNameWrapper>
          <BankAccountContainer>
            <img src={arrowRight} alt="arrow-right" />
            <img src={stripeBankIcon} alt="stripe-bank" />
            <Column>
              <Row>
                <BankName>
                  {bankName} <span>{currency}</span>
                </BankName>
              </Row>
              <Row>
                <img src={stripeDelimiter} alt="stripe-delimiter" />
                <p style={{ paddingRight: 10 }}>{routingNumber}</p>
                <img src={stripeDelimiter} alt="stripe-delimiter" />
                <p>•••• {last4}</p>
              </Row>
            </Column>
          </BankAccountContainer>
        </PaperComponent>

        {/* <PaperComponent>
					<CardNameWrapper>
						<MainTitleDark>Payout receipts</MainTitleDark>
						<Text>Gross volume</Text>
					</CardNameWrapper>
				</PaperComponent> */}
      </BankAccountWrapper>

      <RowOffsetBottom>
        <img src={stripeBadge} alt="stripe-badge" />
      </RowOffsetBottom>
    </MainContentOffset>
  );
};
const TransactionsSelector = createSelector(
  selectTipsErrors,
  selectTipsPending,
  selectTipsList,
  selectPayoutsList,
  selectPayoutsNextAmount,
  selectAllTipsList,
  selectTotalSum,
  selectAverageTip,
  selectCustomersCount,
  selectBankName,
  selectBankCurrency,
  selectLast4,
  selectRoutingNumber,
  selectUserObject,
  (
    errors,
    pending,
    tips,
    payouts,
    nextPayoutAmount,
    allTips,
    totalSum,
    averageTip,
    customersCount,
    bankName,
    currency,
    last4,
    routingNumber,
    user
  ) => ({
    errors,
    pending,
    tips,
    payouts,
    nextPayoutAmount,
    allTips,
    totalSum,
    averageTip,
    customersCount,
    bankName,
    currency,
    last4,
    routingNumber,
    profiles: user.profile,
  })
);
const mapStateToProps = TransactionsSelector;

const mapDispatchToProps = {
  fetchTips: tipsAction.fetchPending,
  fetchPayouts: tipsAction.fetchPayoutPending,
  fetchTipStats: tipsAction.fetchTipStatsPending,
  fetchBank: tipsAction.fetchBankPending,
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffTipsScreen);
