import React from 'react';
import styled from 'styled-components';

import {
  HelperLink,
  AuthLabelText,
  AuthForm,
} from '../../screens/AuthScreen/styles';
import {
  Divider,
  AuthButton,
  InputValidation,
  Column,
  SmallText,
} from '../MainComponents/MainComponents';
import { SignUpTopLabel, SignUpText, NameInputWrapper } from './styles';
import MessageComponent from '../MessageComponent/MessageComponent';
import FormCheckBoxComponent from '../FormCheckBoxComponent/FormCheckBoxComponent';
import { mainTheme } from '../../mainStyles';

const SignupFormComponent = ({
  signUp,
  signInFacebook,
  signInGoogle,
  errors,
  message,
}) => {
  return (
    <AuthForm
      style={{ borderRadius: 0, paddingBottom: 10 }}
      fullWidth
      as="form"
      onSubmit={signUp}
      maxWidth={465}
    >
      <Column>
        {/* <SignUpTopLabel>
					Sign up with <SignUpText onClick={signInFacebook}>Facebook</SignUpText> or{' '}
					<SignUpText onClick={signInGoogle}>Google</SignUpText>
				</SignUpTopLabel>
				<Divider /> */}
        <NameInputWrapper>
          <InputValidation
            type="name"
            error={errors.firstName}
            name="firstName"
            placeholder="First Name"
          />
          <div />
          <InputValidation
            type="name"
            error={errors.lastName}
            name="lastName"
            placeholder="Last Name"
          />
        </NameInputWrapper>
        <InputValidation
          type="email"
          name="email"
          error={errors.email}
          placeholder="Email"
        />
        <InputValidation
          placeholder="Password"
          name="password"
          type="password"
          error={errors.password}
        />
        <InputValidation
          placeholder="Password Confirmation"
          name="password_confirmation"
          error={errors.password_confirmation}
          type="password"
        />

        <FormCheckBoxComponent
          text={() => (
            <>
              I agree to the{' '}
              <Link
                href="https://www.rwrdapp.com/business-terms-and-conditions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions
              </Link>{' '}
              and{' '}
              <Link
                href="https://www.rwrdapp.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy policy
              </Link>
            </>
          )}
          error={'terms_agree' in errors || ''}
          name="terms_agree"
        />

        {errors.password && (
          <MessageComponent
            type="danger"
            text="Password must be six characters long and contain at least one number"
          />
        )}
        <MessageComponent type="danger" text={message} />

        <AuthButton>Sign up</AuthButton>
      </Column>
    </AuthForm>
  );
};

const Link = styled.a`
  color: ${(props) => mainTheme.colors.textDark};
`;

export default SignupFormComponent;
