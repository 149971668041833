import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { AuthLabelsWrapper, AuthIcon, AuthLabel, AuthSubtitle } from './styles';
import BreadCrumbs from '../BreadCrumbsComponent/BreadCrumbs';

const labels = {
  '/auth': {
    title: 'LOGIN',
    subtitle: '',
  },
  '/auth/': {
    title: 'LOGIN',
    subtitle: '',
  },
  '/auth/login': {
    title: 'LOGIN',
    subtitle: '',
  },
  '/auth/signin': {
    title: 'WELCOME TO THE RWRD PORTAL',
    subtitle: `Your account for managing loyalty, analytics and promotions for your venue. \n
		Sign up now, it takes less time than making a brew.`,
  },
  '/auth/signup': {
    title: 'WELCOME TO THE RWRD PORTAL',
    subtitle: `Your account for managing loyalty, analytics and promotions for your venue. \n
		Sign up now, it takes less time than making a brew.`,
  },
  '/auth/subscriptions': {
    title: 'SUBSCRIPTION PACKAGE',
    subtitle: `1 MONTH ROLLING`,
  },
  '/auth/payments': { title: 'FINAL STEP', subtitle: `` },
  '/auth/registered': { title: 'WELCOME TO RWRD', subtitle: `` },
  '/auth/profile': { title: 'PROFILE SETUP', subtitle: `` },
  '/auth/forgot-password': { title: 'RESET PASSWORD', subtitle: `` },
  '/auth/reset-password': { title: 'RESET PASSWORD', subtitle: `` },
  '/auth/forgot-password-sent': { title: 'RESET PASSWORD', subtitle: `` },
};

class AuthLabelsComponent extends Component {
  get label() {
    return (
      labels[this.props.history.location.pathname] || {
        title: 'NOT FOUND',
        subtitle: '',
      }
    );
  }

  render() {
    return (
      <AuthLabelsWrapper>
        {/* <AuthIcon /> */}
        <AuthLabel>{this.label.title}</AuthLabel>
        <AuthSubtitle as="p">{this.label.subtitle}</AuthSubtitle>

        <BreadCrumbs />
      </AuthLabelsWrapper>
    );
  }
}

export default withRouter(AuthLabelsComponent);
