import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';
import {
  selectPostId,
  selectPostTitle,
  selectPostDescription,
  selectPostImage,
  selectPostErrors,
  selectPostPending,
  selectTemp,
} from '../../redux/post/post.selectors';
import { selectPostsList } from '../../redux/posts/posts.selectors';
import { Column } from '../../components/MainComponents/MainComponents';
import { serializeForm } from '../../utils/selectors';
import postActions from '../../redux/post/post.actions';
import PostFormComponent from '../../components/PostFormComponent/PostFormComponent';
import MessageComponent from '../../components/MessageComponent/MessageComponent';
import postsActions from '../../redux/posts/posts.actions';

// Create & Edit Post Form
export class PostFormContainer extends PureComponent {
  state = { showSuccessMessage: false, formKey: 0 };

  componentDidMount() {
    if (this.props.match.params._id) {
      this.props.fetchPost(this.props.match.params._id);
    }
  }

  componentDidUpdate(props) {
    if (this.props.match.params._id !== props.match.params._id) {
      this.props.fetchPost(this.props.match.params._id);
      this.forceUpdate();
    }
    // Show success message when create post action has succeed
    if (
      props.pending !== this.props.pending &&
      props.posts.length < this.props.posts.length &&
      this.props.errors &&
      Object.keys(this.props.errors).length === 0
    ) {
      this.props.fetchPosts();
      this.showSuccessMessage();
    }
  }

  showSuccessMessage = () => {
    this.setState({ showSuccessMessage: true });
    setTimeout(() => this.setState({ showSuccessMessage: false }), 3000);
  };

  submit = (event) => {
    event.preventDefault();
    const data = serializeForm(event.target);
    data.profiles = this.props.tempPost.profiles;
    this.props.fetchCreatePostPending(data);
    this.setState({ formKey: Date.now().toString() });
  };

  render() {
    return (
      <Column as="form" onSubmit={this.submit} key={this.state.formKey}>
        <PostFormComponent
          pending={this.props.pending}
          edit={this.props.id}
          errors={this.props.errors}
          id={this.props.id}
          logo={this.props.logo}
          title={this.props.title}
          description={this.props.description}
          changePostTemp={this.props.changePostTemp}
        />

        {this.state.showSuccessMessage && (
          <MessageComponent
            type="success"
            text="Successfully posted to RWRD App newsfeed"
            customStyles={{ marginTop: 30 }}
          />
        )}
      </Column>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  pending: selectPostPending,
  _id: selectPostId,
  title: selectPostTitle,
  description: selectPostDescription,
  image: selectPostImage,
  errors: selectPostErrors,
  posts: selectPostsList,
  tempPost: selectTemp,
});

const mapDispatchToProps = {
  fetchUpdatePostPending: postActions.fetchUpdatePostPending,
  fetchCreatePostPending: postActions.fetchCreatePostPending,
  fetchPost: postActions.fetchPostPending,
  fetchPosts: postsActions.fetchPending,
  changePostTemp: postActions.changeTemp,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PostFormContainer)
);
