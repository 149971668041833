import React, { useEffect } from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  align-items: end;
  justify-content: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  margin-bottom: calc(50vh);
  width: 80%;
  max-width: 500px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  cursor: pointer;
`;

export const PlaceIdModal = ({ isOpen, onClose }) => {
  useEffect(() => {
    if (!isOpen) return;

    const initMap = () => {
      if (!window.google) return;

      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: -33.8688, lng: 151.2195 },
        zoom: 13,
      });
      const input = document.getElementById('pac-input');
      const autocomplete = new window.google.maps.places.Autocomplete(input, {
        fields: ['place_id', 'geometry', 'formatted_address', 'name'],
      });

      autocomplete.bindTo('bounds', map);

      const infowindow = new window.google.maps.InfoWindow();
      const infowindowContent = document.getElementById('infowindow-content');

      infowindow.setContent(infowindowContent);

      const marker = new window.google.maps.Marker({ map });
      marker.addListener('click', () => {
        infowindow.open(map, marker);
      });

      autocomplete.addListener('place_changed', () => {
        infowindow.close();
        const place = autocomplete.getPlace();

        if (!place.geometry || !place.geometry.location) return;

        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17);
        }

        marker.setPlace({
          placeId: place.place_id,
          location: place.geometry.location,
        });
        marker.setVisible(true);

        infowindowContent.style.display = 'block';
        infowindowContent.children.namedItem('place-name').textContent =
          place.name;
        infowindowContent.children.namedItem('place-id').textContent =
          place.place_id;
        infowindowContent.children.namedItem('place-address').textContent =
          place.formatted_address;
        infowindow.open(map, marker);
      });
    };

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    window.initMap = initMap;

    return () => {
      document.head.removeChild(script);
      delete window.initMap;
    };
  }, [isOpen]);

  return (
    <ModalOverlay isOpen={isOpen}>
      <ModalContent>
        <CloseButton onClick={onClose}>×</CloseButton>
        <h2>Find Google Place ID</h2>
        <p>Type the place name and select to see the Place ID.</p>
        <input
          id="pac-input"
          type="text"
          placeholder="Enter a location"
          style={{ marginBottom: '10px', width: '100%' }}
        />
        <div id="map" style={{ height: '300px', marginBottom: '10px' }} />
        <div id="infowindow-content" style={{ display: 'none' }}>
          <strong id="place-name" />
          <br />
          Place ID: <span id="place-id" />
          <br />
          <span id="place-address" />
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};
