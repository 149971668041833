import { handleActions, combineActions } from 'redux-actions';
import postsActions from './posts.actions';
import postActions from '../post/post.actions';
import authActions from '../auth/auth.actions';

export const postsInitialState = {
  message: '',
  errors: {},
  pending: false,

  posts: [],
};

const postsReducer = handleActions(
  {
    [combineActions(postsActions.fetchPending)]: (state) => ({
      ...state,
      pending: true,
    }),

    [combineActions(postsActions.fetchSuccess)]: (state, action) => ({
      ...state,
      posts: action.payload.posts,
      pending: false,
    }),

    // FILTER POSTS LIST AFTER DELETING POST
    // [combineActions(postActions.fetchDeleteSuccess)]: (state, action) => ({
    //   ...state,
    //   posts: state.posts.filter(post => post._id !== action.payload._id),
    //   pending: false
    // }),

    // set new post to the list after create
    [postActions.fetchCreatePostSuccess]: (state, action) => ({
      ...state,
      posts: [...state.posts, action.payload.post],
    }),

    // update post in the list after update
    [postActions.fetchUpdatePostSuccess]: (state, action) => ({
      ...state,
      posts: state.posts.map((post) => {
        if (post._id === action.payload.post._id) {
          return { ...post, ...action.payload.post };
        }
        return post;
      }),
    }),
    [postActions.fetchDeletePostSuccess]: (state, action) => ({
      ...state,
      posts: state.posts.filter((post) => post._id !== action.payload._id),
    }),

    [postsActions.fetchFailed]: (state, action) => ({
      ...state,
      pending: false,
      error: action.payload.error,
      message: action.payload.message,
    }),
    [combineActions(postsActions.clearPostsState, authActions.logout)]: () => ({
      ...postsInitialState,
    }),
  },
  postsInitialState
);

export default postsReducer;
