import React from 'react';
import {
  StyledCard,
  StyledCardBadge,
  StyledCardContent,
  StyledCardPrice,
  StyledCardTag,
  StyledCardTitle,
  StyledCardValue,
  StyledButtonLink,
} from './styles';

const AddonsItem = ({
  isActive,
  title,
  price,
  term,
  item,
  badge,
  tags,
  handleCarouselActiveItem,
  handlePopup,
}) => {
  const coloredTags = tags.map((tag) => (
    <StyledCardTag key={tag.id} color={tag.tag_color}>
      {tag.tag_text}
    </StyledCardTag>
  ));
  return (
    <StyledCard
      isActive={isActive}
      onClick={() => {
        handleCarouselActiveItem(item);
      }}
    >
      {badge && <StyledCardBadge>{badge}</StyledCardBadge>}
      <StyledCardContent isActive={isActive}>
        <StyledCardTitle>{title}</StyledCardTitle>
        <StyledCardValue>
          <StyledCardPrice>£{price}</StyledCardPrice>
          {!!term && term}
        </StyledCardValue>
        {tags && <div>{coloredTags}</div>}
        <StyledButtonLink
          tagsNumber={tags.length}
          onClick={() => {
            handlePopup(item);
          }}
        >
          Learn more...
        </StyledButtonLink>
      </StyledCardContent>
    </StyledCard>
  );
};

export default AddonsItem;
