import styled from 'styled-components';
import { InputMain } from '../MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';

export const ColorPickerInput = styled(InputMain).attrs({ as: 'div' })`
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
`;
export const ColorPickerWrapper = styled.div`
  position: relative;
`;
export const PickerCover = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.1);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;
export const SelectedColor = styled.div`
  cursor: pointer;
  position: absolute;
  left: 6px;
  right: 6px;
  top: 6px;
  bottom: 6px;
  background-color: ${(props) => props.bgColor || mainTheme.colors.white};
`;

const pickedHeight = 136;
export const PickedContainer = styled.div`
  position: absolute;
  left: 0;
  top: -${pickedHeight + mainTheme.offset.xsmall}px;
  z-index: 9;
  background-color: ${(props) =>
    props.picker === 'circle' ? '#bdbdbd' : 'none'};
  padding: ${(props) =>
    props.picker === 'circle' ? `${mainTheme.offset.xsmall}px` : 'none'};
  height: ${(props) =>
    props.picker === 'circle' ? `${pickedHeight}px` : 'auto'};
  border-radius: ${(props) =>
    props.picker === 'circle' ? `${mainTheme.radius.xsmall}px` : 'none'};
`;
