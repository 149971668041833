import styled from 'styled-components';
import { mainTheme } from '../../mainStyles';
import {
  FieldLabel,
  Button,
} from '../../components/MainComponents/MainComponents';

export const OnboardingButton = styled(Button)`
  margin-left: 25px;
  margin-bottom: 0;
  text-transform: uppercase;
`;

export const Link = styled.a`
  font-weight: 400;
  color: ${mainTheme.colors.grey};
  text-decoration: underline;
  cursor: pointer;
`;

export const Title = styled(FieldLabel)`
  font-family: Avenir;
  font-size: 14px;
  color: #000;
  margin-bottom: 0;
  letter-spacing: 1px;
`;

export const StepBlock = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Circle = styled.span`
  width: 45px;
  height: 45px;
  background-color: #909090;
  border-radius: 50%;
  margin-right: 15px;
`;
export const Line = styled.div`
  height: 70px;
  border-left: 2px solid #909090;
  position: static;
  margin-left: 22px;
`;

export const TermsCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckBox = styled.input`
  height: 20px;
  width: 20px;
  margin-right: 5px;
  appearance: none;
  border: 1px solid ${mainTheme.colors.grey};
  border-radius: 3px;
  outline: none;
  position: relative;

  &:checked {
    &:after {
      content: '✔';
      font-size: 14px;
      position: absolute;
      top: 0px;
      left: 3px;
      color: ${mainTheme.colors.grey};
    }
  }
`;
