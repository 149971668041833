import styled from 'styled-components';

export const StyledSigninPageWrapper = styled.div`
  display: flex;
  width: 100%;

  /* @media (max-width: 990px) {
		flex-direction: column;
	} */

  /* new styles */
  flex-direction: column;
`;
