import React from 'react';
import { AuthForm } from '../../screens/AuthScreen-v2/styles';
import {
  Column,
  StyledButton,
  StyledFormTitle,
  StyledInput,
  StyledInputContainer,
  StyledInputLabel,
  StyledLogoContainer,
} from '../MainComponents/MainComponents';
import MessageComponent from '../MessageComponent/MessageComponent';

import lock from '../../assets/images/icons/lock.svg';
import logo2 from '../../assets/images/image4.svg';

const ResetPasswordFormComponent = ({
  resetPassword,
  errors,
  message,
  successMessage,
}) => {
  return (
    <AuthForm fullWidth as="form" onSubmit={resetPassword} width={555}>
      <Column
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <StyledLogoContainer>
          <img src={logo2} alt="logo" />
        </StyledLogoContainer>
        <div>
          <StyledFormTitle marginBot={48}>Create new password</StyledFormTitle>
        </div>

        <StyledInputContainer marginBot={24}>
          <StyledInputLabel>New password</StyledInputLabel>
          <StyledInput
            icon={lock}
            placeholder="Enter new password"
            name="password"
            type="password"
            errors={errors.password}
          />
        </StyledInputContainer>

        <StyledInputContainer marginBot={32}>
          <StyledInputLabel>Confirm password</StyledInputLabel>
          <StyledInput
            icon={lock}
            placeholder="Confirm password"
            name="password_confirmation"
            type="password"
            errors={errors.password}
          />
        </StyledInputContainer>
        <MessageComponent type="danger" text={message} />
        <MessageComponent type="success" text={successMessage} />
        <StyledButton bgColor="#f5cc28" type="submit">
          Change password
        </StyledButton>
      </Column>
    </AuthForm>
  );
};

export default ResetPasswordFormComponent;
