import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { DropDownImagePickerOption } from './styles';
import { Dropdown } from '../MainComponents/MainComponents';
import HiddenInput from '../HiddenInput/HiddenInput';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: '#ededed',
  }),
};

const CustomSelect = (props) => <Select styles={customStyles} {...props} />;

export default class DropDownImagePickerComponent extends Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    name: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
  };

  state = {
    selected: '',
  };

  getSelected = (value) => {
    return this.props.options.find((option) => option.id == value);
  };

  renderOption = (option) => {
    return <DropDownImagePickerOption image={option.name} />;
  };

  handleChange = (image) => {
    this.setState({ selected: image });
    this.props.onChange(image.id);
  };

  clear = () => this.setState({ selected: this.props.defaultValue });

  render() {
    const selectedFromProps = this.getSelected(this.props.defaultValue);
    const inputValue = this.state.selected
      ? this.state.selected.id
      : selectedFromProps
      ? selectedFromProps.id
      : '';

    return (
      <>
        <Dropdown
          error={this.props.error}
          arrowRenderer={() => <div>a</div>}
          as={CustomSelect}
          value={this.state.selected || selectedFromProps}
          formatOptionLabel={this.renderOption}
          onChange={this.handleChange}
          options={this.props.options}
        />
        <HiddenInput
          value={inputValue}
          onClear={this.clear}
          name={this.props.name}
        />
      </>
    );
  }
}
