import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mainTheme, newTheme } from '../../mainStyles';

const associate = {
  warning: mainTheme.colors.google,
  danger: mainTheme.colors.danger,
  info: mainTheme.colors.grey,
  success: mainTheme.colors.success,
};

export default class ErrorMessageComponent extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string,
    text: PropTypes.string,
    customStyles: PropTypes.object,
  };

  static defaultProps = {
    // One of type  [success, info, warning, danger]
    type: 'info',
    text: '',
    customStyles: {},
  };

  getColor = () => {
    let color = associate.info;
    color = associate[this.props.type] || color;
    return color;
  };

  render() {
    return (
      <ErrorMessage color={this.getColor()} style={this.props.customStyles}>
        {this.props.text}
      </ErrorMessage>
    );
  }
}

const ErrorMessage = styled.p`
  font-family: ${newTheme.fontFamily.primary.light};
  color: ${(props) => props.color};
  font-size: ${mainTheme.fontSizes.small}px;
`;
