import styled from 'styled-components';
import { mainTheme } from '../../mainStyles';

export const DropDownImagePickerComponentWrapper = styled.div`
  position: relative;
`;

export const DropDownImagePickerOption = styled.div`
  background-color: ${mainTheme.colors.white};
  width: 100%;
  height: 29px;
  background-image: url(${(props) => props.image});
  background-position: center;
  min-width: 240px;
  background-size: cover;
  background-repeat: no-repeat;
`;
