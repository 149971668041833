import styled from 'styled-components';
import { mainTheme } from '../../mainStyles';
import {
  PaperComponent,
  InputValidation,
} from '../../components/MainComponents/MainComponents';

const detailWidth = 524;
const paymentWidth = 387;
const offset = 40;

const mediaQuery = detailWidth + paymentWidth + offset;

export const PaymentsSubscriptionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: stretch;
  justify-content: space-between;
  @media (max-width: ${mediaQuery}px) {
    flex-direction: column;
    padding: ${mainTheme.offset.small}px;
  }
`;

export const PaymentsSubscriptionContent = styled(PaymentsSubscriptionWrapper)`
  padding: 0;
  @media (max-width: ${mediaQuery}px) {
    padding: 0;
  }
`;
export const SubscriptionDescription = styled(PaperComponent)`
  max-width: 524px;
  flex: 1;
  width: 100%;
  @media (max-width: ${mediaQuery}px) {
    max-width: initial;
  }
`;

export const SubscriptionPayment = styled(SubscriptionDescription)`
  max-width: 380px;
  margin-left: 41px;
  justify-content: space-between;
  flex: 1;
  @media (max-width: ${mediaQuery}px) {
    max-width: initial;
    margin-left: 0;
    margin-top: ${mainTheme.offset.medium}px;
  }
`;
export const InputSmall = styled(InputValidation)`
  max-width: 60px;
`;

export const InputCard = styled(InputSmall)`
  margin-right: 25px;
  padding-left: 15px;
  padding-right: 15px;
`;
