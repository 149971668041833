import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { MainContentOffset } from '../MainScreen/styles';
import {
  RowOffsetBottom,
  MainTitleDark,
  SmallBlock,
  ColumnOffsetBottom,
  MobileContent,
} from '../../components/MainComponents/MainComponents';
import { RegionalFilter, FilterButton, ProfilePicker } from './styles';

import ChartsPeriods from '../../containers/ChartsPeriods/ChartsPeriods';
import ChartsStatisticViewsContainer from '../../containers/ChartsStatisticViewsContainer/ChartsStatisticViewsContainer';
import profileActions from '../../redux/profile/profile.actions';
import {
  getFormattedAnalyticsData,
  getFormattedCustomesInsights,
} from '../../utils/stats';
import { CustomerInsightsContainer } from '../../containers/CustomerInsights/CustomerInsightsContainer';

export const regionalFilters = {
  region: 'Regions',
  national: 'National',
  global: 'Global',
};
class DashboardScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeGrain: 'week',
      regionalFilter: regionalFilters.region,
      selectedProfile: { _id: null, name: 'All profiles' },
      profileOptions: [
        { value: null, label: 'All profiles' },
        ...(props.user.profile?.map((p) => {
          return {
            value: p._id,
            label: p.name,
          };
        }) || []),
      ],
    };
  }

  // componentDidMount() {
  // 	this.makeStatsRequest();
  // }
  componentDidMount() {
    console.log(this.props.profile);
    if (
      this.props.profile?.analytics?.length ||
      this.props.profile.pendingAnalytics
    ) {
      return;
    }
    this.makeStatsRequest();
  }

  componentDidUpdate(prevProps, prevState) {
    const { period } = this.props.stats;
    const { regionalFilter, selectedProfile } = this.state;
    // If time filter changes, make a request accordingly
    if (
      prevProps.stats.period !== period ||
      prevState.regionalFilter !== regionalFilter ||
      prevState.selectedProfile !== selectedProfile
    ) {
      this.makeStatsRequest(period, regionalFilter);
    }

    if (
      Array.isArray(this.props.user.profile) &&
      // prevProps.user &&
      !!prevProps.user.profile &&
      !!this.props.user.profile &&
      // prevProps.user.profile.length !== this.props.user.profile.length &&
      this.props.user.profile.length !== this.state.profileOptions.length - 1
    ) {
      this.setState({
        profileOptions: [
          { value: null, label: 'All profiles' },
          ...(this.props.user.profile?.map((p) => {
            return {
              value: p._id,
              label: p.name,
            };
          }) || []),
        ],
      });
    }
  }

  onSelectedProfileChange = (item) => {
    this.setState({
      selectedProfile: {
        _id: item.value,
        name: item.label,
      },
    });
  };

  makeStatsRequest(period = 4, regionalFilter = 'Regions') {
    const today = moment();
    let momentPeriod = '';
    // Total -> Default
    let timeGrain = '';
    let from_date = '';
    let to_date = '';

    // Week -> request to get analytics data - Week must start on a Monday and end on a Monday (from Flurry)
    if (period === 2) {
      momentPeriod = 'isoWeek';
      timeGrain = 'week';
    }

    if (period === 3) {
      momentPeriod = 'month';
      timeGrain = 'month';
    }

    if (period === 4) {
      timeGrain = 'all';
      from_date = '2010-01-01';
      to_date = moment().add(1, 'days').format('YYYY-MM-DD');
    } else {
      from_date = today.startOf(momentPeriod).format('YYYY-MM-DD');
      to_date = today.endOf(momentPeriod).add(1, 'days').format('YYYY-MM-DD');
    }

    const timeLapse = `${from_date}/${to_date}`;
    this.setState({ timeGrain });
    if (!this.state.selectedProfile || !this.state.selectedProfile._id) {
      this.props.fetchAnalyticsAll(timeGrain, timeLapse, regionalFilter);
    } else {
      const profileId = this.state.selectedProfile._id;
      this.props.fetchAnalyticsById(
        timeGrain,
        timeLapse,
        regionalFilter,
        profileId
      );
    }
  }

  toggleRegionalFilter = (filter) => {
    this.setState({ regionalFilter: filter });
  };

  render() {
    const formattedAnalyticsData = getFormattedAnalyticsData(
      this.props.profile.analytics
    );
    const formattedCustomersInsightsData = getFormattedCustomesInsights(
      this.props.profile.analytics
    );
    return (
      <MainContentOffset>
        <ProfilePicker
          value={{
            value: this.state.selectedProfile._id,
            label: this.state.selectedProfile.name,
          }}
          name="selectedProfile"
          onSelect={(item) => this.onSelectedProfileChange(item)}
          options={this.state.profileOptions}
        />
        <RowOffsetBottom as={MobileContent} wrap>
          <SmallBlock>
            <ColumnOffsetBottom>
              <MainTitleDark>Venue Analytics</MainTitleDark>
            </ColumnOffsetBottom>
          </SmallBlock>

          <ColumnOffsetBottom>
            <ChartsPeriods />
          </ColumnOffsetBottom>
        </RowOffsetBottom>

        <RowOffsetBottom as={MobileContent}>
          <ChartsStatisticViewsContainer
            selectedProfile={this.state.selectedProfile}
            analyticsData={formattedAnalyticsData}
            timeGrain={this.state.timeGrain}
          />
        </RowOffsetBottom>

        <>
          <RowOffsetBottom as={MobileContent} wrap>
            <SmallBlock>
              <ColumnOffsetBottom style={{ marginBottom: 0 }}>
                <MainTitleDark>Customer Insights</MainTitleDark>
              </ColumnOffsetBottom>
              <RegionalFilter>
                <FilterButton
                  style={{
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                  }}
                  onClick={() =>
                    this.toggleRegionalFilter(regionalFilters.region)
                  }
                  active={this.state.regionalFilter === regionalFilters.region}
                >
                  {regionalFilters.region}
                </FilterButton>
                <FilterButton
                  style={{ borderWidth: 1 }}
                  onClick={() =>
                    this.toggleRegionalFilter(regionalFilters.national)
                  }
                  active={
                    this.state.regionalFilter === regionalFilters.national
                  }
                >
                  {regionalFilters.national}
                </FilterButton>
                <FilterButton
                  style={{
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                  onClick={() =>
                    this.toggleRegionalFilter(regionalFilters.global)
                  }
                  active={this.state.regionalFilter === regionalFilters.global}
                >
                  {regionalFilters.global}
                </FilterButton>
              </RegionalFilter>
            </SmallBlock>
          </RowOffsetBottom>

          <RowOffsetBottom as={MobileContent}>
            <CustomerInsightsContainer
              analyticsData={formattedCustomersInsightsData}
            />
          </RowOffsetBottom>
        </>
      </MainContentOffset>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    profile: state.profile,
    stats: state.statistic,
  };
};

const mapDispatchToProps = {
  fetchAnalyticsAll: profileActions.fetchAnalyticsAllPending,
  fetchAnalyticsById: profileActions.fetchAnalyticsByIdPending,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen);
