import styled from 'styled-components';
import DropDownComponent from '../../components/DropDownComponent/DropDownComponent';
import {
  Row,
  PaperComponent,
  Column,
  Text,
} from '../../components/MainComponents/MainComponents';

export const ChartsWrapper = styled(Row)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 49%);
  grid-gap: 20px;
  justify-content: space-between;
  align-content: space-between;
  align-items: stretch;
  margin-bottom: 30px;
  // height: 350px;

  @media (max-width: 1040px) {
    grid-template-columns: repeat(auto-fill, 100%);
  }

  @media (max-width: 990px) {
    grid-template-columns: repeat(auto-fill, 80%);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, 100%);
  }
`;

export const Wrapper = styled(Row)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 48%);
  grid-gap: 20px;
  justify-content: space-between;

  text-transform: uppercase;
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  & > h2 {
    font-size: 24px;
  }
  & p {
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: 1040px) {
    grid-template-columns: repeat(auto-fill, 100%);
  }

  @media (max-width: 990px) {
    grid-template-columns: repeat(auto-fill, 80%);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, 100%);
  }
`;

export const FullWidthWrapper = styled(ChartsWrapper)`
  grid-template-columns: repeat(auto-fill, 100%);
`;

export const FullWidthPaper = styled(PaperComponent)`
  max-width: none;
`;

export const BankAccountWrapper = styled(ChartsWrapper)`
  grid-template-columns: repeat(auto-fill, 35%);
  justify-content: start;
  text-transform: uppercase;
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
`;

export const BankAccountContainer = styled(Row)`
  margin: 10px 0;
  font-weight: 800;
  color: rgba(96, 96, 96, 0.9);
  & img {
    padding-right: 10px;
  }
`;

export const BankName = styled(Text)`
  text-transform: uppercase;
  font-family: 'Avenir Heavy';
  & span {
    font-size: 12px;
    padding: 2px;
    background: rgba(173, 179, 196, 0.54);
    border-radius: 5px;
  }
`;

export const PercentLabel = styled.span`
  background: #cbf4c9;
  color: #3f8b68;
  padding: 0px 6px;
  border-radius: 5px;
  margin-left: 10px;
`;

export const FilterContainer = styled.div`
  width: 60%;
  margin-right: 15px;
  & div {
    margin: 0;
  }
`;

export const TipsAmountContainer = styled(Row)`
  margin-top: 25px;
  align-items: center;
`;

export const LineChartWrapper = styled.div`
  margin-top: 10px;
`;

export const SelectContainer = styled.div`
  & div {
    margin: 0;
  }
`;

export const CardNameWrapper = styled(Column)`
  & h2 {
    letter-spacing: 1px;
  }
`;

export const ProfilePicker = styled(DropDownComponent)`
  width: 30%;
`;
