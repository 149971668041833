import React from 'react';
import styled from 'styled-components';
import {
  StatisticViewComponentWrapper,
  StatisticComponentValue,
  StatisticComponentImage,
  Label,
  CustomRow,
  RegionalFilter,
  FilterButton,
} from './styles';
import DiffArrowComponent from '../DiffArrowComponent/DiffArrowComponent';

export const regionalFilters = { region: 'Region', total: 'Total' };

export default class StatisticViewComponent extends React.PureComponent {
  static defaultProps = {
    name: '',
    value: 0,
    diff: 0,
  };

  render() {
    const { regionalFilter, onClickFilter, value, name, icon /* diff */ } =
      this.props;

    return (
      <StatisticViewComponentWrapper>
        <Label>{name}</Label>

        <CustomRow>
          <StatisticComponentValue style={{ fontSize: 34 }}>
            {value}

            {/* <DiffArrowComponent diff={diff} /> */}
          </StatisticComponentValue>
          <StatisticComponentImage image={icon} />
        </CustomRow>

        {regionalFilter && (
          <RegionalFilter>
            <FilterButton
              style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
              onClick={() => onClickFilter(regionalFilters.region)}
              active={regionalFilter === regionalFilters.region}
            >
              {regionalFilters.region}
            </FilterButton>
            <FilterButton
              style={{ borderTopRightRadius: 10, borderBottomRightRadius: 10 }}
              onClick={() => onClickFilter(regionalFilters.total)}
              active={regionalFilter === regionalFilters.total}
            >
              {regionalFilters.total}
            </FilterButton>
          </RegionalFilter>
        )}
      </StatisticViewComponentWrapper>
    );
  }
}
