import { createAction } from 'redux-actions';

const fetchMePending = createAction('user--fetchMePending');
const fetchMeSuccess = createAction('user--fetchMeSuccess', (user) => ({
  user,
}));
const fetchMeFailed = createAction(
  'user--fetchMeFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

const fetchUpdateActivePending = createAction(
  'user--fetchUpdateActivePending',
  (active) => ({ active })
);
const fetchUpdateActiveSuccess = createAction(
  'user--fetchUpdateActiveSuccess',
  (active) => ({ active })
);
const fetchUpdateActiveFailed = createAction(
  'user--fetchUpdateActiveFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

// Update user account email, firstName, lastName
const updateAccountFetchPending = createAction(
  'user--updateAccountFetchPending'
);
const updateAccountFetchSuccess = createAction(
  'user--updateAccountFetchSuccess',
  (user) => ({ user })
);
const updateAccountFetchFailed = createAction(
  'user--updateAccountFetchFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

// Update user account password
const updateAccountPasswordFetchPending = createAction(
  'user--updateAccountPasswordFetchPending'
);
const updateAccountPasswordFetchSuccess = createAction(
  'user--updateAccountPasswordFetchSuccess'
);
const updateAccountPasswordFetchFailed = createAction(
  'user--updateAccountPasswordFetchFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

// create user's stripe Connect account
const connectAccountPending = createAction('user--connectAccountPending');
const connectAccountSuccess = createAction('user--connectAccountSuccess');
const connectAccountFailed = createAction(
  'user--connectAccountFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

// delete user account
const deletePending = createAction('user--deletePending');
const deleteSuccess = createAction('user--deleteSuccess');
const deleteFailed = createAction(
  'user--deleteFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

const feedbackPending = createAction('user--feedbackPending', (form) => ({
  form,
}));
const feedbackSuccess = createAction('user--feedbackSuccess');
const feedbackFailed = createAction(
  'user--feedbackFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);
// const fetchUpdateActivePending = createAction('user--fetchUpdateActivePending');
// const fetchUpdateActiveSuccess = createAction('user--fetchUpdateActiveSuccess');
// const fetchUpdateActiveFailed = createAction('user--fetchUpdateActiveFailed', (errors = {}, message = '') => ({
// 	errors,
// 	message
// }));

const clearUserState = createAction('user--clearUserState');
const clearUserErrors = createAction('user--clearUserErrors');

const userActions = {
  feedbackPending,
  feedbackSuccess,
  feedbackFailed,

  fetchMeFailed,
  fetchMePending,
  fetchMeSuccess,

  fetchUpdateActiveFailed,
  fetchUpdateActivePending,
  fetchUpdateActiveSuccess,

  updateAccountFetchFailed,
  updateAccountFetchPending,
  updateAccountFetchSuccess,

  updateAccountPasswordFetchFailed,
  updateAccountPasswordFetchPending,
  updateAccountPasswordFetchSuccess,

  deleteFailed,
  deletePending,
  deleteSuccess,

  connectAccountPending,
  connectAccountSuccess,
  connectAccountFailed,

  clearUserErrors,
  clearUserState,
};

export default userActions;
