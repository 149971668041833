import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { StripeProvider, Elements } from 'react-stripe-elements';

import {
  SubscriptionDescription,
  SubscriptionPayment,
  PaymentsSubscriptionWrapper,
  InputCard,
  PaymentsSubscriptionContent,
} from './styles';
import {
  TitleDark,
  Text,
  InputValidation,
  Button,
  RowOffsetBottom,
} from '../../components/MainComponents/MainComponents';
import { serializeForm } from '../../utils/selectors';
import {
  selectPaymentsPending,
  selectPaymentsErrors,
  selectPaymentsMessage,
} from '../../redux/payments/payments.selectors';
import { selectUserObject } from '../../redux/user/user.selectors';
import paymentsActions from '../../redux/payments/payments.actions';
import MessageComponent from '../../components/MessageComponent/MessageComponent';
import CreditCardDetails from '../CreditCardDetails/CreditCardDetails';
import storage from '../../utils/storage';

class SubscriptionDetailsContainer extends Component {
  static defaultProps = {
    description: true,
  };

  onClickBackButton = () => {
    this.props.history.push('/auth/subscriptions');
  };

  componentDidMount() {
    const step = storage.storageGet('step');
    if (!step && !!this.props.history) this.props.history.push('/auth/signin');

    if (this.props.user.card && this.props.description) {
      // Step completed - navigate to next step
      this.props.history.push('/auth/registered');
    }
    window.addEventListener('popstate', this.onClickBackButton);
  }

  render() {
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <PaymentsSubscriptionWrapper>
          <PaymentsSubscriptionContent>
            {this.props.description ? (
              <SubscriptionDescription style={{ borderRadius: 0 }}>
                <RowOffsetBottom>
                  <TitleDark as="h2">SUBSCRIPTION DETAILS</TitleDark>
                </RowOffsetBottom>
                <RowOffsetBottom>
                  <Text>
                    You are signing up for a <b>FREE TRIAL</b> with{' '}
                    <span style={{ fontFamily: 'Raleway' }}>RWRD</span>. There
                    is no obligation to continue your subscription and you can
                    cancel at any time.
                    <b> No payment will be taken</b>.
                  </Text>
                </RowOffsetBottom>
                <RowOffsetBottom>
                  <Text>
                    After your free trial, the subscription will renew
                    automatically on a monthly basis.
                  </Text>
                </RowOffsetBottom>

                <RowOffsetBottom>
                  <TitleDark as="h2">WHAT HAPPENS NEXT?</TitleDark>
                </RowOffsetBottom>
                <RowOffsetBottom>
                  <Text>
                    Once you click <b>submit</b> you will be taken to set up
                    your venue profile.
                  </Text>
                </RowOffsetBottom>
                <RowOffsetBottom>
                  <Text>
                    Once this is complete the{' '}
                    <b>
                      <span style={{ fontFamily: 'Raleway' }}>RWRD</span> Team
                      will contact you to arrange your in venue on-boarding.
                    </b>
                  </Text>
                </RowOffsetBottom>

                <RowOffsetBottom>
                  <Text>
                    Your free trial will not start until the on-boarding is
                    complete, and your venue is live on the{' '}
                    <span style={{ fontFamily: 'Raleway' }}>RWRD</span> app.
                  </Text>
                </RowOffsetBottom>
              </SubscriptionDescription>
            ) : null}
            <SubscriptionPayment
              style={{ maxHeight: 350, borderRadius: 0, minWidth: 300 }}
              as="div"
            >
              <RowOffsetBottom>
                <TitleDark as="h2">CARD DETAILS</TitleDark>
              </RowOffsetBottom>

              <Elements>
                <CreditCardDetails />
              </Elements>
            </SubscriptionPayment>
          </PaymentsSubscriptionContent>
        </PaymentsSubscriptionWrapper>
      </StripeProvider>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: selectUserObject,
});

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionDetailsContainer);
