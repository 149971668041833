import React from 'react';

import AddonsItem from '../AddonsItem/AddonsItem';
import {
  StyledDots,
  StyledItem,
  StyledRadioInput2,
  StyledRadioInput3,
  StyledSlider,
  Styledtestimonials,
} from './styles';

const TwoItemsCarousel = ({
  carouselActiveItem,
  items,
  getAddonData,
  handlePopup,
  handleCarouselActiveItem,
}) => {
  const addon0 = getAddonData(items[0]);
  const addon1 = getAddonData(items[1]);

  return (
    <StyledSlider>
      <StyledRadioInput2
        type="radio"
        name="testimonial"
        id="t-2"
        checked={carouselActiveItem === items[0]}
      />
      <StyledRadioInput3
        type="radio"
        name="testimonial"
        id="t-3"
        checked={carouselActiveItem === items[1]}
      />
      <Styledtestimonials className="testimonials">
        <StyledItem
          isActive={carouselActiveItem === items[0]}
          left
          for="t-2"
          onClick={() => {
            handleCarouselActiveItem(items[0]);
          }}
        >
          <AddonsItem
            isActive={carouselActiveItem === items[0]}
            item={items[0]}
            badge={addon0[0].card.badge}
            title={addon0[0].card.title}
            price={addon0[0].card.price}
            term={addon0[0].card.period}
            tags={addon0[0].card.tags}
            handlePopup={handlePopup}
            handleCarouselActiveItem={handleCarouselActiveItem}
          />
        </StyledItem>
        <StyledItem
          isActive={carouselActiveItem === items[1]}
          right
          for="t-3"
          onClick={() => {
            handleCarouselActiveItem(items[1]);
          }}
        >
          <AddonsItem
            isActive={carouselActiveItem === items[1]}
            item={items[1]}
            badge={addon1[0].card.badge}
            title={addon1[0].card.title}
            price={addon1[0].card.price}
            term={addon1[0].card.period}
            tags={addon1[0].card.tags}
            handlePopup={handlePopup}
            handleCarouselActiveItem={handleCarouselActiveItem}
          />
        </StyledItem>
      </Styledtestimonials>
      <StyledDots className="dots">
        <label
          htmlFor="t-2"
          onClick={() => {
            handleCarouselActiveItem(items[0]);
          }}
        />
        <label
          htmlFor="t-3"
          onClick={() => {
            handleCarouselActiveItem(items[1]);
          }}
        />
      </StyledDots>
    </StyledSlider>
  );
};

export default TwoItemsCarousel;
