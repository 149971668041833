import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import configStore from '../../redux/config.js';
import { AppContainerUI } from './styles';
import { mainTheme, GlobalStyle } from '../../mainStyles';
import RouterContainer from '../RouterContainer/RouterContainer';

export default class AppContainer extends Component {
  render() {
    return (
      <AppContainerUI>
        <Provider store={configStore}>
          <ThemeProvider theme={mainTheme}>
            <>
              <GlobalStyle />
              <RouterContainer />
            </>
          </ThemeProvider>
        </Provider>
      </AppContainerUI>
    );
  }
}
