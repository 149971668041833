import styled from 'styled-components';
import {
  PaperComponent,
  FieldLabel,
  Row,
} from '../MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';

export const StatisticViewComponentWrapper = styled(PaperComponent)`
  max-width: 220px;
  width: 100%;
  border-radius: 6px;
  margin-bottom: ${mainTheme.offset.small}px;
  padding: ${mainTheme.offset.xsmall}px ${mainTheme.offset.small}px;
  position: relative;

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

export const StatisticComponentValue = styled.h3`
  color: ${mainTheme.colors.primary};
  font-family: 'Avenir Bold';
  font-size: 36px;
  font-weight: 900;
  letter-spacing: 3px;
  line-height: normal;
`;

export const StatisticComponentImage = styled.div`
  width: 23px;
  height: 23px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${(props) => props.image});
  align-self: flex-end;
  margin-bottom: 10px;
`;

export const Label = styled(FieldLabel)`
  margin-bottom: 0;
  margin-right: 20px;
  min-height: 30px;
`;

export const CustomRow = styled(Row)`
  justify-content: space-between;
  align-items: baseline;
  flex: 1;

  & > h3 {
    align-self: flex-end;
  }
`;

export const RegionalFilter = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FilterButton = styled.button`
  width: 48%;
  height: 18px;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${mainTheme.colors.white};
  text-transform: uppercase;
  font-size: 8px;
  letter-spacing: 1px;
  background-color: ${(props) =>
    props.active ? '#474D4F' : mainTheme.colors.lightgrey};
  color: ${(props) =>
    props.active ? mainTheme.colors.white : mainTheme.colors.textDark};
`;
