import { fork, put, takeLatest } from 'redux-saga/effects';
import client, { handleRequestError } from '../../utils/client';
import paymentsActions from './payments.actions';
import userActions from '../user/user.actions';
import storage from '../../utils/storage';

function* fetchSubscription(action) {
  try {
    const response = yield client.get('/payments/subscriptions');
    yield put(paymentsActions.fetchSubscriptionSuccess(response.data));
  } catch (error) {
    yield handleRequestError(
      error,
      paymentsActions.fetchSubscriptionFailed,
      fetchSubscription,
      action
    );
  }
}

function* fetchSubscribe(action) {
  try {
    const { subscription } = action.payload;
    const { addon } = action.payload;
    const response = yield client.post('/user/subscription', {
      subscription,
      addon,
    });
    // Update registration step
    storage.storageSet('step', response.data.step);
    yield put(
      paymentsActions.fetchSubscribeSuccess(response.data.subscription)
    );
  } catch (error) {
    yield handleRequestError(
      error,
      paymentsActions.fetchSubscribeFailed,
      fetchSubscription,
      action
    );
  }
}

function* fetchCreateStripeCard(action) {
  try {
    const { token } = action.payload;
    const response = yield client.post('/user/payment-card', { token });

    // Update registration step
    storage.storageSet('step', response.data.step);

    yield put(paymentsActions.fetchCreateStripeCardSuccess(response.data));
  } catch (error) {
    yield handleRequestError(
      error,
      paymentsActions.fetchCreateStripeCardFailed,
      fetchCreateStripeCard,
      action
    );
  }
}

function* fetchUpdateStripeCard(action) {
  try {
    const { token } = action.payload;
    const response = yield client.put('/user/payment-card', { token });
    yield put(paymentsActions.fetchUpdateStripeCardSuccess(response.data));
    yield put(userActions.fetchMePending());
  } catch (error) {
    yield handleRequestError(
      error,
      paymentsActions.fetchUpdateStripeCardFailed,
      fetchUpdateStripeCard,
      action
    );
  }
}
function* PaymentsFlow() {
  yield takeLatest(paymentsActions.fetchSubscriptionPending, fetchSubscription);
  yield takeLatest(paymentsActions.fetchSubscribePending, fetchSubscribe);
  yield takeLatest(
    paymentsActions.fetchCreateStripeCardPending,
    fetchCreateStripeCard
  );
  yield takeLatest(
    paymentsActions.fetchUpdateStripeCardPending,
    fetchUpdateStripeCard
  );
}

function* paymentsWorker() {
  yield fork(PaymentsFlow);
}

export default paymentsWorker;
