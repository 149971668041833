import styled from 'styled-components';
import { SmallBlock } from '../../components/MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';

export const ProfileViewWrapper = styled(SmallBlock)`
  padding: 0 ${mainTheme.offset.large}px;
  @media (max-width: ${mainTheme.sizes.small}px) {
    justify-content: center;
    width: 100%;
    padding: 0;
  }
`;
