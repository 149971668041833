import { createSelector } from 'reselect';

// Tips by range
const rootSelector = (state) => state.tips;
export const selectTipsList = createSelector(rootSelector, (tips) => tips.tips);
// export const selectTipsTotal = createSelector(
//   rootSelector,
//   tips => (tips.total / 100).toFixed(2)
// );

// Payouts
export const selectPayoutsList = createSelector(
  rootSelector,
  (tips) => tips.payouts
);
export const selectPayoutsNextAmount = createSelector(rootSelector, (tips) =>
  (tips.nextPayoutAmount / 100).toFixed(2)
);

// All tips
export const selectAllTipsList = createSelector(
  rootSelector,
  (tips) => tips.allTips
);
export const selectTotalSum = createSelector(rootSelector, (tips) =>
  (tips.totalSum / 100).toFixed(2)
);
export const selectAverageTip = createSelector(rootSelector, (tips) =>
  (tips.averageTip / 100).toFixed(2)
);
export const selectCustomersCount = createSelector(
  rootSelector,
  (tips) => tips.customersCount
);

// Bank details
export const selectBankName = createSelector(
  rootSelector,
  (tips) => tips.bankName
);
export const selectBankCurrency = createSelector(
  rootSelector,
  (tips) => tips.currency
);
export const selectLast4 = createSelector(rootSelector, (tips) => tips.last4);
export const selectRoutingNumber = createSelector(
  rootSelector,
  (tips) => tips.routingNumber
);

// Common
export const selectTipsPending = createSelector(
  rootSelector,
  (tips) => tips.pending
);
export const selectTipsErrors = createSelector(
  rootSelector,
  (tips) => tips.errors
);
export const selectTipsMessage = createSelector(
  rootSelector,
  (tips) => tips.message
);
