import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import paymentsActions from '../../redux/payments/payments.actions';
import {
  selectPaymentsPending,
  selectSubscriptions,
} from '../../redux/payments/payments.selectors';
import { selectUserObject } from '../../redux/user/user.selectors';
import AuthSubscriptionPageComponent from '../../components/AuthSubscriptionPageComponent/AuthSubscriptionPageComponent';
import storage from '../../utils/storage';
import { AUTH_ROUTS } from '../../constants/routes';
import { selectPaymentsMessage } from '../../redux/payments/payments.selectors';

class SubscriptionContainer extends Component {
  static propTypes = {
    subscriptions: PropTypes.array,
  };

  static defaultProps = {
    subscriptions: [],
  };

  componentDidMount() {
    const step = storage.storageGet('step');
    if (step === 'registered') this.props.history.push(AUTH_ROUTS.CONFIRMATION);
    if (step) this.props.history.push(step);

    // if (typeof this.props.user.subscription === 'string') {
    // 	// This step has been completed - navigate to next step
    // 	this.props.history.push('/auth/payments');
    // }

    // if (!this.props.subscriptions.length) {
    // 	this.props.fetchSubscriptions();
    // }
    // window.addEventListener('popstate', () => this.props.history.push('/auth/signin'));
  }

  onSubscribe = (value) => {
    this.props.subscribe(value);
  };

  subscribe = (id) => {
    this.onSubscribe(id);
  };

  render() {
    return (
      <AuthSubscriptionPageComponent
        handleSubscribe={this.subscribe}
        message={this.props.message}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  pending: selectPaymentsPending,
  subscriptions: selectSubscriptions,
  user: selectUserObject,
  message: selectPaymentsMessage,
});

const mapDispatchToProps = {
  // fetchSubscriptions: paymentsActions.fetchSubscriptionPending,
  subscribe: paymentsActions.fetchSubscribePending,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionContainer);
