import styled from 'styled-components';
import DropDownComponent from '../../components/DropDownComponent/DropDownComponent';
import { mainTheme } from '../../mainStyles';

export const RegionalFilter = styled.div`
  top: 0px;
  right: 0px;
  width: 180px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FilterButton = styled.button`
  width: 68px;
  height: 18px;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${mainTheme.colors.white};
  text-transform: uppercase;
  font-size: 8px;
  letter-spacing: 1px;
  background-color: ${(props) =>
    props.active ? '#474D4F' : mainTheme.colors.lightgrey};
  color: ${(props) =>
    props.active ? mainTheme.colors.white : mainTheme.colors.textDark};
`;

export const ProfilePicker = styled(DropDownComponent)`
  width: 30%;
`;
