import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  TextAreaWrapper,
  TextAreaMain,
} from '../MainComponents/MainComponents';

export default class TextAreaComponent extends PureComponent {
  static propTypes = {
    defaultValue: PropTypes.string,
    name: PropTypes.string,
    onChange: () => {},
  };

  static defaultProps = {
    defaultValue: '',
    name: '',
    onInputChange: () => {},
  };

  state = {
    value: '',
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.value && props.defaultValue) {
      return {
        ...state,
        value: props.defaultValue,
      };
    }
    return state;
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultValue != prevProps.defaultValue) {
      this.setState({ value: this.props.defaultValue });
    }
  }

  onInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({ value });
    this.props.onChange && this.props.onChange(value);
    this.props.onInputChange(value, name);
  };

  render() {
    const { maxLength, defaultValue, ...props } = this.props;
    return (
      <TextAreaWrapper data-max={maxLength - this.state.value.length}>
        <TextAreaMain
          {...props}
          onChange={this.onInputChange}
          maxLength={maxLength}
          value={this.state.value}
        />
      </TextAreaWrapper>
    );
  }
}
