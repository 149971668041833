import React from 'react';

import BrandingBoosterCard from '../BrandingBoosterCard/BrandingBoosterCard';

import { StyledLayout } from '../../../../screens/AuthScreen-v2/styles';
import { StyledLayoutWrapper } from '../../../MainComponents/MainComponents';
import {
  StyledPageWrapper,
  StyledSection,
} from '../../../AuthSubscriptionPageComponent/styles';
import {
  StyledAddButton,
  StyledAddonContainer,
  StyledButtonContainer,
  StyledCancelButton,
  StyledCloseButton,
  StyledContainer,
  StyledDescriptionText,
  StyledTextSection,
  StyledTitleText,
  StyledLine,
  StyledListContainer,
  StyledListTitle,
  StyledList,
  StyledListItem,
  StyledListItemText,
} from './styles';

import { ReactComponent as CloseIcon } from '../../../../assets/images/popup-close-icon.svg';
import includedIcon from '../../../../assets/images/icons/included.svg';
import popupBg from '../../../../assets/images/backgrounds/popupBg.png';

const list = [
  {
    id: 1,
    text: 'Blog post',
  },
  {
    id: 4,
    text: 'Custom card design',
  },
  {
    id: 2,
    text: 'Interviews',
  },
  {
    id: 5,
    text: 'Shareable social asset',
  },
  {
    id: 3,
    text: 'Content creation',
  },

  {
    id: 6,
    text: 'Venue spotlight on RWRD newsletter',
  },
];

const BrandingBoosterPopup = ({
  card,
  handleBrandingBoostPopup,
  acceptOffer,
  skipOffer,
}) => {
  return (
    <StyledPageWrapper>
      <StyledLayout height="100%">
        <StyledLayoutWrapper>
          <StyledSection paddingTop={30} paddingBot={30} bgImage={popupBg}>
            <StyledCloseButton
              onClick={() => handleBrandingBoostPopup(false, 1)}
            >
              <CloseIcon />
            </StyledCloseButton>
            <StyledContainer>
              <StyledTextSection>
                <StyledTitleText marginBot={16}>
                  Do you need branding support?
                </StyledTitleText>
                <StyledLine />

                {/* addon  */}
                <StyledAddonContainer>
                  <BrandingBoosterCard
                    isActive
                    badge={card.badge}
                    title={card.title}
                    price={card.price}
                    term={card.period}
                  />
                </StyledAddonContainer>
                <StyledListContainer>
                  <StyledListTitle>
                    What's included in Branding Boost?
                  </StyledListTitle>

                  <StyledList>
                    {list.map((item) => (
                      <StyledListItem key={item.id}>
                        <img src={includedIcon} alt="" />{' '}
                        <StyledListItemText>{item.text}</StyledListItemText>
                      </StyledListItem>
                    ))}
                  </StyledList>
                </StyledListContainer>
              </StyledTextSection>
              <StyledButtonContainer>
                <StyledCancelButton onClick={skipOffer}>
                  Skip
                </StyledCancelButton>
                <StyledAddButton onClick={acceptOffer}>
                  Add to my plan
                </StyledAddButton>
              </StyledButtonContainer>
            </StyledContainer>
          </StyledSection>
        </StyledLayoutWrapper>
      </StyledLayout>
    </StyledPageWrapper>
  );
};

export default BrandingBoosterPopup;
