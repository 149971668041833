import moment from 'moment';

import { linearRegression, calculateRating } from './index';
import {
  milkChoicesOptions,
  customerDietsOptions,
  customerAgesOptions,
  coffeesPerDayOptions,
  drinkMostOftenOptions,
  buyForHomeOptions,
  favouriteCoffeeOptions,
} from '../constants/dashboard';

const toObjectWithDefaultValue = (array, defaultValue) => {
  return array.reduce((acc, value) => {
    acc[value] = defaultValue;
    return acc;
  }, {});
};

export const groupByDate = (data) => {
  return data.reduce((groups, obj) => {
    const date = moment(obj.updatedAt).format('YYYY-MM-DD');
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(obj);
    return groups;
  }, {});
};

export const getFormattedAnalyticsData = (analytics) => {
  const stampsHistory = analytics.find(
    (an) => an.statName === 'stampsHistory'
  ) || {
    data: { stamps: 0, redeems: 0 },
  };
  const ratingsHistory = analytics.find(
    (an) => an.statName === 'ratingsHistory'
  ) || { data: [] };
  const favoritesHistory = analytics.find(
    (an) => an.statName === 'favoritesHistory'
  ) || { data: [] };

  const ratings = groupByDate(ratingsHistory.data);
  const favorites = groupByDate(favoritesHistory.data);

  const xData = Object.keys(ratings);

  const ratingData = xData.map((date) => {
    return parseFloat(calculateRating(ratings[date]).toFixed(1));
  });
  const favoritesData = Object.keys(favorites).map((date) =>
    favorites[date] ? favorites[date].length : 0
  );

  // Calculate increase tendency
  const ratingsR = linearRegression(ratingData);
  const favoritesR = linearRegression(favoritesData);

  return {
    stampsHistory,
    ratingsHistory,
    favoritesHistory,
    xData,
    ratingData,
    ratingsDiff: ratingsR.m,
    favoritesDiff: favoritesR.m,
  };
};

export const getFormattedCustomesInsights = (analytics) => {
  return analytics.find((an) => an.statName === 'membersInsightsProcessed')
    ?.data;
};
