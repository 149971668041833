import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { ColumnOffsetBottom } from '../../components/MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';
import DoughnutChart from '../DoughnutChart/DoughnutChart';
import withBubbleChat from '../../hoc/WithBubbleChat/WithBubbleChat';
import { ChartsWrapper, HorizontalBarsWrapper } from './styles';
import { statsWithMessageTitles } from '../../constants/dashboard';
import SplitBar from '../../components/SplitBar/SplitBarComponent';

const DoughnutChartWithBubbleChat = withBubbleChat(DoughnutChart);
const SplitBarWithBubbleChat = withBubbleChat(SplitBar);

export class CustomerInsightsContainer extends Component {
  render() {
    const { analyticsData } = this.props;
    const { takeoutPerc, dontWorkRemotelyPerc, malesPerc } = analyticsData
      ? {
          takeoutPerc:
            (analyticsData.takeout /
              (analyticsData.sitIn + analyticsData.takeout)) *
              100 || 50,
          dontWorkRemotelyPerc:
            (analyticsData.dontWorkRemotely /
              (analyticsData.workingRemotely +
                analyticsData.dontWorkRemotely)) *
              100 || 50,
          malesPerc:
            (analyticsData.males /
              (analyticsData.females + analyticsData.males)) *
              100 || 50,
        }
      : {
          takeoutPerc: 0,
          dontWorkRemotelyPerc: 0,
          malesPerc: 0,
        };

    const analyticsDataSets = analyticsData || {
      milkChoices: [],
      customerDiets: [],
      customerAges: [],
      coffeesPerDay: [],
      drinkMostOften: [],
      buyForHome: [],
      favouriteCoffee: [],
    };
    const milkChoices = {
      labels: Object.keys(analyticsDataSets.milkChoices),
      datasets: [
        {
          data: Object.values(analyticsDataSets.milkChoices),
          backgroundColor: mainTheme.colors.greyScheme,
          hoverBackgroundColor: mainTheme.colors.greyScheme,
        },
      ],
    };
    const customerDiets = {
      labels: Object.keys(analyticsDataSets.customerDiets),
      datasets: [
        {
          data: Object.values(analyticsDataSets.customerDiets),
          backgroundColor: mainTheme.colors.greyScheme,
          hoverBackgroundColor: mainTheme.colors.greyScheme,
        },
      ],
    };
    const customerAges = {
      labels: Object.keys(analyticsDataSets.customerAges),
      datasets: [
        {
          data: Object.values(analyticsDataSets.customerAges),
          backgroundColor: mainTheme.colors.greyScheme,
          hoverBackgroundColor: mainTheme.colors.greyScheme,
        },
      ],
    };
    const coffeesPerDay = {
      labels: Object.keys(analyticsDataSets.coffeesPerDay),
      datasets: [
        {
          data: Object.values(analyticsDataSets.coffeesPerDay),
          backgroundColor: mainTheme.colors.greyScheme,
          hoverBackgroundColor: mainTheme.colors.greyScheme,
        },
      ],
    };
    const drinkMostOften = {
      labels: Object.keys(analyticsDataSets.drinkMostOften),
      datasets: [
        {
          data: Object.values(analyticsDataSets.drinkMostOften),
          backgroundColor: mainTheme.colors.greyScheme,
          hoverBackgroundColor: mainTheme.colors.greyScheme,
        },
      ],
    };
    const buyForHome = {
      labels: Object.keys(analyticsDataSets.buyForHome),
      datasets: [
        {
          data: Object.values(analyticsDataSets.buyForHome),
          backgroundColor: mainTheme.colors.greyScheme,
          hoverBackgroundColor: mainTheme.colors.greyScheme,
        },
      ],
    };
    const favouriteCoffee = {
      labels: Object.keys(analyticsDataSets.favouriteCoffee),
      datasets: [
        {
          data: Object.values(analyticsDataSets.favouriteCoffee),
          backgroundColor: mainTheme.colors.greyScheme,
          hoverBackgroundColor: mainTheme.colors.greyScheme,
        },
      ],
    };
    const sitInTakeout = [
      { title: 'Sit-in', perc: 100 - takeoutPerc },
      { title: 'Takeout', perc: takeoutPerc },
    ];
    const customersWorkingRemotely = [
      { title: 'Remotely', perc: 100 - dontWorkRemotelyPerc },
      { title: 'Office', perc: dontWorkRemotelyPerc },
    ];
    const customerGender = [
      { title: 'Female', perc: 100 - malesPerc },
      { title: 'Male', perc: malesPerc },
    ];
    return (
      <ColumnOffsetBottom>
        <HorizontalBarsWrapper>
          <ReactTooltip />

          <SplitBarWithBubbleChat
            title={statsWithMessageTitles.sitInTakeout}
            data={sitInTakeout}
          />

          <SplitBarWithBubbleChat
            title={statsWithMessageTitles.customersWorkingRemotely}
            data={customersWorkingRemotely}
          />

          <SplitBarWithBubbleChat
            title={statsWithMessageTitles.customerGender}
            data={customerGender}
          />
        </HorizontalBarsWrapper>

        <ChartsWrapper>
          <DoughnutChartWithBubbleChat
            title={statsWithMessageTitles.milkChoices}
            data={milkChoices}
          />
          <DoughnutChartWithBubbleChat
            title={statsWithMessageTitles.customerDiets}
            data={customerDiets}
          />
          <DoughnutChartWithBubbleChat
            title={statsWithMessageTitles.customerAges}
            data={customerAges}
          />
          <DoughnutChartWithBubbleChat
            title={statsWithMessageTitles.coffeesPerDay}
            data={coffeesPerDay}
          />
          <DoughnutChartWithBubbleChat
            title={statsWithMessageTitles.drinkMostOften}
            data={drinkMostOften}
          />
          <DoughnutChartWithBubbleChat
            title={statsWithMessageTitles.buyForHome}
            data={buyForHome}
          />
          <DoughnutChartWithBubbleChat
            title={statsWithMessageTitles.favouriteCoffee}
            data={favouriteCoffee}
          />
        </ChartsWrapper>
      </ColumnOffsetBottom>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    analytics: state.profile.analytics,
    totalStamps: state.profile.stamps,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerInsightsContainer);
