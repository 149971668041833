import { handleActions } from 'redux-actions';
import appActions from './app.actions';

export const appInitialState = {
  message: '',
  errors: {},
  pending: 0,

  sidebar: false,
};

const appReducer = handleActions(
  {
    [appActions.toggleSidebar]: (state, action) => ({
      ...state,
      sidebar: !state.sidebar,
    }),
  },
  appInitialState
);

export default appReducer;
