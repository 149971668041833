import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../../../../assets/images/popup-close-icon.svg';
import { newTheme } from '../../../../mainStyles';

export const StyledCloseIcon = styled(CloseIcon)`
  path {
    stroke: white;
  }
`;

export const StyledCloseButton = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;

  @media (max-width: 1207px) {
    top: 15px;
    right: 15px;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 50px;
  justify-content: space-evenly;

  background-color: white;

  @media (max-width: 890px) {
    padding: 15px;
  }
`;

export const StyledTitleText = styled.div`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 33px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-align: center;

  margin-bottom: 16px;
`;

export const StyledAddonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTextSection = styled.div`
  max-width: 680px;

  @media (max-width: 1230px) {
    max-width: 580px;
  }

  @media (max-width: 1048px) {
    max-width: 520px;
  }

  @media (max-width: 890px) {
    max-width: 510px;
  }
`;

export const StyledImageSection = styled.div`
  @media (max-width: 1144px) {
    padding: 55px 0 0 0;
    img {
      width: 280px;
    }
  }

  @media (max-width: 890px) {
    img {
      width: 280px;
    }
  }
`;

export const StyledDescriptionText = styled.div`
  font-family: ${newTheme.fontFamily.primary.regular};
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: center;

  color: #000000;

  margin-bottom: 32px;
`;

export const StyledSubDescriptionText = styled(StyledDescriptionText)`
  color: #5d5d5b;
`;

export const StyledSubTitleText = styled.div`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: -0.02em;
  text-align: center;

  margin-bottom: 24px;
`;

export const StyledColumnTitleText = styled.div`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 19px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: -0.02em;
  text-align: center;
`;
export const StyledColumnDescriptionText = styled.div`
  font-family: ${newTheme.fontFamily.primary.regular};
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;

  color: #5d5d5b;
`;

export const StyledColumnsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 32px;

  @media (max-width: 1214px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledColumn = styled.div`
  width: 32%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background: #ffffff;

  border: 2px solid #131312;

  @media (max-width: 1214px) {
    width: 50%;
    margin-bottom: 5px;
  }

  @media (max-width: 890px) {
    width: 100%;
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 40px;
`;

export const StyledAddButton = styled.button`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  padding: 16px 36px;
  border: 0;
  border-radius: 4px;
  margin-right: 16px;
  z-index: 10;
  cursor: pointer;

  color: white;
  background: #131312;

  &:hover {
    background: #212121;
  }
`;

export const StyledCancelButton = styled.button`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  padding: 16px 36px;
  border: 0;
  border-radius: 4px;
  margin-right: 16px;
  z-index: 10;
  cursor: pointer;

  color: #131312;
  background: white;
`;
