import { handleActions, combineActions } from 'redux-actions';
import postActions from './post.actions';
import authActions from '../auth/auth.actions';

export const postInitialState = {
  message: '',
  errors: {},
  pending: false,

  id: null,
  title: '',
  description: '',
  image: '',

  temp: {
    title: '',
    description: '',
    image: '',
  },
};

const postReducer = handleActions(
  {
    [combineActions(
      postActions.fetchPostPending,
      postActions.fetchCreatePostPending,
      postActions.fetchUpdatePostPending,
      postActions.fetchDeletePostPending
    )]: (state, action) => ({ ...state, pending: true }),

    [combineActions(
      postActions.fetchPostSuccess,
      postActions.fetchUpdatePostSuccess,
      postActions.selectPost
    )]: (state, action) => ({
      ...state,
      ...action.payload.post,
      pending: false,
    }),

    [postActions.fetchDeletePostSuccess]: (state, action) => {
      if (action.payload._id === state.id) {
        return { ...postInitialState };
      }
      return {
        ...state,
        pending: false,
      };
    },

    [combineActions(
      postActions.fetchCreatePostFailed,
      postActions.fetchPostFailed,
      postActions.fetchUpdatePostFailed,
      postActions.fetchDeletePostFailed
    )]: (state, action) => ({
      ...state,
      pending: false,
      errors: action.payload.errors,
      message: action.payload.message,
    }),

    [combineActions(postActions.fetchCreatePostSuccess, authActions.logout)]:
      () => ({ ...postInitialState }),

    [postActions.changeTemp]: (state, action) => ({
      ...state,
      temp: {
        ...state.temp,
        [action.payload.key]: action.payload.value,
      },
    }),

    [postActions.clearTemp]: (state, action) => ({
      ...state,
      temp: {
        ...postInitialState.temp,
      },
    }),
  },
  postInitialState
);

export default postReducer;
