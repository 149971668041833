import axios from 'axios';
import { put, delay } from 'redux-saga/effects';
import store from '../redux/config';
import storage from './storage';
import authActions from '../redux/auth/auth.actions';
import { history } from '../containers/RouterContainer/RouterContainer';

const REQUEST_ABORT_CODE = 'ECONNABORTED';

export const parseQuery = (queryString) => {
  const query = {};
  const pairs = (
    queryString[0] === '?' ? queryString.substr(1) : queryString
  ).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};

export function* handleRequestError(error, onFailedAction, recall, action) {
  if (error.recall && recall) {
    yield delay(2000);
    console.info('RECALL: ', recall.name);
    yield recall(action);
  } else if (error.response) {
    if (typeof error.response.data === 'object') {
      yield put(
        onFailedAction(error.response.data.errors, error.response.data.message)
      );
    } else {
      yield put(onFailedAction({}, error.response.data));
    }
  } else {
    yield put(onFailedAction());
  }
}

const requestDelay = (ms) =>
  new Promise((res) => setTimeout(() => res(ms), ms));

const RequestClient = class {
  constructor() {
    const token =
      window.token ||
      storage.get('bearer_token') ||
      storage.sessionStorageGet('bearer_token');
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_SERVER_API_URL || '/api/v1/',
      timeout: process.env.REACT_APP_REQUEST_TIMEOUT,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this.instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  setToken(token = '', rememberMe = true) {
    this.instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    if (rememberMe) {
      storage.set('bearer_token', token);
    } else {
      storage.sessionStorageSet('bearer_token', token);
    }
  }

  clearToken() {
    this.instance.defaults.headers.common.Authorization = '';
    storage.clear('bearer_token');
    storage.sessionStorageClear('bearer_token');
  }

  // TODO: remove all requestDelay after finished  testing
  async get(endpoint, params = {}) {
    try {
      await requestDelay(process.env.REACT_APP_REQUEST_DELAY);

      const response = await this.instance.get(endpoint, params);
      return this.handleData(response);
    } catch (error) {
      this.handleError(error);
    }
  }

  async post(endpoint, body, params = {}) {
    try {
      await requestDelay(process.env.REACT_APP_REQUEST_DELAY);
      const response = await this.instance.post(endpoint, body, params);

      return this.handleData(response);
    } catch (error) {
      this.handleError(error);
    }
  }

  async put(endpoint, body, params = {}) {
    try {
      await requestDelay(process.env.REACT_APP_REQUEST_DELAY);

      const response = await this.instance.put(endpoint, body, params);

      return this.handleData(response);
    } catch (error) {
      this.handleError(error);
    }
  }

  async delete(endpoint, params) {
    try {
      await requestDelay(process.env.REACT_APP_REQUEST_DELAY);

      const response = await this.instance.delete(endpoint, { params });

      return this.handleData(response);
    } catch (error) {
      this.handleError(error);
    }
  }

  handleData(response) {
    if (response.data.step) {
      // this is redirect from backend
      storage.storageSet('step', response.data.step);
      history.replace(response.data.step);
    }
    return response;
  }

  handleError(error) {
    if (
      error.code === REQUEST_ABORT_CODE ||
      ('response' in error && error.response === undefined)
    ) {
      error.recall = true;
    } else if (error.response.status === 401) {
      store.dispatch(authActions.logout());
    }
    throw error;
  }
};

export const countDown = (current, dec = 1) =>
  current - dec <= 0 ? 0 : current - dec;
export const countUp = (current, inc = 1) => current + inc;

const client = new RequestClient();

export default client;
