import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { PaymentDetailWrapper, PaymentText, CardIcon } from './styles';
import {
  Row,
  RightBlock,
  PaperComponent,
} from '../../components/MainComponents/MainComponents';
import {
  selectUserCard,
  selectUserObject,
} from '../../redux/user/user.selectors';
import CardPaymentModal from '../CardPaymentModal/CardPaymentModal';

class PaymentDetailContainers extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.user.card !== this.props.user.card && this.cardModal) {
      this.cardModal.close();
    }
  }

  update = () => this.cardModal.open();

  render() {
    return (
      <RightBlock style={{ borderRadius: 7 }} as={PaperComponent}>
        <PaymentDetailWrapper>
          <Row>
            <CardIcon />
            <PaymentText>{this.props.card}</PaymentText>
          </Row>

          <PaymentText style={{ cursor: 'pointer' }} onClick={this.update}>
            UPDATE
          </PaymentText>

          <CardPaymentModal ref={(modal) => (this.cardModal = modal)} />
        </PaymentDetailWrapper>
      </RightBlock>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  card: selectUserCard,
  user: selectUserObject,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentDetailContainers);
