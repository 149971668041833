import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { serializeForm } from '../../utils/selectors';
import {
  Column,
  RowOffsetBottom,
} from '../../components/MainComponents/MainComponents';
import profileActions from '../../redux/profile/profile.actions';
import { selectUserObject } from '../../redux/user/user.selectors';

import StampControlContainer from '../../containers/StampControlContainer/StampControlContainer';
import StampViewContainer from '../../containers/StampViewContainer/StampViewContainer';
import { MainContentOffset } from '../MainScreen/styles';
import BottomControlPanel from '../../components/BottomControlPanel/BottomControlPanel';
import MessageComponent from '../../components/MessageComponent/MessageComponent';
import userActions from '../../redux/user/user.actions';

class CardScreen extends Component {
  state = { showSuccessMessage: false };

  componentDidMount() {
    this.props.fetchProfile(this.props.user.profile[0]._id);
  }

  showSuccessMessage = () => {
    this.setState({ showSuccessMessage: true });
    setTimeout(() => this.setState({ showSuccessMessage: false }), 3000);
  };

  formSubmit = (event) => {
    event.preventDefault();
    const data = serializeForm(event.target);
    const updatedStamp = {
      stamp: {
        color: data['stamp.color'],
        pattern: data['stamp.pattern'],
        backgroundColor: data['stamp.background'],
        total: data['stamp.total'],
        textColor: data['stamp.textColor'],
      },
    };
    this.props.updateStamp(updatedStamp);
    this.showSuccessMessage();
  };

  render() {
    return (
      <Column as="form" onSubmit={this.formSubmit}>
        <MainContentOffset>
          <RowOffsetBottom wrap>
            <StampViewContainer />
            <StampControlContainer />
            {this.state.showSuccessMessage && (
              <MessageComponent
                type="success"
                text="Successfully updated card design!"
                customStyles={{ marginTop: 30 }}
              />
            )}
          </RowOffsetBottom>
        </MainContentOffset>
        <BottomControlPanel />
      </Column>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: selectUserObject,
});

const mapDispatchToProps = {
  fetchProfile: profileActions.fetchPending,
  updateStamp: profileActions.fetchUpdateStampPending,
  fetchMe: userActions.fetchMePending,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardScreen);
