import React, { useState } from 'react';

import {
  CardNumberElement,
  CardCVCElement,
  CardExpiryElement,
  injectStripe,
} from 'react-stripe-elements';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import StepsProgresComponent from '../StepsProgresComponent/StepsProgresComponent';

import paymentsActions from '../../redux/payments/payments.actions';
import { selectUserObject } from '../../redux/user/user.selectors';
import {
  selectPaymentsPending,
  selectPaymentsErrors,
  selectPaymentsMessage,
} from '../../redux/payments/payments.selectors';

import { StyledLayout } from '../../screens/AuthScreen-v2/styles';
import {
  StyledButton,
  StyledPageWrapper,
  StyledSection,
} from '../AuthSubscriptionPageComponent/styles';
import {
  StyledErrorMessage,
  StyledInputContainer,
  StyledLayoutWrapper,
  StyledLinkButton,
  StyledSectionWrapper,
} from '../MainComponents/MainComponents';
import {
  StyledTitleContainer,
  StyledCardInput,
  StyledInputLabel,
  StyledInput,
  StyledLogoTitle,
  StyledTitle,
  StyledPageDescription,
  StyledFormWrapper,
} from './styles';

import bgImage from '../../assets/images/backgrounds/phoneBg.png';
import logoImage from '../../assets/images/logo-image.png';
import paymentMethods from '../../assets/images/icons/payment-methods.png';

const style = {
  base: {
    iconColor: '#c4f0ff',
    color: '#131312',
    fontWeight: '300',
    fontFamily: 'inherit',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    ':-webkit-autofill': {
      color: '#131312',
      backgroundColor: '#ffffff',
    },
    '::placeholder': {
      color: '#959492',
    },
  },
  invalid: {
    color: '#ff0000',
    ':-webkit-autofill': {
      color: '#ff0000',
      backgroundColor: '#ffffff',
    },
  },
};

const CardDetailsComponent = ({
  stripe,
  message,
  pending,
  user,
  fetchUpdateStripeCardPending,
  fetchStripeCardPending,
}) => {
  const [state, setState] = useState({ stripeError: '', stripeLoading: false });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (stripe) {
      setState((state) => {
        return {
          ...state,
          stripeLoading: true,
        };
      });
      try {
        const payload = await stripe.createToken();

        if (payload.error && payload.error.message) {
          setState((state) => {
            return {
              ...state,
              stripeError: payload.error.message,
            };
          });
        } else {
          setState((state) => {
            return {
              ...state,
              stripeError: '',
            };
          });

          if (user && typeof user.card === 'string') {
            // User is updating his card details
            fetchUpdateStripeCardPending(payload.token);
          } else {
            fetchStripeCardPending(payload.token);
          }
        }
      } catch (error) {
        console.log('// Stripe error', error);
        setState((state) => {
          return {
            ...state,
            stripeError: 'Oops. There has been a problem, please try again.',
          };
        });
      }
      setState((state) => {
        return {
          ...state,
          stripeLoading: false,
        };
      });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  const errorMessage = state.stripeError || message;

  return (
    <StyledPageWrapper>
      <StyledLayout height="100%">
        <StyledLayoutWrapper>
          <StyledSection
            paddingTop={56}
            paddingBot={0}
            as="form"
            onSubmit={onSubmit}
          >
            {/* leftside */}
            <StyledSectionWrapper>
              <StepsProgresComponent currentStep={3} />
              <StyledTitleContainer>
                {/* <StyledChevronButton>
									<ChevronLeft />
								</StyledChevronButton> */}
                <div>
                  <StyledTitle marginBot={6}>Card Details</StyledTitle>
                  <StyledPageDescription>
                    Kick off your RWRD journey & activate your account
                  </StyledPageDescription>
                </div>
              </StyledTitleContainer>
              <StyledFormWrapper>
                <StyledInputContainer marginBot={24}>
                  {/* <CreditCardDetails /> */}
                  <StyledInputLabel>Name</StyledInputLabel>
                  <StyledInput
                    maxLength={50}
                    placeholder="Enter card holder’s name"
                  />
                  <StyledInputLabel>Card Number</StyledInputLabel>
                  <StyledCardInput
                    as={CardNumberElement}
                    style={style}
                    maxLength={50}
                    placeholder="Enter card number"
                    icon={paymentMethods}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <div style={{ width: '48%' }}>
                      <StyledInputLabel>Expiry</StyledInputLabel>
                      <StyledInput
                        as={CardExpiryElement}
                        style={style}
                        placeholder="MM / YY"
                      />
                    </div>
                    <div style={{ width: '48%' }}>
                      <StyledInputLabel>CVV</StyledInputLabel>
                      <StyledInput
                        as={CardCVCElement}
                        style={style}
                        placeholder="CVV"
                      />
                    </div>
                  </div>
                  {errorMessage && (
                    <div>
                      <StyledErrorMessage>{message}</StyledErrorMessage>
                    </div>
                  )}
                </StyledInputContainer>
              </StyledFormWrapper>
            </StyledSectionWrapper>
            <div>
              <StyledLinkButton
                href="https://calendly.com/rwrd/chat-about-rwrd-with-holly"
                target="_blank"
                rel="noopener noreferrer"
              >
                Book a call
              </StyledLinkButton>
              <StyledButton
                prymary
                type="Submit"
                disabled={pending || state.stripeLoading}
              >
                {/* onClick={history.push(AUTH_ROUTS.CONFIRMATION)}>  */}
                Activate
              </StyledButton>
            </div>
          </StyledSection>
          <StyledSection paddingTop={20} bgImage={bgImage}>
            <div>
              <img src={logoImage} alt="logo" />
            </div>
            <StyledLogoTitle>
              Activate your account to join the RWRD community. <br /> <br />
              14 day free trial - no payment taken now.
            </StyledLogoTitle>
          </StyledSection>
        </StyledLayoutWrapper>
      </StyledLayout>
    </StyledPageWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  pending: selectPaymentsPending,
  message: selectPaymentsMessage,
  errors: selectPaymentsErrors,
  user: selectUserObject,
});

const mapDispatchToProps = {
  fetchStripeCardPending: paymentsActions.fetchCreateStripeCardPending,
  fetchUpdateStripeCardPending: paymentsActions.fetchUpdateStripeCardPending,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectStripe(CardDetailsComponent));
