import styled from 'styled-components';
import { newTheme } from '../../mainStyles';

export const StyledCongratsImage = styled.div`
  display: flex;
  padding: 30px;
  background-color: white;

  border-radius: 50%;
  box-shadow: 0px 16px 32px rgba(238, 193, 11, 0.16);

  margin-bottom: 30px;
`;

export const StyledSection = styled.div`
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  ${({ phone }) => phone && 'justify-content: flex-start;'};
  display: flex;
  flex-direction: column;
  padding: 0 30px;

  padding-top: ${({ paddingTop }) => paddingTop && `${paddingTop}px;`};
  padding-bottom: ${({ paddingBot }) => paddingBot && `${paddingBot}px;`};
  padding-left: ${({ paddingLeft }) => paddingLeft && `${paddingLeft}px;`};
  padding-right: ${({ paddingRight }) => paddingRight && `${paddingRight}px;`};

  ${({ bgImage }) =>
    bgImage && `background: url(${bgImage}) center center/cover no-repeat;`};

  @media (max-width: 925px) {
    padding: 25px 5px;
  }
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 450px;

  @media (max-width: 1000px) {
    width: 350px;
  }
`;

export const StyledPageDescription = styled.div`
  font-family: ${newTheme.fontFamily.primary.light};
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: ${({ marginBot }) => (marginBot ? `${marginBot}px` : '24px')};
`;

export const StyledVideoBlock = styled.div`
  iframe {
    width: 100%;
    height: 250px;
  }
  @media (max-width: 1000px) {
    width: 350px;
    iframe {
      height: 200px;
      width: 350px;
    }
  }
`;
export const StyledContentBlock = styled.div`
  @media (max-width: 1000px) {
    width: 350px;
  }
`;

export const StyledIconContainer = styled.div`
  margin-right: 16px;
`;

export const StyledListDescription = styled.div`
  font-family: ${newTheme.fontFamily.primary.light};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #5d5d5b;
`;

export const StyledListTitle = styled.div`
  font-family: ${newTheme.fontFamily.primary.regular};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  margin-bottom: 8px;

  color: #131312;
`;

export const StyledListItem = styled.div`
  display: flex;
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(19, 19, 18, 0.06);
  border-radius: 4px;
  margin: 6px 0;

  padding: 16px;
`;

export const StyledTitle = styled.div`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;

  margin-bottom: ${({ marginBot }) => (marginBot ? `${marginBot}px` : '48px')};
`;

export const StyledImageTitle = styled.div`
  font-family: ${newTheme.fontFamily.primary.regular};
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;

  margin-bottom: 24px;
`;

export const StyledButton = styled.button`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  padding: 16px 36px;
  border: 0;
  border-radius: 4px;
  margin-right: 16px;
  margin-top: 5px;
  z-index: 10;

  color: #131312;

  cursor: pointer;

  background: ${({ prymary }) => (prymary ? '#F5CC28' : '#efefef;')};

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: ${({ prymary }) => (prymary ? '#fedc54' : '#f3f3f3;')};
  }

  @media (max-width: 1000px) {
    margin-right: 10px;
    padding: 16px 26px;
  }
`;
