import { handleActions, combineActions } from 'redux-actions';
import paymentsActions from './payments.actions';
import authActions from '../auth/auth.actions';

export const paymentsInitialState = {
  message: '',
  errors: {},
  pending: false,

  subscriptions: [],
};

const paymentsReducer = handleActions(
  {
    // ###FETCH_SUBSCRIPTIONS
    [combineActions(
      paymentsActions.fetchSubscriptionPending,
      paymentsActions.fetchSubscribePending,
      paymentsActions.fetchCreateStripeCardPending
    )]: (state, action) => ({
      ...state,
      pending: true,
    }),
    [paymentsActions.fetchSubscriptionSuccess]: (state, action) => ({
      ...state,
      pending: false,
      subscriptions: action.payload.subscriptions,
    }),
    [combineActions(
      paymentsActions.fetchSubscriptionFailed,
      paymentsActions.fetchSubscribeFailed,
      paymentsActions.fetchCreateStripeCardFailed
    )]: (state, action) => ({
      ...state,
      errors: action.payload.errors,
      message: action.payload.message,
      pending: false,
    }),
    // ##SUBSCRIBE

    [combineActions(
      paymentsActions.fetchSubscribeSuccess,
      paymentsActions.fetchCreateStripeCardSuccess
    )]: (state, action) => ({
      ...state,
      pending: false,
    }),
    [combineActions(authActions.logout)]: (state, action) => ({
      ...paymentsInitialState,
    }),
  },
  paymentsInitialState
);

export default paymentsReducer;
