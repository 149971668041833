import React, { useRef } from 'react';

import shevronOpened from '../../../assets/images/icons/shevron-opened.svg';
import shevronClosed from '../../../assets/images/icons/shevron-closed.svg';

import {
  AccordionBody,
  AccordionToggle,
  Collapse,
  StyledAccordionTitle,
  StyledCard,
  StyledText,
} from '../styles';

const AccordionTextItem = ({ handleToggle, active, item }) => {
  const contentEl = useRef();
  const { header, id, content } = item;

  return (
    <StyledCard>
      <AccordionToggle active={active} id={id} onClick={() => handleToggle(id)}>
        <StyledAccordionTitle>{header}</StyledAccordionTitle>
        <img
          src={active === id ? shevronOpened : shevronClosed}
          width="24px"
          height="24px"
          alt="shevron"
        />
      </AccordionToggle>

      <Collapse
        active={active}
        id={id}
        ref={contentEl}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: '0px' }
        }
      >
        <AccordionBody>
          {content.text.map((paragraph) => (
            <StyledText>{paragraph}</StyledText>
          ))}
        </AccordionBody>
      </Collapse>
    </StyledCard>
  );
};

export default AccordionTextItem;
