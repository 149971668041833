import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { mainTheme, newTheme } from '../../mainStyles';

export const getMaxWidth = (props) => {
  if (!isNaN(props.maxWidth)) {
    return props.maxWidth;
  }
  return props.theme.sizes[props.maxWidth] || props.theme.sizes.medium;
};
export const PaperComponent = styled.div`
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  border: none;
  max-width: ${getMaxWidth}px;
  padding: ${mainTheme.offset.medium}px;
  border-radius: ${(props) => props.theme.radius.xsmall}px;
  background-color: ${(props) => props.background || props.theme.colors.white};
  box-shadow: 0 15px 30px 0 rgba(150, 150, 150, 0.3);
  display: flex;
  flex-direction: ${(props) => props.direction || 'column'};
`;

export const BoardComponent = styled(PaperComponent)`
  padding: ${mainTheme.offset.xlarge}px;
  @media (max-width: 991px) {
    padding: ${mainTheme.offset.medium}px;
  }
  @media (max-width: 768px) {
    padding: ${mainTheme.offset.small}px;
  }
`;

export const Button = styled.button`
  border-radius: ${(props) => props.theme.radius.xsmall}px;
  white-space: nowrap;
  text-decoration: none;
  background-color: ${(props) =>
    props.background && props.theme.colors[props.background]
      ? props.theme.colors[props.background]
      : props.theme.colors.primary};
  color: ${(props) =>
    props.colors && props.theme.colors[props.colors]
      ? props.theme.colors[props.colors]
      : props.theme.colors.white};
  padding: ${(props) =>
    `${props.theme.offset.xsmall}px ${props.theme.offset.medium}px`};
  border: none;
  margin: 0 0 ${(props) => props.theme.offset.small}px 0;
  font-size: ${(props) => props.theme.fontSizes.button}px;
  outline: none;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export const AuthButton = styled(Button)`
  text-align: center;
  padding: 16px;
  font-size: 20px;
  margin-bottom: 24px;
`;

export const MainButton = styled(AuthButton)`
  /* font-size: ${mainTheme.fontSizes.small}px; */
  line-height: 25px;
  width: ${(props) => (props.fullWidth ? '100%' : 'initial')};
  min-height: 40px;
  padding: ${mainTheme.offset.xsmall}px ${mainTheme.offset.large}px;
  font-weight: 600;
  font-family: Avenir;
  font-size: 11px;
  letter-spacing: 2.55px;
  text-transform: uppercase;

  letter-spacing: 3px;
`;

export const Input = styled.input`
  background-color: ${(props) => props.theme.colors.foreground};
  border-radius: ${(props) => props.theme.radius.xsmall}px;
  min-height: 48px;
  border: none;
  background-color: ${(props) =>
    props.background && props.theme.colors[props.background]
      ? props.theme.colors[props.background]
      : props.theme.colors.foreground};
  color: ${(props) =>
    props.colors && props.theme.colors[props.colors]
      ? props.theme.colors[props.colors]
      : props.theme.colors.text};
  padding: ${(props) =>
    `${props.theme.offset.xsmall}px ${props.theme.offset.medium}px`};
  margin: 0 0 ${(props) => props.theme.offset.small}px 0;
  resize: none;
  font-size: ${(props) => props.theme.fontSizes.input}px;
  &:focus {
    outline: none;
  }
`;

export const InputValidation = styled(Input)`
  border: 1px solid
    ${(props) =>
      props.error ? props.theme.colors.danger : props.theme.colors.foreground};
  width: 100%;
  &::after {
    content: attr(error);
    color: ${mainTheme.colors.danger};
  }
`;

export const InputMain = styled(InputValidation)`
  font-size: 10px;
  color: ${mainTheme.colors.primary};
  line-height: normal;
  width: 100%;
  min-height: 40px;
  letter-spacing: 3px;
  border: 1px solid
    ${(props) =>
      props.error ? props.theme.colors.danger : props.theme.colors.grey};
`;

export const TimeInput = styled(InputMain)`
  &::-webkit-calendar-picker-indicator {
    padding: 0px;
  }
`;

export const InputMainCharacters = styled(InputMain)`
  padding-right: 120px;
`;

export const TextAreaWrapper = styled.div`
  position: relative;
  margin: 0 0 ${(props) => props.theme.offset.small}px 0;
  display: flex;
  &:after {
    content: '' attr(data-max) ' CHARACTERS';
    position: absolute;
    display: inline-block;
    right: 0;
    font-weight: 400;
    font-family: Raleway;
    letter-spacing: 2.5px;
    bottom: ${(props) => (props.input ? 27 : 0)}px;
    font-size: 10px;
    color: ${mainTheme.colors.grey};
    padding: 6px;
  }
`;
export const TextAreaMain = styled(InputMain).attrs({
  as: 'textarea',
})`
  min-height: 125px;
  margin: 0;
  position: relative;
  font-family: Avenir;
`;

export const Dropdown = styled(InputMain)`
  padding: 0;
  > div {
    border: none;
    background: ${mainTheme.colors.foreground};
    border-radius: 2;
    &:focus {
      outline: none;
      border: none;
    }
  }
`;

export const Divider = styled.hr`
  margin-bottom: ${(props) => props.theme.offset.medium}px;
  border-top: 1px solid ${(props) => props.theme.colors.foreground};
  width: 100%;
`;

export const SmallText = styled.p`
  color: ${mainTheme.colors.text};
  font-family: Helvetica;
  line-height: 20px;
  font-size: ${mainTheme.fontSizes.xsmall}px;
  font-weight: ${mainTheme.fontWeight.normal};
  letter-spacing: 1.13px;
  word-break: break-word;
`;

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
`;

export const Title = styled.h1`
  color: ${mainTheme.colors.white};
  font-family: Raleway;
  word-break: break-word;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 4.06px;
`;

export const TitleDark = styled(Title)`
  color: ${mainTheme.colors.textDark};
`;

export const MainTitle = styled.h2`
  font-family: 'Avenir Medium';
  font-size: 20px;
  font-weight: 500;
  word-break: break-word;
  letter-spacing: 2.5px;
  color: ${mainTheme.colors.white};
`;

export const MainTitleDark = styled(MainTitle)`
  color: ${mainTheme.colors.textDark};
`;
export const Text = styled.p`
  font-family: ${(props) => props.fontFamily || mainTheme.fontFamily.light};
  font-size: ${(props) => props.size || 15}px;
  color: ${(props) => props.color || mainTheme.colors.textDark};
  letter-spacing: 1.56px;
  line-height: 26px;
  word-break: break-word;
  font-weight: ${(props) => props.fontWeight || mainTheme.fontWeight.normal};
`;

export const SmallLabel = styled(Text)`
  font-family: 'Avenir Light';
  font-size: 12px;
  color: ${mainTheme.colors.textDark};
  font-weight: ${mainTheme.fontWeight.normal};
  letter-spacing: 1.1px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};
`;

export const RowOffsetBottom = styled(Row)`
  margin-bottom: ${(props) =>
    mainTheme.offset[props.offset] || mainTheme.offset.small}px;
`;

export const Column = styled.div`
  width: 100%;
  flex: 1;
  text-transform: none;
  display: flex;
  flex-direction: column;
`;
export const ColumnOffsetBottom = styled(Column)`
  margin-bottom: ${(props) =>
    mainTheme.offset[props.offset] || mainTheme.offset.small}px;
`;

export const FieldLabel = styled.div`
  font-family: 'Avenir Heavy';
  font-size: 10px;
  color: ${mainTheme.colors.primary};
  line-height: normal;
  font-weight: 800;
  letter-spacing: 3px;
  text-transform: uppercase;
  word-break: break-word;
  margin-bottom: ${mainTheme.offset.xsmall}px;

  color: ${mainTheme.colors.primary};
`;

export const SectionLabel = styled.div`
  font-family: 'Avenir Heavy';
  font-size: 16px;
  color: ${mainTheme.colors.primary};
  line-height: normal;
  font-weight: 800;
  letter-spacing: 3px;
  text-transform: uppercase;
  word-break: break-word;
  margin-bottom: ${mainTheme.offset.xsmall}px;

  color: ${mainTheme.colors.primary};
`;

export const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SmallBlock = styled(Block)`
  min-width: 270px;
  flex-basis: 330px;
`;

export const LargeBlock = styled(SmallBlock)`
  max-width: 700px;
  flex-basis: 600px;
  min-width: 300;
`;

const media = 645;

export const HalfContentWrapper = styled(Row)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  @media (max-width: ${media}px) {
    flex-wrap: wrap;
  }
`;
export const HalfContent = styled(ColumnOffsetBottom)`
  flex-direction: column;
  /* &:first-child { */
  margin-right: ${mainTheme.offset.small}px;
  /* } */
  &:last-child {
    margin-left: auto;
  }
  flex-basis: 280px;
  @media (max-width: ${media}px) {
    max-width: initial;
    width: 100%;
    &:first-child {
      margin-right: 0;
      max-width: initial;
    }
  }
`;

export const Avatar = styled.div`
  display: inline-block;
  width: 43px;
  height: 43px;
  background-color: ${mainTheme.colors.primary};
  border-radius: 50%;
  color: ${mainTheme.colors.grey};
  font-family: 'Avenir Bold';
  text-align: center;
  line-height: 43px;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 2.56px;
  text-transform: uppercase;
  padding-left: 2px;
  background-image: ${(props) =>
    props.backgroundImage ? `url(${props.backgroundImage})` : 'none'};
  background-size: contain;
  background-repeat: no-repeat;
`;

export const RightBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: initial;
  flex-basis: 550px;
  flex-grow: 1;
`;

export const FromControls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  > button {
    margin-left: ${mainTheme.offset.small}px;
    margin-bottom: 0;
  }
`;

export const MobileContent = styled.div`
  @media (max-width: ${mainTheme.sizes.large}px) {
    padding: ${mainTheme.offset.small}px !important;
  }
`;

// -----------new styles v2-----------

export const StyledLogoContainer = styled.div`
  margin: 0 0 32px;

  ${({ marginBot }) => marginBot && `margin-bottom: ${marginBot}px;`};

  @media (max-width: 380px) {
    margin-bottom: 8px;
  }
`;

export const StyledFormTitle = styled.div`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 32px;
  line-height: 43px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 16px;
  word-wrap: none;

  @media (max-width: 380px) {
    margin-bottom: 8px;
  }
  ${({ marginBot }) => marginBot && `margin-bottom: ${marginBot}px;`};
  ${({ bold }) => bold && `font-family: ${newTheme.fontFamily.primary.bold}`}
`;

export const StyledFormDescription = styled.div`
  font-family: ${newTheme.fontFamily.primary.light};
  color: #5d5d5b;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-weight: 400px;
  text-align: center;
  margin-bottom: 51px;

  @media (max-width: 380px) {
    margin-bottom: 8px;
  }
`;

export const StyledInputContainer = styled.div`
  position: relative;
  width: 100%;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px`}
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight}px`}
	${({ marginBot }) => marginBot && `margin-bottom: ${marginBot}px`}
	${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}px`}
`;

export const StyledIconImage = styled.img`
  position: absolute;

  left: 24px;
  top: ${({ top }) => (top ? `${top}px;` : '45px;')};

  @media (max-width: 450px) {
    left: 9px;
  }
`;

export const StyledInput = styled.input`
  font-family: ${newTheme.fontFamily.primary.regular};
  width: ${({ width }) => width || '100%;'};
  padding: ${({ padding }) =>
    padding ? `${padding}px;` : '17px 17px 17px 64px;'};
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  border-radius: 4px;

  color: ${({ value }) => (value ? '#131312' : '#959492')};
  ${({ borderColor }) => borderColor && 'border: #DFDFDE;'}
  border: 1px solid ${({ errors }) => (errors ? 'red;' : '#DFDFDE;')};
  border: 1px solid
    ${({ borderColor, errors }) =>
      borderColor && errors ? 'red;' : '#DFDFDE;'};

  ${({ icon }) =>
    icon && `background: url(${icon}) no-repeat scroll 23px 15px !important;`}
  ${({ icon, vertical }) =>
    icon &&
    vertical &&
    `background: url(${icon}) no-repeat scroll 23px ${vertical}px !important;`}

	&:hover {
    border: 1px solid ${({ disabled }) => (disabled ? '#DFDFDE;' : 'black;')};
  }
  &:focus {
    border: 1px solid ${({ disabled }) => (disabled ? '#DFDFDE;' : 'black;')};
    outline: none;
  }
  &:active {
    border: 1px solid ${({ disabled }) => (disabled ? '#DFDFDE;' : 'black;')};
  }
  &:focus-visible {
    border: 1px solid ${({ disabled }) => (disabled ? '#DFDFDE;' : 'black;')};
    color: black;
  }

  @media (max-width: 450px) {
    ${({ icon }) =>
      icon && `background: url(${icon}) no-repeat scroll 8px 15px !important;`}
    padding-left: 34px;
  }
`;

export const StyledTimePickerInput = styled(StyledInput)`
  ${({ icon }) =>
    icon && `background: url(${icon}) no-repeat scroll 23px 19px !important;`};
  background-color: ${({ disabled }) => !disabled && 'white'};
`;

export const StyledTextArea = styled.textarea`
  font-family: ${newTheme.fontFamily.primary.regular};
  width: 100%;
  height: 150px;
  padding: 17px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  border-radius: 4px;
  color: #959492;
  color: ${({ value }) => (value ? '#131312' : '#959492')};
  resize: none;
  ${({ borderColor }) => borderColor && 'border: #DFDFDE;'}
  border: 1px solid ${({ errors }) => (errors ? 'red;' : '#DFDFDE;')};
  border: 1px solid
    ${({ borderColor, errors }) =>
      borderColor && errors ? 'red;' : '#DFDFDE;'};

  ${({ icon }) =>
    icon && `background: url(${icon}) no-repeat scroll 23px 15px !important;`}
  ${({ icon, vertical }) =>
    icon &&
    vertical &&
    `background: url(${icon}) no-repeat scroll 23px ${vertical}px !important;`}

	&:hover {
    border: 1px solid black;
  }
  &:focus {
    border: 1px solid black;
    outline: none;
  }
  &:active {
    border: 1px solid black;
  }
  &:focus-visible {
    border: 1px solid black;
    color: black;
  }

  @media (max-width: 450px) {
    ${({ icon }) =>
      icon && `background: url(${icon}) no-repeat scroll 8px 15px;`}
    padding-left: 34px;
  }
`;

export const StyledInputLabel = styled.div`
  font-family: ${newTheme.fontFamily.primary.medium};
  align-self: flex-start;
  font-size: 16px;
  line-height: 21px;
  margin: 0 0 8px 0;
`;

export const StyledLinkContainer = styled.div`
  font-family: ${newTheme.fontFamily.primary.light};
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
`;

export const StyledButton = styled.button`
  font-family: ${newTheme.fontFamily.primary.regular};
  text-align: center;
  padding: 16px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;
  background: #f5cc28;
  color: #131312;
  border: 0;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  ${({ bgColor }) =>
    bgColor ? `background: ${bgColor};` : 'background: #EFEFEF;'};

  @media (max-width: 380px) {
    margin-bottom: 10px;
  }

  &:disabled {
    background: #909090;
    cursor: not-allowed;
  }
`;

export const StyledLink = styled(Link)`
  font-family: ${newTheme.fontFamily.primary.regular};
  text-align: center;
  text-decoration: none;
  padding: 16px;
  font-size: 18px;
  line-height: 24px;
  background: #f5cc28;
  color: #131312;
  border: 0;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  ${({ bgColor }) =>
    bgColor ? `background: ${bgColor};` : 'background: #EFEFEF;'};

  @media (max-width: 380px) {
    margin-bottom: 10px;
  }
`;

export const StyledIconButton = styled(StyledButton);

export const StyledContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    margin: 0 15px 0 0;
  }
`;

export const StyledLinesBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: 380px) {
    margin-bottom: 8px;
  }
`;

export const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  background: #959492;
`;

export const StyledLinesTitle = styled.div`
  font-family: ${newTheme.fontFamily.primary.light};
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #959492;
  padding: 0 24px;
`;

export const StyledText = styled.p`
  text-align: center;
  font-family: ${newTheme.fontFamily.primary.light};
  margin-bottom: 20px;
  letter-spacing: 0;

  a {
    color: black;
    text-decoration: none;
  }
`;

export const StyledTimeInput = styled(StyledInput)`
  width: 48%;
  text-align: center;
  &::-webkit-calendar-picker-indicator {
    padding: inherit;
    text-align: center;
  }
`;

export const StyledDropdown = styled(StyledInput)`
  &:hover,
  &:active,
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
    color: black;
    background: #fff;
  }
  box-shadow: none;
  padding: 0;
  border: none;
  background: #fff;
  > div {
    font-family: ${newTheme.fontFamily.primary.light};
    background: #fff;
    background-color: none;
    width: 100%;
    padding: 9px 0px 9px 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    border-radius: 4px;
    color: #959492;
    box-shadow: none;
    cursor: pointer;
    ${({ borderColor }) => borderColor && 'border: #DFDFDE;'}
    border: 1px solid ${({ errors }) => (errors ? 'red;' : '#959492;')};
    border: 1px solid
      ${({ borderColor, errors }) =>
        borderColor && errors ? 'red;' : '#DFDFDE;'};

    ${({ icon }) =>
      icon &&
      `background: url(${icon}) no-repeat scroll 23px 15px !important;`};
    ${({ icon, vertical }) =>
      icon &&
      vertical &&
      `background: url(${icon}) no-repeat scroll 23px ${vertical}px !important;`};

    &:hover {
      border: 1px solid black;
      box-shadow: none;
      background-color: none;
    }
    &:focus {
      border: 1px solid black;
      outline: none;
      box-shadow: none;
      background-color: none;
    }
    &:active {
      border: 1px solid black;
      box-shadow: none;
      background-color: none;
    }
    &:focus-visible {
      border: 1px solid black;
      color: black;
      box-shadow: none;
      background-color: none;
    }
  }
`;

export const StyledErrorMessage = styled.div`
  font-family: ${newTheme.fontFamily.primary.light};
  color: red;
  font-size: 13px;
`;

export const StyledLayoutWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const StyledSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: ${({ flexGrow }) => flexGrow || 1};
`;

export const StyledPageDescription = styled.div`
  font-family: ${newTheme.fontFamily.primary.light};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: ${({ marginBot }) => (marginBot ? `${marginBot}px` : '24px')};
`;

export const StyledProgressBarComponent = styled.div`
  width: 450px;
  height: 4px;
  background: #efefef;
  margin-bottom: ${({ marginBot }) => (marginBot ? `${marginBot}px` : '48px')};

  @media (max-width: 1000px) {
    width: 350px;
  }
`;

export const StyledProgress = styled.div`
  background: #221c02;
  height: 100%;
  width: ${({ progress }) => progress && `${(100 / 7) * progress}%`};
`;

export const StyledPreviewTitle = styled.div`
  font-family: ${newTheme.fontFamily.primary.medium};
  color: #131312;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0;
  margin: 0 0 32px 0;
`;

export const StyledLinkButton = styled.a`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  text-decoration: none;
  padding: 16px 36px;
  border: 0;
  border-radius: 4px;
  margin-right: 16px;
  margin-top: 5px;
  z-index: 10;

  color: #131312;

  cursor: pointer;

  background: ${({ prymary }) => (prymary ? '#F5CC28' : '#efefef;')};

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: ${({ prymary }) => (prymary ? '#fedc54' : '#f3f3f3;')};
  }

  @media (max-width: 1000px) {
    margin-right: 10px;
    padding: 16px 26px;
  }
`;

export const ReCAPTCHAContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
`;
