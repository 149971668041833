import React from 'react';
import PropTypes from 'prop-types';
import { StampComponentWrapper, StampCheckbox, StampsRow } from './styles';
import { Row } from '../MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';
import ShortProfileComponent from '../ShortProfileComponent/ShortProfileComponent';

export default class StampComponent extends React.PureComponent {
  static propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    pattern: PropTypes.string,
    background: PropTypes.string,
    stamp_color: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    total: PropTypes.number,
    textColor: PropTypes.string,
    // start: PropTypes.number
  };

  static defaultProps = {
    min: 3,
    max: 10,
    total: 10,
    // start: 5,
    background: mainTheme.colors.white,
    stamp_color: mainTheme.colors.success,
    pattern: '',
    title: '',
    subtitle: '',
    textColor: '#2f2f2f',
  };

  render() {
    const {
      background,
      pattern,
      image,
      title,
      subtitle,
      total,
      stamp_color,
      min,
      max,
      textColor,
    } = this.props;
    let repeats = max;
    if (total >= min && total <= max) {
      repeats = total;
    }
    if (total > max) {
      repeats = max;
    }

    return (
      <StampComponentWrapper background={background} pattern={pattern}>
        <Row style={{ zIndex: 2 }}>
          <ShortProfileComponent
            rate={4}
            image={image}
            title={title}
            subtitle={subtitle}
            textColor={textColor}
          />
        </Row>

        <StampsRow>
          {Array.from(' '.repeat(repeats)).map((stamp, idx) => (
            <StampCheckbox stamp_color={stamp_color} checked={idx === 0} />
          ))}
        </StampsRow>
      </StampComponentWrapper>
    );
  }
}
