import React from 'react';
import { useSelector } from 'react-redux';
import { selectProfileObject } from '../../redux/profile/profile.selectors';
import HighLightsPickerComponent from '../HighLightsPickerComponent/ImagePickerComponent';
import { RowOffsetBottom } from '../MainComponents/MainComponents';

const ImageHighlightsComponent = ({ handleChange, ...props }) => {
  const { temp } = useSelector(selectProfileObject);

  const handleChangeHighlights = (key, value, index) => {
    if (temp[key].length !== 3) {
      const newTemp = ['', '', ''];
      const result = newTemp.reduce((acc, el, i) => {
        if (index === i) {
          return [...acc, value];
        }
        return [...acc, el];
      }, []);
      return handleChange(key, result);
    }
    const result = temp[key].reduce((acc, el, i) => {
      if (index === i) {
        return [...acc, value];
      }
      return [...acc, el];
    }, []);
    handleChange(key, result);
  };

  return (
    <RowOffsetBottom>
      <HighLightsPickerComponent
        maxFileSizeDescr="Max file size 4MB"
        maxImgSizeDescr="Min 300px x 300px"
        maxFileSize={4194304}
        imageHeight={140}
        imageWidth={140}
        value={temp.highlights[0]}
        defaultValue={temp.highlights[0] | ''}
        stateKey="highlights"
        onChange={(value) => handleChangeHighlights('highlights', value, 0)}
        clearImage={(key, value) => handleChangeHighlights(key, value, 0)}
      />
      <HighLightsPickerComponent
        maxFileSizeDescr="Max file size 4MB"
        maxImgSizeDescr="Min 300px x 300px"
        maxFileSize={4194304}
        imageHeight={140}
        imageWidth={140}
        value={temp.highlights[1]}
        defaultValue={temp.highlights[1] | ''}
        stateKey="highlights"
        onChange={(value) => handleChangeHighlights('highlights', value, 1)}
        clearImage={(key, value) => handleChangeHighlights(key, value, 1)}
      />
      <HighLightsPickerComponent
        maxFileSizeDescr="Max file size 4MB"
        maxImgSizeDescr="Min 300px x 300px"
        maxFileSize={4194304}
        imageHeight={140}
        imageWidth={140}
        value={temp.highlights[2]}
        defaultValue={temp.highlights[2] | ''}
        stateKey="highlights"
        onChange={(value) => handleChangeHighlights('highlights', value, 2)}
        clearImage={(key, value) => handleChangeHighlights(key, value, 2)}
      />
    </RowOffsetBottom>
  );
};

export default ImageHighlightsComponent;
