import React, { Component } from 'react';
import PropTypes from 'prop-types';
import loader from '../../assets/images/loader.svg';
import {
  LoaderWrapper,
  LoaderImage,
  LoaderBackdrop,
  LoaderImagesWrapper,
} from './styles';
import LogoComponent from '../LogoComponent/LogoComponent';

export default class LoaderComponent extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    backdrop: PropTypes.bool,
    LoaderWrapperElement: PropTypes.node,
    inside: PropTypes.bool,
  };

  static defaultProps = {
    inside: true,
  };

  render() {
    if (!this.props.inside) {
      return this.props.loading ? (
        <LoaderWrapper>
          <LoaderBackdrop
            as={
              this.props.LoaderWrapperElement
                ? this.props.LoaderWrapperElement
                : undefined
            }
            backdrop={this.props.loading && this.props.backdrop}
          >
            {this.props.children}
          </LoaderBackdrop>
          {this.props.loading ? (
            <LoaderImagesWrapper>
              <LogoComponent />
              <LoaderImage src={loader} />
            </LoaderImagesWrapper>
          ) : null}
        </LoaderWrapper>
      ) : (
        this.props.children
      );
    }

    return (
      <LoaderWrapper>
        <LoaderBackdrop
          as={
            this.props.LoaderWrapperElement
              ? this.props.LoaderWrapperElement
              : undefined
          }
          backdrop={this.props.loading && this.props.backdrop}
        >
          {this.props.children}
        </LoaderBackdrop>
        {this.props.loading ? (
          <LoaderImagesWrapper>
            <LogoComponent />
            <LoaderImage src={loader} />
          </LoaderImagesWrapper>
        ) : null}
      </LoaderWrapper>
    );
  }
}
