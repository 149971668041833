import styled from 'styled-components';
import cardImage from '../../assets/images/mastercard.png';
import { FieldLabel } from '../../components/MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';

export const PaymentDetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`;
export const PaymentText = styled(FieldLabel)`
  font-weight: 400;
  text-decoration: none;
  margin-bottom: 0;
  line-height: 20px;
  min-width: 65px;
`;

export const CardIcon = styled.img.attrs({
  src: cardImage,
})`
  width: 35px;
  height: 25px;
  margin-right: ${mainTheme.offset.small}px;
`;
