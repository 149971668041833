import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { selectTemp } from '../../redux/profile/profile.selectors';
import { serializeForm } from '../../utils/selectors';
import {
  PaperComponent,
  SmallBlock,
  RightBlock,
  RowOffsetBottom,
  FromControls,
  MainButton,
  Text,
} from '../../components/MainComponents/MainComponents';
import ProfileEditingContainer from '../ProfileEditingContainer/ProfileEditingContainer';
import ProfileViewContainer from '../ProfileViewContainer/ProfileViewContainer';
import profileActions from '../../redux/profile/profile.actions';
import StampViewContainer from '../StampViewContainer/StampViewContainer';
import StampControlContainer from '../StampControlContainer/StampControlContainer';
import storage from '../../utils/storage';
import {
  selectUserLogin,
  selectUserObject,
  selectUserRegistrationStep,
} from '../../redux/user/user.selectors';
import userActions from '../../redux/user/user.actions';
import ScrollToTopContainer from '../ScrollToTopContainer';
import authActions from '../../redux/auth/auth.actions';
import {
  AuthBottomContent,
  AuthWrapper,
  AuthContent,
} from '../../screens/AuthScreen/styles';
import { mainTheme } from '../../mainStyles';
import logo from '../../assets/images/icons/RWRD_logo-white.svg';
import AuthLabelsComponent from '../../components/AuthLabelsComponent/AuthLabelsComponent';
import FooterContainer from '../FooterContainer/FooterContainer';

class AuthProfile extends PureComponent {
  componentDidMount() {
    this.props.fetchMe();
  }

  logout = () => {
    this.props.logout();
  };

  submitProfile = (event) => {
    event.preventDefault();
    if (this.profileForm) {
      const data = serializeForm(this.profileForm);
      const isAuthorizationStep = Boolean(storage.storageGet('step'));
      const profile = {
        name: data.name,
        logo: data.logo,
        backgroundImage: data.backgroundImage,
        description: data.description,
        address: data.address,
        postcode: data.postcode,
        coffeeBeans: data.coffeeBeans,
        coffeeMachine: data.coffeeMachine,
        coffeeGrinder: data.coffeeGrinder,
        stamp: {
          color: isAuthorizationStep
            ? data['stamp.color']
            : this.props.user.profile[0].stamp.color,
          pattern: isAuthorizationStep
            ? data['stamp.pattern']
            : this.props.user.profile[0].stamp.pattern,
          backgroundColor: isAuthorizationStep
            ? data['stamp.background']
            : this.props.user.profile[0].stamp.backgroundColor,
          total: isAuthorizationStep
            ? data['stamp.total'] || 10
            : this.props.user.profile[0].stamp.total,
          textColor: isAuthorizationStep
            ? data['stamp.textColor'] || '#2f2f2f'
            : this.props.user.profile[0].stamp.textColor,
        },
      };

      const {
        temp: {
          lat,
          lng,
          city,
          state,
          country,
          coffee_options,
          coffeeMachine,
          coffeeGrinder,
          coffeeBeans,
          openingHours,
          top_features,
          highlights,
          logo,
        },
      } = this.props;
      if (lat && lng) {
        profile.lat = lat;
        profile.lng = lng;
      }
      profile.city = city;
      profile.state = state;
      profile.country = country;
      profile.coffee_options = coffee_options;
      profile.coffee_machine = coffeeMachine;
      profile.coffee_grinder = coffeeGrinder;
      profile.coffee_bean = coffeeBeans;
      profile.openingHours = openingHours;
      profile.top_features = top_features;
      profile.highlights = highlights;
      profile.logo = logo;

      this.props.createUserProfile(profile);
    }
  };

  render() {
    const isAuthorizationStep = Boolean(storage.storageGet('step'));

    return (
      <ScrollToTopContainer>
        <AuthWrapper>
          <HeaderContent>
            <a
              href="https://www.rwrdapp.com/"
              style={{
                textDecoration: 'none',
              }}
            >
              <HeaderLogo src={logo} />
            </a>
            {[
              '/auth/signin',
              '/auth/forgot-password',
              '/auth/forgot-password-sent',
            ].includes(this.props.history.location.pathname) ? (
              <a
                href="https://www.rwrdapp.com/"
                style={{
                  textDecoration: 'none',
                }}
              >
                <Text
                  onClick={this.logout}
                  style={{
                    color: mainTheme.colors.white,
                    fontFamily: 'Raleway',
                    fontSize: 18,
                    letterSpacing: 2.25,
                  }}
                >
                  HOME
                </Text>
              </a>
            ) : (
              <Link
                to="/auth/signin"
                style={{
                  textDecoration: 'none',
                }}
              >
                <Text
                  onClick={this.logout}
                  style={{
                    color: mainTheme.colors.white,
                    fontFamily: 'Raleway',
                    fontSize: 18,
                    letterSpacing: 2.25,
                  }}
                >
                  LOGOUT
                </Text>
              </Link>
            )}
          </HeaderContent>
          <AuthBottomContent>
            <AuthContent
              maxWidth={
                this.props.location.pathname === '/auth/signin'
                  ? '1200px'
                  : '990px'
              }
            >
              <AuthLabelsComponent />
              <>
                <form
                  ref={(form) => (this.profileForm = form)}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 26,
                  }}
                >
                  <PaperComponent style={{ borderRadius: 7, maxWidth: 990 }}>
                    <RowOffsetBottom wrap>
                      <SmallBlock>
                        <RowOffsetBottom>
                          <ProfileViewContainer />
                        </RowOffsetBottom>
                      </SmallBlock>
                      <RightBlock>
                        <ProfileEditingContainer />
                      </RightBlock>
                    </RowOffsetBottom>
                  </PaperComponent>

                  <RowOffsetBottom
                    wrap
                    style={{
                      maxWidth: 990,
                      marginTop: 60,
                      marginBottom: 60,
                      visibility: isAuthorizationStep ? 'visible' : 'hidden',
                      height: isAuthorizationStep ? 'auto' : 0,
                    }}
                  >
                    <StampViewContainer />
                    <StampControlContainer />
                  </RowOffsetBottom>

                  <FromControls>
                    <MainButton type="button" onClick={this.submitProfile}>
                      Save
                    </MainButton>
                  </FromControls>
                </form>
              </>
            </AuthContent>
          </AuthBottomContent>
        </AuthWrapper>
        <FooterContainer />
      </ScrollToTopContainer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  temp: selectTemp,
  user: selectUserObject,
  isLogin: selectUserLogin,
  step: selectUserRegistrationStep,
});

const mapDispatchToProps = {
  createUserProfile: profileActions.fetchCreatePending,
  fetchMe: userActions.fetchMePending,
  logout: authActions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthProfile);

const HeaderContent = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 65px;
  margin: 0 auto;
  margin-top: 25px;
  padding: 0 25px;
  align-items: center;
  z-index: 2;
`;

const HeaderLogo = styled.img`
  height: 41px;
`;
