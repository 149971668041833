import React from 'react';

import AddonsItem from '../AddonsItem/AddonsItem';

import {
  StyledDots,
  StyledItem,
  StyledRadioInput2,
  StyledSlider,
  Styledtestimonials,
} from './styles';

const OneItemsCarousel = ({
  items,
  getAddonData,
  handleCarouselActiveItem,
  handlePopup,
}) => {
  const addon0 = getAddonData(items[0]);

  return (
    <StyledSlider>
      <StyledRadioInput2 type="radio" name="testimonial" id="t-2" checked />
      <Styledtestimonials className="testimonials">
        <StyledItem
          isActive
          left
          for="t-2"
          onClick={() => {
            handleCarouselActiveItem(items[0]);
          }}
        >
          <AddonsItem
            isActive
            item={items[0]}
            badge={addon0[0].card.badge}
            title={addon0[0].card.title}
            price={addon0[0].card.price}
            term={addon0[0].card.period}
            tags={addon0[0].card.tags}
            handlePopup={handlePopup}
            handleCarouselActiveItem={handleCarouselActiveItem}
          />
        </StyledItem>
      </Styledtestimonials>
      <StyledDots className="dots">
        <label
          htmlFor="t-2"
          onClick={() => {
            handleCarouselActiveItem(items[0]);
          }}
        />
      </StyledDots>
    </StyledSlider>
  );
};

export default OneItemsCarousel;
