import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Title, SplitBarWrapper } from './styles';

class SplitBar extends Component {
  static defaultProps = {
    title: 'SplitBar Title',
    data: [
      { title: 'Left', perc: 50 },
      { title: 'Right', perc: 50 },
    ],
  };

  static propTypes = {
    title: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({ title: PropTypes.string, perc: PropTypes.number })
    ),
  };

  render() {
    const { title, data } = this.props;
    const [left, right] = data;
    return (
      <div>
        <Title style={{ justifyContent: 'flex-start' }}>{title}</Title>
        <SplitBarWrapper>
          <Title
            noMargin
            style={{ width: `${left.perc}%`, minWidth: `17%` }}
            data-tip={`${left.perc.toFixed(0)}%`}
          >
            {left.title}
          </Title>
          <Title
            color="#FFFFFF"
            noMargin
            style={{
              width: `${right.perc}%`,
              backgroundColor: '#474d4f',
              minWidth: `17%`,
            }}
            data-tip={`${right.perc.toFixed(0)}%`}
          >
            {right.title}
          </Title>
        </SplitBarWrapper>
      </div>
    );
  }
}

export default SplitBar;
