import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../containers/RouterContainer/RouterContainer';
import storage from '../../utils/storage';
import client from '../../utils/client';

import StepsProgresComponent from '../StepsProgresComponent/StepsProgresComponent';
import MobilePreviewComponent from '../MobilePreviewComponent/MobilePreviewComponent';
import StampControlContainer from '../../containers/StampControlContainer-v2/StampControlContainer';

import profileActions from '../../redux/profile/profile.actions';
import { selectProfileObject } from '../../redux/profile/profile.selectors';

import { AUTH_ROUTS } from '../../constants/routes';

import {
  StyledButton,
  StyledPageWrapper,
  StyledSection,
  StyledTitle,
} from '../AuthSubscriptionPageComponent/styles';
import { StyledLayout } from '../../screens/AuthScreen-v2/styles';
import {
  StyledInputContainer,
  StyledLayoutWrapper,
  StyledSectionWrapper,
} from '../MainComponents/MainComponents';
import {
  StyledProgress,
  StyledProgressBarComponent,
  StyledPageDescription,
  StyledFormWrapper,
} from './styles';

import bgImage from '../../assets/images/backgrounds/phoneBg.png';

const AuthProfileComponentStep7 = () => {
  const step = storage.storageGet('step');
  if (step && step !== AUTH_ROUTS.PROFILE_STEP1) history.push(step);
  if (!step) history.push(AUTH_ROUTS.SIGN_IN);

  const { temp } = useSelector(selectProfileObject);
  const dispatch = useDispatch();

  const defaultValues = {
    stamps: [
      { value: 3, label: '3' },
      { value: 4, label: '4' },
      { value: 5, label: '5' },
      { value: 6, label: '6' },
      { value: 7, label: '7' },
      { value: 8, label: '8' },
      { value: 9, label: '9' },
      { value: 10, label: '10' },
    ],
    textColorOptions: [
      { value: '#ffffff', label: 'White' },
      { value: '#2f2f2f', label: 'Dark' },
    ],
    initialValues: {
      total: 10,
      color: '#2d8c7a',
      backgroundColor: '#faefa7',
      pattern: 26,
      textColor: '#2f2f2f',
    },
  };

  const setStorageStampData = (key, value) => {
    const storageTemp = storage.storageGet('temp');
    if (storageTemp) {
      const temp = JSON.parse(storageTemp);
      const newValue = { ...temp, stamp: { ...temp.stamp, [key]: value } };
      storage.storageSet('temp', JSON.stringify(newValue));
    } else {
      const newValue = { stamp: { [key]: value } };
      storage.storageSet('temp', JSON.stringify(newValue));
    }
  };
  // 	const storageTemp = storage.storageGet('temp');
  // 	if (storageTemp) {
  // 		const values = Object.entries(JSON.parse(storageTemp));
  // 		if (!values) {
  // 			return;
  // 		}
  // 		values.forEach((el) => {
  // 			tempChange(el[0], el[1]);
  // 		});
  // 	}
  // };

  const deleteStorageData = (keys) => {
    const storageTemp = storage.storageGet('temp');
    if (storageTemp) {
      const values = Object.entries(JSON.parse(storageTemp));
      const result = values.filter((el) => !keys.includes(el[0]));
      const newTemp = result.reduce((acc, el) => {
        return { ...acc, [el[0]]: el[1] };
      }, {});
      storage.storageSet('temp', JSON.stringify(newTemp));
    }
  };
  // 	checkStorageData();
  // }, []);

  const handleChange = (key, value) => {
    setStorageStampData(key, value);
    dispatch(profileActions.changeStampTemp(key, value));
  };

  const postProfileData = async () => {
    dispatch(profileActions.isFetching(true));
    const payload = {
      name: temp.name,
      logo: temp.logo,
      backgroundImage: temp.backgroundImage,
      description: temp.description,
      address: temp.address,
      country: temp.country,
      state: temp.state,
      postcode: temp.postcode,
      placeId: temp.placeId,
      coffee_grinder: temp.coffeeGrinder ? temp.coffeeGrinder : null,
      coffee_machine: temp.coffeeMachine ? temp.coffeeMachine : null,
      coffee_bean: temp.coffeeBeans ? temp.coffeeBeans : null,
      coffee_options: temp.coffee_options,
      top_features: temp.top_features,
      stamp: {
        total: temp.stamp.total,
        color: temp.stamp.color,
        backgroundColor: temp.stamp.backgroundColor,
        pattern: temp.stamp.pattern.toString(),
        textColor: temp.stamp.textColor,
      },
      openingHours: {
        monOpen: temp.openingHours.monOpen,
        monClose: temp.openingHours.monClose,
        monOperational: temp.openingHours.monOperational,
        tueOpen: temp.openingHours.tueOpen,
        tueClose: temp.openingHours.tueClose,
        tueOperational: temp.openingHours.tueOperational,
        wedOpen: temp.openingHours.wedOpen,
        wedClose: temp.openingHours.wedClose,
        wedOperational: temp.openingHours.wedOperational,
        thuOpen: temp.openingHours.thuOpen,
        thuClose: temp.openingHours.thuClose,
        thuOperational: temp.openingHours.thuOperational,
        friOpen: temp.openingHours.friOpen,
        friClose: temp.openingHours.friClose,
        friOperational: temp.openingHours.friOperational,
        satOpen: temp.openingHours.satOpen,
        satClose: temp.openingHours.satClose,
        satOperational: temp.openingHours.satOperational,
        sunOpen: temp.openingHours.sunOpen,
        sunClose: temp.openingHours.sunClose,
        sunOperational: temp.openingHours.sunOperational,
      },
      highlights: temp.highlights,
    };

    if (temp.lat && temp.lng) {
      payload.lat = temp.lat;
      payload.lng = temp.lng;
    }
    if (temp.city && temp.city) {
      payload.city = temp.city;
    }

    try {
      await client.post('/profile', payload);
    } catch (error) {
      console.log(error);
    }
    dispatch(profileActions.isFetching(false));
  };

  const setNextPage = () => {
    postProfileData();
  };

  const skipThisPage = () => {
    dispatch(
      profileActions.changeTemp('stamp', {
        total: defaultValues.initialValues.total,
        color: defaultValues.initialValues.color,
        backgroundColor: defaultValues.initialValues.backgroundColor,
        pattern: defaultValues.initialValues.pattern,
        textColor: defaultValues.initialValues.textColor,
      })
    );
    deleteStorageData(['stamp']);
    postProfileData();
  };

  useEffect(() => {
    if (!temp.stamp.total) {
      handleChange('total', 10);
    }
    if (!temp.stamp.color) {
      handleChange('color', '#2d8c7a');
    }
    if (!temp.stamp.backgroundColor) {
      handleChange('backgroundColor', '#faefa7');
    }
    if (!temp.stamp.pattern) {
      handleChange('pattern', 26);
    }
    if (!temp.stamp.textColor) {
      handleChange('textColor', '#2f2f2f');
    }
  }, []);

  return (
    <StyledPageWrapper>
      <StyledLayout height="100%">
        <StyledLayoutWrapper>
          <StyledSection paddingTop={26} paddingBot={26}>
            {/* leftside */}
            <StyledSectionWrapper>
              <StepsProgresComponent currentStep={2} />
              <StyledTitle marginBot={8}>Create your venue profile</StyledTitle>
              <StyledPageDescription>
                More than one site? Don't worry you can add more later!
              </StyledPageDescription>
              <StyledProgressBarComponent>
                <StyledProgress progress={7} />
              </StyledProgressBarComponent>
              <StyledFormWrapper>
                <StyledInputContainer marginBot={24}>
                  <StampControlContainer
                    defaultValues={defaultValues}
                    handleChange={handleChange}
                  />
                </StyledInputContainer>
              </StyledFormWrapper>
            </StyledSectionWrapper>
            <div>
              <StyledButton onClick={skipThisPage}>Skip</StyledButton>
              <StyledButton prymary onClick={setNextPage}>
                Save & Continue
              </StyledButton>
            </div>
          </StyledSection>
          <StyledSection phone paddingTop={56} bgImage={bgImage}>
            <div>
              <MobilePreviewComponent step={3} />
            </div>
          </StyledSection>
        </StyledLayoutWrapper>
      </StyledLayout>
    </StyledPageWrapper>
  );
};

export default AuthProfileComponentStep7;
