import styled from 'styled-components';

import { ReactComponent as DeleteIcon } from '../../../../assets/images/icons/addon-delete.svg';
import { newTheme } from '../../../../mainStyles';

export const StyledCardBadge = styled.div`
  position: absolute;
  top: -1px;
  right: -1px;
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
  padding: 4px 8px;
  background: #f5cc28;
  border-radius: 16px;

  @media (max-width: 1112px) {
    font-size: 0.7rem;
    padding: 2px 4px;
  }
`;

export const StyledCard = styled.div`
  position: relative;
  border-radius: 16px;
  padding: ${({ isActive }) => (isActive ? '40px;' : '28px 16px 16px 16px;')};
  border: 2px solid ${({ isActive }) => (isActive ? '#f5cc28;' : '#DFDFDE;')};
  width: 100%;
  max-width: ${({ isActive }) => (isActive ? '397px;' : '349px;')};
  transition: 0.2s;
  background-color: white;

  @media (max-width: 1112px) {
    padding: ${({ isActive }) => (isActive ? '30px;' : '14px 8px 8px 8px;')};
    max-width: ${({ isActive }) => (isActive ? '380px;' : '283px;')};
  }
  @media (max-width: 900px) {
    padding: ${({ isActive }) => (isActive ? '20px;' : '14px 8px 8px 8px;')};
    max-width: ${({ isActive }) => (isActive ? '306px;' : '283px;')};
  }
`;
export const StyledCardContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 317px;
  border-radius: 16px;
  padding: 8px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    124deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(236, 239, 241, 1) 100%
  );

  ${({ addOn }) => addOn && 'margin-top: 30px;'}

  @media (max-width: 1112px) {
    max-width: 330px;

    ${({ addOn }) => addOn && 'margin-top: 15px;'}
  }
  @media (max-width: 900px) {
    max-width: 260px;

    ${({ addOn }) => addOn && 'margin-top: 15px;'}
  }
`;
export const StyledCardTitle = styled.div`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  margin-bottom: 8px;

  @media (max-width: 1112px) {
    font-size: 1em;
    margin-bottom: 4px;
  }
`;
export const StyledCardValue = styled.div`
  font-family: ${newTheme.fontFamily.primary.regular};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: #90a4ae;
  margin-bottom: 16px;

  ${({ addOn }) => addOn && 'margin-bottom: 0px;'}

  @media (max-width: 1112px) {
    font-size: 1em;
    margin-bottom: 10px;
  }
`;
export const StyledCardPrice = styled.span`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0;
  padding-right: 8px;
  color: #263238;

  @media (max-width: 1112px) {
    font-size: 1.7rem;
  }
`;
export const StyledCardTag = styled.div`
  font-family: ${newTheme.fontFamily.secondary1.medium};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 4px 8px;
  width: fit-content;
  border-radius: 4px;
  background-color: ${({ color }) =>
    color === 'green' ? '#e8f5e9' : color === 'yellow' ? '#FDF2C9' : 'none'};
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1112px) {
    font-size: 0.7rem;
    margin-bottom: 10px;
  }

  ${({ addOn }) => addOn && 'margin-top: 8px;'}
`;

export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  cursor: pointer;
  &:hover {
    svg {
      path {
        stroke: #ff0000;
      }
    }
  }
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  &:hover {
    fill: black;
  }
`;

export const StyledTotalPriceContaner = styled.div`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0;
  text-align: left;

  border: 1px solid #000000;
  border-radius: 16px;
  background: #ffffff;

  padding: 20px;

  margin-top: 30px;

  @media (max-width: 1112px) {
    font-size: 24px;
    line-height: 28px;
    margin-top: 15px;
  }
`;

export const StyledTermSpan = styled.span`
  font-family: ${newTheme.fontFamily.primary.regular};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: #90a4ae;
  margin-left: 10px;
`;
