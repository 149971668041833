import styled from 'styled-components';
import { newTheme } from '../../../../mainStyles';

export const StyledCardContent = styled.div`
  position: relative;
  width: 100%;
  /* max-width: 317px; */
  border-radius: 16px;
  padding: 16px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    124deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(236, 239, 241, 1) 100%
  );

  /* @media (max-width: 1112px) {
		max-width: 330px;

		margin-top: 15px;
	}
	@media (max-width: 900px) {
		max-width: 260px;

		margin-top: 15px;
	} */
`;

export const StyledCardBadge = styled.div`
  position: absolute;
  top: -1px;
  right: -1px;
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
  padding: 4px 8px;
  background: #f5cc28;
  border-radius: 16px;
  z-index: 5;

  @media (max-width: 1112px) {
    font-size: 0.7rem;
    padding: 2px 4px;
  }
`;

export const StyledCardTitle = styled.div`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  margin-bottom: 8px;

  @media (max-width: 1112px) {
    font-size: 1em;
    margin-bottom: 4px;
  }
`;

export const StyledCardValue = styled.div`
  font-family: ${newTheme.fontFamily.primary.regular};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: #90a4ae;
  margin-bottom: 16px;

  margin-bottom: 0px;

  @media (max-width: 1112px) {
    font-size: 1em;
    margin-bottom: 10px;
  }
`;

export const StyledCardPrice = styled.span`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0;
  padding-right: 8px;
  color: #263238;

  @media (max-width: 1112px) {
    font-size: 1.7rem;
  }
`;

export const StyledButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  cursor: pointer;
  &:hover {
    svg {
      path {
        stroke: #ff0000;
      }
    }
  }
`;

export const StyledCardContainer = styled.div`
  position: relative;
  display: flex;
  border: 2px solid #f5cc28;
  border-radius: 16px;
  padding: 16px;
  background-color: white;
  width: 400px;
`;

export const StyledCardTag = styled.div`
  font-family: ${newTheme.fontFamily.secondary1.medium};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 4px 8px;
  width: fit-content;
  border-radius: 4px;
  background-color: ${({ color }) =>
    color === 'green' ? '#e8f5e9' : color === 'yellow' ? '#FDF2C9' : 'none'};
  margin-top: 8px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0px;
  }

  @media (max-width: 1112px) {
    font-size: 0.7rem;
    margin-bottom: 10px;
  }
`;
