import React, { useState } from 'react';
import Switch from 'react-switch';
import Picker from 'react-scrollable-picker';

import { useSelector } from 'react-redux';
import { selectProfileObject } from '../../redux/profile/profile.selectors';

import { StyledTimePickerInput } from '../MainComponents/MainComponents';
import {
  StyledButton,
  StyledButtonsContainer,
  StyledContainer,
  StyledPicker,
  StyledTimeContainer,
  StyledTitle,
  StyledToggleTitle,
  StyledWrapper,
} from './styles';

import clocksIcon from '../../assets/images/icons/clocks.svg';

const days = [
  {
    name: 'Monday',
    key: 'mon',
  },
  {
    name: 'Tuesday',
    key: 'tue',
  },
  {
    name: 'Wednesday',
    key: 'wed',
  },
  {
    name: 'Thursday',
    key: 'thu',
  },
  {
    name: 'Friday',
    key: 'fri',
  },
  {
    name: 'Saturday',
    key: 'sat',
  },
  {
    name: 'Sunday',
    key: 'sun',
  },
];

const optionGroups = {
  hours: [
    { value: '00', label: '00' },
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
  ],
  minutes: [
    { value: '00', label: '00' },
    { value: '05', label: '05' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '20', label: '20' },
    { value: '25', label: '25' },
    { value: '30', label: '30' },
    { value: '35', label: '35' },
    { value: '40', label: '40' },
    { value: '45', label: '45' },
    { value: '50', label: '50' },
    { value: '55', label: '55' },
    { value: '59', label: '59' },
  ],
};

const initialState = {
  hours: '12',
  minutes: '30',
};

const DailyWorkSchedule = ({ handleChange, dayString, dayKey }) => {
  const { temp } = useSelector(selectProfileObject);

  const [openTime, setOpenTime] = useState(initialState);
  const [closeTime, setCloseTime] = useState(initialState);
  const [isOpenPanel, setIsOpenPanel] = useState();
  const [isClosePanel, setIsClosePanel] = useState();

  const handleChangeValue = (name, value, key) => {
    switch (key) {
      case 'Open':
        setOpenTime((state) => ({
          ...state,
          [name]: value,
        }));
        return;
      case 'Close':
        setCloseTime((state) => ({
          ...state,
          [name]: value,
        }));

      default:
        break;
    }
  };

  const handleData = (key) => {
    switch (key) {
      case 'Open':
        handleChange({
          ...temp.openingHours,
          [`${dayKey}${key}`]: `${openTime.hours}${openTime.minutes}`,
        });
        return;
      case 'Close':
        handleChange({
          ...temp.openingHours,
          [`${dayKey}${key}`]: `${closeTime.hours}${closeTime.minutes}`,
        });

      default:
        break;
    }
  };

  const getTime = (key) => {
    const isTime =
      temp.openingHours[`${dayKey}${key}`][0] &&
      temp.openingHours[`${dayKey}${key}`][1] &&
      temp.openingHours[`${dayKey}${key}`][2] &&
      temp.openingHours[`${dayKey}${key}`][3];

    if (!temp.openingHours[`${dayKey}Operational`]) {
      return '';
    }
    if (!isTime) {
      return '';
    }
    return `${temp.openingHours[`${dayKey}${key}`][0]}${
      temp.openingHours[`${dayKey}${key}`][1]
    }:${temp.openingHours[`${dayKey}${key}`][2]}${
      temp.openingHours[`${dayKey}${key}`][3]
    }`;
  };

  return (
    <StyledWrapper>
      <StyledContainer spaceBetween marginBot={16}>
        <StyledTitle>{dayString}</StyledTitle>
        <StyledContainer>
          <StyledToggleTitle>Closed</StyledToggleTitle>
          <div style={{ margin: '0px 16px' }}>
            <Switch
              onChange={() =>
                handleChange({
                  ...temp.openingHours,
                  [`${dayKey}Operational`]:
                    !temp.openingHours[`${dayKey}Operational`],
                })
              }
              handleDiameter={20}
              checked={temp.openingHours[`${dayKey}Operational`]}
              uncheckedIcon={false}
              checkedIcon={false}
              height={24}
              width={48}
              onColor="#221C02"
              offColor="#DFDFDE"
            />
          </div>
          <StyledToggleTitle>Open</StyledToggleTitle>
        </StyledContainer>
      </StyledContainer>

      <StyledTimeContainer>
        <div style={{ position: 'relative', width: '48%' }}>
          <StyledTimePickerInput
            icon={clocksIcon}
            readOnly
            disabled={!temp.openingHours[`${dayKey}Operational`]}
            value={getTime('Open')}
            onClick={() => setIsOpenPanel(true)}
          />
          {isOpenPanel && (
            <StyledPicker>
              <Picker
                itemHeight={46}
                optionGroups={optionGroups}
                valueGroups={openTime}
                onChange={(name, value) =>
                  handleChangeValue(name, value, 'Open')
                }
              />

              <StyledButtonsContainer>
                <StyledButton
                  onClick={() => {
                    setIsOpenPanel(false);
                    handleData('Open');
                  }}
                >
                  Ok
                </StyledButton>
                <StyledButton onClick={() => setIsOpenPanel(false)}>
                  Cancel
                </StyledButton>
              </StyledButtonsContainer>
            </StyledPicker>
          )}
        </div>

        <div style={{ position: 'relative', width: '48%' }}>
          <StyledTimePickerInput
            icon={clocksIcon}
            readOnly
            disabled={!temp.openingHours[`${dayKey}Operational`]}
            value={getTime('Close')}
            onClick={() => setIsClosePanel(true)}
          />
          {isClosePanel && (
            <StyledPicker>
              <Picker
                optionGroups={optionGroups}
                valueGroups={closeTime}
                onChange={(name, value) =>
                  handleChangeValue(name, value, 'Close')
                }
              />

              <StyledButtonsContainer>
                <StyledButton
                  onClick={() => {
                    setIsClosePanel(false);
                    handleData('Close');
                  }}
                >
                  Ok
                </StyledButton>
                <StyledButton onClick={() => setIsClosePanel(false)}>
                  Cancel
                </StyledButton>
              </StyledButtonsContainer>
            </StyledPicker>
          )}
        </div>
      </StyledTimeContainer>
    </StyledWrapper>
  );
};

const OpeningTimesComponent = ({ handleChange }) => {
  return (
    <>
      {days.map((day) => (
        <DailyWorkSchedule
          key={day.key}
          handleChange={handleChange}
          dayString={day.name}
          dayKey={day.key}
        />
      ))}
    </>
  );
};

export default OpeningTimesComponent;
