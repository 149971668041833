import { createAction } from 'redux-actions';

// ##FETCH_SUBSCRIPTION
export const fetchSubscriptionPending = createAction(
  'payments--fetchSubscriptionPending'
);
export const fetchSubscriptionSuccess = createAction(
  'payments--fetchSubscriptionSuccess'
);
export const fetchSubscriptionFailed = createAction(
  'payments--fetchSubscriptionFailed'
);

// ##SUBSCRIBE
export const fetchSubscribePending = createAction(
  'payments--fetchSubscribePending',
  (payload) => payload
);
export const fetchSubscribeSuccess = createAction(
  'payments--fetchSubscribeSuccess',
  (payload) => payload
);
export const fetchSubscribeFailed = createAction(
  'payments--fetchSubscribeFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);
// ##CREATE_CARD
export const fetchCreateStripeCardPending = createAction(
  'payments--fetchCreateStripeCardPending',
  (token) => ({
    token,
  })
);
export const fetchCreateStripeCardSuccess = createAction(
  'payments--fetchCreateStripeCardSuccess'
);
export const fetchCreateStripeCardFailed = createAction(
  'payments--fetchCreateStripeCardFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);
// ##UPDATE_CARD
export const fetchUpdateStripeCardPending = createAction(
  'payments--fetchUpdateStripeCardPending',
  (token) => ({
    token,
  })
);
export const fetchUpdateStripeCardSuccess = createAction(
  'payments--fetchUpdateStripeCardSuccess'
);
export const fetchUpdateStripeCardFailed = createAction(
  'payments--fetchUpdateStripeCardFailed',
  (errors = {}, message = '') => ({
    errors,
    message,
  })
);

const paymentsActions = {
  // ##FETCH_SUBSCRIPTION
  fetchSubscriptionFailed,
  fetchSubscriptionPending,
  fetchSubscriptionSuccess,
  // ##SUBSCRIBE
  fetchSubscribePending,
  fetchSubscribeSuccess,
  fetchSubscribeFailed,
  // ##CREATE_CARD
  fetchCreateStripeCardPending,
  fetchCreateStripeCardSuccess,
  fetchCreateStripeCardFailed,
  // ##UPDATE_CARD
  fetchUpdateStripeCardPending,
  fetchUpdateStripeCardSuccess,
  fetchUpdateStripeCardFailed,
};
export default paymentsActions;
