import styled from 'styled-components';
import {
  Row,
  RowOffsetBottom,
  FieldLabel,
} from '../../components/MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';
import { NavIcon } from '../SideBarContainer/styles';
import hamburger from '../../assets/images/icons/menu.svg';

export const HeaderContainerWrapper = styled(RowOffsetBottom)`
  padding: ${mainTheme.offset.small}px ${mainTheme.offset.xlarge}px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${mainTheme.sizes.large}px) {
    padding: ${mainTheme.offset.small}px ${mainTheme.offset.small}px;
  }
`;

export const HeaderIcon = styled(NavIcon)`
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right: 0;
`;

export const HeaderControlSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${mainTheme.offset.xlarge}px;
`;
export const HeaderHideOnMobile = styled(HeaderControlSection)`
  @media (max-width: ${mainTheme.sizes.small}px) {
    display: none;
  }
`;

export const HeaderText = styled(FieldLabel)`
  margin: 0 ${mainTheme.offset.xsmall}px;
`;
export const HeaderControls = styled(Row)`
  justify-content: flex-end;
  align-items: center;
`;
export const ToggleSidebarIcon = styled(HeaderIcon).attrs({
  src: hamburger,
})`
  display: none;
  @media (max-width: ${mainTheme.sizes.large}px) {
    display: block;
  }
`;
