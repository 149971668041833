import React, { PureComponent } from 'react';

export default class HiddenInput extends PureComponent {
  static defaultProps = {
    onClear() {
      console.log(`Trying to reset:  unhandled input`);
    },
  };

  reset = () => {
    this.props.onClear();
  };

  render() {
    return (
      <input
        type="hidden"
        className="hiddenInput"
        onClick={this.reset}
        name={this.props.name}
        value={this.props.value}
      />
    );
  }
}
