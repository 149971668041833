import { fork, takeEvery, takeLatest } from 'redux-saga/effects';
import appActions from './app.actions';
import { saveUser } from '../auth/auth.saga';
import userActions from '../user/user.actions';
import client from '../../utils/client';
import { history } from '../../containers/RouterContainer/RouterContainer';

function onInit() {
  try {
    const params = new URLSearchParams(window.location.search);

    const [token, user, step] = [
      params.get('token'),
      params.get('user'),
      params.get('step'),
    ];
    if (token) {
      client.setToken(token);
    }
    if (user) {
      const parsed = JSON.parse(user);
      saveUser(parsed);
      userActions.fetchMeSuccess(parsed);
      // setTimeout(() => history.push('/dashboard'), 1000);
      if (step) {
        history.push(step);
      } else {
        history.push('/dashboard');
      }
    }
  } catch (error) {}
}

function* AppFlow() {
  yield takeEvery(appActions.init, onInit);
}

function* appWorker() {
  yield fork(AppFlow);
}

export default appWorker;
