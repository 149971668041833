import DropNCrop from '@synapsestudios/react-drop-n-crop';
import styled from 'styled-components';
import ReactDOM from 'react-dom';

import React from 'react';
import {
  PaperComponent,
  Row,
  MainButton,
  Column,
} from '../MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';

const initialCropState = {
  result: null,
  filename: null,
  filetype: null,
  src: null,
  error: null,
};

const PICKER_ROOT = document.getElementById('image-picker-root');
const initialState = {
  open: false,
  ...initialCropState,
};
class DropNCropComponent extends React.Component {
  static defaultProps = {
    image: '',
    aspectRatio: 1 / 1,
    onClose: () => {},
    onSelect: () => {},
  };

  state = {
    ...initialState,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.image && !state.src && !state.open) {
      return {
        ...state,
        src: props.image,
      };
    }
    return state;
  }

  onChange = (value) => {
    this.setState(value);
  };

  onSelect = () => {
    this.props.onSelect(this.state.result);
    this.close();
  };

  close = () => {
    this.setState({ open: false, ...initialState });
    this.props.onClose();
  };

  open = () => {
    if (this.state.src) {
      this.setState({ ...initialCropState });
    }
    this.setState({ open: true });
  };

  render() {
    if (!this.state.open) return null;
    return ReactDOM.createPortal(
      <Wrapper>
        <BackDrop onClick={this.close} />
        <PaperComponent
          style={{ borderRadius: 0, zIndex: 2, maxWidth: '100%' }}
        >
          <DropNCrop
            cropperOptions={{
              aspectRatio: this.props.aspectRatio,
            }}
            onChange={this.onChange}
            value={this.state}
            maxFileSize={20971520}
          />
          <Row style={{ justifyContent: 'flex-end', marginTop: 25 }}>
            <MainButton
              disabled={!this.state.result}
              onClick={this.onSelect}
              style={{
                marginBottom: 0,
                width: 175,
                borderRadius: 0,
                backgroundColor: this.state.result
                  ? mainTheme.colors.primary
                  : mainTheme.colors.grey,
              }}
            >
              DONE
            </MainButton>
          </Row>
        </PaperComponent>
      </Wrapper>,
      PICKER_ROOT
    );
  }
}

export default DropNCropComponent;

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const BackDrop = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(40, 40, 40, 0.4);
`;
