import styled from 'styled-components';
import { Column, Text } from '../MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';
import phoneImage from '../../assets/images/phone_view.png';
import defaultCoverPhoto from '../../assets/images/default/default-cover-photo.png';

export const ProfileViewMobile = styled.div`
  margin-top: ${mainTheme.offset.xlarge}px;
  width: 100%;
  background-image: url(${phoneImage});
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 380px;
  min-width: 380px;
  height: 600px;
  padding: 55px 13px 55px 13px;
  margin-left: 50px;
  align-self: center;

  overflow: hidden;

  z-index: 9;
`;

export const ProfileViewComponentWrapper = styled(Column)`
  width: 100%;
  background-color: ${mainTheme.colors.white};
  max-width: 240px;
  margin-top: 10px;
  margin-left: 25px;

  height: 480px;
  overflow: hidden;
  z-index: 9;
  border-radius: 0% 0% 10% 10%;
`;

export const ProfileScrollWrapper = styled(Column)`
  width: 100%;
  max-width: 240px;
  height: 490px;
  overflow: hidden;
  overflow-y: scroll;
  ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

export const ProfileViewOffset = styled.div`
  padding: 7px;
`;
export const ProfileViewBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 130px;
  width: 100%;
  background-image: url(${(props) => props.image || defaultCoverPhoto});
  background-size: cover;
  background-repeat: no-repeat;
  & div {
    color: ${mainTheme.colors.white} !important;
  }
`;

export const ProfileViewContent = styled.div``;
export const ProfileViewDescription = styled(Text)`
  font-family: 'Avenir Heavy';
  line-height: normal;
  font-weight: 800;
  word-break: break-word;
  color: rgb(64, 64, 64);
  font-size: 10px;
  text-transform: none;
  letter-spacing: normal;
  margin: 0px;
`;

export const ProfilePostsWrapper = styled.div`
  flex-direction: column;
`;

export const ProfilePostWrapper = styled.div`
  margin-bottom: ${mainTheme.offset.medium}px;
`;
