import React, { Component } from 'react';
import styled from 'styled-components';
import { mainTheme } from '../../mainStyles';
import logoDark from '../../assets/images/icons/logo-teapot.png';

export default class LogoComponent extends Component {
  render() {
    return (
      <LogoWrapper>
        <LogoImage />
        <LogoText>RWRD</LogoText>
      </LogoWrapper>
    );
  }
}

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img.attrs({
  src: logoDark,
})`
  width: 23px;
  height: 28px;
  margin-right: 16px;
`;
const LogoText = styled.h2`
  color: ${mainTheme.colors.textDark};
  font-family: Raleway;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 4.06px;
  text-transform: uppercase;
`;
