import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  PostComponentWrapper,
  PostImage,
  PostTitle,
  PostSubtitle,
  PostRow,
  PostDeleteIcon,
} from './styles';
import { Column } from '../MainComponents/MainComponents';
import ShortProfileComponent from '../ShortProfileComponent/ShortProfileComponent';

export default class PostComponent extends Component {
  static defaultProps = {
    onPostClick: (post) => {},
    // onPostDelete: _id => console.log('Deleted post: ', _id),
    backdrop: true,
  };

  static propTypes = {
    address: PropTypes.string,
    logo: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    backdrop: PropTypes.bool,
  };

  onPostClick = () => {
    this.props.onPostClick({
      address: this.props.address,
      title: this.props.title,
      description: this.props.description,
      image: this.props.image,
      _id: this.props._id,
    });
  };

  onPostDelete = () => this.props.onPostDelete(this.props._id);

  renderDescription = () => {
    const { description } = this.props;

    return <PostSubtitle>{description}</PostSubtitle>;
  };

  render() {
    return (
      <PostComponentWrapper
        backdrop={this.props.backdrop}
        onClick={this.onPostClick}
      >
        <ShortProfileComponent
          rate={4}
          title={this.props.name}
          subtitle={this.props.address}
          image={this.props.logo}
        />
        <PostImage image={this.props.image} />
        <PostRow>
          <Column>
            <PostTitle>{this.props.title}</PostTitle>
            {this.renderDescription()}
          </Column>
        </PostRow>

        {this.props.onPostDelete ? (
          <PostDeleteIcon onClick={this.onPostDelete} />
        ) : null}
      </PostComponentWrapper>
    );
  }
}
