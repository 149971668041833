import styled from 'styled-components';
import { Column } from '../MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';

export const LoaderWrapper = styled.div`
  position: relative;
  flex: 1;
  /* display: flex; */
  z-index: 100;
  margin-bottom: 24px;
`;

export const LoaderBackdrop = styled.div`
  z-index: 100;
  background-color: ${(props) =>
    props.backdrop ? 'rgba(250, 250, 250, 0.6)' : 'transparent'};
  filter: ${(props) => (props.backdrop ? 'blur(2px)' : 'blur(0px)')};
  transition: 0.4s;
  /* display: flex;
	flex: 1;
	flex-wrap: wrap; */
  &::after {
    content: '';
    display: ${(props) => (props.backdrop ? 'block' : 'none')};
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
  }
`;
const imageWidth = 100;

const wrapperWidth = 140;
const wrapperHeight = 105;

export const LoaderImagesWrapper = styled(Column)`
  position: absolute;
  text-align: center;
  width: initial;
  left: 50%;
  z-index: 100;
  margin-left: -${wrapperWidth / 2}px;
  top: 50%;
  margin-top: -${wrapperHeight / 2}px;
`;
export const LoaderImage = styled.img`
  height: 10px;
  width: ${imageWidth}px;
  margin: 0 auto;
`;

export const LoaderLogoImage = styled.img`
  width: ${wrapperWidth}px;
  margin: 0 auto ${mainTheme.offset.small}px;
  height: 33px;
`;
