import { createGlobalStyle } from 'styled-components';
import postImage from './assets/images/default/post.png';
import avenirFont from './assets/fonts/Avenir/AvenirNextLTPro-Regular.woff2';
import avenirBoldFont from './assets/fonts/Avenir/AvenirNextLTPro-Bold.woff2';
import avenirMediumFont from './assets/fonts/Avenir/AvenirNextLTPro-Medium.woff2';
import avenirHeavyFont from './assets/fonts/Avenir/AvenirNextLTPro-Medium.woff2';
import avenirLightFont from './assets/fonts/Avenir/AvenirNextLTPro-Light.woff2';
import helveticaFont from './assets/fonts/Helvetica/Helvetica.ttf';
import ralewayFont from './assets/fonts/Raleway/Raleway-Regular.ttf';
import GeneralSansBold from './assets/fonts/GeneralSansVariable/GeneralSans-Bold.woff2';
import GeneralSansRegular from './assets/fonts/GeneralSansVariable/GeneralSans-Regular.woff2';
import GeneralSansLight from './assets/fonts/GeneralSansVariable/GeneralSans-Light.woff2';
import GeneralSansMedium from './assets/fonts/GeneralSansVariable/GeneralSans-Medium.woff2';
import GeneralSansSemibold from './assets/fonts/GeneralSansVariable/GeneralSans-Semibold.woff2';
import LexendMedium from './assets/fonts/Lexend/Lexend-Medium.ttf';
import OutfitMedium from './assets/fonts/Outfit/Outfit-Medium.ttf';
import OutfitLight from './assets/fonts/Outfit/Outfit-Light.ttf';

export const mainTheme = {
  colors: {
    primary: '#404040',
    background: '#FBFBFB',
    foreground: '#F5F5F5',
    grey: '#BBBBBB',
    danger: '#D14757',
    light: '#F8F8F8',
    success: '#4DC84B',
    text: '#878787',
    textDark: '#212121',
    white: '#ffffff',
    black: '#000000',
    google: '#D44837',
    facebook: '#3B5999',
    main: '#f7f7f7',
    backdrop: 'rgba(255,255,255, 0.5)',
    lightgrey: '#dfe6e9',
    blue: '#7687DB',
    greyMid: '#ADB3C4',
    greyScheme: [
      // '#191B1C',
      '#2D3436',
      '#474D4F',
      '#6e7f80',
      '#536872',
      '#708090',
      '#536878',
      '#36454f',
      '#636E72',
      '#626768',
      '#7C8081',
      '#969A9B',
      '#B2BEC3',
      '#C5CED2',
      '#D8DFE1',
      '#DFE6E9',
    ],
  },
  offset: {
    xsmall: 12,
    small: 20,
    medium: 26,
    large: 32,
    xlarge: 48,
  },
  radius: {
    xsmall: 3,
    small: 6,
    medium: 12,
    large: 20,
    xlarge: 28,
    rounded: '50%',
  },
  sizes: {
    xsmall: 320,
    small: 560,
    medium: 768,
    large: 991,
    xlarge: 1400,
  },

  fontSizes: {
    xsmall: 10,
    small: 16,
    input: 16,
    button: 20,
    text: 16,
    medium: 20,
  },
  fontFamily: {
    primary: 'Avenir',
    bold: 'Avenir Bold',
    medium: 'Avenir Medium',
    light: 'Avenir light',
    helvetica: 'Helvetica',
    generalSense: 'General Sense Variable',
    generalSenseItalic: 'General Sense Variable Italic',
  },
  fontWeight: {
    normal: 300,
    bold: 600,
  },
  images: {
    postImage,
    default:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADCCAMAAAB6zFdcAAAAY1BMVEXDw8MAAADIyMhwcHCKioqkpKS8vLyurq7KysqQkJCAgIB4eHh7e3tra2uenp6qqqq2trZISEgXFxe+vr5ZWVlCQkJUVFQODg7Pz89PT0+GhoZmZmYvLy8eHh6Tk5MoKCgzMzNxC9pTAAACOklEQVR4nO3b6W6CQBhGYWZsdayKuNS2dtH7v8qKIJszNkAT48t5fhuSOYAfAkYRAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADAwNj+7r2E3l5Gfb3cewk92anpb/rYR4J9+ocGT4/fIB73EUs0eO6zBPus0aDXBqQaWNdlyik1cJPZdhG79hvQaeDm52/4j/YbkGlQXCbs2h4JQg2KWb9suwGZBsuiwazlemQa2LhosBhqgyj587o3NDd1GrjXS4ON/3Munifeleo0KA6EH/9y3CL9eeibGUINbLI6rfIQuEiyP9nM8KxVqMFpVyfLJHTS50fJt28DSg1ufeiYnynb68NkIA3OXwb51LiKMIwGlYsHYybN5Q6jwbh62+wwyAblpcPZZ+NsGEIDN2vcQG1cQWg2sLayq9MlNozrn1Zs4OaHXXlZvHm7anCsnQ2KDdyouq/dx1UCY0bVCIIN8kH4mq3K7T0JjIkraxZscBmEs3Rf24k3Qe3HpV6DchCmD17sOtBgVZ4Ncg0qg/BtE7ldIIEx8yKCWoPaIFy933ocWzyfU2uwqR37oxsJzLrYgFYD7yAMuTyI0GoQGIQh+yyCVIPgIAzJfkZLNYi+WzbI7qwpNXDblgnyO2tCDTq9m5W+iyXUIPl7xR6JUgP71anBl9Vp4D47JUjvrKk0cN1f1Jw6kQZ20p1IA97R7HwalB69Ae9sR7y7n+I/HAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAK39Au/PGUolQi5+AAAAAElFTkSuQmCC',
  },
  animation: {
    xSlow: 1000,
    slow: 800,
    medium: 600,
    fast: 400,
    xFast: 200,
  },
};

export const newTheme = {
  fontFamily: {
    primary: {
      light: 'General Sans Light',
      regular: 'General Sans Regular',
      medium: 'General Sans Medium',
      semiBold: 'General Sans Semibold',
      bold: 'General Sans Bold',
    },
    secondary1: {
      medium: 'Lexend Medium',
    },
    secondary2: {
      light: 'Outfit Light',
      medium: 'Outfit Medium',
    },
  },
};

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
	transition: 0.2s;
  }
  body, html {
    scroll-behavior: smooth;
  }
  body {
    display: flex;
    flex-direction: column;
  }
  body, html, div, span, ul, li, button, input , p, h1,h2,h3,h4,h5,h6{
      padding: 0;
      margin: 0;
      font-family: Avenir;
  }
 	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:active,
	input:-webkit-autofill:focus {
		background-color: #FFFFFF !important;
		color: #131312 !important;
		-webkit-box-shadow: 0 0 0 1000px white inset !important;
		-webkit-text-fill-color: #131312 !important;
	}
	div:-webkit-autofill,
	div:-webkit-autofill:hover,
	div:-webkit-autofill:active,
	div:-webkit-autofill:focus {
		background-color: #FFFFFF !important;
		color: #131312 !important;
		-webkit-box-shadow: 0 0 0 1000px white inset !important;
		-webkit-text-fill-color: #131312 !important;
	}
  p {
    font-family: Avenir;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1.56px;
  }
  strong, b {
    font-family: 'Avenir Bold';
  }

  .text-medium {
    font-family: ${mainTheme.fontFamily.medium}
  }
  .row {
    display: flex;
    flex-direction: row;
  }
  .column {
    display: flex;
    flex-direction: column;
  }
  .color-primary {
    color: ${mainTheme.colors.primary};
  }
  .i {
    width: 16px;
    height: 16px;
    color: ${mainTheme.colors.primary}
  }

  @font-face {
    font-family: Avenir;
    src: url(${avenirFont});
  }
  @font-face {
    font-family: 'Avenir Medium';
    src: url(${avenirMediumFont});
  }
  @font-face {
    font-family: 'Avenir Bold';
    src: url(${avenirBoldFont});
  }
  @font-face {
    font-family: 'Avenir Light';
    src: url(${avenirLightFont});
  }
  @font-face {
    font-family: 'Avenir Heavy';
    src: url(${avenirHeavyFont});
  }
  @font-face {
    font-family: Raleway;
    src: url(${ralewayFont});
  }
  @font-face {
    font-family: Helvetica;
    src: url(${helveticaFont});
  }
  @font-face {
    font-family: 'General Sans Bold';
    src: url(${GeneralSansBold});
  }

  @font-face {
    font-family: 'General Sans Regular';
    src: url(${GeneralSansRegular});
  }
  
  @font-face {
    font-family: 'General Sans Light';
    src: url(${GeneralSansLight});
  }

  @font-face {
    font-family: 'General Sans Medium';
    src: url(${GeneralSansMedium});
  }
  @font-face {
    font-family: 'General Sans Semibold';
    src: url(${GeneralSansSemibold});
  }
  @font-face {
    font-family: 'Lexend Medium';
    src: url(${LexendMedium});
  }
  @font-face {
    font-family: 'Outfit Medium';
    src: url(${OutfitMedium});
  }
  @font-face {
    font-family: 'Outfit Light';
    src: url(${OutfitLight});
  }

`;
