import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import ColorPickerComponent from '../../components/ColorPickerComponent/ColorPickerComponent';
import {
  FieldLabel,
  Column,
  InputMain,
  RightBlock,
  HalfContentWrapper,
  HalfContent,
  BoardComponent,
} from '../../components/MainComponents/MainComponents';
import DropDownComponent from '../../components/DropDownComponent/DropDownComponent';
import DropDownImagePickerComponent from '../../components/DropDownImagePickerComponent/DropDownImagePickerComponent';
import {
  selectTemp,
  selectProfileStamp,
  selectProfileErrors,
  selectProfilePending,
} from '../../redux/profile/profile.selectors';
import { patterns } from '../../constants/patterns';
import profileActions from '../../redux/profile/profile.actions';

const textColorOptions = [
  { value: '#ffffff', label: 'White' },
  { value: '#2f2f2f', label: 'Dark' },
];

export class StampControlContainer extends Component {
  state = {
    total: 10,
    color: '#2d8c7a',
    backgroundColor: '#faefa7',
    pattern: '26',
    textColor: '#2f2f2f',
  };

  componentDidUpdate(prevProps) {
    const { color, backgroundColor, pattern, textColor } = this.props;
    if (color && color !== this.state.color) {
      this.setState({ color });
    }
    if (backgroundColor && backgroundColor !== this.state.backgroundColor) {
      this.setState({ backgroundColor });
    }
    if (pattern && pattern !== this.state.pattern) {
      this.setState({ pattern });
    }
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
    this.props.handleChange(key, value);
  };

  getTotal = () => {
    const {
      total,
      temp: { stamp },
    } = this.props;
    let t = '';
    if (total) {
      t = total;
    }
    if (stamp.total) {
      t = stamp.total;
      if (stamp.total > 10) {
        t = 10;
      }
      // if (stamp.total < 3) {
      // 	t = 3;
      // }
    }
    return t;
  };

  getTextColor = () => {
    const {
      textColor,
      temp: { stamp },
    } = this.props;
    let color = textColor;
    if (stamp.textColor) {
      color = stamp.textColor;
    }
    return color;
  };

  render() {
    const patternOptions = [...patterns.slice(-1), ...patterns.slice(0, -1)];
    return (
      <RightBlock as={BoardComponent} style={{ position: 'relative' }}>
        <HalfContentWrapper>
          <HalfContent offset="xsmall">
            <Column>
              <FieldLabel>TOTAL STAMPS</FieldLabel>
              <InputMain
                type="number"
                error={'total' in this.props.errors}
                max="10"
                min="3"
                onChange={(event) =>
                  this.handleChange('total', event.target.value)
                }
                name="stamp.total"
                placeholder="3-10"
                value={this.getTotal()}
              />
            </Column>
          </HalfContent>
          <HalfContent offset="xsmall">
            <Column>
              <FieldLabel>TEXT COLOUR</FieldLabel>
              <DropDownComponent
                error={'textColor' in this.props.errors}
                onSelect={(event) =>
                  this.handleChange('textColor', event.value)
                }
                defaultValue={
                  textColorOptions.filter(
                    (option) => option.value === this.getTextColor()
                  )[0]
                }
                name="stamp.textColor"
                placeholder="Text Colour"
                options={textColorOptions}
                value={
                  textColorOptions.filter(
                    (option) => option.value === this.getTextColor()
                  )[0]
                }
                extractor="value"
              />
            </Column>
          </HalfContent>
          <HalfContent offset="xsmall">
            <Column>
              <FieldLabel>STAMP COLOUR</FieldLabel>
              <ColorPickerComponent
                defaultValue={this.state.color}
                name="stamp.color"
                onChange={(value) => this.handleChange('color', value)}
                error={'color' in this.props.errors}
              />
            </Column>
          </HalfContent>
        </HalfContentWrapper>
        <HalfContentWrapper>
          <HalfContent offset="xsmall">
            <Column>
              <FieldLabel>BACKGROUND PATTERN</FieldLabel>
              <DropDownImagePickerComponent
                defaultValue={this.state.pattern}
                name="stamp.pattern"
                onChange={(value) => this.handleChange('pattern', value)}
                error={'pattern' in this.props.errors}
                options={patternOptions}
                placeholder="Total Stamps"
              />
            </Column>
          </HalfContent>
          <HalfContent offset="xsmall">
            <Column>
              <FieldLabel>BACKGROUND COLOUR</FieldLabel>
              <ColorPickerComponent
                error={'backgroundColor' in this.props.errors}
                onChange={(value) =>
                  this.handleChange('backgroundColor', value)
                }
                defaultValue={this.state.backgroundColor}
                name="stamp.background"
              />
            </Column>
          </HalfContent>
        </HalfContentWrapper>
      </RightBlock>
    );
  }
}

const stampControlSelector = createSelector(
  selectTemp,
  selectProfileErrors,
  selectProfileStamp,
  selectProfilePending,
  (temp, errors, stamp, pending) => ({
    temp,
    errors,
    ...stamp,
    pending,
  })
);

const mapStateToProps = stampControlSelector;

const mapDispatchToProps = {
  handleChange: profileActions.changeStampTemp,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StampControlContainer);
