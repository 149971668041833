import React from 'react';

import { AuthForm } from '../../screens/AuthScreen-v2/styles';
import { StyledLink } from '../../screens/AuthScreen/styles';
import {
  Column,
  StyledLogoContainer,
  StyledFormTitle,
  StyledFormDescription,
  StyledInputContainer,
  StyledInputLabel,
  StyledInput,
  StyledLine,
  StyledLinesBlock,
  StyledButton,
  StyledContainer,
  StyledLinkContainer,
  StyledLinesTitle,
  StyledIconImage,
} from '../MainComponents/MainComponents';
import MessageComponent from '../MessageComponent/MessageComponent';
import logo2 from '../../assets/images/image4.svg';
import lock from '../../assets/images/icons/lock.svg';
import email from '../../assets/images/icons/еmail.svg';
import google from '../../assets/images/icons/google.svg';
import { StyledRememberContainer } from './styles';
import { AUTH_ROUTS } from '../../constants/routes';

const SigninFormComponent = ({
  signIn,
  signInFacebook,
  signInGoogle,
  errors,
  message,
}) => {
  return (
    <AuthForm style={{ borderRadius: 4 }} fullWidth width={555}>
      <Column
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* <AuthButton type={'button'} background={'facebook'} onClick={signInFacebook}>
					Log in with Facebook
				</AuthButton>
				<AuthButton type={'button'} background={'google'} onClick={signInGoogle}>
					Log in with Google
				</AuthButton>

				<Divider /> */}
        <StyledLogoContainer>
          <img src={logo2} alt="logo" />
        </StyledLogoContainer>

        <div>
          <StyledFormTitle>Log in to RWRD</StyledFormTitle>
          <StyledFormDescription>
            Enter your details below
          </StyledFormDescription>
        </div>
        <form style={{ width: '100%' }} onSubmit={signIn}>
          <StyledInputContainer marginBot={24}>
            <StyledInputLabel>Email</StyledInputLabel>
            <StyledInput
              // icon={email}
              type="email"
              errors={errors.username}
              name="email"
              placeholder="Your email"
            />
            <StyledIconImage src={email} alt="email" />
          </StyledInputContainer>
          <StyledInputContainer marginBot={8}>
            <StyledInputLabel>Password</StyledInputLabel>
            <StyledInput
              // icon={lock}
              placeholder="Your password"
              name="password"
              type="password"
              errors={errors.password}
            />
            <StyledIconImage src={lock} alt="lock" />
          </StyledInputContainer>
          <MessageComponent type="danger" text={message} />
          <StyledRememberContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                type="checkbox"
                name="rememberMe"
              />
              Remember me
            </div>
            <div>
              <StyledLink to="forgot-password">Forgot password?</StyledLink>
            </div>
          </StyledRememberContainer>
          <StyledButton bgColor="#f5cc28" type="submit">
            Log in
          </StyledButton>
        </form>

        <StyledLinesBlock>
          <StyledLine />
          <StyledLinesTitle>OR</StyledLinesTitle>
          <StyledLine />
        </StyledLinesBlock>
        <StyledButton onClick={signInGoogle}>
          <StyledContainer>
            <img src={google} alt="" />
            <span>Continue with Google</span>
          </StyledContainer>
        </StyledButton>
        <StyledLinkContainer>
          Don’t have an account?{' '}
          <StyledLink to={AUTH_ROUTS.SIGN_UP}>Register</StyledLink>
        </StyledLinkContainer>
      </Column>
    </AuthForm>
  );
};

export default SigninFormComponent;
