import React, { useState, useEffect, useRef } from 'react';
import { GoogleApiWrapper } from 'google-maps-react';

import { isStyledComponent } from 'styled-components';
import { InputMain } from '../MainComponents/MainComponents';

let autoComplete;

function handleScriptLoad(updateQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current
  );
  // autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener('place_changed', () =>
    handlePlaceSelect(updateQuery)
  );
}

async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace();
  updateQuery(addressObject);
}

const PlaceAutoCompleteComponent = (props) => {
  const { placeholder, onChange } = props;
  const [query, setQuery] = useState('');
  const autoCompleteRef = useRef(null);

  const changeAddress = (addressObject) => {
    setQuery(addressObject.formatted_address);
    const address = addressObject.address_components;
    const placeInfo = {
      address: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
      placeId: addressObject.place_id,
      lat: addressObject.geometry
        ? addressObject.geometry.location.lat()
        : 51.5,
      lng: addressObject.geometry
        ? addressObject.geometry.location.lng()
        : 0.12,
      openingHours: [
        { day: 0, operational: true, open: '', close: '' },
        { day: 1, operational: true, open: '', close: '' },
        { day: 2, operational: true, open: '', close: '' },
        { day: 3, operational: true, open: '', close: '' },
        { day: 4, operational: true, open: '', close: '' },
        { day: 5, operational: true, open: '', close: '' },
        { day: 6, operational: true, open: '', close: '' },
      ],
    };
    let street_number = '';
    let route = '';
    let sublocality_level_1 = '';
    if (address) {
      address.map((item) => {
        if (item.types.includes('street_number'))
          street_number = item.long_name;

        if (item.types.includes('route')) route = item.long_name;

        if (item.types.includes('sublocality_level_1'))
          sublocality_level_1 = item.long_name;

        if (item.types.includes('locality')) placeInfo.city = item.long_name;
        if (item.types.includes('postal_town')) placeInfo.city = item.long_name;

        if (item.types.includes('administrative_area_level_1'))
          placeInfo.state = item.long_name;

        if (item.types.includes('country')) placeInfo.country = item.short_name;

        if (item.types.includes('postal_code'))
          placeInfo.postalCode = item.long_name;
      });
    }

    if (addressObject.opening_hours && addressObject.opening_hours.periods) {
      if (
        addressObject.opening_hours.periods.length == 1 &&
        !addressObject.opening_hours.periods[0].close
      ) {
        placeInfo.openingHours = [
          { day: 0, operational: true, open: '0000', close: '0000' },
          { day: 1, operational: true, open: '0000', close: '0000' },
          { day: 2, operational: true, open: '0000', close: '0000' },
          { day: 3, operational: true, open: '0000', close: '0000' },
          { day: 4, operational: true, open: '0000', close: '0000' },
          { day: 5, operational: true, open: '0000', close: '0000' },
          { day: 6, operational: true, open: '0000', close: '0000' },
        ];
      } else {
        const dayMap = {
          0: 'closed',
          1: 'closed',
          2: 'closed',
          3: 'closed',
          4: 'closed',
          5: 'closed',
          6: 'closed',
        };
        addressObject.opening_hours.periods.map((item) => {
          let day = 0;
          const close = item.close ? item.close.time : '0000';
          const open = item.open ? item.open.time : '0000';
          item.open.day == 0 ? (day = 6) : (day = parseInt(item.open.day) - 1);

          dayMap[day] = 'open';

          if (item.open && !item.close) {
            dayMap[day] = '24hr';
          }

          const obj = { day, operational: true, open, close };
          placeInfo.openingHours[day] = obj;
        });
        for (let i = 0; i < 7; i++) {
          if (dayMap[i] == 'closed') {
            placeInfo.openingHours[i].operational = false;
          }
        }
      }
      const tempTimes = {};
      placeInfo.openingHours.map((item) => {
        switch (item.day) {
          case 0:
            tempTimes.monOpen = item.open;
            tempTimes.monClose = item.close;
            tempTimes.monOperational = item.operational;
            break;
          case 1:
            tempTimes.tueOpen = item.open;
            tempTimes.tueClose = item.close;
            tempTimes.tueOperational = item.operational;
            break;
          case 2:
            tempTimes.wedOpen = item.open;
            tempTimes.wedClose = item.close;
            tempTimes.wedOperational = item.operational;
            break;
          case 3:
            tempTimes.thuOpen = item.open;
            tempTimes.thuClose = item.close;
            tempTimes.thuOperational = item.operational;
            break;
          case 4:
            tempTimes.friOpen = item.open;
            tempTimes.friClose = item.close;
            tempTimes.friOperational = item.operational;
            break;
          case 5:
            tempTimes.satOpen = item.open;
            tempTimes.satClose = item.close;
            tempTimes.satOperational = item.operational;
            break;
          case 6:
            tempTimes.sunOpen = item.open;
            tempTimes.sunClose = item.close;
            tempTimes.sunOperational = item.operational;
            break;
          default:
            break;
        }
      });
      placeInfo.openingHours = tempTimes;
    } else {
      placeInfo.openingHours = {
        monOpen: '',
        monClose: '',
        monOperational: false,
        tueOpen: '',
        tueClose: '',
        tueOperational: false,
        wedOpen: '',
        wedClose: '',
        wedOperational: false,
        thuOpen: '',
        thuClose: '',
        thuOperational: false,
        friOpen: '',
        friClose: '',
        friOperational: false,
        satOpen: '',
        satClose: '',
        satOperational: false,
        sunOpen: '',
        sunClose: '',
        sunOperational: false,
      };
    }

    placeInfo.address = street_number;
    if (placeInfo.address != '' && route != '')
      placeInfo.address += `, ${route}`;
    else placeInfo.address += route;

    if (placeInfo.address != '' && sublocality_level_1 != '')
      placeInfo.address += `,${sublocality_level_1}`;
    else placeInfo.address += sublocality_level_1;

    onChange(placeInfo);
  };
  useEffect(() => {
    handleScriptLoad(changeAddress, autoCompleteRef);
  }, []);

  return (
    <InputMain
      ref={autoCompleteRef}
      placeholder={placeholder}
      value={query}
      onChange={(event) => setQuery(event.target.value)}
    />
  );
};

export default GoogleApiWrapper((props) => ({
  apiKey: 'AIzaSyAh9u99l2pJYHOcERlMEQSfyIJpJ4l2Agw',
  libraries: ['places'],
}))(PlaceAutoCompleteComponent);
// export default PlaceAutoCompleteComponent;
