import React from 'react';
import { Route, Switch, Redirect } from 'react-router/esm/react-router';
import { useSelector } from 'react-redux';
import ForgotPasswordContainer from '../../containers/ForgotPasswordContainer/ForgotPasswordContainer';
import ResetPasswordContainer from '../../containers/ResetPasswordContainer/ResetPasswordContainer';
import SigninContainer from '../../containers/SigninContainer-v2/SigninContainer';
import SignupContainer from '../../containers/SignupContainer-v2/SignupContainer';
import ForgotPasswordSent from '../../containers/ForgotPasswordSent-v2/ForgotPasswordSent';
import SubscriptionContainer from '../../containers/SubscriptionsContainer-v2/SubscriptionContainer';
import AuthProfileComponentStep4 from '../../components/AuthProfileComponentStep4/AuthProfileComponentStep4';
import AuthProfileComponentStep2 from '../../components/AuthProfileComponentStep2/AuthProfileComponentStep2';
import AuthProfileComponentStep3 from '../../components/AuthProfileComponentStep3/AuthProfileComponentStep3';
import AuthProfileComponentStep5 from '../../components/AuthProfileComponentStep5/AuthProfileComponentStep5';
import AuthProfileComponentStep6 from '../../components/AuthProfileComponentStep6/AuthProfileComponentStep6';
import AuthProfileComponentStep7 from '../../components/AuthProfileComponentStep7/AuthProfileComponentStep7';
import AuthProfileComponentStep1 from '../../components/AuthProfileComponentStep1/AuthProfileComponentStep1';
import AuthAddonsComponent from '../../components/AuthAddonsComponent/AuthAddonsComponent';
import PaymentsContainer from '../../containers/PaymentsContainer/PaymentsContainer';
import AuthConfirmationComponent from '../../components/AuthConfirmationComponent/AuthConfirmationComponent';
import AuthCongratulationsComponent from '../../components/AuthCongratulationsComponent/AuthCongratulationsComponent';
import AuthProfile from '../../containers/AuthProfile-v2/AuthProfile';
import { AUTH_ROUTS } from '../../constants/routes';
import LoaderComponent from '../../components/LoaderComponent/LoaderComponent';
import { selectSomePending } from '../../redux/app/app.selectors';

export const AuthScreen = () => {
  const pending = useSelector(selectSomePending);
  return (
    <LoaderComponent backdrop loading={pending}>
      <Switch>
        {/* ----new pages---- */}

        <Route path={AUTH_ROUTS.SIGN_IN} component={SigninContainer} />
        <Route path={AUTH_ROUTS.SIGN_UP} component={SignupContainer} />
        <Route
          path={AUTH_ROUTS.FORGOT_PASSWORD}
          component={ForgotPasswordContainer}
        />
        <Route
          path={AUTH_ROUTS.RESET_PASSWORD}
          component={ResetPasswordContainer}
        />
        <Route
          path={AUTH_ROUTS.FORGOT_PASSWORD_SENT}
          component={ForgotPasswordSent}
        />

        <Route
          path={AUTH_ROUTS.SUBSCRIPTIONS}
          component={SubscriptionContainer}
        />

        <Route
          path={AUTH_ROUTS.PROFILE_STEP1}
          component={AuthProfileComponentStep1}
        />
        <Route
          path={AUTH_ROUTS.PROFILE_STEP2}
          component={AuthProfileComponentStep2}
        />
        <Route
          path={AUTH_ROUTS.PROFILE_STEP3}
          component={AuthProfileComponentStep3}
        />
        <Route
          path={AUTH_ROUTS.PROFILE_STEP4}
          component={AuthProfileComponentStep4}
        />
        <Route
          path={AUTH_ROUTS.PROFILE_STEP5}
          component={AuthProfileComponentStep5}
        />
        <Route
          path={AUTH_ROUTS.PROFILE_STEP6}
          component={AuthProfileComponentStep6}
        />
        <Route
          path={AUTH_ROUTS.PROFILE_STEP7}
          component={AuthProfileComponentStep7}
        />

        <Route path={AUTH_ROUTS.ADDONS} component={AuthAddonsComponent} />

        <Route path={AUTH_ROUTS.PAYMENTS} component={PaymentsContainer} />

        <Route
          path={AUTH_ROUTS.CONFIRMATION}
          component={AuthConfirmationComponent}
        />

        <Route
          path={AUTH_ROUTS.CONGRATULATIONS}
          component={AuthCongratulationsComponent}
        />

        {/* ----old pages---- */}

        <Route path={AUTH_ROUTS.PROFILE} component={AuthProfile} />

        <Route
          path="*"
          component={() => <Redirect to={AUTH_ROUTS.SIGN_IN} />}
        />
      </Switch>
    </LoaderComponent>
  );
};
