import React, { Component } from 'react';
import Select, { Creatable } from 'react-select';
import PropTypes from 'prop-types';

import { Dropdown } from '../MainComponents/MainComponents';
import HiddenInput from '../HiddenInput/HiddenInput';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: '#F5F5F5',
    color: '#333',
  }),
};

const CustomSelect = (props) => <Select styles={customStyles} {...props} />;
const CustomCreatable = (props) => (
  <Creatable styles={customStyles} {...props} />
);

export default class DropDownComponent extends Component {
  static propTypes = {
    onSelect: PropTypes.func,
    extractor: PropTypes.string,
  };

  static defaultProps = {
    onSelect: () => {},
    extractor: '_id',
  };

  state = {
    selected: '',
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.selected && props.defaultValue) {
      return {
        ...state,
        selected: props.defaultValue,
      };
    }
    return state;
  }

  onSelect = (option) => {
    this.props.onSelect(option);
    this.setState({ selected: option });
  };

  clear = () => {
    this.setState({ selected: this.props.defaultValue });
  };

  render() {
    const { onSelect, name, ...props } = this.props;
    return (
      <>
        <Dropdown
          value={this.state.selected}
          onChange={this.onSelect}
          as={props.creatable ? CustomCreatable : CustomSelect}
          {...props}
        />
        <HiddenInput
          name={this.props.name}
          value={
            this.state.selected ? this.state.selected[this.props.extractor] : ''
          }
          onClear={this.clear}
        />
      </>
    );
  }
}
