import React, { Component } from 'react';
import styled from 'styled-components';
import { FieldLabel, Row, Column } from '../MainComponents/MainComponents';
import StarsComponent from '../StarsComponent/StarsComponent';
import { mainTheme } from '../../mainStyles';
import defaultUserLogoImage from '../../assets/images/default/default-user-logo.png';

export const Image = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-right: ${mainTheme.offset.xsmall}px;
  background-image: url(${(props) => props.image || defaultUserLogoImage});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const ProfileRow = styled(Row)`
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Title = styled(FieldLabel)`
  margin: 0;
  word-break: break-word;
`;

export const Subtitle = styled(Title)`
  font-size: 10px;
  text-transform: none;
  letter-spacing: normal;
`;

export const ShortProfileComponentWrapper = styled(Row)`
  padding: ${mainTheme.offset.xsmall}px;
`;
export default class ShortProfileComponent extends Component {
  static defaultProps = {
    rate: 4,
    title: '',
    subtitle: '',
    image: '',
    textColor: '#2f2f2f',
  };

  render() {
    return (
      <ShortProfileComponentWrapper>
        <Image image={this.props.image} />
        <Column style={{ justifyContent: 'space-around' }}>
          <Title style={{ color: this.props.textColor }}>
            {this.props.title}
          </Title>
          <StarsComponent count={this.props.rate} />
          <Subtitle style={{ color: this.props.textColor }}>
            {this.props.subtitle}
          </Subtitle>
        </Column>
      </ShortProfileComponentWrapper>
    );
  }
}
