import styled from 'styled-components';
import { newTheme } from '../../mainStyles';

export const StyledCardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  min-height: 385px;
  margin-top: 90px;

  /* @media (max-width: 927px) {
		margin-top: 0px;
	} */
`;

export const StyledCarouselContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-top: 90px;

  @media (max-width: 927px) {
    margin-top: 0px;
  }
`;

export const StyledBadge = styled.div`
  border-radius: 40px;
  font-family: ${newTheme.fontFamily.primary.semiBold};
  font-weight: 500;
  margin-left: 8px;
  color: #4caf50;
  background-color: #e8f5e9;
  padding: 0 15px;
  font-size: 12px;
  align-items: center;
  display: flex;
`;
export const StyledButton = styled.button`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  padding: 16px 36px;
  border: 0;
  border-radius: 4px;
  margin-right: 16px;
  z-index: 10;
  cursor: pointer;

  background: ${({ prymary }) => (prymary ? '#F5CC28' : '#efefef;')};

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: ${({ prymary }) => (prymary ? '#fedc54' : '#f3f3f3;')};
  }
`;

export const StyledAccordion = styled.div``;

export const StyledSection = styled.div`
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  ${({ phone }) => phone && 'justify-content: flex-start;'};
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  padding: 0 30px;

  padding-top: ${({ paddingTop }) => paddingTop && `${paddingTop}px;`};
  padding-bottom: ${({ paddingBot }) => paddingBot && `${paddingBot}px;`};
  padding-left: ${({ paddingLeft }) => paddingLeft && `${paddingLeft}px;`};
  padding-right: ${({ paddingRight }) => paddingRight && `${paddingRight}px;`};

  ${({ bgImage }) =>
    bgImage && `background: url(${bgImage}) 0 0/cover no-repeat;`};

  @media (max-width: 925px) {
    padding: 25px 5px;
  }
`;

export const StyledTitle = styled.div`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: ${({ marginBot }) => (marginBot ? `${marginBot}px` : '48px')};
`;

export const StyledTabs = styled.div`
  border-radius: 40px;
  font-family: ${newTheme.fontFamily.primary.medium};
  border: 1px solid #eceff1;
  display: flex;
  padding: 8px;
`;

export const StyledTab = styled.div`
  display: flex;
  border-radius: 40px;
  font-family: ${newTheme.fontFamily.primary.medium};
  border: 1px solid ${({ term }) => (term ? '#F5CC28' : '#eceff1')};
  padding: 8px 16px;
  cursor: pointer;
  margin-right: 5px;
  &:last-child {
    margin-right: 0px;
  }
  background: ${({ term }) => term && '#F5CC28'};
`;
export const StyledTabTitle = styled.div`
  border-radius: 40px;
  font-family: ${newTheme.fontFamily.primary.medium};

  @media (max-width: 927px) {
    font-size: 12px;
  }
`;

export const StyledPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
