import { createSelector } from 'reselect';

const rootSelector = (state) => state.profile;

export const selectProfileErrors = createSelector(
  rootSelector,
  (profile) => profile.errors
);

export const selectProfileMessage = createSelector(
  rootSelector,
  (profile) => profile.message
);

export const selectProfilePending = createSelector(
  rootSelector,
  (profile) => Boolean(profile.pending) || Boolean(profile.pendingAnalytics)
);

export const selectProfileDataPending = createSelector(
  rootSelector,
  (profile) => Boolean(profile.pending)
);

export const selectProfileAnalyticsPending = createSelector(
  rootSelector,
  (profile) => Boolean(profile.pendingAnalytics)
);

export const selectTemp = createSelector(
  rootSelector,
  (profile) => profile.temp
);

export const selectProfileShortName = createSelector(rootSelector, (profile) =>
  profile.logo ? profile.logo : profile.name.slice(0, 1)
);

export const selectProfileObject = createSelector(rootSelector, (profile) => ({
  _id: profile._id,
  logo: profile.logo,
  name: profile.name,
  address: profile.address,
  description: profile.description,
  backgroundImage: profile.backgroundImage,
  stamp: {
    total: profile.stamp.total,
    color: profile.stamp.color,
    backgroundColor: profile.stamp.backgroundColor,
    pattern: profile.stamp.pattern,
    textColor: profile.stamp.textColor,
  },
  postcode: profile.postcode,
  city: profile.city,
  state: profile.state,
  country: profile.country,
  lat: profile.lat,
  lng: profile.lng,
  temp: profile.temp,
  allCoffeeOptions: profile.allCoffeeOptions,
  allCoffeeGrinders: profile.allCoffeeGrinders,
  allCoffeeMachines: profile.allCoffeeMachines,
  allCoffeeBeans: profile.allCoffeeBeans,
  allTopFeatures: profile.allTopFeatures,
  coffeeMachine: profile.coffee_machine,
  coffeeGrinder: profile.coffee_grinder,
  coffeeBeans: profile.coffee_beans,
  selectedCoffeeOptions: profile.coffee_options,
  selectedTopFeatures: profile.top_features,
  openingHours: profile.openingHours,
  highlights: profile.highlights,
  placeId: profile.googlePlaceID,
}));
export const selectCoffeeOptions = createSelector(
  rootSelector,
  (profile) => profile.allCoffeeOptions
);
export const selectCoffeeBeans = createSelector(
  rootSelector,
  (profile) => profile.allCoffeeBeans
);
export const selectCoffeeMachines = createSelector(
  rootSelector,
  (profile) => profile.allCoffeeMachines
);
export const selectCoffeeGrinders = createSelector(
  rootSelector,
  (profile) => profile.allCoffeeGrinders
);
export const selectTopFeatures = createSelector(
  rootSelector,
  (profile) => profile.allTopFeatures
);

const marge = (data, temp) =>
  Object.entries(data).reduce((acc, [key, value]) => {
    if (key === 'stamp') {
      acc[key] = marge(value, temp.stamp);
      return acc;
    }
    acc[key] = temp[key] || value;
    return acc;
  }, {});

export const selectProfileCurrent = createSelector(
  selectTemp,
  selectProfileObject,
  (temp, profile) => {
    const current = marge(profile, temp);

    return current;
  }
);

export const selectProfileStamp = createSelector(
  selectProfileObject,
  (profile) => profile.stamp
);

export const selectProfileAnalytics = createSelector(
  rootSelector,
  (profile) => profile.analytics
);
