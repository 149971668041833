export const chartPeriods = [
  // {
  // 	id: 1,
  // 	label: 'Today'
  // },
  {
    id: 2,
    label: 'This Week',
  },
  {
    id: 3,
    label: 'This Month',
  },
  {
    id: 4,
    label: 'Total',
  },
];
