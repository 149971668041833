import React from 'react';
import styled from 'styled-components';
import SubscriptionCardComponent from '../SubscriptionCardComponent/SubscriptionCardComponent';

const TwoCardsCarousel = ({
  carouselActiveItemId,
  carouselItemsIds,
  getsubscriptionData,
  handleActiveCarouselItemId,
}) => {
  const item0 = getsubscriptionData(carouselItemsIds[0]);
  const item1 = getsubscriptionData(carouselItemsIds[1]);
  return (
    <StyledSlider>
      <StyledRadioInput2
        type="radio"
        name="testimonial"
        id="t-2"
        checked={carouselActiveItemId === carouselItemsIds[0]}
      />
      <StyledRadioInput3
        type="radio"
        name="testimonial"
        id="t-3"
        checked={carouselActiveItemId === carouselItemsIds[1]}
      />
      <Styledtestimonials className="testimonials">
        <StyledItem
          isActive={carouselActiveItemId === carouselItemsIds[0]}
          left
          for="t-2"
          onClick={() => {
            handleActiveCarouselItemId(carouselItemsIds[0]);
          }}
        >
          <SubscriptionCardComponent
            isActive={carouselActiveItemId === carouselItemsIds[0]}
            title={item0.card.title}
            price={item0.card.price}
            period={item0.period}
            badge={item0.card.badge}
            tags={item0.card.package_tags}
            handleActiveCarouselItemId={handleActiveCarouselItemId}
            subscriptionId={carouselItemsIds[0]}
          />
        </StyledItem>
        <StyledItem
          isActive={carouselActiveItemId === carouselItemsIds[1]}
          right
          for="t-3"
          onClick={() => {
            handleActiveCarouselItemId(carouselItemsIds[1]);
          }}
        >
          <SubscriptionCardComponent
            isActive={carouselActiveItemId === carouselItemsIds[1]}
            title={item1.card.title}
            price={item1.card.price}
            period={item1.period}
            badge={item1.card.badge}
            tags={item1.card.package_tags}
            handleActiveCarouselItemId={handleActiveCarouselItemId}
            subscriptionId={carouselItemsIds[1]}
          />
        </StyledItem>
      </Styledtestimonials>
      <StyledDots className="dots">
        <label
          htmlFor="t-2"
          onClick={() => {
            handleActiveCarouselItemId(carouselItemsIds[0]);
          }}
        />
        <label
          htmlFor="t-3"
          onClick={() => {
            handleActiveCarouselItemId(carouselItemsIds[1]);
          }}
        />
      </StyledDots>
    </StyledSlider>
  );
};

export default TwoCardsCarousel;

export const StyledSlider = styled.div`
  width: 100%;
  max-width: 660px;
  min-height: 385px;
  input {
    display: none;
  }

  @media (max-width: 927px) {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const Styledtestimonials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 375px;
  perspective: 500px;
  overflow: hidden;

  @media (max-width: 1112px) {
    min-height: 290px;
  }
  @media (max-width: 927px) {
    width: 100%;
  }
`;

export const StyledItem = styled.label`
  top: 0;
  ${({ left }) => left && 'left: 0;'};
  ${({ right }) => right && 'right: 0;'};
  position: absolute;
  background-color: #fff;
  width: 397px;
  transition: transform 0.2s;
  transform-style: preserve-3d;
  user-select: none;
  cursor: pointer;

  @media (max-width: 1112px) {
    width: 306px;
  }

  @media (max-width: 927px) {
    right: auto;
    left: auto;
    top: auto;
    width: 283px;
  }
`;

export const StyledMyCard = styled.div`
  height: 100%;
  width: 100%;
  max-width: 24rem;
`;

export const StyledCardTitle = styled.p`
  font-weight: bold;
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
  text-align: center;
`;

export const StyledCardImg = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
  max-height: 6rem;
  border-radius: 9999px;
  width: 100px;
  border-radius: 50%;
  border: 13px solid #3b344d;
`;

export const StyledCardDescription = styled.p`
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  text-align: center;
`;

export const StyledDots = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    display: block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #413b52;
    margin: 7px;
    transition: transform 0.2s, color 0.2s;
  }

  @media (max-width: 927px) {
    flex-direction: column-reverse;
  }
`;

export const StyledRadioInput2 = styled.input`
  &:checked ~ .dots label[for='t-2'] {
    transform: scale(2);
  }
  &:checked ~ .dots label[for='t-3'] {
    transform: scale(1.5);
    background-color: #d9d9d9;
  }
  &:checked ~ .testimonials label[for='t-2'] {
    z-index: 3;
  }
  &:checked ~ .testimonials label[for='t-3'] {
    transform: translateX(80px) translateZ(-90px) translateY(0px);
    z-index: 2;

    @media (max-width: 1112px) {
      transform: translateX(55px) translateZ(-90px) translateY(0px);
    }

    @media (max-width: 927px) {
      transform: translateX(0px) translateZ(-90px) translateY(-90px);
    }
  }
`;

export const StyledRadioInput3 = styled.input`
  &:checked ~ .dots label[for='t-2'] {
    transform: scale(1.5);
    background-color: #d9d9d9;
  }
  &:checked ~ .dots label[for='t-3'] {
    transform: scale(2);
  }
  &:checked ~ .testimonials label[for='t-2'] {
    transform: translateX(-44px) translateZ(-90px) translateY(-0px);

    @media (max-width: 1112px) {
      transform: translateX(-30px) translateZ(-90px) translateY(0px);
    }
    @media (max-width: 927px) {
      transform: translateX(0px) translateZ(-90px) translateY(100px);
    }
  }
  &:checked ~ .testimonials label[for='t-3'] {
    z-index: 3;
  }
`;
