import React, { Component } from 'react';
import { ChromePicker, CirclePicker } from 'react-color';
import PropTypes from 'prop-types';
import {
  ColorPickerWrapper,
  PickedContainer,
  SelectedColor,
  ColorPickerInput,
  PickerCover,
} from './styles';
import HiddenInput from '../HiddenInput/HiddenInput';

const isMobile =
  window.navigator.userAgent.match(/Android/i) ||
  window.navigator.userAgent.match(/webOS/i) ||
  window.navigator.userAgent.match(/iPhone/i) ||
  window.navigator.userAgent.match(/iPad/i) ||
  window.navigator.userAgent.match(/iPod/i) ||
  window.navigator.userAgent.match(/BlackBerry/i) ||
  window.navigator.userAgent.match(/Windows Phone/i);

export default class ColorPickerComponent extends Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    name: PropTypes.string,
    picker: PropTypes.string,
    colors: PropTypes.array,
  };

  static defaultProps = {
    onChange: () => {},
    picker: 'chrome',
    colors: ['#ffffff', '#2f2f2f'],
  };

  state = {
    color: '',
    open: false,
  };

  componentDidMount() {
    if (isMobile) {
      document.addEventListener('touchstart', this.handleClickOutside);
    } else {
      document.addEventListener('mousedown', this.handleClickOutside);
    }
  }

  componentWillUnmount() {
    if (isMobile) {
      document.removeEventListener('touchstart', this.handleClickOutside);
    } else {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }

  handleClickOutside = (event) => {
    if (this.colorPicker && !this.colorPicker.contains(event.target)) {
      if (this.state.open) {
        this.close();
      }
    }
  };

  // static getDerivedStateFromProps = (props, state) => {
  // 	if (!state.color && props.defaultValue) {
  // 		return {
  // 			...state,
  // 			color: props.defaultValue
  // 		};
  // 	}
  // 	return state;
  // };

  clear = () => {
    this.setState({ open: false, color: this.props.defaultValue });
  };

  onSelect = (color) => {
    this.setState({ color: color.hex });
    this.props.onChange(color.hex);
  };

  open = () => {
    this.setState({ open: true });
  };

  close = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <ColorPickerWrapper bgColor={this.state.color || this.props.defaultValue}>
        {this.state.open && (
          <>
            <PickerCover />
            <PickedContainer
              picker={this.props.picker}
              ref={(picker) => (this.colorPicker = picker)}
            >
              {this.props.picker === 'circle' ? (
                <CirclePicker
                  colors={this.props.colors}
                  color={this.state.color || this.props.defaultValue}
                  onChangeComplete={this.onSelect}
                />
              ) : (
                <ChromePicker
                  color={this.state.color || this.props.defaultValue}
                  onChangeComplete={this.onSelect}
                  disableAlpha
                />
              )}
            </PickedContainer>
          </>
        )}
        <HiddenInput
          name={this.props.name}
          value={this.state.color || this.props.defaultValue}
          onClear={this.clear}
        />
        <ColorPickerInput error={this.props.error} onClick={this.open}>
          <SelectedColor
            bgColor={this.state.color || this.props.defaultValue}
          />
        </ColorPickerInput>
      </ColorPickerWrapper>
    );
  }
}
