import styled from 'styled-components';
import { newTheme } from '../../mainStyles';

export const StyledTitle = styled.div`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
`;

export const StyledToggleTitle = styled.div`
  font-family: ${newTheme.fontFamily.primary.regular};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
`;

export const StyledButton = styled.button`
  font-family: ${newTheme.fontFamily.primary.regular};
  font-size: 13px;
  background: white;
  border: none;
  color: black;
  cursor: pointer;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 15px 5px;
`;

export const StyledPicker = styled.div`
  position: absolute;
  background: white;
  border: 1px solid black;
  border-radius: 4px;
  width: 200px;
  z-index: 5;
  bottom: -73px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  ${({ spaceBetween }) => spaceBetween && 'justify-content: space-between;'}
  border-radius: 4px;
  background: #fafafa;
  margin-bottom: 8px;
  color: #131312;
`;

export const StyledContainer = styled.div`
  display: flex;
  ${({ column }) => column && 'flex-direction: column'}
  ${({ spaceBetween }) => spaceBetween && 'justify-content: space-between;'}
	${({ marginBot }) => marginBot && `margin-bottom: ${marginBot}px;`}
`;

export const StyledTimeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
