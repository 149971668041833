import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Column, Row } from '../../components/MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';
import closeIcon from '../../assets/images/icons/close.svg';

const sideBarWidth = 290;
export const SideBarWrapper = styled(Column)`
  padding: 0;
  max-width: ${sideBarWidth}px;
  background-color: ${mainTheme.colors.white};
  height: 100%;
  position: relative;
  transition: 0.5s;
  @media (max-width: ${mainTheme.sizes.large}px) {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 101;
    transform: translateX(${(props) => (props.active ? 0 : -sideBarWidth)}px);
  }
`;

export const SideBarCloseIcon = styled.img.attrs({
  src: closeIcon,
})`
  width: 16px;
  height: 16px;
  position: absolute;
  right: 20px;
  top: 20px;
  display: none;
  cursor: pointer;
  @media (max-width: ${mainTheme.sizes.large}px) {
    display: flex;
  }
`;

export const SideBarBackDrop = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 200;
  background-color: ${mainTheme.colors.backdrop};
`;

export const SideBarLogo = styled(Row)`
  align-items: center;
  justify-content: center;
  padding: 34px 0;
`;
export const SideBarLinksWrapper = styled(Column)``;

export const SideBarNavLink = styled(NavLink).attrs({
  activeClassName: 'active',
})`
  padding: ${mainTheme.offset.small}px 0 ${mainTheme.offset.small}px 65px;
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: flex-start;
  background-color: ${mainTheme.colors.white};
  color: ${mainTheme.colors.primary};
  &.active {
    color: ${mainTheme.colors.foreground};
    background-color: ${mainTheme.colors.main};
  }
`;

export const NavIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: ${mainTheme.offset.small}px;
`;

export const SideBarNavLinkLabel = styled.p`
  color: ${mainTheme.colors.primary};
  font-family: 'Avenir Medium';
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 2.14px;
`;
