import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  ColumnOffsetBottom,
  MainTitleDark,
  MobileContent,
  Text,
} from '../../components/MainComponents/MainComponents';
import StampComponent from '../../components/StampComponent/StampComponent';
import { mainTheme } from '../../mainStyles';
import {
  selectProfileCurrent,
  selectTemp,
} from '../../redux/profile/profile.selectors';
import { StampViewWrapper } from './styles';

class StampViewContainer extends Component {
  render() {
    return (
      <StampViewWrapper as={MobileContent}>
        <ColumnOffsetBottom>
          <MainTitleDark>Card Design</MainTitleDark>
          <Text color={mainTheme.colors.grey}>
            Customise your loyalty card.
          </Text>
        </ColumnOffsetBottom>
        <StampComponent
          background={this.props.stamp.backgroundColor || '#faefa7'}
          stamp_color={this.props.stamp.color || '#2d8c7a'}
          pattern={this.props.stamp.pattern || '26'}
          title={this.props.name}
          subtitle={this.props.address}
          image={this.props.logo}
          total={this.props.stamp.total || 10}
          textColor={this.props.stamp.textColor || '#2f2f2f'}
        />
      </StampViewWrapper>
    );
  }
}

const mapStateToProps = selectProfileCurrent;

export default connect(mapStateToProps)(StampViewContainer);
