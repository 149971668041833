import React from 'react';
import { AuthForm } from '../../screens/AuthScreen-v2/styles';

import logo2 from '../../assets/images/image4.svg';
import google from '../../assets/images/icons/google.svg';
import emailIcon from '../../assets/images/icons/еmail.svg';
import {
  Column,
  StyledButton,
  StyledContainer,
  StyledFormDescription,
  StyledFormTitle,
  StyledInput,
  StyledInputContainer,
  StyledInputLabel,
  StyledLine,
  StyledLinesBlock,
  StyledLinesTitle,
  StyledLinkContainer,
  StyledLogoContainer,
} from '../MainComponents/MainComponents';
import MessageComponent from '../MessageComponent/MessageComponent';
import { StyledLink } from '../../screens/AuthScreen/styles';
import { AUTH_ROUTS } from '../../constants/routes';

const ForgotPasswordComponent = ({ resetPassword, email, errors, message }) => {
  return (
    <AuthForm fullWidth as="form" onSubmit={resetPassword} width={555}>
      <Column
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <StyledLogoContainer>
          <img src={logo2} alt="logo" />
        </StyledLogoContainer>
        <div>
          <StyledFormTitle>Forgot password?</StyledFormTitle>
          <StyledFormDescription>
            Enter your email address below
          </StyledFormDescription>
        </div>
        <StyledInputContainer marginBot={24}>
          <StyledInputLabel>Email</StyledInputLabel>
          <StyledInput
            defaultValue={email}
            icon={emailIcon}
            type="email"
            errors={errors.email}
            name="email"
            placeholder="Your email"
            autoFocus
          />
        </StyledInputContainer>

        <MessageComponent type="danger" text={message} />

        <StyledButton bgColor="#f5cc28" type="submit">
          Send reset link
        </StyledButton>
        <StyledLinesBlock>
          <StyledLine />
          <StyledLinesTitle>OR</StyledLinesTitle>
          <StyledLine />
        </StyledLinesBlock>
        <StyledButton>
          <StyledContainer>
            <img src={google} alt="" />
            <span>Continue with Google</span>
          </StyledContainer>
        </StyledButton>
        <StyledLinkContainer>
          Don’t have an account?{' '}
          <StyledLink to={AUTH_ROUTS.SIGN_UP}>Register</StyledLink>
        </StyledLinkContainer>
      </Column>
    </AuthForm>
  );
};

export default ForgotPasswordComponent;
