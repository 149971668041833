import React from 'react';

import { ReactComponent as DeleteIcon } from '../../../../assets/images/icons/addon-delete.svg';

import { StyledPreviewTitle } from '../../../MainComponents/MainComponents';
import {
  StyledButton,
  StyledCard,
  StyledCardBadge,
  StyledCardContent,
  StyledCardPrice,
  StyledCardTag,
  StyledCardTitle,
  StyledCardValue,
  StyledTermSpan,
  StyledTotalPriceContaner,
  StyledWrapper,
} from './styles';

const TotalAddonsComponent = ({
  selectedAddons,
  getAddonData,
  handleRemoveAddon,
  totalPrice,
  badge,
  tags,
  period,
  price,
  title,
}) => {
  const addons = selectedAddons.map((id) => getAddonData(id));

  const addonCards = addons.map((addon) => (
    <StyledCardContent addOn>
      <StyledButton onClick={() => handleRemoveAddon(addon[0].id)}>
        <DeleteIcon />
      </StyledButton>
      <StyledCardTitle>{addon[0].card.title}</StyledCardTitle>
      <StyledCardValue addOn>
        <StyledCardPrice>£{addon[0].card.price}</StyledCardPrice>
        {addon[0].card.period}
      </StyledCardValue>
    </StyledCardContent>
  ));
  return (
    <StyledWrapper>
      <StyledPreviewTitle>Your chosen plan</StyledPreviewTitle>
      <StyledCard isActive>
        {badge && <StyledCardBadge>{badge}</StyledCardBadge>}
        <StyledCardContent>
          <StyledCardTitle>{title}</StyledCardTitle>
          <StyledCardValue>
            <StyledCardPrice>£{price}</StyledCardPrice>
            {period === 1
              ? '/month, excl VAT'
              : period === 12
              ? '/annum, excl VAT'
              : ''}
          </StyledCardValue>
          {tags &&
            tags.map((tag) => (
              <StyledCardTag color={tag.color}>{tag.text}</StyledCardTag>
            ))}
        </StyledCardContent>
        {addonCards}
      </StyledCard>

      <StyledTotalPriceContaner>
        Total: £{totalPrice}{' '}
        <StyledTermSpan>
          {period === 1
            ? '/month, excl VAT'
            : period === 12
            ? '/annum, excl VAT'
            : ''}
        </StyledTermSpan>
      </StyledTotalPriceContaner>
    </StyledWrapper>
  );
};

export default TotalAddonsComponent;
