import styled from 'styled-components';
import { newTheme } from '../../mainStyles';

export const StyledChevronButton = styled.div`
  border: none;
  padding-top: 4px;
  background: transparent;
  margin-right: 20px;

  cursor: pointer;
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;

  width: 450px;

  @media (max-width: 1000px) {
    width: 350px;
  }
`;

export const StyledLogoTitle = styled.div`
  font-family: ${newTheme.fontFamily.primary.light};
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0;
  text-align: center;
  width: 400px;

  margin-top: 64px;
`;

export const StyledTitle = styled.div`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;

  margin-bottom: ${({ marginBot }) => (marginBot ? `${marginBot}px` : '48px')};
`;

export const StyledInputLabel = styled.div`
  font-family: ${newTheme.fontFamily.primary.regular};
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
`;

export const StyledInput = styled.input`
  font-family: ${newTheme.fontFamily.primary.light};
  width: 100%;
  padding: 17px 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  /* color: ${({ value }) => (value ? '#131312' : '#959492')}; */
  color: black;
  /* border: 1px solid #959492; */
  ${({ borderColor }) => borderColor && 'border: #DFDFDE;'}
  margin-bottom: 24px;
  background-color: #fff !important;

  border: 1px solid ${({ errors }) => (errors ? 'red;' : '#DFDFDE;')};
  border: 1px solid
    ${({ borderColor, errors }) =>
      borderColor && errors ? 'red;' : '#DFDFDE;'};
  border: 1px solid
    ${({ errorMessage }) => (errorMessage ? 'red;' : '#DFDFDE;')};

  ${({ icon }) =>
    icon && `background: url(${icon}) no-repeat scroll 23px 15px !important;`}
  ${({ icon, vertical }) =>
    icon &&
    vertical &&
    `background: url(${icon}) no-repeat scroll 23px ${vertical}px; !important`}

	&:hover {
    border: 1px solid black;
  }
  &:focus {
    border: 1px solid black;
    outline: none;
  }
  &:active {
    border: 1px solid black;
  }
  &:focus-visible {
    border: 1px solid black;
    color: black;
  }

  @media (max-width: 450px) {
    ${({ icon }) =>
      icon && `background: url(${icon}) no-repeat scroll 8px 15px;`}
    padding-left: 34px;
  }
`;
export const StyledCardInput = styled(StyledInput)`
  ${({ icon }) =>
    icon && `background: url(${icon}) no-repeat scroll 95% 15px !important;`}
  background-color: #fff !important;
  @media (max-width: 450px) {
    ${({ icon }) =>
      icon && `background: url(${icon}) no-repeat scroll 95% 15px !important;`}
    padding-left: 24px;
  }
`;

export const StyledDescription = styled.div`
  font-family: ${newTheme.fontFamily.primary.light};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;

  color: #5d5d5b;
`;

export const StyledPhoneSection = styled.div`
  font-family: 'Outfit Medium';
  font-size: 10px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0.08em;
  text-align: left;
  color: #959492;

  padding-top: ${({ paddingTop }) => paddingTop && `${paddingTop}px`};
  padding-bottom: ${({ paddingBot }) => paddingBot && `${paddingBot}px`};
  padding-left: ${({ paddingLeft }) => paddingLeft && `${paddingLeft}px`};
  padding-right: ${({ paddingRight }) => paddingRight && `${paddingRight}px`};

  height: ${({ height }) => height && `${height}`};
  max-height: ${({ maxHeight }) => maxHeight && `${maxHeight}`};
  border-bottom: 4px solid #efefef;
`;

export const StyledPhoneLogoImage = styled.div`
  position: absolute;
  bottom: -32px;
  left: 31px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : 'red')};
  background-size: contain;
  background-repeat: no-repeat;
`;

export const StyledPhoneBgImage = styled.div`
  position: relative;
  width: 100%;
  height: 135px;
  background: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : 'gray')};
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 40px 40px 0 0;
`;

export const StyledMobilePhone = styled.div`
  height: 795px;
  width: 390px;
  padding: 15px;
`;

export const StyledPageDescription = styled.div`
  font-family: ${newTheme.fontFamily.primary.light};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: ${({ marginBot }) => (marginBot ? `${marginBot}px` : '24px')};
`;

export const StyledProgressBarComponent = styled.div`
  width: 450px;
  height: 4px;
  background: #efefef;
  margin-bottom: ${({ marginBot }) => (marginBot ? `${marginBot}px` : '48px')};
  @media (max-width: 1000px) {
    width: 350px;
  }
`;

export const StyledFormWrapper = styled.div`
  width: 450px;

  @media (max-width: 1000px) {
    width: 350px;
  }
`;

export const StyledProgress = styled.div`
  background: #221c02;
  height: 100%;
  width: ${({ progress }) => progress && `${(100 / 7) * progress}%`};
`;
