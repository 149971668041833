// This must be the first line in src/index.js
// import './wdyr'; //toggle for wdyr (DEV ONLY)
import 'react-app-polyfill/ie9';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import '@synapsestudios/react-drop-n-crop/lib/react-drop-n-crop.css';
import App from './containers/AppContainer/AppContainer';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: 'https://2e30a6a1336547fcac7bfabbf8f1bcb4@o942173.ingest.sentry.io/5891383',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
