import styled from 'styled-components';
import { newTheme } from '../../../../mainStyles';

export const StyledCloseButton = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;

  @media (max-width: 1207px) {
    top: 15px;
    right: 15px;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const StyledTitleText = styled.div`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #131312;
  margin-bottom: 16px;
`;

export const StyledAddonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 680px;
  flex-grow: 1;
  justify-content: flex-start;
`;

export const StyledLine = styled.div`
  width: 450px;
  background: #221c02;
  height: 4px;
  margin-bottom: 64px;
`;

export const StyledDescriptionText = styled.div`
  font-family: ${newTheme.fontFamily.primary.light};
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  color: #131312;
  margin-bottom: 32px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 40px;
`;

export const StyledAddButton = styled.button`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  padding: 16px 36px;
  border: 0;
  border-radius: 4px;
  z-index: 10;
  cursor: pointer;

  color: #131312;
  background: #f5cc28;

  &:hover {
    background: #fedc54;
  }
`;

export const StyledCancelButton = styled.button`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  padding: 16px 36px;
  border: 0;
  border-radius: 4px;
  margin-right: 16px;
  z-index: 10;
  cursor: pointer;

  color: #131312;
  background: white;
`;

export const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px 16px;
  background: #ffffff;
  border: 1px solid #131312;
  box-shadow: 0px 48px 96px -32px rgba(245, 204, 40, 0.32);
  border-radius: 4px;
  margin-top: 40px;
`;

export const StyledListTitle = styled.div`
  font-family: ${newTheme.fontFamily.primary.medium};
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 32px;
  margin-bottom: 24px;

  color: #131312;
`;

export const StyledList = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, 50%);
`;

export const StyledListItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
`;

export const StyledListItemText = styled.span`
  display: block;
  padding-left: 12px;
  font-family: ${newTheme.fontFamily.primary.regular};
  font-size: 17px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0;
  text-align: left;
  color: #5d5d5b;

  @media (max-width: 1112px) {
    font-size: 1em;
  }
`;
