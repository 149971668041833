import { handleActions, combineActions } from 'redux-actions';
import authActions from './auth.actions';
import { countUp, countDown } from '../../utils/client';

export const authInitialState = {
  message: '',
  errors: {},
  pending: 0,
  action: '',
  successMessage: '',

  email: '',
  first_name: '',
  last_name: '',
  password: '',
  password_confirmation: '',

  rememberMe: false,

  year_agree: true,
  terms_agree: true,
};

const authReducer = handleActions(
  {
    [combineActions(
      authActions.fetchSigninPending,
      authActions.fetchSignupPending
    )]: (state, action) => ({
      ...state,
      action: action.payload.action,
      errors: {},
      message: '',
    }),
    [combineActions(
      authActions.fetchSigninPending,
      authActions.fetchSignupPending,
      authActions.fetchSigninFacebookPending,
      authActions.fetchSigninGooglePending,
      authActions.fetchForgotPasswordPending,
      authActions.fetchResetPasswordPending
    )]: (state, action) => ({
      ...state,
      pending: countUp(state.pending),
      errors: {},
      message: '',
    }),
    [combineActions(
      authActions.fetchSigninFailed,
      authActions.fetchSignupFailed,
      authActions.fetchSigninGoogleFailed,
      authActions.fetchSigninFacebookFailed,
      authActions.fetchForgotPasswordFailed,
      authActions.fetchResetPasswordFailed
    )]: (state, action) => ({
      ...state,
      errors: action.payload.errors,
      message: action.payload.message,
      pending: countDown(state.pending),
    }),
    [combineActions(
      authActions.fetchSigninSuccess,
      authActions.fetchSignupSuccess,
      authActions.fetchSigninFacebookSuccess,
      authActions.fetchSigninGoogleSuccess,
      authActions.fetchForgotPasswordSuccess
    )]: (state, action) => ({
      ...state,
      pending: countDown(state.pending),
      errors: {},
    }),
    [authActions.fetchResetPasswordSuccess]: (state, action) => ({
      ...state,
      pending: countDown(state.pending),
      errors: {},
      successMessage: action.payload.successMessage,
    }),
    [authActions.rememberMe]: (state, action) => {
      return { ...state, rememberMe: action.payload.rememberMe };
    },

    [combineActions(authActions.clearAuthState, authActions.logout)]: () => ({
      ...authInitialState,
    }),
  },
  authInitialState
);

export default authReducer;
