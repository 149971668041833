import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BottomControlPanelWrapper, ClearButton } from './styles';
import { MainButton } from '../MainComponents/MainComponents';
import { mainTheme } from '../../mainStyles';

export default class BottomControlPanel extends Component {
  static propTypes = {
    cancel: PropTypes.bool,
    save: PropTypes.bool,
  };

  static defaultProps = {
    cancel: true,
    save: true,
  };

  render() {
    return (
      <BottomControlPanelWrapper transparent={this.props.transparent}>
        {this.props.cancel && (
          <ClearButton color={mainTheme.colors.light} type="reset">
            CANCEL
          </ClearButton>
        )}
        {this.props.save && <MainButton type="submit">SAVE</MainButton>}
      </BottomControlPanelWrapper>
    );
  }
}
