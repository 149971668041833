import React, { Component } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  selectAuthErrors,
  selectAuthMessage,
  selectAuthPending,
  selectAuthAction,
} from '../../redux/auth/auth.selectors';
import authActions from '../../redux/auth/auth.actions';
import { serializeForm } from '../../utils/selectors';
import { StyledSigninPageWrapper } from './styles';
import SignupFormComponent from '../../components/SignupFormComponent/SignupFormComponent';
import SigninFormComponent from '../../components/SigninFormComponent-v2/SigninFormComponent';
import { StyledLayout } from '../../screens/AuthScreen-v2/styles';
import bgImage from '../../assets/images/backgrounds/signInSignUp.jpg';
import { StyledPageWrapper } from '../../components/AuthSubscriptionPageComponent/styles';
import { history } from '../RouterContainer/RouterContainer';

export class SigninContainer extends Component {
  signIn = (event) => {
    event.preventDefault();
    const values = serializeForm(event.target);
    this.props.fetchSigninPending(values);
  };

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (query.token && query.user) {
      this.props.setAuthInfoFromSocialLogin(
        query.token,
        query.user,
        query.step
      );
    }
  }

  signInFacebook = () => {
    this.props.signInFacebook();
  };

  signInGoogle = () => {
    this.props.signInGoogle();
  };

  render() {
    const { action, errors, message } = this.props;
    return (
      <StyledLayout login bgImage={bgImage}>
        <StyledSigninPageWrapper>
          <SigninFormComponent
            signIn={this.signIn}
            signInFacebook={this.signInFacebook}
            signInGoogle={this.signInGoogle}
            errors={action === 'signin' ? errors : {}}
            message={action === 'signin' ? message : ''}
          />
        </StyledSigninPageWrapper>
      </StyledLayout>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  errors: selectAuthErrors,
  message: selectAuthMessage,
  pending: selectAuthPending,
  action: selectAuthAction,
});

const mapDispatchToProps = {
  fetchSigninPending: authActions.fetchSigninPending,
  clearAuthState: authActions.clearAuthState,
  signInFacebook: authActions.fetchSigninFacebookPending,
  signInGoogle: authActions.fetchSigninGooglePending,
  setAuthInfoFromSocialLogin: authActions.setAuthInfoFromSocialLogin,
  fetchSignupPending: authActions.fetchSignupPending,
};

export default connect(mapStateToProps, mapDispatchToProps)(SigninContainer);
